import logError from 'Helpers/logError';
import ProductResource from 'Resources/Product';

ReactTemplates.upFilters.filterTypes.historyOrderPage = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		const t = window.Tools.$translate;

		this.lang = {
			title: t('default.order'),
			orders: t('default.orders'),
			all: t('default.all'),
			has: t('advancedSearch.has'),
			hasNot: t('advancedSearch.hasNot'),
			stage: t('default.stage'),
			from: t('date.from'),
			to: t('date.to'),
			selectStages: t('filter.history.selectStage'),
			selectProducts: t('filter.history.selectProduct'),
			date: t('default.date'),
			products: t('default.products'),
			orderValue: t('field.order.value'),
			showStagesWithZeroProbability: t('filter.history.showStagesWithZeroProbability')
		};

		const filterValue = this.getFilterValue();
		const values = filterValue.OrderRowProduct.value;
		this.productIdToObject(values);

		this.datePresets = _.map(this.props.filter.presets, function (preset) {
			return {
				id: preset,
				name: t('date.' + preset)
			};
		});

		this.onFromValueChanged = _.debounce(this.onFromValueChanged, 300);
		this.onToValueChanged = _.debounce(this.onToValueChanged, 300);
	},
	onDatePresetChanged: function (event) {
		const value = event.target.value;
		const filterValue = this.getFilterValue();

		if (value !== filterValue.Date.value.preset) {
			this.onChange([
				{ path: 'value.Date.value.preset', value: value },
				{ path: 'value.Date.value.start', value: null },
				{ path: 'value.Date.value.end', value: null }
			]);
		}
	},
	componentDidUpdate: function (prevProps) {
		const filterValue = this.getFilterValue();
		const oldFilterValue = this.getFilterValue(prevProps);

		if (!_.isEqual(oldFilterValue.OrderRowProduct.value, filterValue.OrderRowProduct.value)) {
			this.productIdToObject(filterValue.OrderRowProduct.value);
		}

		if (
			filterValue.Value.value.start !== oldFilterValue.Value.value.start ||
			filterValue.Value.value.end !== oldFilterValue.Value.value.end
		) {
			this.setState({
				from: filterValue.Value.value.start,
				to: filterValue.Value.value.end
			});
		}
	},
	getInitialState: function () {
		const filterValue = this.getFilterValue();
		let showZeroProbabilityStages = false;

		const localStorageShowZeroProbabilityStages = localStorage.getItem(
			'listFilters.history.showZeroProbabilityStages'
		);

		if (localStorageShowZeroProbabilityStages === 'true') {
			showZeroProbabilityStages = true;
		} else if (showZeroProbabilityStages === null) {
			localStorage.setItem('listFilters.history.showZeroProbabilityStages', 'false');
		}
		return {
			from: filterValue.Value.value.start || '',
			to: filterValue.Value.value.end || '',
			showZeroProbabilityStages: showZeroProbabilityStages,
			productValues: []
		};
	},
	toggleShowZeroProbabilityStages: function () {
		this.setState(function (prevState) {
			const newValue = !prevState.showZeroProbabilityStages;

			localStorage.setItem('listFilters.history.showZeroProbabilityStages', newValue.toString());

			return {
				showZeroProbabilityStages: newValue
			};
		});
	},
	onFromValueChangedState: function (e) {
		let value = e.target.value;
		value = value.replace(/[^\d.-]/g, '');

		this.onFromValueChanged(value);
		this.setState({
			from: value
		});
	},
	onFromValueChanged: function (value) {
		this.onChange([{ path: 'value.Value.value.start', value: value }]);
	},
	onToValueChangedState: function (e) {
		let value = e.target.value;
		value = value.replace(/[^\d.-]/g, '');

		this.onToValueChanged(value);
		this.setState({
			to: value
		});
	},
	onToValueChanged: function (value) {
		this.onChange([{ path: 'value.Value.value.end', value: value }]);
	},
	onStageChanged: function (values) {
		this.onChange([{ path: 'value.Stage.value', value: _.pluck(values, 'id') }]);
	},
	onProductChanged: function (values) {
		this.onChange([{ path: 'value.OrderRowProduct.value', value: _.pluck(values, 'id') }], 'product');
	},
	onInactiveFilter: function () {
		this.onChange([{ path: 'inactive', value: true }]);
	},
	onHasOrderTrue: function () {
		this.onChange([
			{ path: 'value.hasOrder', value: true },
			{ path: 'inactive', value: false }
		]);
	},
	onHasOrderFalse: function () {
		this.onChange([
			{ path: 'value.hasOrder', value: false },
			{ path: 'inactive', value: false }
		]);
	},
	onFromDateChanged: function (date) {
		this.onChange([{ path: 'value.Date.value.start', value: date }]);
	},
	onToDateChanged: function (date) {
		this.onChange([{ path: 'value.Date.value.end', value: date }]);
	},
	getFilterWithValue: function (filter) {
		const newFilter = _.cloneDeep(filter);

		if (newFilter.value) {
			return newFilter;
		} else {
			return _.assign(newFilter, newFilter.generate());
		}
	},
	getFilterValue: function (inProps) {
		const props = inProps || this.props;
		const filter = props.filter;

		return filter.value ? _.cloneDeep(filter.value) : filter.generate().value;
	},
	onChange: function (updates, type = null) {
		const props = this.props;
		const newFilter = this.getFilterWithValue(props.filter);

		updates.forEach(function (update) {
			_.set(newFilter, update.path, update.value);
		});

		if (type === 'product') {
			const pathRes = updates[0].path.split('.').reduce((acc, part) => acc?.[part], newFilter);
			this.productIdToObject(pathRes)
				.then(() => {
					props.onChange(newFilter, {
						action: 'add'
					});
				})
				.catch(e => {
					logError(e, 'Failed to get products');
				});
		} else {
			props.onChange(newFilter, {
				action: 'add'
			});
		}
	},
	classes: function (classes) {
		return _.map(classes, function (expression, classes) {
			return expression ? classes : '';
		}).join(' ');
	},
	stageIdToObject: function (values) {
		const stages = window.Tools.AppService.getStages('order', true);

		return _.reduce(
			values,
			function (res, stageId) {
				const item = _.find(stages, { id: stageId });

				if (item) {
					res.push(item);
				}
				return res;
			},
			[]
		);
	},
	productIdToObject: async function (values) {
		const shouldFetchProduct = Tools.AppService.getTotals('products') > 4000;
		const stages = window.Tools.AppService.getProducts(true);
		try {
			let productValues = [];
			if (shouldFetchProduct) {
				const { data: foundProduct } = await ProductResource.find({ id: values, usePriceLists: true });
				productValues = values.reduce((res, stageId) => {
					let item = stages.find(stage => stage.id === stageId);

					if (!item) {
						item = foundProduct.find(product => product.id === stageId);
					}
					if (item) {
						res.push(item);
					}
					return res;
				}, []);
			} else {
				productValues = values.reduce((res, stageId) => {
					const item = stages.find(stage => stage.id === stageId);
					if (item) {
						res.push(item);
					}
					return res;
				}, []);
			}
			this.setState({ productValues });
		} catch (e) {
			logError(e, 'Failed to get products');
		}
	},
	stageSortFn: function (data) {
		if (!Array.isArray(data)) {
			return data;
		}
		return _.clone(data).sort(function (a, b) {
			if (a.probability === b.probability) {
				return a.name >= b.name;
			} else if (a.probability === 0) {
				return 1;
			} else if (b.probability === 0) {
				return -1;
			} else {
				return a.probability >= b.probability;
			}
		});
	},
	render: function () {
		const classes = this.classes;
		const filterValue = this.getFilterValue();
		const filter = this.props.filter;

		const isInactive = filter.value ? filter.isInactive(filter) : true;
		const stageValues = this.stageIdToObject(filterValue.Stage.value);
		const currency = Tools.AppService.getMetadata().defaultCurrency;

		return (
			<div className="history-filter">
				<div className="title">{this.lang.title}</div>
				<div className="history-filter-row extra-thin-row">
					<div className="flex-btn-group">
						<button
							data-test-id="listfilters-history-order-inactive"
							className={classes({
								'btn up-btn btn-bright-blue no-shadow': isInactive,
								'btn up-btn btn-light-grey no-shadow': !isInactive
							})}
							onClick={this.onInactiveFilter}
						>
							{this.lang.all}
						</button>
						<button
							data-test-id="listfilters-history-order-has"
							className={classes({
								'btn up-btn btn-bright-blue no-shadow': filterValue.hasOrder && !isInactive,
								'btn up-btn btn-light-grey no-shadow': !filterValue.hasOrder || isInactive
							})}
							onClick={this.onHasOrderTrue}
						>
							{this.lang.has}
						</button>
						<button
							data-test-id="listfilters-history-order-hasNot"
							className={classes({
								'btn up-btn btn-bright-blue no-shadow': !filterValue.hasOrder && !isInactive,
								'btn up-btn btn-light-grey no-shadow': filterValue.hasOrder || isInactive
							})}
							onClick={this.onHasOrderFalse}
						>
							{this.lang.hasNot}
						</button>
					</div>
				</div>
				<div className="history-filter-row">
					<label className="secondary-title">{this.lang.date}</label>
					<div
						className={
							'datepicker-group' +
							(filterValue.Date.value.preset === 'custom' ? ' datepicker-custom-visible' : '')
						}
					>
						<div className="datepicker-select-wrap" data-test-id="listfilters-history-order-dateselect">
							<ReactTemplates.INPUTS.upSelect
								key={'datepicker-select'}
								disabled={isInactive}
								data={this.datePresets}
								required={true}
								multiple={false}
								className="datepicker-select"
								onChange={this.onDatePresetChanged}
								defaultValue={filterValue.Date.value.preset}
							/>
						</div>
						{filterValue.Date.value.preset === 'custom' ? (
							<div className="datepicker-wrap" key="datepicker-wrap">
								<div id="datepicker-container">
									<ReactTemplates.upFilters.components.datepicker
										disabled={isInactive}
										placeholder={this.lang.from}
										name={'startDate'}
										className="form-control"
										value={filterValue.Date.value.start}
										onChange={this.onFromDateChanged}
									/>
									<ReactTemplates.upFilters.components.datepicker
										disabled={isInactive}
										placeholder={this.lang.to}
										name={'endDate'}
										className="form-control"
										value={filterValue.Date.value.end}
										onChange={this.onToDateChanged}
									/>
								</div>
							</div>
						) : null}
					</div>
				</div>
				{this.state.showZeroProbabilityStages ? (
					<div
						key="showZeroProbabilityStages"
						className="history-filter-row"
						data-test-id="listfilters-history-order-stages"
					>
						<label className="secondary-title">{this.lang.stage}</label>
						<ReactTemplates.INPUTS.upStages
							disabled={isInactive}
							multiple={true}
							entity={'order'}
							className={'form-control'}
							value={stageValues}
							onChange={this.onStageChanged}
							placeholder={this.lang.selectStages}
							sortFn={this.stageSortFn}
						/>
					</div>
				) : (
					<div
						key="dontShowZeroProbabilityStages"
						className="history-filter-row"
						data-test-id="listfilters-history-order-stages"
					>
						<label className="secondary-title">{this.lang.stage}</label>
						<ReactTemplates.INPUTS.upStages
							disabled={isInactive}
							multiple={true}
							entity={'order'}
							className={'form-control'}
							value={stageValues}
							onChange={this.onStageChanged}
							placeholder={this.lang.selectStages}
							sortFn={this.stageSortFn}
							hideZeroProbability={true}
						/>
					</div>
				)}
				<div className="history-filter-row thin-row" data-test-id="listfilters-history-order-showzerostages">
					<label className="secondary-title font-size-12">{this.lang.showStagesWithZeroProbability}</label>
					<br />
					<ReactTemplates.upFilters.components.toggle
						disabled={isInactive}
						className="toggle-lg toggle-bright-blue"
						checked={this.state.showZeroProbabilityStages}
						onChange={this.toggleShowZeroProbabilityStages}
					/>
				</div>
				<div className="history-filter-row" data-test-id="listfilters-history-order-products">
					<label className="secondary-title">{this.lang.products}</label>
					<ReactTemplates.INPUTS.upProducts
						disabled={isInactive}
						multiple={true}
						className={'form-control'}
						value={this.state.productValues}
						onChange={this.onProductChanged}
						placeholder={this.lang.selectProducts}
					/>
				</div>
				<div className="history-filter-row">
					<label className="secondary-title">{this.lang.orderValue + ' (' + currency.iso + ')'}</label>
					<div className="history-filter-range">
						<div className="icon-input-wrap" data-test-id="listfilters-history-order-valuefrom">
							<input
								className="form-control"
								placeholder={this.lang.from + ':'}
								disabled={isInactive}
								onChange={this.onFromValueChangedState}
								value={this.state.from || ''}
							/>
							<i className="fa fa-money" />
						</div>
						{' - '}
						<div className="icon-input-wrap" data-test-id="listfilters-history-order-valueto">
							<input
								className="form-control"
								placeholder={this.lang.to + ':'}
								disabled={isInactive}
								onChange={this.onToValueChangedState}
								value={this.state.to || ''}
							/>
							<i className="fa fa-money" />
						</div>
					</div>
				</div>
			</div>
		);
	}
});
