import BemClass from '@upsales/components/Utils/bemClass';
import React, { useState, useEffect, ComponentProps, useRef } from 'react';
import './AppSidebar.scss';
import { Block, Card, Icon, IconName, Link, OutsideClick, Text, ClickableItem, Tooltip } from '@upsales/components';
import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import Links from 'App/resources/Links';
import logError from 'Helpers/logError';
import { RootState } from 'Store/index';
import history from 'App/pages/routes/history';
import { useAppDispatch, useFeatureAvailable, useSelector, useSoftDeployAccess } from '../hooks';
import { useTranslation } from 'Components/Helpers/translate';
import LinkType from 'App/resources/Model/Links';
import { Feature, getFeatureAvailableFromState, getSoftDeployAccessFromState } from '../hooks/featureHelper';
import { globalTracker } from 'App/babel/helpers/Tracker';
import { openDrawer } from 'Services/Drawer';
import openModal, { shouldOpenModal } from 'App/services/Modal';
import InAppChat from 'Services/InAppChat';
import SupportToken from 'App/babel/resources/SupportToken';
import { SidebarSavedModalButton, SidebarSavedLocalStorageModalButton } from '../SidebarSavedModalButton';
import { setSidebarLinks } from 'Store/actions/AppActions';
import { AppState } from 'Store/reducers/AppReducer';
import useCleanMyDataNotification from 'App/components/CleanMyData/useCleanMyDataNotification';

// When SIDEBAR_REACT is released we can remove all css references to #sidebar

const useAddActions = (): { title: string; type: string; show: boolean; icon?: IconName; do: () => void }[] => {
	const hasActivities = useFeatureAvailable(Feature.ACTIVITIES_AND_APPOINTMENTS);
	const hasTodoList = useSoftDeployAccess('TODO_LIST') && hasActivities;
	const { metadata, self, accessRights, admin, customerId, accountSelf } = useSelector(({ App }) => ({
		metadata: App.metadata,
		self: App.self,
		accessRights: App.accessRights,
		admin: App.self?.administrator ?? false,
		customerId: App.customerId,
		accountSelf: App.accountSelf
	}));

	const showEmail =
		useFeatureAvailable(Feature.EMAIL) &&
		(admin || metadata?.map.mailActivated || !!metadata?.integrations.inits.mail?.length || false);

	const showGroupMailAndMailTemplate =
		(useFeatureAvailable(Feature.GROUP_MAIL) && admin) || metadata?.map.mailActivated || false;

	const hasCustomerSupport = useFeatureAvailable(Feature.CUSTOMER_SUPPORT);
	const showSupportTicket = !!self?.support && hasCustomerSupport;

	const hasProjectPlan = useSoftDeployAccess('PROJECT_PLAN');
	const hasProjectPlanFeature = useFeatureAvailable(Feature.PROJECT_PLAN);
	const showProjectPlan = hasProjectPlan || hasProjectPlanFeature;
	return [
		{
			title: 'default.account',
			type: 'account',
			show: getFeatureAvailableFromState(accountSelf, Feature.COMPANIES_AND_CONTACTS),
			icon: 'building-o',
			do: () => {
				if (shouldOpenModal('CreateAccount')) {
					openModal('CreateAccount');
				} else {
					Tools.$upModal.open('createAccount', { customerId });
				}
			}
		},
		{
			title: 'default.contact',
			type: 'contact',
			show: getFeatureAvailableFromState(accountSelf, Feature.COMPANIES_AND_CONTACTS),
			icon: 'user',
			do: () => {
				Tools.$upModal.open('editContact', { customerId });
			}
		},
		{
			title: 'default.activity',
			type: 'activity',
			show:
				getFeatureAvailableFromState(accountSelf, Feature.ACTIVITIES_AND_APPOINTMENTS) &&
				!(getSoftDeployAccessFromState(accountSelf, 'REMOVE_ACTIVITIES') && hasTodoList),
			icon: 'activity',
			do: () => {
				Tools.$upModal.open('editActivity', {});
			}
		},
		{
			title: 'todo.createTodo',
			type: 'todo',
			show: hasTodoList,
			icon: 'activity',
			do: () => {
				openDrawer('CreateTodo');
			}
		},
		{
			title: 'todo.planACall',
			type: 'phonecall',
			show: hasTodoList,
			icon: 'phone',
			do: () => {
				openDrawer('CreateCall');
			}
		},
		{
			title: 'todo.bookAppointment',
			type: 'appointment',
			show: getFeatureAvailableFromState(accountSelf, Feature.ACTIVITIES_AND_APPOINTMENTS),
			icon: 'calendar',
			do: () => {
				Tools.$upModal.open('editAppointment', {});
			}
		},
		{
			title: 'default.opportunity',
			type: 'opportunity',
			show: getFeatureAvailableFromState(accountSelf, Feature.PIPELINE),
			icon: 'opportunity',
			do: () => {
				Tools.$upModal.open('editOrder', { customerId, type: 'opportunity' });
			}
		},
		{
			title: 'default.order',
			type: 'order',
			show: getFeatureAvailableFromState(accountSelf, Feature.ORDERS),
			icon: 'dollar',
			do: () => {
				Tools.$upModal.open('editOrder', { customerId, type: 'order' });
			}
		},
		{
			title: 'default.agreement',
			type: 'agreement',
			show:
				!!metadata?.params.AgreementEnabled &&
				getFeatureAvailableFromState(accountSelf, Feature.RECURRING_ORDER) &&
				accessRights.Agreement,
			icon: 'recurring-order',
			do: () => {
				if (getSoftDeployAccessFromState(accountSelf, 'SUBSCRIPTION_MODAL')) {
					const dontWait = getSoftDeployAccessFromState(accountSelf, 'DONT_WAIT_SUBSCRIPTIONS');
					openModal('CreateSubscription', { createdFrom: 'sidebar', dontWait });
				} else {
					Tools.$upModal.open('editAgreement', { customerId });
				}
			}
		},
		{
			title: 'default.email',
			type: 'mail',
			show: showEmail,
			icon: 'envelope',
			do: () => {
				if (getSoftDeployAccessFromState(accountSelf, 'NEW_MAIL')) {
					openDrawer('NewSingleMail');
				} else {
					Tools.$upModal.open('sendEmail', { customerId, type: 'mail' });
				}
			}
		},
		{
			title: 'default.mailinglist',
			type: 'mailCampaign',
			show: showGroupMailAndMailTemplate && (admin || self?.userParams.mailAdmin || false),
			icon: 'email-campaign',
			do: () => {
				Tools.$upModal.open('createGroupMail');
			}
		},
		{
			title: 'mail.template',
			type: 'mailTemplate',
			show: showGroupMailAndMailTemplate,
			icon: 'envelope',
			do: () => {
				Tools.$upModal.open('createMailTemplate');
			}
		},
		{
			title: 'default.campaign',
			type: 'campaign',
			show: getFeatureAvailableFromState(accountSelf, Feature.PROJECTS),
			icon: 'bullhorn',
			do: () => {
				Tools.$upModal.open('editCampaign', { customerId });
			}
		},
		{
			title: 'form.form',
			type: 'form',
			show: getFeatureAvailableFromState(accountSelf, Feature.FORMS),
			icon: 'form',
			do: () => {
				Tools.$upModal.open('createForm', { formType: 'form' });
			}
		},
		{
			title: 'form.landingPage',
			type: 'landingpage',
			show: getFeatureAvailableFromState(accountSelf, Feature.FORMS),
			icon: 'file-text',
			do: () => {
				Tools.$upModal.open('createForm', { formType: 'landing' });
			}
		},
		{
			title: 'default.adCampaign',
			type: 'engage',
			show:
				getFeatureAvailableFromState(accountSelf, Feature.ADS) &&
				!getSoftDeployAccessFromState(accountSelf, 'HIDE_ADVERTISING'),
			icon: 'list-alt',
			do: () => {
				Tools.$state.go('editAd');
			}
		},
		{
			title: 'utm.generator',
			type: 'utm',
			show: getFeatureAvailableFromState(accountSelf, Feature.UTM_GENERATOR),
			icon: 'link',
			do: () => {
				openDrawer('UtmLinkGenerator');
			}
		},
		{
			title: 'ticket.createTicket',
			type: 'support',
			show: showSupportTicket,
			icon: 'customer-support',
			do: () => {
				openDrawer('EditTicket');
			}
		},
		{
			title: 'projectPlan.createProjectPlan',
			type: 'projectPlan',
			show: showProjectPlan,
			icon: 'project',
			do: () => {
				openDrawer('EditProjectPlan');
			}
		}
	];
};

const SidebarButton = ({
	active,
	notification,
	icon,
	loading = false,
	renderDropdown,
	onClick,
	tooltip
}: {
	icon: ComponentProps<typeof Icon>['name'];
	loading?: boolean;
	active?: boolean;
	notification?: boolean;
	renderDropdown?: (close: () => void) => JSX.Element;
	onClick?: () => void;
	tooltip: string;
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const clickedOutside = useRef(false);
	const { t } = useTranslation();
	const doClick = () => {
		onClick?.();
		if (renderDropdown) {
			if (!clickedOutside.current && !isOpen) {
				setIsOpen(true);
			} else if (clickedOutside.current && !isOpen) {
				clickedOutside.current = false;
			} else {
				clickedOutside.current = false;
				setIsOpen(true);
			}
		}
	};
	const doOutsideClick = () => {
		if (isOpen) {
			clickedOutside.current = true;
			setIsOpen(false);
		} else {
			clickedOutside.current = false;
		}
	};
	const classes = new BemClass('AppSidebar__button-wrap').mod({ active });

	return (
		<div className={classes.b()}>
			<Tooltip title={t(tooltip)} position="right" distance={24} disabled={loading}>
				<ClickableItem
					block
					icon={icon}
					borderRadius
					color="grey-1"
					onClick={() => doClick()}
					loading={loading}
					active={isOpen}
				/>
				{active ? <Icon name="exclamation" className={classes.elem('active-icon').b()} /> : null}
				{notification ? <Icon color="white" name="exclamation" className={'notification-badge'} /> : null}
			</Tooltip>
			{renderDropdown ? (
				<OutsideClick outsideClick={doOutsideClick} targetClass="AppSidebar__dropdown">
					<dialog className={new BemClass('AppSidebar__dropdown').b()} open={isOpen}>
						<Card>{renderDropdown(() => setIsOpen(false))}</Card>
					</dialog>
				</OutsideClick>
			) : null}
		</div>
	);
};

const openChat = function (accountSelf: AppState['accountSelf']) {
	if (!InAppChat.isOpened()) {
		if (getSoftDeployAccessFromState(accountSelf, 'CHAT_SESSION')) {
			SupportToken.hasSession()
				.then(data => {
					if (!data) {
						openModal('AllowAccess', {
							onClose: InAppChat.open
						});
					} else {
						InAppChat.open();
					}
				})
				.catch(() => {
					InAppChat.open();
				});
		} else {
			InAppChat.open();
		}
	}
};

export default () => {
	const classes = new BemClass('AppSidebar');
	const { t } = useTranslation();
	const [loadingLinks, setLoadingLinks] = useState(true);
	const dispatch = useAppDispatch();
	const [chatUnread, setChatUnread] = useState(0);
	const displayCalendar = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS);
	const hasHelp = (window as any)._elev !== undefined;
	const linksPromise = useRef<CancelablePromise<{ data: LinkType[] }> | null>(null);
	const { self, links, accountSelf } = useSelector(({ App }: RootState) => ({
		self: App.self,
		links: App.sidebarLinks,
		accountSelf: App.accountSelf
	}));
	const [shouldShowCleanMyDataNotificationBadge, openCleaningModal] = useCleanMyDataNotification(self);

	const showReactSidebar =
		getSoftDeployAccessFromState(accountSelf, 'SIDEBAR_REACT') &&
		getSoftDeployAccessFromState(accountSelf, 'REMOVE_FRAME');
	const addActions = useAddActions();
	const hasChat = InAppChat.isEnabledAndLoaded();

	const hasCleanMyData = useSoftDeployAccess('CLEAN_MY_DATA_VIEW');
	const hasNewDuplicateView = useSoftDeployAccess('NEW_DUPLICATE_VIEW');
	const isAdmin = self?.administrator;

	useEffect(() => {
		let unregisterChatCount: () => void;

		if (showReactSidebar && self) {
			// Get links
			// TODO: Put in redux so we can get updated links on add/remove
			linksPromise.current = makeCancelable(Links.find('sidebar', self.id));

			linksPromise.current.promise
				.then(res => {
					dispatch(setSidebarLinks(res.data));
					setLoadingLinks(false);
				})
				.catch(e => logError(e, 'Failed to load links'));

			if (hasChat) {
				unregisterChatCount = InAppChat.on('unreadCount', function (unreadCount: number) {
					setChatUnread(unreadCount);
				});
			}
		}
		return () => {
			linksPromise.current?.cancel();
			unregisterChatCount?.();
		};
	}, []);

	return showReactSidebar ? (
		<div className={classes.b()}>
			<SidebarButton
				icon="plus"
				loading={loadingLinks}
				tooltip="sidebar.tooltip.add"
				renderDropdown={close => {
					return (
						<div className={classes.elem('add-dropdown').b()}>
							<Block space="mtl mrl mll mbl">
								<Text bold size="sm">
									{t('default.add')}
								</Text>
							</Block>
							{addActions.map(action =>
								action.show ? (
									<ClickableItem
										block
										icon={action.icon}
										key={action.type}
										onClick={() => {
											globalTracker.track('add', { type: action.type, location: 'sidebar' });
											action.do();
											close();
										}}
										title={t(action.title)}
									/>
								) : null
							)}
						</div>
					);
				}}
			/>
			{displayCalendar ? (
				<SidebarButton
					icon="calendar"
					loading={loadingLinks}
					tooltip="sidebar.tooltip.calendar"
					onClick={() => {
						history.push(`/calendar/?date=${new Date().toJSON()}&selected=workWeek`);
					}}
				/>
			) : null}
			{links.length ? (
				<SidebarButton
					icon="link"
					loading={loadingLinks}
					tooltip="sidebar.tooltip.dynamiclinks"
					renderDropdown={close => {
						return (
							<div className={classes.elem('dynamiclinks-dropdown').b()}>
								<Block space="mtl mrl mll mbl">
									<Text bold size="sm">
										{t('default.links')}
									</Text>
								</Block>
								{links.map(link => (
									<Link nostyle key={link.id} href={link.href} target={link.target}>
										<ClickableItem
											block
											renderLeft={() =>
												link.icon.includes('fa-') ? null : (
													<img className={classes.elem('link-img').b()} src={link.icon} />
												)
											}
											icon={
												link.icon.includes('fa-')
													? (link.icon.replace('fa-', '') as IconName)
													: undefined
											}
											iconStyle={{ color: '#' + link.color }}
											title={link.value}
											onClick={() => {
												close();
											}}
										/>
									</Link>
								))}
							</div>
						);
					}}
				/>
			) : null}
			{hasHelp ? (
				<SidebarButton
					icon="question"
					loading={loadingLinks}
					tooltip="sidebar.tooltip.help"
					onClick={() => window._elev.openModule?.('articles')}
				/>
			) : null}
			{hasChat ? (
				<SidebarButton
					icon="comment"
					active={chatUnread > 0}
					loading={loadingLinks}
					tooltip="sidebar.tooltip.chat"
					onClick={() => openChat(accountSelf)}
				/>
			) : null}
			{hasCleanMyData && hasNewDuplicateView && isAdmin ? (
				<SidebarButton
					data-testid="cleanMyDataButton"
					icon="broom"
					loading={loadingLinks}
					tooltip="sidebar.tooltip.cleanMyData"
					onClick={() => openCleaningModal()}
					notification={shouldShowCleanMyDataNotificationBadge}
				/>
			) : null}
			{/*
				UIElements never worked in the old sidebar,
				so i will fix it in another ticket and spend some time to make it fit in properly
			 */}
			{/* <UiElements elements={uiElements} type="sidebar" /> */}
			<SidebarSavedModalButton />
			<SidebarSavedLocalStorageModalButton />
		</div>
	) : null;
};
