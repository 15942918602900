import PropTypes from 'prop-types';
import React from 'react';
import AccountManagerRow from '../../AccountManagerRow';

// these needs to be moved to approppriate places later on
import CompanyBlock from './CompanyBlock/CompanyBlock';
import ContactBlock from './ContactBlock/ContactBlock';

import UiElements from '../../UiElements/UiElements';
import { Tooltip, Icon, Flex, Text } from '@upsales/components';

class AppointmentModalSidebar extends React.Component {
	constructor(props) {
		super(props);
		this.hasCalendar = Tools.FeatureHelper.hasSoftDeployAccess('APPOINTMENT_CALENDAR');
		this.onlyEditAsHost = props.appointment?.hostHasCalendarIntegration;
	}

	renderHost() {
		const { appointment } = this.props;
		const user = appointment.isExternalHost
			? { name: Tools.$translate('appointment.externalHost') }
			: appointment.regBy;
		return (
			<div className="host-user">
				<AccountManagerRow user={user} />
			</div>
		);
	}

	renderParticipants(list = [], type) {
		const self = this;

		if (type === 'users') {
			return list.map((user, key) => {
				var conflictingMeetings = this.props.conflicting;
				_.uniq(conflictingMeetings);

				var conflict = [];
				_.each(this.props.conflicting, meeting => {
					const conflictingUser = _.find(meeting.users, { id: user.id });
					if (conflictingUser) {
						conflict.push({ id: user.id });
					}
				});

				const currentUser = _.find(conflict, { id: user.id });

				return (
					<Tooltip
						key={key}
						title={
							currentUser
								? Tools.$translate('appointment.userGotConflictingAppointment')
								: !self.hasCalendar
								? `${Tools.$translate('account.relations.groupSize.see')} ${
										user.name
								  } ${Tools.$translate('appointment.availability').toLowerCase()}`
								: ''
						}
						theme={currentUser ? 'orange' : undefined}
						position="right"
					>
						<Flex
							className={`participant-users ${conflict.length ? 'warning' : 'no-conflict'}`}
							key={`appointment-participants-${user.name}`}
							onClick={this.props.checkConflicting}
						>
							<AccountManagerRow user={user} isHost={this.shouldMarkUserAsHost(user)} />
							{!self.hasCalendar || currentUser ? (
								<Icon
									name={currentUser ? 'exclamation-triangle' : 'calendar'}
									className="participant-icon"
								/>
							) : null}
						</Flex>
					</Tooltip>
				);
			});
		}

		if (type === 'emailAttendees') {
			return _.map(list, attendee => {
				return (
					<Flex
						alignItems="center"
						key={`appointment-participants-${attendee.email}`}
						className={'participant-emailAttendees participants'}
					>
						<Text>{attendee.email}</Text>
					</Flex>
				);
			});
		}

		return _.map(list, user => {
			const blockProps = {
				user: user,
				onClick: this.props.openContact,
				saveContact: this.props.actions.saveContact,
				setJourneyStep: this.props.actions.setJourneyStep,
				key: `regular-contact-${user.id}-${user.name}`,
				originalUser: Object.assign({}, user),
				openContact: this.props.actions.openContact
			};

			if (self.props.activeContact === user.id) {
				blockProps.open = true;
			}

			return <ContactBlock {...blockProps} />;
		});
	}

	generateDynamicLinks(links) {
		return _.map(links, link => {
			let icon = '';

			if (link.icon && link.icon.indexOf('fa') === 0) {
				const styleObj = {};

				if (link.color) {
					styleObj.color = `#${link.color}`;
				}

				icon = <b className={`icon fa ${link.icon} custom-icon`} style={styleObj} />;
			} else if (link.icon && link.icon.indexOf('fa') !== 0) {
				icon = <img src={link.icon} />;
			}

			return (
				<li key={link.value}>
					<a target={link.target || '_blank'} href={link.href}>
						{icon}
						{link.value}
					</a>
				</li>
			);
		});
	}

	shouldMarkUserAsHost(user) {
		const { appointment } = this.props;
		return !!(
			this.onlyEditAsHost &&
			!appointment.isExternalHost &&
			appointment.regBy &&
			appointment.regBy.id === user.id
		);
	}

	shouldRenderHost() {
		const { appointment } = this.props;

		if (!this.onlyEditAsHost || !appointment.regBy) {
			return false;
		}

		if (appointment.isExternalHost) {
			return true;
		}

		const hostParticipant = _.find(appointment.users, user => user.id === appointment.regBy.id);
		return !hostParticipant;
	}

	render() {
		const { appointment, actions, links, uiElements } = this.props;

		const upsalesUsers = this.renderParticipants(appointment.users, 'users');
		const contactUsers = this.renderParticipants(appointment.contacts, 'contacts');
		const emailAttendees = this.renderParticipants(appointment.emailAttendees, 'emailAttendees');
		const dynamicLinks = this.generateDynamicLinks(links);

		return (
			<div className="appointment-sidebar modal-sidebar">
				<div className="section company-section">
					<label>{Tools.$translate('default.client')}</label>
					<CompanyBlock
						client={appointment.client}
						actions={actions}
						openContact={this.props.actions.openContact}
					/>
				</div>
				{this.shouldRenderHost() ? (
					<div className="section host-section">
						<label>{Tools.$translate('appointment.host')}</label>
						{this.renderHost()}
					</div>
				) : null}
				<Flex className="section participants-section" direction="column">
					<label>{Tools.$translate('default.appointmentUser')}</label>
					{contactUsers}
					{emailAttendees}
					{upsalesUsers}
				</Flex>
				{links && links.length ? (
					<div className="section dynamiclinks-section">
						<label>{Tools.$translate('default.links')}</label>
						<ul>{dynamicLinks}</ul>
					</div>
				) : null}

				{uiElements && uiElements.sidebar && (
					<div className="section ui-elements edge-to-edge">
						<UiElements
							elements={uiElements && uiElements.sidebar}
							object={appointment}
							type="editAppointment"
							onObjectChange={() => {
								/* update of object is not supported for appointment yet */
							}}
						/>
					</div>
				)}
			</div>
		);
	}
}

AppointmentModalSidebar.propTypes = {
	conflicting: PropTypes.array,
	checkConflicting: PropTypes.func,
	openContact: PropTypes.func,
	saveContact: PropTypes.func,
	appointment: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	links: PropTypes.array,
	uiElements: PropTypes.object
};

export default AppointmentModalSidebar;
