import { ACTIVITY_PRIORITY } from 'App/babel/enum/activity';
import PlanPhonecallsDrawerCallListSelect from 'Components/PlanPhonecallsDrawer/PlanPhonecallsDrawerCallListSelect';
import {
	getUsersByTag,
	prepDates,
	parseDate,
	getAvailableFields,
	formatDateType,
	attachScopeFunctions,
	executeSave
} from 'App/babel/helpers/multiModals';

angular.module('domain.activity').controller('UpdateActivityMulti', [
	'$scope',
	'$modalParams',
	'$multiSelect',
	'ActionProperties',
	'FeatureHelper',
	'$safeApply',
	function ($scope, $modalParams, $multiSelect, ActionProperties, FeatureHelper, $safeApply) {
		var arrayProperties = [];
		$scope.properties = $scope.properties ?? {};
		$scope.isPriorityEnable = FeatureHelper.hasSoftDeployAccess('ACTIVITY_PRIORITIZATION');
		$scope.hasTodosCallLists =
			FeatureHelper.hasSoftDeployAccess('TODO_LIST') && FeatureHelper.hasSoftDeployAccess('CALL_LISTS');
		$scope.hasRemoveActivities = FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES');
		$scope.ACTIVITY_PRIORITY = ACTIVITY_PRIORITY;
		$scope.isTrigger = $modalParams.isTrigger;
		$scope.tagsVisible = false;
		$scope.modalParams = $modalParams;
		$scope.CallListSelect = PlanPhonecallsDrawerCallListSelect;
		$scope.CallListSelectProps = {
			value: $scope.properties.CallList,
			anchor: '.up-modal-content',
			onChange: val => {
				$scope.properties.CallList = val;
				$scope.CallListSelectProps.value = val;
				$safeApply($scope);
			}
		};

		$scope.modalTagListProps = {
			modalParams: $modalParams,
			onClose: function () {
				$scope.tagsVisible = false;
				$scope.$apply();
			}
		};

		$scope.save = function () {
			var props = angular.copy($scope.properties);
			if (props.Date && moment(props.Date).isValid()) {
				props.Date = moment(props.Date).format('YYYY-MM-DD');
			} else if (props.Date && typeof props.Date === 'string' && props.Date.indexOf('{{') === 0) {
				// date is a tag
				// shouldn't do anything with it but shouldnt delete it either?
			} else {
				delete props.Date;
			}
			if (props.Time) {
				props.Time = moment(props.Time).format('HH:mm');
			} else {
				delete props.Time;
			}

			executeSave({
				scope: $scope,
				modalParams: $modalParams,
				multiSelect: $multiSelect,
				entityType: 'Activity',
				method: 'updateActivity',
				arrayProperties,
				scopeProperties: props
			});
		};

		$scope.toggleAvailableTags = function () {
			$scope.tagsVisible = !$scope.tagsVisible;
		};

		$scope.togglePriority = function (priority) {
			if ($scope.properties.Priority !== priority) {
				$scope.properties.Priority = priority;
			}
		};

		attachScopeFunctions($scope, [
			'changeCustomDate',
			'createCampaign',
			'addTime',
			'addField',
			'removeField',
			'setDate'
		]);

		var init = function () {
			const meta = $modalParams.meta;
			const startingFields = meta.activityCustomFields.data;
			const { dateTags, dateTypes, customDateTypes } = prepDates($modalParams.entity || $modalParams.tagEntity);

			$scope.isEdit = !!$modalParams.properties;
			$scope.removeAction = $modalParams.removeAction;
			$scope.actionIndex = $modalParams.actionIndex;

			$scope.properties = {};
			$scope.availableFields = getAvailableFields($scope, startingFields, customDateTypes);
			$scope.customFieldLength = $scope.availableFields.length;
			$scope.customDateTypes = formatDateType(customDateTypes);

			if ($scope.isTrigger) {
				$scope.dateTypes = formatDateType(dateTypes);
				$scope.date = parseDate($modalParams.properties, $scope.dateTypes.data, 'Date');
				$scope.setDate();
			}

			// Users
			$scope.users = angular.copy(meta.activeUsers.data);
			$scope.users.unshift(...getUsersByTag($modalParams.tagEntity));

			// parse properties
			if ($scope.isPriorityEnable) {
				$scope.properties.Priority = null;
			}
			ActionProperties.parse($scope.properties, $modalParams.properties, arrayProperties);

			if ($modalParams.isTodoList) {
				$scope.properties.User = '{{General.CurrentUserId}}';
			}

			// parse custom
			$scope.customFields = [];
			ActionProperties.parseCustomFields(
				$scope.availableFields,
				$scope.customFields,
				$scope.properties,
				'Activity',
				dateTags
			);

			$scope.activityTypes = meta.activityTypes.data;

			if ($scope.properties.ShowTime) {
				$scope.time = $scope.properties.Date;
			}
		};

		init();
	}
]);
