import Bem from '@upsales/components/Utils/bemClass';
import Client from 'App/resources/Model/Client';
import FlexItem from 'App/components/FlexItem';
import React, { useMemo, useState } from 'react';
import T from 'Components/Helpers/translate';
import { Flex, Text, Icon, EllipsisTooltip, Toggle } from '@upsales/components';
import { openDrawer } from 'Services/Drawer';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import ClientAttributes from 'App/babel/attributes/Client';
import ClientResource from 'App/resources/Client';
import useGetData from 'App/components/hooks/useGetData';
import LocalStorage from 'Components/Helpers/LocalStorage';
import { useTranslation } from 'Components/Helpers/translate';

import './SubaccountSelector.scss';

type Props = {
	client: Pick<Client, 'id' | 'name' | 'active' | 'operationalAccount'>;
	includingSubaccountData: boolean;
};

const fetcher = (rb: RequestBuilder, { clientId, isSubaccount }: { isSubaccount: boolean; clientId: number }) => {
	if (isSubaccount) {
		rb.addFilter(ClientAttributes.id, comparisonTypes.Equals, clientId);
		return ClientResource.find(rb.build());
	} else {
		rb.addFilter(ClientAttributes.operationalAccount.attr.id, comparisonTypes.Equals, clientId);
		return ClientResource.find(rb.build());
	}
};

const SubaccountSelector = ({ client, includingSubaccountData }: Props) => {
	const classes = new Bem('SubaccountSelector');
	const isSubaccount = !!client.operationalAccount;
	const isInactive = !client.active;
	const clientId = isSubaccount ? client.operationalAccount!.id : client.id;
	const { t } = useTranslation();
	const [toggle, setToggle] = useState<boolean>(includingSubaccountData);

	// This component needs its own state as subAccountIds is an empty array in the parent component if includingSubaccountData is false
	const {
		loading,
		metadata: { total }
	} = useGetData({
		fetcher,
		fetcherProps: {
			clientId,
			isSubaccount
		},
		broadcastTypes: ['account.subaccountsAdded', 'account.subaccountsDeleted'],
		limit: 1000,
		offset: 0
	});

	const { title, subtitle } = useMemo(() => {
		if (isSubaccount) {
			return {
				title: T('account.subaccounts.showingOf'),
				subtitle: client.operationalAccount!.name
			};
		} else if (total === 0) {
			return {
				title: T('account.subaccounts'),
				subtitle: T('account.subaccounts.add')
			};
		} else {
			return {
				title: T('account.subaccounts'),
				subtitle: T('account.subaccounts.show', { count: total })
			};
		}
	}, [client.operationalAccount, total]);

	const onClick = () => {
		openDrawer('SubaccountDrawer', {
			client: {
				id: isSubaccount ? client.operationalAccount!.id : client.id,
				name: isSubaccount ? client.operationalAccount!.name : client.name,
				hasAccess: isSubaccount ? total !== 0 : true
			},
			account: client as Client,
			includingSubaccountData
		});
	};

	const onChange = (value: boolean, e: React.ChangeEvent) => {
		e.stopPropagation();
		setToggle(value);

		const params = { includeSubaccountData: value };

		const localStorage = new LocalStorage();
		localStorage.setValue('includeSubaccountClientId' + client.id, value);

		setTimeout(() => {
			Tools.$rootScope.$broadcast('account.refresh', params);
		}, 150);
	};

	return (
		<Flex className={classes.mod({ isSubaccount, isInactive }).b()} onClick={onClick}>
			<FlexItem flexGrow={1}>
				<EllipsisTooltip title={title}>
					<Text
						className={classes.elem('Title').b()}
						color="inherit"
						ellipsis
						loading={loading}
						size={'sm'}
						space="mbs"
					>
						{title}
					</Text>
				</EllipsisTooltip>
				<EllipsisTooltip title={subtitle}>
					<Text
						bold={true}
						className={classes.elem('Subtitle').b()}
						color="inherit"
						ellipsis
						loading={loading}
						size={'md'}
					>
						{subtitle}
					</Text>
				</EllipsisTooltip>
				{!isSubaccount ? (
					<Flex gap="u3">
						<Toggle checked={toggle} onChange={onChange} size="sm" space="mtl" />
						<Text space="mtl" size="xs">
							{t('account.subaccount.includeSubaccountData')}
						</Text>
					</Flex>
				) : null}
			</FlexItem>
			<Flex alignItems="center" space="mlm">
				<Flex className={classes.elem('IconButton').b()} justifyContent="center" alignItems="center">
					<Icon name={'expand'} />
				</Flex>
			</Flex>
		</Flex>
	);
};

export default SubaccountSelector;
