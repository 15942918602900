import { ButtonSelect, Column, Row, Text, Input } from '@upsales/components';
import React, { useState } from 'react';
import { useTranslation } from 'Components/Helpers/translate';
import './DocumentTemplateEditorDesign.scss';
import './DocumentTemplateAvailableTags.scss';
import bemClass from '@upsales/components/Utils/bemClass';

type DocumentTemplateTag = Tools.ClassicTag & {
	tagValue: string | undefined;
};

const getOrderRowTagValues = (tag: Tools.ClassicTag) => {
	if (tag.useMath) {
		if (tag.valueThree) {
			if (tag.useFormatter) {
				return `currencyFormatter (math (math this.${tag.valueOne} '${tag.operator}' this.${tag.valueTwo}) '${tag.secondOperator}' ${tag.valueThree}) ../order.currency`;
			}
			return `math (math this.${tag.valueOne} '${tag.operator}' this.${tag.valueTwo}) '${tag.secondOperator}' ${tag.valueThree}`;
		}
		if (tag.useFormatter) {
			return `currencyFormatter (math this.${tag.valueOne} '${tag.operator}' this.${tag.valueTwo}) ../order.currency`;
		}
		return `math this.${tag.valueOne} '${tag.operator}' this.${tag.valueTwo}`;
	}
	if (tag.useFormatter) {
		return `currencyFormatter this.${tag.value} ../order.currency`;
	}
	return `this.${tag.value}`;
};

const getTagValueForPdfTemplate = (tag: Tools.ClassicTag) => {
	if (tag.rowProperty) {
		return tag.value;
	}
	if (tag.orderRow) {
		return getOrderRowTagValues(tag);
	}
	if (tag.useMath) {
		if (tag.useFormatter) {
			return `currencyFormatter (math ${tag.valueOne} '${tag.operator}' ${tag.valueTwo}) ../order.currency`;
		}
		return `math ${tag.valueOne} '${tag.operator}' ${tag.valueTwo}`;
	}
	if (tag.useFormatter) {
		return `${tag.value}`;
	}

	let KeyValueArray: string[];
	if (tag.pdfTemplateTag) {
		KeyValueArray = tag.pdfTemplateTag.split('.');
		if (KeyValueArray[0] === 'order') {
			return tag.pdfTemplateTag;
		} else {
			if (KeyValueArray[0] === 'general') {
				return tag.pdfTemplateTag;
			}
			if (KeyValueArray[0] === 'custom') {
				let objectPath = 'order';
				const parseLinks = true;
				if (tag.nameMapper && tag.nameMapper !== 'order') {
					if (tag.nameMapper === 'Order_Row') {
						objectPath = 'this';
					} else if (tag.nameMapper === 'product') {
						objectPath = 'this.product';
					} else {
						objectPath = `${'order'}.${tag.nameMapper}`;
					}
				}
				return `customField ${objectPath} ${tag.tagId} ${parseLinks}`;
			}
			return `${'order'}.${tag.pdfTemplateTag}`;
		}
	}
};

const sortTags = (a: DocumentTemplateTag, b: DocumentTemplateTag) => {
	const aNumericStart = a.name.match(/^\d+/);
	const bNumericStart = b.name.match(/^\d+/);
	if (!aNumericStart === !bNumericStart) {
		return a.name.localeCompare(b.name || '', undefined, { numeric: true });
	}
	return aNumericStart ? 1 : -1;
};

export const useTagService = (isProductTable?: boolean) => {
	const tags: Tools.ClassicTag[] = Tools.TagsService.getTagsByEntity('Order') as Tools.ClassicTag[]; // The ClassicTag is just a bigger type with more optional parameters
	const productTags: Tools.ClassicTag[] = Tools.TagsService.getTagsByEntity('Product') as Tools.ClassicTag[];
	const classicTags: Tools.ClassicTag[] = Tools.TagService.getClassicTagsByEntity('Order').filter(
		tag => tag.pdfTemplateTag
	); // Need more processing with math

	const tagsToBeProcessed = [...tags, ...classicTags, ...productTags];
	const allTagsWithTagsValue: DocumentTemplateTag[] = tagsToBeProcessed
		.map(tag => ({
			...tag,
			tagValue: getTagValueForPdfTemplate(tag)
		}))
		.sort(sortTags);
	const allTags = allTagsWithTagsValue.reduce((accumulator: DocumentTemplateTag[], currentTag) => {
		const existingTagIndex = accumulator.findIndex(
			tag => tag.pdfTemplateTag === currentTag.pdfTemplateTag || tag.tagValue === currentTag.tagValue
		);

		if (existingTagIndex !== -1) {
			// Combining properties, assuming that the properties are the same if they exists on both objects
			const existingTag = { ...currentTag, ...accumulator[existingTagIndex] };
			existingTag.name = existingTag.name.length > currentTag.name.length ? existingTag.name : currentTag.name;

			// Replace the existing tag in the accumulator array
			accumulator[existingTagIndex] = existingTag;
		} else {
			// Add the current tag to the accumulator if no matching tag was found
			accumulator.push(currentTag);
		}

		return accumulator;
	}, []);

	const checkProductTable = (tag: Tools.ClassicTag) =>
		isProductTable ||
		(!tag.orderRow &&
			!tag.nameMapper?.includes('Order_Row') &&
			tag.entity !== 'product' && // filter out product tags if it is not a product table
			!tag.nameMapper?.includes('product'));

	const orderTags = allTags.filter(
		tag =>
			!tag.pdfTemplateTag?.includes('client') &&
			!tag.pdfTemplateTag?.includes('contact') &&
			checkProductTable(tag)
	);
	const companyTags = allTags.filter(tag => tag.pdfTemplateTag?.includes('client') && checkProductTable(tag));
	const contactTags = allTags.filter(tag => tag.pdfTemplateTag?.includes('contact') && checkProductTable(tag));

	return { allTags, orderTags, companyTags, contactTags };
};

type Props = {
	handleClick?: (tagValue: string, tagName: string) => void;
	isProductTable?: boolean;
};

const DocumentTemplateAvailableTags = ({ handleClick, isProductTable }: Props) => {
	const parentClasses = new bemClass('DocumentTemplateEditorDesign');
	const classes = new bemClass('DocumentTemplateAvailableTags');
	const { t } = useTranslation();
	const lang = {
		availableTagsInfo: t('admin.documentTemplateEditor.availableTagsInfo'),
		searchTags: t('tag.searchTags'),
		noTags: t('tag.noTags'),
		order: t('admin.documentTemplateEditor.order'),
		company: t('admin.documentTemplateEditor.company'),
		contact: t('admin.documentTemplateEditor.contact'),
		copied: t('admin.documentTemplateEditor.copied')
	};

	const [copiedIndex, setCopiedIndex] = useState<number | null>(null);
	const [tagToggle, setTagToggle] = useState(lang.order);
	const [searchTag, setSearchTag] = useState('');

	const { orderTags, companyTags, contactTags } = useTagService(isProductTable ?? false);

	const handleCopyClick = (index: number, textToCopy: string) => {
		if (textToCopy.toLowerCase().includes('date')) {
			// Slice 1 solution since we can't modify the handleBars compiler as required
			textToCopy = textToCopy.substring(0, 2) + 'dateFormater ' + textToCopy.substring(2);
		}
		navigator.clipboard
			.writeText(textToCopy)
			.then(() => {
				setCopiedIndex(index);
				setTimeout(() => setCopiedIndex(null), 2000); // Reset copied state after 2 seconds
			})
			.catch(error => {
				console.error('Failed to copy text:', error);
			});
	};

	const availableTags = () => {
		let selectedTags: DocumentTemplateTag[];
		if (tagToggle === lang.order) {
			selectedTags = orderTags;
		} else if (tagToggle === lang.company) {
			selectedTags = companyTags;
		} else {
			selectedTags = contactTags;
		}
		return selectedTags
			.filter(tag => {
				// If the component is rendered in the modal for adding a new column to a product table, only show tags that can be used in a product table
				if (handleClick) {
					if (!(tag.orderRow || tag.nameMapper === 'Order_Row' || tag.nameMapper === 'product')) {
						return false;
					}
				}

				if (searchTag && searchTag.length) {
					const matchName = tag.name.toLowerCase().indexOf(searchTag.toLowerCase()) >= 0;
					const matchTag = tag.tagValue && tag.tagValue.toLowerCase().indexOf(searchTag.toLowerCase()) >= 0;
					return matchName || matchTag;
				}
				return true;
			})
			.map((item, index) => {
				const isCopied = copiedIndex === index;
				if (item.tagValue) {
					const tagString = `{{${item.tagValue}}}`;
					return (
						<Row
							key={item.tagValue}
							className={classes.elem('tag-row').b()}
							data-testid="tag-row"
							onClick={
								handleClick
									? () => handleClick(tagString, item.name)
									: () => handleCopyClick(index, tagString)
							}
						>
							<Column align="left" className={classes.elem('tag-column').b()}>
								<Text size="md">{item.name}</Text>
								<Text size="sm" className={classes.elem('tag').b()}>
									{tagString}
								</Text>
							</Column>
							{!handleClick ? (
								<Column align="right">
									{isCopied ? (
										<Text size="sm" className={classes.elem('tag').b()}>
											{lang.copied}
										</Text>
									) : null}
								</Column>
							) : null}
						</Row>
					);
				}
				return null;
			});
	};

	return (
		<div data-testid="DocumentTemplateAvailableTags" className={parentClasses.elem('sidebar-section').b()}>
			<div className={classes.elem('sidebar-section-top').b()}>
				<Row className={parentClasses.elem('sidebar-section-input-row').b()}>
					<Column>
						<div className={classes.elem('info-text').b()}>
							<Text color="blue" size="sm">
								{lang.availableTagsInfo}
							</Text>
						</div>
					</Column>
				</Row>
				<Row className={parentClasses.elem('sidebar-section-input-row').b()}>
					<Column>
						<Input
							data-testid="search-tag"
							color="grey-2"
							noborder={true}
							icon="search"
							placeholder={lang.searchTags}
							value={searchTag}
							onChange={e => setSearchTag(e.target.value)}
						/>
					</Column>
				</Row>
				{!handleClick ? (
					<Row className={parentClasses.elem('sidebar-section-input-row').b()}>
						<Column>
							<ButtonSelect
								options={[lang.order, lang.company, lang.contact]}
								value={tagToggle}
								size="md"
								onChange={e => setTagToggle(e)}
							/>
						</Column>
					</Row>
				) : null}
			</div>
			<Row className={classes.elem('content').b()}>
				<Column className={classes.elem('content-inner').b()}>{availableTags()}</Column>
			</Row>
		</div>
	);
};

export default DocumentTemplateAvailableTags;
