import { isEmpty, pick, has, get, set, merge, isArray } from 'lodash';
import { replaceItem } from '@upsales/components/Utils/arrayHelpers';
import Order, { OrderCustomField } from 'App/resources/Model/Order';
import { ConnectedClient } from 'App/resources/Model/Client';
import ProjectResource from 'App/resources/Project';
import SalesCoach from 'App/resources/Model/SalesCoach';
import ProductResource from 'App/babel/resources/Product';
import CustomField, { CustomFieldWithValue } from 'App/resources/Model/CustomField';
import { makeCancelable, CancelablePromise } from 'Helpers/promise';
import logError from 'App/babel/helpers/logError';
import OrderRow, { BundleRow, OrderRowInternalState } from 'App/resources/Model/OrderRow';
import ProductCategory from 'App/resources/Model/ProductCategory';

type AllowedCustomField = Pick<CustomFieldWithValue, 'id' | 'value' | 'name' | 'default'>;

export const allowedCustomFields = [
	'id', // Since this is used for lookup, there is no way of changing this
	'value',
	'name',
	'default'
];

export const allowedOrderRowFields = [
	'product',
	'productId',
	'custom',
	'discount',
	'price',
	'listPrice',
	'quantity',
	'purchaseCost',
	'bundleRows'
] as const;

export const allowedOrderFields = [
	'description',
	'date',
	'stage',
	'probability',
	'clientConnection',
	'project',
	'salesCoach',
	'marketingContribution',
	'recurringInterval',
	'notes',
	'custom',
	'orderRow'
];

export const nonAllowedCustomDataTypes = ['Calculation'];

export type OrderUpdatedFields = Partial<
	Pick<
		Order,
		| 'description'
		| 'date'
		| 'stage'
		| 'probability'
		| 'clientConnection'
		| 'project'
		| 'salesCoach'
		| 'marketingContribution'
		| 'recurringInterval'
		| 'notes'
		| 'custom'
		| 'orderRow'
	>
> & {
	salesCoach: SalesCoach;
};

type ObjectWithKeys = {
	[key: string]: boolean | ObjectWithKeys;
};

export type UpdatedFields = Partial<Order> & {
	orderRow?: Partial<OrderRow>;
};
class OnOrderEditHelpers {
	currentUpdate?: CancelablePromise<Order>;
	currentProductFetch?: CancelablePromise<Order['project']>;
	hasCompanyRelation: boolean = false;
	hasMarketingContribution: boolean = false;
	hasRecurringInterval: boolean = false;
	hasContributionMargin: boolean = false;

	constructor() {
		this.currentUpdate = undefined;
	}

	getFeatureState = (hasContributionMargin: boolean) => {
		this.hasContributionMargin = hasContributionMargin;
		this.hasCompanyRelation = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.COMPANY_RELATIONS);
		this.hasMarketingContribution = Tools.FeatureHelper.hasSoftDeployAccess('ORDER_MARKETING_CONTRIBUTION');

		const metadata = Tools.AppService.getMetadata();
		const hasRecurringSalesModel = metadata.params.SalesModel === 'rr';
		const hasRecurringOrder = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.RECURRING_ORDER);

		this.hasRecurringInterval = hasRecurringOrder && hasRecurringSalesModel;
	};

	getAllowedFieldsToUpdate = (updatedFields: UpdatedFields) => {
		return Object.fromEntries(
			Object.entries(updatedFields).filter(([field]) => allowedOrderFields.includes(field as string))
		) as OrderUpdatedFields;
	};

	getOrderStage = (stageId: number) => {
		const stages = Tools.AppService.getStages('all', false);
		return stages.find(stage => stage.id === stageId);
	};

	getClientConnection = (
		relatedAccounts: ConnectedClient[],
		currentClientConnection?: Order['clientConnection'],
		id?: number
	): Order['clientConnection'] | ConnectedClient => {
		if (!id) {
			return undefined;
		}
		const foundClientConnection = relatedAccounts.find(ra => ra.id === id);
		return foundClientConnection || currentClientConnection;
	};

	getProject = async (currentProject: Order['project'], id?: number): Promise<Order['project']> => {
		try {
			const { data: project } = await ProjectResource.get(id);
			return project || currentProject;
		} catch (err) {
			return currentProject;
		}
	};

	getSalesCoach = (salesCoaches: SalesCoach[], currentSalesCoach: SalesCoach, id?: number): SalesCoach[] => {
		const foundSalesCoach = salesCoaches.find(sc => sc.id === id);
		if (!foundSalesCoach) {
			return currentSalesCoach ? [currentSalesCoach] : [];
		}
		return [foundSalesCoach];
	};

	pickAllowedCustomFields = (customFields: Order['custom'] | OrderRow['custom']): AllowedCustomField[] => {
		return customFields.map(customField => {
			const allowedFields: AllowedCustomField = pick(customField, allowedCustomFields);
			return allowedFields;
		});
	};

	getCustomFieldValue = (customField: CustomFieldWithValue, updatedValue: string | boolean | Date | null) => {
		if (customField.datatype === 'Boolean') {
			return `${updatedValue}` === 'false' || `${updatedValue}` === '0' || !updatedValue ? false : true;
		}
		return updatedValue;
	};

	updateCustomFields = (
		entity: Order | OrderRow,
		updatedCustomFields: Order['custom'] | OrderRow['custom'],
		productCategories: ProductCategory[] = []
	) => {
		const allowedCustomFieldChanges = this.pickAllowedCustomFields(updatedCustomFields);
		let existingCustomFields = [...(entity.custom ?? [])];
		allowedCustomFieldChanges.forEach(changedItem => {
			const indexToReplace = existingCustomFields.findIndex(item => item.id === changedItem.id);
			if (indexToReplace === -1) {
				return;
			}
			const existingCustomField = existingCustomFields[indexToReplace];
			if (nonAllowedCustomDataTypes.includes(existingCustomField.datatype)) {
				return;
			}

			const hiddenByProductCategory = this.isHiddenByProductCategory(
				entity,
				existingCustomField,
				productCategories
			);
			if (hiddenByProductCategory) {
				return;
			}

			const updatedItem: any = {
				...existingCustomField,
				...changedItem
			};

			let value;
			if (has(changedItem, 'value')) {
				value = this.getCustomFieldValue(existingCustomField, changedItem.value ?? null);
				updatedItem.value = value;
			}

			existingCustomFields = replaceItem(existingCustomFields, indexToReplace, updatedItem) as OrderCustomField[];

			if (entity.$mappedCustom?.[changedItem.id]) {
				if (has(changedItem, 'value')) {
					entity.$mappedCustom[changedItem.id].value = value ?? null;
				}

				if (has(changedItem, 'name')) {
					entity.$mappedCustom[changedItem.id].name = changedItem.name;
				}

				if (has(changedItem, 'default')) {
					entity.$mappedCustom[changedItem.id].default = changedItem.default;
				}
			}
		});

		return existingCustomFields;
	};

	getProduct = async (productId: number) => {
		try {
			const { data: product } = await ProductResource.get(productId);
			return product;
		} catch (err) {
			logError(err, `onOrderEditHelpers:getProduct:${productId}`);
			return null;
		}
	};

	updateBundleRows = (existingBundleRows: BundleRow[] = [], updatedBundleRows: BundleRow[] = []) => {
		if (!existingBundleRows.length) {
			return updatedBundleRows;
		}
		return existingBundleRows.map((existingRow, index) => {
			const matchedUpdatedBundleRow = updatedBundleRows.find(updatedRow => {
				const idMatch =
					(updatedRow.id ?? undefined) !== undefined &&
					(existingRow.id ?? undefined) !== undefined &&
					updatedRow.id === existingRow.id;

				if (idMatch) {
					return true;
				}

				const uuidMatch =
					(updatedRow.uuid ?? undefined) !== undefined &&
					(existingRow.uuid ?? undefined) !== undefined &&
					updatedRow.uuid === existingRow.uuid;

				return uuidMatch;
			});

			if (!matchedUpdatedBundleRow) {
				return existingRow;
			}

			let bundleProduct = undefined;
			if (existingRow.product) {
				bundleProduct = existingRow.product;
			}
			if (matchedUpdatedBundleRow.product) {
				if (bundleProduct) {
					bundleProduct = { ...bundleProduct, ...matchedUpdatedBundleRow.product };
				} else {
					bundleProduct = matchedUpdatedBundleRow.product;
				}
			}

			const updatedBundleRow: BundleRow = {
				...existingRow,
				...matchedUpdatedBundleRow,
				product: bundleProduct
			};
			return updatedBundleRow;
		});
	};

	isHiddenByProductCategory = (
		entity: Order | OrderRow,
		customField: OrderCustomField,
		productCategories: ProductCategory[]
	) => {
		if (!('product' in entity)) {
			return false;
		}

		const productCategoryId = entity.product?.category?.id;
		if (!productCategoryId) {
			return false;
		}

		const productCategory = productCategories.find(category => {
			return category.id === productCategoryId;
		});

		if (!productCategory) {
			return false;
		}

		if (!productCategory.orderRowFields?.length) {
			return false;
		}

		const hiddenByProductCategory = !productCategory.orderRowFields.some(field => field.id === customField.id);

		return hiddenByProductCategory;
	};

	updateOrderRows = async (
		order: Order,
		updatedOrderRows: Order['orderRow'] = [],
		productCategories: ProductCategory[]
	) => {
		for (const updatedRow of updatedOrderRows) {
			const foundOrderRow = order.orderRow.find(row => {
				return (row.id && row.id === updatedRow.id) || row.sortId === updatedRow.sortId;
			});
			if (!foundOrderRow) {
				continue;
			}

			const productChanged = !!updatedRow.product && updatedRow.product?.id !== foundOrderRow.product?.id;

			const productIdChanged = !!updatedRow.productId && updatedRow.productId !== foundOrderRow.productId;
			if (productChanged || productIdChanged) {
				const productId = updatedRow.product?.id ?? updatedRow.productId;
				const foundProduct = await this.getProduct(productId);
				if (foundProduct) {
					foundOrderRow.product = foundProduct;
					foundOrderRow.productId = foundProduct.id;
				}
			}

			if (has(updatedRow, 'bundleRows')) {
				foundOrderRow.bundleRows = this.updateBundleRows(foundOrderRow.bundleRows, updatedRow.bundleRows);
			}

			if (has(updatedRow, 'discount')) {
				foundOrderRow.discount = updatedRow.discount ?? 0;
				// editOrder.js requires us to set this
				(foundOrderRow as OrderRow & { $discount: number }).$discount = updatedRow.discount ?? 0;
			}

			if (has(updatedRow, '$discountPercent')) {
				// editOrder.js requires us to set this
				(foundOrderRow as OrderRow & { $discountPercent: number }).$discountPercent =
					(updatedRow as OrderRowInternalState).discountPercent ?? 0;
			}

			if (updatedRow.custom) {
				foundOrderRow.custom = this.updateCustomFields(foundOrderRow, updatedRow.custom, productCategories);
			}

			if (has(updatedRow, 'price')) {
				foundOrderRow.price = updatedRow.price ?? 0;
			}

			if (has(updatedRow, 'listPrice')) {
				foundOrderRow.listPrice = updatedRow.listPrice ?? 0;
			}

			if (has(updatedRow, 'quantity')) {
				foundOrderRow.quantity = updatedRow.quantity;
			}

			if ((this.hasContributionMargin || this.hasRecurringInterval) && has(updatedRow, 'purchaseCost')) {
				foundOrderRow.purchaseCost = updatedRow.purchaseCost ?? 0;
			}
		}
		return order.orderRow;
	};

	getUpdatedOrder = async (
		order: Order,
		updatedFields: UpdatedFields,
		relatedAccounts: ConnectedClient[] = [],
		salesCoaches: SalesCoach[] = [],
		productCategories: ProductCategory[]
	) => {
		if (!updatedFields) {
			return order;
		}

		const onlyAllowedUpdatedFields = this.getAllowedFieldsToUpdate(updatedFields);

		const {
			custom: updatedCustomFields,
			stage,
			clientConnection,
			project,
			salesCoach,
			marketingContribution,
			recurringInterval,
			orderRow: updatedOrderRows,
			...orderUpdatedFields
		} = onlyAllowedUpdatedFields;

		if (stage?.id && stage.id !== order.stage?.id) {
			const foundStage = this.getOrderStage(stage.id);
			if (foundStage) {
				order.stage = foundStage;
			}
		}

		if (
			this.hasCompanyRelation &&
			!isEmpty(clientConnection) &&
			clientConnection?.id !== order.clientConnection?.id
		) {
			order.clientConnection = this.getClientConnection(
				relatedAccounts,
				order.clientConnection,
				clientConnection?.id
			);
		}

		if (!isEmpty(project) && project?.id !== order?.project?.id) {
			if (project.id) {
				this.currentProductFetch = makeCancelable(this.getProject(order.project, project?.id));
				order.project = await this.currentProductFetch.promise;
			} else {
				order.project = undefined;
			}
		}

		// An order/opportunity can only have one sales coach, but it is saved in array
		if (!isEmpty(salesCoach) && salesCoach.id !== order.salesCoach?.[0]?.id) {
			order.salesCoach = this.getSalesCoach(salesCoaches, order.salesCoach?.[0], salesCoach?.id);
		}

		if (marketingContribution !== undefined && this.hasMarketingContribution) {
			order.marketingContribution = marketingContribution;
		}

		if (this.hasRecurringInterval && recurringInterval !== undefined) {
			const validIntervals = Tools.AppService.getStaticValues('recurringInterval').map(interval =>
				parseInt(interval.id)
			);

			if (validIntervals.includes(recurringInterval)) {
				order.recurringInterval = recurringInterval;
			}
		}

		if (updatedCustomFields) {
			order.custom = this.updateCustomFields(order, updatedCustomFields);
		}

		if (updatedOrderRows) {
			order.orderRow = await this.updateOrderRows(order, updatedOrderRows, productCategories);
		}

		return { ...order, ...orderUpdatedFields };
	};

	cancelCurrentUpdate = () => {
		this.currentUpdate?.cancel();
		this.currentProductFetch?.cancel();
	};

	updateOrder = async (
		order: Order,
		updatedFields: UpdatedFields,
		relatedAccounts: ConnectedClient[] = [],
		salesCoaches: SalesCoach[] = [],
		hasContributionMargin: boolean,
		productCategories: ProductCategory[] = []
	) => {
		this.cancelCurrentUpdate();
		this.getFeatureState(hasContributionMargin);

		this.currentUpdate = makeCancelable<Order>(
			this.getUpdatedOrder(order, updatedFields, relatedAccounts, salesCoaches, productCategories)
		);
		const updatedOrder = await this.currentUpdate.promise;
		return updatedOrder;
	};

	/*
		If we have fields on the orders (which are not custom fields) that for some reason is hidden,
		then the applications should not be able to force them to be visible.
		So if they are trying to do it, then we simply remove them from the enquiry
	*/
	omitForcedVisibleFields = (fields: ObjectWithKeys = {}) => {
		return Object.keys(fields).reduce((onlyHiddenFields: ObjectWithKeys, fieldPath) => {
			// Only custom fields can be forced to be visible
			if (fieldPath === 'custom') {
				onlyHiddenFields[fieldPath] = fields[fieldPath];
				return onlyHiddenFields;
			}

			const value = fields[fieldPath];

			if (typeof value === 'object') {
				onlyHiddenFields[fieldPath] = this.omitForcedVisibleFields(value as ObjectWithKeys);
				return onlyHiddenFields;
			}
			if (value === false) {
				onlyHiddenFields[fieldPath] = value;
			}
			return onlyHiddenFields;
		}, {});
	};

	getKeysAsPaths = (
		objectWithKeys: ObjectWithKeys,
		order: Order,
		onlyAddKeyWithThisValue?: boolean,
		path: string = '',
		paths: string[] = [],
		useIdForCustomFields: boolean = false
	) => {
		for (let key of Object.keys(objectWithKeys)) {
			const value = objectWithKeys[key];
			const isNestedObject = typeof value === 'object';

			if (key === 'selectedCurrency') {
				key = 'currency';
			}

			if (!isNestedObject) {
				const compareValue = onlyAddKeyWithThisValue !== undefined;
				if (compareValue) {
					if (onlyAddKeyWithThisValue === value) {
						paths.push(path ? `${path}.${key}` : key);
					}
				} else {
					paths.push(path ? `${path}.${key}` : key);
				}

				if (key === 'discount') {
					// Here we need to add $discount as well,
					// since changes is made to this variable in the view instead of discount
					paths.push(`${path}.$discount`);
				} else if (key === 'discountPercent') {
					paths.push(`${path}.$discountPercent`);
				}
			} else {
				const keyInLowerCase = key.toLocaleLowerCase();
				if (keyInLowerCase.startsWith('orderrow@')) {
					const orderRowId = parseInt(keyInLowerCase.split('@')[1]);
					const index = order.orderRow.findIndex(row => row.id === orderRowId);
					let updatedPath = path ? `${path}.orderRow` : 'orderRow';
					updatedPath += `.[${index}]`;

					if (index !== -1) {
						this.getKeysAsPaths(
							value as ObjectWithKeys,
							order,
							onlyAddKeyWithThisValue,
							updatedPath,
							paths,
							useIdForCustomFields
						);
					}
				} else if (keyInLowerCase === 'custom') {
					const customFieldPath = path ? `${path}.custom` : 'custom';
					const customFields = get(order, customFieldPath, []) as CustomField[];
					Object.entries(value).forEach(([id, customFieldValue]) => {
						let updatedPath = customFieldPath;

						let index = 1;
						if (useIdForCustomFields) {
							updatedPath += `.[${id}]`;
						} else {
							index = customFields.findIndex(field => field.id === parseInt(id));
							updatedPath += `.[${index}]`;
						}

						if (index !== -1) {
							const compareValue = onlyAddKeyWithThisValue !== undefined;
							if (compareValue) {
								if (onlyAddKeyWithThisValue === customFieldValue) {
									paths.push(updatedPath);
								}
							} else {
								paths.push(updatedPath);
							}
						}
					});
				} else if (keyInLowerCase === 'orderrow') {
					order.orderRow.forEach((row, index) => {
						let updatedPath = path ? `${path}.orderRow` : 'orderRow';
						updatedPath += `.[${index}]`;
						this.getKeysAsPaths(
							value as ObjectWithKeys,
							order,
							onlyAddKeyWithThisValue,
							updatedPath,
							paths,
							useIdForCustomFields
						);
					});
				} else {
					this.getKeysAsPaths(
						value as ObjectWithKeys,
						order,
						onlyAddKeyWithThisValue,
						path ? `${path}.${key}` : key,
						paths,
						useIdForCustomFields
					);
				}
			}
		}
		return paths;
	};

	getCustomFieldIdFromPath = (path: string) => {
		const customFieldParts = path.split('.');

		const lastPart = customFieldParts.pop();
		if (!lastPart) {
			return null;
		}
		const id = parseInt(lastPart.replace(/\[(\d+)\]/, '$1'));
		return !isNaN(id) ? id : null;
	};

	revertChangesIfAffectedField = (
		orderBefore: Order,
		currentOrder: Order,
		affectedFields: ObjectWithKeys,
		revertIfValue: boolean
	) => {
		const fieldPathsWithId = ['project', 'clientConnection'];
		const revertedFields: UpdatedFields = {};

		const keysAsPaths = this.getKeysAsPaths(affectedFields, orderBefore, revertIfValue);
		for (let fieldPath of keysAsPaths) {
			if (fieldPath.endsWith('.all')) {
				const fieldPathWithoutAll = fieldPath.replace(/\.all$/, '');
				const fieldValueBefore = get(orderBefore, fieldPathWithoutAll);
				set(revertedFields, fieldPathWithoutAll, fieldValueBefore);
			} else {
				const fieldValueBefore = get(orderBefore, fieldPath);
				const fieldValueAfter = get(currentOrder, fieldPath);

				if (fieldValueBefore !== fieldValueAfter) {
					const orderRowPath = fieldPath.split('.').slice(0, 2).join('.');
					if (fieldPath.startsWith('orderRow.')) {
						const orderRowIdPath = `${orderRowPath}.id`;
						if (fieldPath.endsWith('.all')) {
							set(revertedFields, orderRowPath, get(orderBefore, orderRowPath));
						} else {
							set(revertedFields, orderRowIdPath, get(orderBefore, orderRowIdPath));
						}
					}
					if (fieldPath.endsWith('$discount')) {
						const orderRowDiscountPath = `${orderRowPath}.discount`;
						set(revertedFields, orderRowDiscountPath, fieldValueBefore);
					}
					if (fieldPathsWithId.includes(fieldPath) && !fieldValueBefore) {
						fieldPath += '.id';
					}
					set(revertedFields, fieldPath, fieldValueBefore);
				}
			}
		}
		return { order: revertedFields };
	};

	mergeRevertedFieldWithUpdatedFields = (revertedFields: any, updatedFields: any) => {
		return merge(revertedFields, updatedFields, (objValue, srcValue) => {
			if (isArray(objValue)) {
				return srcValue;
			}
		});
	};
}

const onOrderEditHelpers = new OnOrderEditHelpers();

export default onOrderEditHelpers;
