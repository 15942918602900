import { status } from 'App/resources/MailCampaign';
import { NOTIFICATION_TYPES } from 'App/babel/enum/notification';
import { Text } from '@upsales/components';
import { CurrencyFormat } from 'App/babel/utils/numberFormat';
import t from 'Components/Helpers/translate';
const MAIL_CAMPAIGN_STATUS = status;

ReactTemplates.navbar.notificationAlert = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			didSign: t('esign.didSign').toLowerCase(),
			viewed: t('esign.viewed').toLowerCase(),
			rejected: t('esign.rejected').toLowerCase(),
			undelivered: t('esign.undelivered').toLowerCase(),
			sharedView: t('notifications.sharedView').toLowerCase(),
			sharedViewWithYou: t('notifications.sharedView.withYou').toLowerCase(),
			didAssign: t('default.didAssign').toLowerCase(),
			yourMail: t('mail.yourMail'),
			toYou: t('default.toYou').toLowerCase(),
			to: t('default.to').toLowerCase(),
			worth: t('notifications.order.value.worth').toLowerCase(),
			mail: {
				soft_bounce: t('mail.bounced').toLowerCase(),
				hard_bounce: t('mail.bounced').toLowerCase(),
				error: t('mail.failed').toLowerCase()
			},
			createdAgreement: t('default.createdAgreement').toLowerCase(),
			yearlyValue: t('default.yearlyValue').toLowerCase(),
			with: t('default.with').toLowerCase(),
			at: t('default.at').toLowerCase(),
			formSubmited: t('event.form.submited').toLowerCase(),
			visited: t('event.visited').toLowerCase(),
			failed: t('noti.failed').toLowerCase(),
			oneOffValue: t('default.oneOffValue'),
			monthlyValue: t('default.monthlyValue'),
			annualValue: t('default.annualValue'),
			done: t('default.done').toLowerCase(),
			export: t('export.export').toLowerCase(),
			exporting: t('export.exporting'),
			hadMarketingEvent: t('event.hadMarketingEvent'),
			closedDeal: t('default.closedDeal')
		};
	},
	getIconClass: function () {
		var notification = this.props.notification;
		var classNames = ['fa', 'Icon--green'];

		if (notification.type === NOTIFICATION_TYPES.PROSPECTING_MONITOR_AMENDMENT) {
			classNames.push('Icon Icon-bell');
		}
		if (notification.type === NOTIFICATION_TYPES.ADDON_PROMOTION) {
			classNames.push('Icon Icon-warning');
		}
		if (
			notification.type === NOTIFICATION_TYPES.TICKET ||
			notification.type === NOTIFICATION_TYPES.TICKET_ASSIGNED ||
			notification.type === NOTIFICATION_TYPES.TICKET_ACCOUNT_MANAGERS ||
			notification.type === NOTIFICATION_TYPES.TICKET_REPLIES
		) {
			classNames.push('Icon Icon-customer-support');
		}
		if (notification.type === 'Order' || notification.type === 'Agreement') {
			classNames.push('fa-dollar');
		}
		if (notification.type === 'Assigned') {
			classNames.push('fa-star');
		}
		if (notification.type === 'ListView' || notification.type === 'ReportView') {
			classNames.push('fa-eye');
		}
		if (notification.type === 'Submit') {
			classNames.push('fa-file');
		}
		if (notification.type === 'Mail') {
			classNames.push('fa-envelope');

			if (notification.action.indexOf('bounce') >= 0) {
				classNames.push('failed-notification-mail');
			}
		}
		if (notification.type === 'Visit') {
			classNames.push('fa-globe');
		}
		if (notification.type === 'MarketingCustom') {
			classNames.push('fa-bullseye');
		}
		if (notification.type === 'AppCallback') {
			if (notification.message && notification.message.includes('"easybooking"')) {
				classNames.push('fa-calendar');
			} else {
				classNames.push('fa-info-circle');
			}
		}
		if (notification.type === 'Job' && notification.status !== 100 && notification.status !== -1) {
			classNames.push('fa-circle-o-notch');
		}
		if (notification.type === 'Job' && notification.status === -1) {
			classNames.push('fa-times');
		}
		if (notification.type === 'Job' && notification.status !== 100 && notification.status !== -1) {
			classNames.push('fa-spin');
		}
		if (notification.type === 'Job' && notification.status === 100) {
			classNames.push('fa-check-circle-o');
		}
		if (notification.type === 'Job' && notification.status === -1) {
			classNames.push('fa-times');
		}
		if (notification.type === 'System' && notification.action === 'message') {
			classNames.push('fa-exclamation-circle');
		}
		if (notification.type === 'System' && notification.action === 'alert') {
			classNames.push('fa-exclamation-triangle');
		}
		if (notification.type === 'Export' && notification.status !== -1) {
			classNames.push('fa-check-square-o');
		}
		if (notification.type === 'Export' && notification.status === -1) {
			classNames.push('fa-times');
		}
		if (notification.type === 'Import' && notification.status !== -1) {
			classNames.push('fa-check-square-o');
		}
		if (notification.type === 'Import' && notification.status === -1) {
			classNames.push('fa-times');
		}
		if (notification.type === 'Esign') {
			classNames.push('fa-edit');
		}
		if (notification.type === 'Mention') {
			classNames.push('fa-comment');
		}
		if (notification.type === 'Default') {
			classNames.push('fa-info-circle');
		}
		if (notification.type === 'MailCampaign' && notification.status === -1) {
			classNames.push('fa-exclamation-triangle');
		}
		if (notification.type === 'Provisioning') {
			classNames.push('Icon Icon-tiers');
		}
		if (notification.type === 'prospecting:new:signals') {
			classNames.push('fa-rss');
		}
		if (notification.type === 'prospecting:news') {
			classNames.push('fa-newspaper');
		}

		return classNames.join(' ');
	},
	getContent: function () {
		var notification = this.props.notification;
		var contact = null;
		var metadata = Tools.AppService.getMetadata();
		const defaultCurrencyIso = metadata.customerCurrencies.find(c => c.masterCurrency)?.iso;

		switch (notification.type) {
			case NOTIFICATION_TYPES.ADDON_PROMOTION: {
				return <span>{t('prospecting.promoNotification.title')}</span>;
			}
			case NOTIFICATION_TYPES.PROSPECTING_MONITOR_AMENDMENT: {
				return (
					<span>
						{t('notifications.prospectingMonitor.amendments')} <b>{notification.client.name}</b>
					</span>
				);
			}
			case 'prospecting:signals:general':
			case 'prospecting:signals:accounts':
			case 'prospecting:signals:directors':
				return (
					<span>
						{t('notifications.prospectingSignals.new', { count: 1 })} <b>{notification.client.name}</b>
					</span>
				);
			case 'prospecting:news': {
				const obj = JSON.parse(notification.message);
				return (
					<span>
						{t('notifications.prospectingNews.body', { count: obj.newsCount })}{' '}
						<b>{notification.client.name}</b>
					</span>
				);
			}
			case 'prospecting:new:signals':
				return (
					<span>
						{t('notifications.prospectingSignals.new', { count: notification.message })}{' '}
						<b>{notification.client.name}</b>
					</span>
				);
			case 'Assigned':
				return (
					<span>
						<b>{notification.registeredBy ? notification.registeredBy.name : ''} </b>
						{this.lang.didAssign} <b>{notification.client ? notification.client.name : ''} </b>
						{this.lang.toYou}
					</span>
				);
			case NOTIFICATION_TYPES.TICKET_ASSIGNED: {
				const [, oldUserid] = notification.action.split(':');

				if (parseInt(oldUserid) === Tools.AppService.getSelf()?.id) {
					return (
						<>
							{` ${t('ticket.NoLongerAssigned')} ${notification.message}  ${t(
								'default.on'
							).toLowerCase()} `}
							<b>{notification.client?.name || ''}</b>
						</>
					);
				} else if (notification.action === 'assigned') {
					return (
						<>
							<b>{notification.registeredBy?.name || 'Someone'}</b>
							{` ${t('ticket.assignedTicket').toLowerCase()} ${notification.message} ${t(
								'default.toYou'
							).toLowerCase()}`}
						</>
					);
				} else if (notification.action === 'Closed') {
					const message = Tools.FeatureHelper.hasSoftDeployAccess('TICKET_NOTIFICATION_V2')
						? `${t('ticket.thatIsAssigned').toLowerCase()} ${t('default.toYou').toLowerCase()}`
						: '';
					return (
						<>
							<b>{notification.registeredBy?.name || ''}</b>
							{` ${t('ticket.closedTicket').toLowerCase()} ${notification.message} ${t(
								'default.on'
							).toLowerCase()} `}
							<b>{notification.client?.name || ''}</b>
							{message}
						</>
					);
				} else {
					return (
						<>
							<b>{notification.registeredBy?.name || ''}</b>
							{` ${t('ticket.assignedTicket').toLowerCase()} ${notification.message} ${t(
								'default.on'
							).toLowerCase()} `}
							<b>{notification.client?.name || ''}</b>
						</>
					);
				}
			}
			case NOTIFICATION_TYPES.TICKET_REPLIES: {
				return (
					<>
						{t('ticket.newMessage') + ' '}
						{notification.message}
						{' ' + t('default.on').toLowerCase() + ' '}
						{notification.client?.name}
					</>
				);
			}
			case NOTIFICATION_TYPES.TICKET_ACCOUNT_MANAGERS:
			case NOTIFICATION_TYPES.TICKET: {
				if (notification.action === 'Closed') {
					const message = Tools.FeatureHelper.hasSoftDeployAccess('TICKET_NOTIFICATION_V2')
						? `${t('ticket.thatIsAssigned').toLowerCase()} ${t('default.toYou').toLowerCase()}`
						: '';
					return (
						<>
							<b>{notification.registeredBy?.name || ''}</b>
							{` ${t('ticket.closedTicket').toLowerCase()} ${notification.message} ${t(
								'default.on'
							).toLowerCase()} `}
							<b>{notification.client?.name || ''}</b>
							{message}
						</>
					);
				}
				const userCreated = !!notification.registeredBy;
				return (
					<span>
						<b>{userCreated ? notification.registeredBy?.name : notification.contact?.name || ''} </b>
						{` ${t(userCreated ? 'ticket.createdTheTicket' : 'ticket.sentInTheTicket').toLowerCase()} ${
							notification.message
						}`}
					</span>
				);
			}
			case 'Order':
				var salesModel = metadata.params.SalesModel;
				var salesModelOption = metadata.params.SalesModelOption;
				var showContributionMargin = salesModel === 'cm' && salesModelOption === 'cm';
				var showRecurring = salesModel === 'rr';
				var showARR = showRecurring && salesModelOption === 'arr';

				var orderValue = 0;
				var recurringValue = 0;
				var oneOffValue = 0;
				var displayRecurring, displayARR, displayMRR, displayOneOff;

				if (notification.order) {
					orderValue = notification.order.orderValue;

					if (showContributionMargin) {
						orderValue = notification.order.contributionMarginLocalCurrency;
					} else if (showRecurring) {
						let formatted;
						if (showARR) {
							recurringValue = notification.order.annualValue;
							formatted = new CurrencyFormat(notification.order.currency || defaultCurrencyIso).short(
								notification.order.annualValue
							);
							displayARR = recurringValue ? `${formatted} (${this.lang.annualValue})` : null;
						} else {
							recurringValue = notification.order.monthlyValue;
							formatted = new CurrencyFormat(notification.order.currency || defaultCurrencyIso).short(
								notification.order.monthlyValue
							);
							displayMRR = recurringValue ? `${formatted} (${this.lang.monthlyValue})` : null;
						}

						oneOffValue = notification.order.oneOffValue;
						formatted = new CurrencyFormat(notification.order.currency || defaultCurrencyIso).short(
							notification.order.oneOffValue
						);
						displayOneOff = oneOffValue ? `${formatted} (${this.lang.oneOffValue})` : null;

						displayRecurring = displayARR || displayMRR;
						if (displayOneOff) {
							if (displayRecurring) {
								displayRecurring = displayRecurring + ' & ' + displayOneOff;
							} else {
								displayRecurring = displayOneOff;
							}
						}
					}
				}

				return (
					<span>
						<b>{notification.registeredBy ? notification.registeredBy.name : ''} </b>
						{this.lang.closedDeal} {this.lang.with}{' '}
						<b>{notification.client ? notification.client.name : ''} </b>
						<b>
							{this.worth}
							<span>
								{showRecurring ? displayRecurring : null}
								{!showRecurring
									? new CurrencyFormat(
											(notification.order && notification.order.currency) || defaultCurrencyIso
									  ).short(orderValue)
									: null}
							</span>
						</b>
					</span>
				);
			case 'ReportView':
			case 'ListView':
				return (
					<span>
						<b>{notification.registeredBy ? notification.registeredBy.name : ''} </b>
						<span>{this.lang.sharedView}</span>
						<b>{' ' + notification.message}</b>
						<span>{' ' + this.lang.sharedViewWithYou + '.'}</span>
					</span>
				);
			case 'Esign':
				var status = '';
				switch (notification.action) {
					case 'signed':
						status = this.lang.didSign;
						break;
					case 'seen':
						status = this.lang.viewed;
						break;
					case 'declined':
						status = this.lang.rejected;
						break;
					case 'undelivered':
						status = this.lang.undelivered;
						break;
				}
				var fileName = '';
				if (notification.esign) {
					if (notification.esign.file) {
						fileName = notification.esign.file.filename;
					} else if (notification.esign.title) {
						fileName = notification.esign.title;
					}
				}
				return (
					<span>
						<b>{notification.contact ? notification.contact.name : notification.registeredBy.name}</b>{' '}
						<span>{status}</span> <b>{fileName}</b>
					</span>
				);
			case 'Agreement':
				return (
					<span>
						<b>{notification.registeredBy ? notification.registeredBy.name : ''} </b>
						{this.lang.createdAgreement} {this.lang.with}{' '}
						<b>{notification.client ? notification.client.name : ''}</b>{' '}
						<b>
							{'('}
							<span>
								{this.lang.yearlyValue}{' '}
								{new CurrencyFormat(
									(notification.agreement && notification.agreement.currency) || defaultCurrencyIso
								).short(notification.agreement ? notification.agreement.yearlyValue : 0)}
							</span>
							{')'}
						</b>
					</span>
				);
			case 'Submit':
				return (
					<span>
						<b>{notification.contact ? notification.contact.name : ''} </b>
						{this.lang.at} <b>{notification.client ? notification.client.name : ''} </b>
						{this.lang.formSubmited} <b>{notification.form ? notification.form.name : ''}</b>
					</span>
				);
			case 'MailCampaign':
				if (notification.action === MAIL_CAMPAIGN_STATUS.NO_UNSUB && notification.mailCampaign) {
					return (
						<span>
							{Tools.$translate('groupMail.wasNotSentMissingUnsub', {
								NAME: notification.mailCampaign.name
							})}
						</span>
					);
				}
				break;
			case 'Visit':
				contact = null;
				if (notification.contact && notification.contact.name) {
					contact = (
						<span>
							<b>{notification.contact.name}</b> {this.lang.at}{' '}
						</span>
					);
				}
				return (
					<span>
						{contact}
						<b>{notification.client ? notification.client.name : ''} </b>
						{this.lang.visited}
					</span>
				);
			case 'Mail':
				return <span>{this.lang.yourMail + ' ' + this.lang.mail[notification.action]}</span>;
			case 'MarketingCustom':
				contact = null;
				if (notification.contact && notification.contact.name) {
					contact = (
						<span>
							<b>{notification.contact.name}</b> {this.lang.at}{' '}
						</span>
					);
				}
				return (
					<span>
						{contact}
						<b>{notification.client ? notification.client.name : ''} </b>
						{this.lang.hadMarketingEvent} <i>{notification.message ? notification.message : ''}</i>
					</span>
				);
			case 'AppCallback': {
				if (notification.message && notification.message.includes('"easybooking"')) {
					return <span>{Tools.$translate('notifications.newEasyBooking')}</span>;
				} else if (notification.message === 'invalid_grant') {
					const appName = notification.action;
					const thirdPartyName = appName.indexOf('Office 365') === 0 ? 'Office 365' : appName;
					const message = Tools.$translate('error.invalidGrant', { appName, thirdPartyName });
					return <span>{message}</span>;
				}

				return (
					<span>
						<b>{notification.action}</b> <i>{notification.message ? notification.message : ''}</i>
					</span>
				);
			}
			case 'SharedEasyBooking':
				return <span>{t(notification.action)}</span>;
			case 'Default':
				if (notification.action === 'ActivityReminder') {
					return <span>{Tools.$translate('notifications.newActivityReminders')}</span>;
				}
				return (
					<span>
						<i>{notification.message ? Tools.$translate(notification.message) : ''}</i>
					</span>
				);
			case 'EasyBookingIntegrationError':
				return <span>{t('admin.appointmentavailability.notification.integrationBrokenText')}</span>;
			case 'System':
				return <span>{notification.message}</span>;
			case 'Provisioning': {
				const nameLangTag = `admin.billing.addon.${notification.message}.name`;
				const translatedAddonName = Tools.$translate(nameLangTag);
				const missingName = translatedAddonName === nameLangTag;
				// Make sure we do not show lang tag if missing name
				const addonName = missingName ? Tools.$translate('admin.billing.addon.anAddon') : translatedAddonName;
				return (
					<span className="provisioning-notification">
						<Text size="sm">
							{notification.status === 100
								? Tools.$translate('admin.billing.addon.reachedNextTierOn')
								: Tools.$translate('admin.billing.addon.boutToReachNextTierOn')}{' '}
						</Text>
						<Text size="sm" bold={!missingName}>
							{addonName}
							{'. '}
						</Text>
					</span>
				);
			}
			case 'Mention': {
				const mentioner = `${notification.registeredBy?.name || t('notificationcenter.mention.someone')}`;
				return <span>{`${mentioner} ${t('notificationcenter.mention.you')}`}</span>;
			}
			case 'Job':
				var text = null;

				if (notification.name) {
					switch (notification.status) {
						case -1:
							text = (
								<span>
									{t(notification.action)} {notification.name} {this.lang.failed}
								</span>
							);
							break;
						case 100:
							text = (
								<span>
									{t(notification.action)} {notification.name} {this.lang.done}
								</span>
							);
							break;
						default:
							text = (
								<span>
									{t(notification.action)} {notification.name}
								</span>
							);
							break;
					}
				} else {
					const name = notification.udo?.name ?? '';
					const action =
						notification.action.indexOf('prospecting-save-bulk:') === 0
							? 'prospecting-save-bulk'
							: notification.action;

					switch (notification.status) {
						case -1:
							if (notification.action.indexOf('prospecting-save-bulk:') === 0) {
								text = <span>{t('noti.prospecting-failed-to-add-companies')}</span>;
							} else {
								text = (
									<span>
										{t('noti.' + action + '-failed', { total: notification.message, name })}
									</span>
								);
							}
							break;
						case 100:
							var splt = (notification.message || '').toString().split(':');
							if (
								!['merge-client', 'merge-contact', 'merge-activity', 'merge-appointment'].includes(
									notification.action
								) &&
								splt &&
								splt.length === 2
							) {
								const changed = splt[0] ? splt[0] : 0;
								const total = splt[1] ? splt[1] : 0;
								text = (
									<span>
										{t('noti.' + action + '-partial-done', {
											changed,
											total,
											name
										})}{' '}
										<br /> {t('default.missingRights')}
									</span>
								);
								/* eslint-disable eqeqeq */
							} else if (notification.message == 0) {
								/* eslint-enable eqeqeq */
								text = (
									<span>
										{t('noti.' + action + '-noop-done', {
											total: notification.message,
											name
										})}{' '}
										<br /> {t('default.missingRights')}
									</span>
								);
							} else {
								if (notification.action.indexOf('prospecting-save-bulk:') === 0) {
									text = <span>{t('noti.prospecting-added-companies')}</span>;
								} else {
									const total = notification.message === '0' ? '' : notification.message;
									text = (
										<span>
											{t('noti.' + action + '-done', {
												total,
												name
											})}
										</span>
									);
								}
							}
							break;
						case 0:
							if (notification.action.indexOf('prospecting-save-bulk:') === 0) {
								text = <span>{t('noti.prospecting-adding-companies')}</span>;
							} else {
								text = <span>{t('noti.' + notification.action, { total: '', name })}</span>;
							}
							break;
						default:
							text = (
								<span>
									{t('noti.' + notification.action, {
										total: notification.message === '0' ? '' : notification.message,
										name
									})}
								</span>
							);
							break;
					}
				}
				return <span>{text}</span>;
			case 'Export':
				var exportText = null;
				if (notification.name) {
					switch (notification.status) {
						case -1:
							exportText = (
								<span>
									{notification.name} {this.lang.export} {this.lang.failed}
								</span>
							);
							break;
						case 100:
							exportText = (
								<span>
									{notification.name} {this.lang.export} {this.lang.done}
								</span>
							);
							break;
						default:
							exportText = (
								<span>
									{this.lang.exporting} {notification.name}
								</span>
							);
							break;
					}
				} else {
					switch (notification.status) {
						case -1:
							exportText = <span>{t('noti.export.' + notification.action + '-failed')}</span>;
							break;
						case 100:
							exportText = <span>{t('noti.export.' + notification.action + '-done')}</span>;
							break;
						default:
							exportText = <span>{t('noti.export.' + notification.action)}</span>;
							break;
					}
				}
				return <span>{exportText}</span>;
			case 'Import':
				var importText = null;

				switch (notification.status) {
					case -1:
						importText = <span>{t('noti.import-failed')}</span>;
						break;
					case 100:
						importText = <span>{t('noti.import-done')}</span>;
						break;
					default:
						var total = ((notification.message || 'r0').split(':')[0] || 'r0').replace('r', '');
						importText = <span>{t('noti.importingRows', { total: total })}</span>;
						break;
				}
				return <span>{importText}</span>;
		}
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	render: function () {
		var self = this;
		var notification = self.props.notification;
		var iconClass = null;
		var content = null;
		var classNames = 'notification-alert';

		if (notification) {
			iconClass = self.getIconClass();
			content = self.getContent();
			classNames += ' visible';
		} else {
			classNames += ' visible not-visible';
		}

		return (
			<div className={classNames} ref={self.setRef.bind(self, '_root')}>
				{notification ? <b className={iconClass}></b> : null}
				{content}
			</div>
		);
	}
});
