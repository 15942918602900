import React, { useEffect, useRef, useState } from 'react';
import { ColumnType, ProductTableColumn } from './DocumentTemplateEditor';
import { Column, Row, Text, Tooltip } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import bemClass from '@upsales/components/Utils/bemClass';
import './DocumentTemplatePreviewProductTable.scss';

interface Props {
	productTableConfig: ColumnType;
}

type ProductTag = {
	name: string;
	description: string;
};

type SummaryTag = {
	listPrice: string;
	discount: string;
	price: string;
};

type Tags = {
	product: ProductTag;
	priceOneOff: string;
	priceRecurring: string;
	quantity: string;
	summary: SummaryTag;
	recurringListPrice: string;
	recurringPrice: string;
	oneOffListPrice: string;
	oneOffPrice: string;
	totalPrice: string;
	totalListPrice: string;
};

const DocumentTemplatePreviewProductTable = ({ productTableConfig }: Props) => {
	const { t } = useTranslation();
	const lang = {
		tag: t('admin.documentTemplateEditor.productTable.tag'),
		totalPriceRecurring: t('admin.documentTemplateEditor.productTable.totalPriceRecurring.defaultValue'),
		totalPriceOneOff: t('admin.documentTemplateEditor.productTable.totalPriceOneOff.defaultValue'),
		totalPrice: t('admin.documentTemplateEditor.productTable.totalPrice.defaultValue')
	};
	const columnRefs = useRef<(HTMLDivElement | null)[]>([]);
	const [columnWidths, setColumnWidths] = useState<number[]>([]);

	const getColumnWidth = (index: number) => {
		return columnWidths[index] || 0;
	};

	useEffect(() => {
		const widths = columnRefs.current.map(ref => {
			return ref ? ref.offsetWidth : 0;
		});
		setColumnWidths(widths);
	}, [productTableConfig]);

	const classes = new bemClass('DocumentTemplatePreviewProductTable');

	const getProductTablePreviewTag = (productTableType: keyof Tags): string | ProductTag | SummaryTag => {
		const tags: Tags = {
			product: {
				name: '{{product.name}}',
				description: '{{product.description}}'
			},
			priceOneOff:
				'{{#unless product.isRecurring}}{{currencyFormatter (math price "*" ../order.currencyRate) ../order.currency}}{{/unless}}',
			priceRecurring:
				'{{#if product.isRecurring}}{{currencyFormatter (math price "*" ../order.currencyRate) ../order.currency}}{{/if}}',
			quantity: '{{quantity}}',
			summary: {
				listPrice:
					'{{currencyFormatter (math (math listPrice "*" ../order.currencyRate) "*" quantity) ../order.currency}}',
				discount:
					'{{currencyFormatter (math (math (math listPrice "*" ../order.currencyRate) "-" (math price "*" ../order.currencyRate)) "*" quantity) ../order.currency}}',
				price: '{{currencyFormatter (math (math (math price "*" ../order.currencyRate) "*" quantity) "-" (math (math listPrice "*" ../order.currencyRate) "-" (math price "*" ../order.currencyRate))) ../order.currency}}'
			},
			recurringListPrice:
				"{{currencyFormatter (math (sumOrderPrice order.orderRow 'listPrice' 'product.isRecurring' 'include') '*' order.currencyRate)  order.currency}}",
			recurringPrice:
				"{{currencyFormatter (math (sumOrderPrice order.orderRow 'price' 'product.isRecurring' 'include') '*' order.currencyRate)  order.currency}}",
			oneOffListPrice:
				"{{currencyFormatter (math (sumOrderPrice order.orderRow 'listPrice' 'product.isRecurring' 'exclude') '*' order.currencyRate)  order.currency}}",
			oneOffPrice:
				"{{currencyFormatter (math (sumOrderPrice order.orderRow 'price' 'product.isRecurring' 'exclude') '*' order.currencyRate)  order.currency}}",
			totalListPrice:
				"{{currencyFormatter (math (sumOrderPrice order.orderRow 'listPrice' 'product.isRecurring' 'ignore') '*' order.currencyRate) order.currency}}",
			totalPrice:
				"{{currencyFormatter (math (sumOrderPrice order.orderRow 'price' 'product.isRecurring' 'ignore') '*' order.currencyRate) order.currency}}"
		};

		return tags[productTableType];
	};

	const renderProductTableColumnText = (text: string, className?: string, bold?: boolean) => (
		<Tooltip title={text}>
			<Text className={className} size="sm" bold={bold}>
				{text}
			</Text>
		</Tooltip>
	);

	const renderProductTableColumn = (column: ProductTableColumn) => {
		let productTablePreviewTag;
		switch (column.type) {
			case 'product':
				productTablePreviewTag = getProductTablePreviewTag(column.type) as {
					name: string;
					description: string;
				};
				return (
					<>
						{renderProductTableColumnText(productTablePreviewTag.name, '', true)}
						{productTableConfig.showProductDescription &&
							renderProductTableColumnText(productTablePreviewTag.description, '', false)}
					</>
				);

			case 'summary':
				productTablePreviewTag = getProductTablePreviewTag(column.type) as {
					listPrice: string;
					discount: string;
					price: string;
				};
				return productTableConfig.showDiscounts ? (
					<>
						{renderProductTableColumnText(
							productTablePreviewTag.listPrice,
							classes.elem('discount').b(),
							true
						)}
						{renderProductTableColumnText(
							productTablePreviewTag.discount,
							classes.elem('discount-red').b()
						)}
						{renderProductTableColumnText(productTablePreviewTag.price)}
					</>
				) : (
					<>{renderProductTableColumnText(productTablePreviewTag.price)}</>
				);
			case 'priceOneOff':
			case 'priceRecurring':
			case 'quantity':
				productTablePreviewTag = getProductTablePreviewTag(column.type) as string;
				return renderProductTableColumnText(productTablePreviewTag);
			default:
				return renderProductTableColumnText(column.tag.replace(/<\/?p>/g, ''));
		}
	};

	return (
		<div data-testid="table">
			<Row noWrap className={classes.elem('table-row').b()} data-testid="product-table-column-titles">
				{productTableConfig.ProductTableColumns?.map((column: ProductTableColumn, index: number) => {
					if (column.isActive) {
						return (
							<React.Fragment key={'product-table-column-title-rows' + column.title}>
								<Column
									ref={el => (columnRefs.current[index] = el)}
									className={classes.elem('table-header').b()}
									align={column.align as 'right' | 'left'}
									data-testid={'product-table-column-' + index}
								>
									<Text size="sm" bold={true}>
										{column.name}
									</Text>
								</Column>
							</React.Fragment>
						);
					} else {
						return null;
					}
				})}
			</Row>
			<hr />
			<Row noWrap className={classes.elem('table-row').b()}>
				{productTableConfig.ProductTableColumns &&
					productTableConfig.ProductTableColumns.map((column: ProductTableColumn, index: number) => {
						if (column.isActive) {
							return (
								<React.Fragment key={'product-table-column-rows' + column.title}>
									<Column
										align={column.align as 'right' | 'left'}
										className={classes.elem('table-content').b()}
										style={{ width: getColumnWidth(index), flexBasis: 'unset' }}
									>
										{renderProductTableColumn(column)}
									</Column>
								</React.Fragment>
							);
						} else {
							return null;
						}
					})}
			</Row>
			<hr className={classes.elem('table-divider').b()} />
			{productTableConfig.showPriceSummation ? (
				<div data-testid="table-price-summation">
					<Row align="right" className={classes.elem('table-summary').b()}>
						<Column />
						<Column align="left">
							{productTableConfig.ProductTableColumns?.find(obj => obj.type === 'priceRecurring')
								?.isActive ? (
								<Text size="sm" bold={true}>
									{productTableConfig.ProductTableTotalPrice?.recurring || lang.totalPriceRecurring}
								</Text>
							) : null}
						</Column>
						<Column align="left" className={classes.elem('table-summary').b()}>
							{productTableConfig.ProductTableColumns?.find(obj => obj.type === 'priceRecurring')
								?.isActive && productTableConfig.showDiscounts
								? renderProductTableColumnText(
										getProductTablePreviewTag('recurringListPrice') as string,
										classes.elem('discount').b(),
										true
								  )
								: null}
							{productTableConfig.ProductTableColumns?.find(obj => obj.type === 'priceRecurring')
								?.isActive
								? renderProductTableColumnText(
										getProductTablePreviewTag('recurringPrice') as string,
										'',
										true
								  )
								: null}
						</Column>
					</Row>
					<Row align="right" className={classes.elem('table-summary').b()}>
						<Column />
						<Column>
							{productTableConfig.ProductTableColumns?.find(obj => obj.type === 'priceOneOff')
								?.isActive ? (
								<Text size="sm" bold={true}>
									{productTableConfig.ProductTableTotalPrice?.oneOff || lang.totalPriceOneOff}
								</Text>
							) : null}
						</Column>
						<Column align="left" className={classes.elem('table-summary').b()}>
							{productTableConfig.ProductTableColumns?.find(obj => obj.type === 'priceOneOff')
								?.isActive && productTableConfig.showDiscounts
								? renderProductTableColumnText(
										getProductTablePreviewTag('oneOffListPrice') as string,
										classes.elem('discount').b(),
										true
								  )
								: null}
							{productTableConfig.ProductTableColumns?.find(obj => obj.type === 'priceOneOff')?.isActive
								? renderProductTableColumnText(
										getProductTablePreviewTag('oneOffPrice') as string,
										'',
										true
								  )
								: null}
						</Column>
					</Row>
					<Row align="right" className={classes.elem('table-summary').b()}>
						<Column />
						<Column>
							{!productTableConfig.ProductTableColumns?.find(obj => obj.type === 'priceOneOff')
								?.isActive &&
							!productTableConfig.ProductTableColumns?.find(obj => obj.type === 'priceRecurring')
								?.isActive ? (
								<Text size="sm" bold={true}>
									{productTableConfig.ProductTableTotalPrice?.total || lang.totalPrice}
								</Text>
							) : null}
						</Column>
						<Column align="left" className={classes.elem('table-summary').b()}>
							{!productTableConfig.ProductTableColumns?.find(obj => obj.type === 'priceOneOff')
								?.isActive &&
							!productTableConfig.ProductTableColumns?.find(obj => obj.type === 'priceRecurring')
								?.isActive &&
							productTableConfig.showDiscounts
								? renderProductTableColumnText(
										getProductTablePreviewTag('totalListPrice') as string,
										classes.elem('discount').b(),
										true
								  )
								: null}
							{!productTableConfig.ProductTableColumns?.find(obj => obj.type === 'priceOneOff')
								?.isActive &&
							!productTableConfig.ProductTableColumns?.find(obj => obj.type === 'priceRecurring')
								?.isActive
								? renderProductTableColumnText(
										getProductTablePreviewTag('totalPrice') as string,
										'',
										true
								  )
								: null}
						</Column>
					</Row>
				</div>
			) : null}
		</div>
	);
};

export default DocumentTemplatePreviewProductTable;
