import React from 'react';
import PropTypes from 'prop-types';
import {
	Title,
	ButtonGroup,
	Button,
	Label,
	Textarea,
	Card,
	CardContent,
	Text,
	Toggle,
	Input,
	Icon,
	Checkbox,
	Loader,
	Tabs,
	Tab,
	Tooltip,
	Link,
	ButtonSelect,
	Block
} from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import colorMappings from '@upsales/components/Utils/colorMappings';
import Select, { components, createFilter } from 'react-select';
import UpSelect from '../../Inputs/UpSelect';
import LocationPicker from 'Components/Inputs/LocationPicker';
import LocalStorage from '../../Helpers/LocalStorage';
import copyToClipboard from 'Services/copyToClipboard';
import _ from 'lodash';
import AgendaTextarea from './AgendaTextarea';
import { parseNewlines } from './AgendaTextarea/Helpers';
import AppointmentOutcomes from './AppointmentOutcomes';
import logError from 'App/babel/helpers/logError';
import ModalHistoryLog from 'App/components/HistoryLog/ModalHistoryLog';
import AppointmentOutcome from 'App/resources/Model/AppointmentOutcome';
import ContactBlock from './ContactBlock/ContactBlock';

import MultipleSelectedValue from '../../Inputs/OptionComponents/multipleSelectedValue';
import MultipleOptionComponent from '../../Inputs/OptionComponents/multipleOptionComponent';
import DescriptionField from './Fields/DescriptionField';
import { useDebouncePromise } from 'Components/Helpers/Debounce';
import { makeCancelable } from 'App/babel/helpers/promise';

import './AppointmentModalFields.scss';
import moment from 'moment';
import DateRangeInput from 'App/components/DateRangeInput';

import AcceptAISuggestionContract from 'App/components/AcceptAISuggestionContract';
import AI from 'App/resources/AI';
import { AITracker } from 'App/babel/helpers/Tracker';

export const isUser = item => item.hasOwnProperty('role');

export const selectOption = (value, label, data, render, disabled = false) => {
	return { value, label, data, render, disabled };
};

import { reactSelectStyles, reactSelectComponents, noOptionsMessage } from 'App/helpers/reactSelectSettings';
import { formatWithSubAccounts } from 'App/helpers/accountsHelper';
const appointmentReactSelectStyles = reactSelectStyles({
	container: { width: '100%' },
	control: { border: 'none', borderRight: `1px solid ${colorMappings.get('grey-6')}` },
	indicatorsContainer: { borderRightWidth: '1px' },
	menu: { zIndex: 100 }
});

const decideUserContact = selectedItem => {
	if (isUser(selectedItem) && typeof selectedItem.id === 'number') {
		return 'user-';
	} else if (!isUser(selectedItem) && typeof selectedItem.id === 'number') {
		return 'contact-';
	}
	return '';
};

export const transformOptions = (appointment, loadingContacts, options, selected = []) => {
	return (
		options?.reduce((res, el) => {
			if (!el) {
				return res;
			}

			const parent = selectOption(
				el.id ? `${decideUserContact(el)}${el.id}` : el.id,
				el.name,
				el,
				<strong className="option-group-header">{el.name}</strong>,
				true
			);

			let gotLoadingRow = false;
			if (el.children && el.children.length && el.children[0].loading === true) {
				gotLoadingRow = true;
			}

			if (
				loadingContacts &&
				appointment.client &&
				!gotLoadingRow &&
				el.name === Tools.$translate('default.contacts')
			) {
				el.children.unshift({
					id: 1337,
					name: 'Loading',
					loading: true,
					disabled: true,
					render: <Loader noU={true} style={{ width: '25px', height: '25px' }} />
				});
			}

			if (!appointment.client && !gotLoadingRow && el.name === Tools.$translate('default.contacts')) {
				el.children.unshift({
					id: 1337,
					loading: true,
					disabled: true,
					name: Tools.$translate('appointment.noClientSelected'),
					render: Tools.$translate('appointment.noClientSelected')
				});
			}

			if (!loadingContacts && gotLoadingRow) {
				el.children = el.children?.filter(elc => elc.name !== 'Loading');
			}

			if (
				!appointment.client &&
				el.name === Tools.$translate('appointmentModal.participantSelect.otherContacts')
			) {
				return res;
			}

			if (
				el.name === Tools.$translate('appointmentModal.participantSelect.otherContacts') &&
				!el.children.length
			) {
				el.children = [
					{
						id: 1338,
						name: Tools.$translate('noResult.generic'),
						render: Tools.$translate('noResult.generic'),
						disabled: true
					}
				];
			}

			const children = el.children?.map(child => {
				const childStyleObj = { paddingLeft: 10 };

				return {
					...selectOption(
						`${decideUserContact(child)}${child.id}`,
						child.loading ? child.render : child.name,
						child,
						<span style={childStyleObj}>{child.name}</span>,
						child.loading ? true : false
					),
					parent: el.name,
					subtitle: child.subtitle
				};
			});

			// remove children that is already selected
			const filteredChildren = children?.filter(child => {
				const childItem = child.data;
				const childValue = child.value;

				return !selected.find(
					selectedItem =>
						`${decideUserContact(selectedItem)}${selectedItem.id}` === childValue &&
						isUser(selectedItem) === isUser(childItem)
				);
			});

			return res.concat(parent).concat(filteredChildren);
		}, []) ?? []
	);
};

const selectOpts = {
	formatResult: function (account, container, query, escape) {
		var title = '';
		var addresses = account.$addresses || account.addresses;
		if (addresses) {
			var city = '';

			if (addresses.Visit?.city) {
				city = addresses.Visit.city;
			} else if (addresses.Mail?.city) {
				city = addresses.Mail.city;
			} else if (addresses.Invoice?.city) {
				city = addresses.Invoice.city;
			} else if (addresses.Delivery?.city) {
				city = addresses.Delivery.city;
			} else if (addresses.Other?.city) {
				city = addresses.Other.city;
			}

			title +=
				'<span class="subtitle grey" style="display:block;text-transform:uppercase;">' +
				escape(city) +
				'</span>';
		}

		if (Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS') && account.operationalAccount) {
			title +=
				'<div class="subtitle grey">' +
				Tools.$translate.instant('account.subaccount.tooltip', { parent: account.operationalAccount.name }) +
				'</div>';
		}

		return (
			'<div style="position:relative;">' +
			(account.name ? escape(account.name) : Tools.$translate('default.noName')) +
			title +
			'</div>'
		);
	},
	ajax: {
		data: term => ({ term }),
		transport: ({ success, data }) => {
			const hasInactiveAccounts = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.INACTIVE_COMPANIES);

			if (!data.term) {
				const latestAccounts = Tools.LatestAccountsService.get(
					Tools.AppService.getCustomerId(),
					Tools.AppService.getSelf().id,
					{
						onlyActive: hasInactiveAccounts
					}
				);
				if (Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS')) {
					return success({
						data: formatWithSubAccounts(latestAccounts, Tools.$translate, true)
					});
				}
				return success({
					data: [
						{
							name: Tools.$translate('default.latestAccounts'),
							children: latestAccounts
						}
					]
				});
			}

			data.term = data.term.toLowerCase();
			const rb = new Tools.RequestBuilder();

			rb.limit = 50;
			rb.fields = ['id', 'name', 'addresses', 'operationalAccount'];
			rb.addFilter(Tools.Account.attr.name, rb.comparisonTypes.Search, data.term);

			if (hasInactiveAccounts) {
				rb.addFilter(Tools.Account.attr.active, rb.comparisonTypes.Equals, true);
			}

			Tools.Account.customer(Tools.AppService.getCustomerId())
				.find(rb.build())
				.then(success)
				.catch(e => {
					console.error('Failed fetching accounts', e);
					success({ data: [] });
				});
		},
		results: ({ data }) => {
			return {
				results: Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS')
					? formatWithSubAccounts(data, Tools.$translate)
					: data
			};
		}
	}
};

const RenderParticipants = ({
	appointment,
	actions,
	loadingContacts,
	participants,
	canEditAsHost,
	participantSelect,
	handleCloseParticipants,
	firstBackSpace,
	activeOptionsContacts,
	setActiveOptionsContacts,
	scrollToElement,
	contactOpened,
	openContact
}) => {
	const editable = appointment.userEditable;
	const participantRef = React.useRef();

	const classes = new BemClass('RenderParticipants');

	const fetchContactsFromOtherCompanies = useDebouncePromise(
		searchInput => actions.getContactsFromOtherCompanies(searchInput),
		[],
		400
	);

	const insertButtonGroupAtTop = array => {
		array.unshift({
			isButtonGroup: true,
			render: (
				<ButtonGroup block className="select-button-group">
					<Button
						shadow="none"
						size="sm"
						onClick={() => {
							scrollToElement('participantsComponent', 0);
							setActiveOptionsContacts(false);
						}}
						type={activeOptionsContacts ? 'lined' : null}
					>
						{Tools.$translate('default.contacts2')}
					</Button>
					<Button
						shadow="none"
						size="sm"
						onClick={() => {
							scrollToElement(
								'participantsComponent',
								(document.getElementsByClassName('react-select-participantsUsers')[0]?.offsetTop ?? 0) -
									50
							);
							setActiveOptionsContacts(true);
						}}
						type={!activeOptionsContacts ? 'lined' : null}
					>
						{Tools.$translate('default.users')}
					</Button>
				</ButtonGroup>
			),
			disabled: true
		});

		return array;
	};

	const allParticipants = [];
	if (appointment.users) {
		allParticipants.push(...(appointment.users ?? []).map(user => ({ ...user, _id: `user-${user.id}` })));
	}
	if (appointment.contacts) {
		allParticipants.push(
			...(appointment.contacts ?? []).map(contact => ({ ...contact, _id: `contact-${contact.id}` }))
		);
	}
	if (appointment.emailAttendees) {
		allParticipants.push(
			...(appointment.emailAttendees ?? []).map(attendee => ({
				...attendee,
				_id: `emailAttendee-${attendee.email}`
			}))
		);
	}
	const hasAppointmentModalParticipantList = Tools.FeatureHelper.hasSoftDeployAccess(
		'APPOINTMENTMODAL_PARTICIPANT_LIST'
	);

	return (
		<div className={classes.b()}>
			<label>
				{Tools.$translate('default.participants')}
				{appointment.client && editable && (
					<span className="btn btn-link btn-bright-blue label-button" onClick={() => actions.createContact()}>
						{Tools.$translate('default.new') + ' ' + Tools.$translate('default.contact').toLowerCase()}
					</span>
				)}
			</label>
			<div className="TextInput form-control no-border-right select2-inside participants-specific">
				<div
					className="icon-holder"
					onClick={evt => {
						const input = participantRef.current;
						if (!input) {
							return;
						}
						if (!input.state.isOpen) {
							input.handleMouseDown?.(evt);
						}
					}}
				>
					<i className="fa fa-user" />
				</div>
				<Select
					isMulti={true}
					hideSelectedOptions={true}
					instanceId="participantsComponent"
					className={participantRef.current?.state.inputValue.length ? 'got-input-text' : ''}
					value={transformOptions(
						appointment,
						loadingContacts,
						(participants ?? []).concat(
							(appointment.emailAttendees ?? []).map(attendee => ({
								data: { ...attendee, isEmailAttendee: true },
								name: attendee.email,
								id: attendee.email
							}))
						)
					)}
					ref={participantRef}
					name="appointment-contacts"
					isDisabled={!editable || (!canEditAsHost && !appointment.isExternalHost)}
					options={insertButtonGroupAtTop(
						transformOptions(
							appointment,
							loadingContacts,
							participantSelect.data,
							appointment.users.concat(
								appointment.contacts && Array.isArray(appointment.contacts) ? appointment.contacts : []
							),
							true
						)
					)}
					onInputChange={v => {
						fetchContactsFromOtherCompanies(v);
					}}
					onMenuClose={handleCloseParticipants}
					filterOption={(option, filter) => {
						if (option.label === Tools.$translate('noResult.generic')) {
							return true;
						}

						if (!filter) {
							return true;
						}

						if (!option.value) {
							return true;
						}

						return option.label.toLowerCase().indexOf(filter.toLowerCase()) > -1;
					}}
					noOptionsMessage={noOptionsMessage}
					onMenuOpen={() => {
						setTimeout(() => {
							// menuListRef is not available immediately for some reason
							participantRef.current?.select?.menuListRef?.scrollIntoView({
								behavior: 'smooth',
								block: 'nearest',
								inline: 'nearest'
							});
						}, 100);
					}}
					onKeyDown={event => {
						const value = participantRef.current?.state.inputValue;
						switch (event.key) {
							case 'Backspace':
								if (value.length <= 0) {
									if (!firstBackSpace) {
										actions.firstBackSpace(true);
										event.preventDefault();
									} else {
										actions.firstBackSpace(false);
									}
								}
								break;
							case 'Enter':
								actions.addEmailAttendee(value);
								break;
							default:
								return;
						}
					}}
					components={reactSelectComponents({
						Option: ({ data, selectOption }) => {
							return (
								<MultipleOptionComponent
									className={
										data.label === Tools.$translate('default.contacts')
											? 'react-select-participantsContacts'
											: data.label === Tools.$translate('default.users')
											? 'react-select-participantsUsers'
											: undefined
									}
									option={data}
									onSelect={selectOption}
								/>
							);
						},
						MultiValueContainer: props => {
							return <MultipleSelectedValue {...props} />;
						},
						MenuList: props => {
							return (
								<components.MenuList {...props} className="react-select-participantsComponent--list" />
							);
						}
					})}
					styles={appointmentReactSelectStyles}
					onChange={(selectedOption, { action, removedValue }) => {
						if (action === 'remove-value' && removedValue.data?.data?.isEmailAttendee) {
							actions.removeEmailAttendee(removedValue.data.data.email);
							return;
						}
						if (action === 'clear') {
							actions.clearEmailAttendees();
						}
						const APPOINTMENT_USERS = [];
						const APPOINTMENT_CONTACTS = [];
						const PARTICIPANTS = [];
						for (const item of selectedOption) {
							const isUsers = item.data && isUser(item.data);

							if (item.data && !item.data.disabled) {
								if (isUsers) {
									APPOINTMENT_USERS.push(item.data);
								} else if (item.data.data?.isEmailAttendee) {
									// we handle emailAttendees separately
									continue;
								} else {
									const contact = appointment.contacts?.find(contact => contact.id === item.data?.id);
									APPOINTMENT_CONTACTS.push(contact || item.data);
								}

								PARTICIPANTS.push(item.data);
							}
						}

						actions.participantChange({
							participants: _.compact(PARTICIPANTS),
							contacts: _.compact(APPOINTMENT_CONTACTS),
							users: _.compact(APPOINTMENT_USERS)
						});
					}}
				/>
			</div>
			{hasAppointmentModalParticipantList && allParticipants.length ? (
				<Block className="select-participant-list" space="mts" borderRadius borderColor="grey-3" border="s">
					{allParticipants.map(participant => {
						const blockProps = {
							user: participant,
							onClick: openContact,
							saveContact: actions.saveContact,
							setJourneyStep: actions.setJourneyStep,
							key: `regular-contact-${participant.id}-${participant.name}`,
							originalUser: Object.assign({}, participant),
							openContact: actions.openContact,
							appointment,
							useNewList: true,
							open: contactOpened === participant.id
						};

						return (
							<ContactBlock
								key={participant._id}
								{...blockProps}
								type={
									!participant.id ? 'emailAttendee' : decideUserContact(participant).replace('-', '')
								}
							/>
						);
					})}
				</Block>
			) : null}
		</div>
	);
};

class AppointmentModalFields extends React.Component {
	constructor(props) {
		super(props);

		const { appointment } = props;

		this.handleCloseParticipants = this.handleCloseParticipants.bind(this);
		this.companyName = Tools.AppService.getAccountSelf().client.name;

		this.hasAppointmentOutcome = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.APPOINTMENT_OUTCOME);
		this.LocalStorage = new LocalStorage();

		this.loggedInCustomerId = Tools.AppService.getCustomerId();
		this.calendarIntegrations = Tools.AppService.getCalendarIntegrations();

		const onlyEditAsHost = appointment.hostHasCalendarIntegration;
		this.canEditAsHost =
			!onlyEditAsHost || (!appointment.isExternalHost && Tools.AppService.getSelf().id === appointment.regBy.id);

		const disablePassedAppointmentDates = Tools.FeatureHelper.hasSoftDeployAccess(
			'DISABLE_PASSED_APPOINTMENT_DATES'
		);
		this.isPassedAppointment = appointment.id && moment().isAfter(appointment.date);
		this.shouldDisableDateFields = disablePassedAppointmentDates && this.isPassedAppointment;

		this.hasTodoList = Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');

		this.haveAccessToAISuggestions = Tools.FeatureHelper.hasSoftDeployAccess('AI_AGENDA');
		const haveEnabledAISuggestions = Tools.AppService.getMetadata().params.AISuggestionsEnabled;
		const showAISuggestions = this.haveAccessToAISuggestions && haveEnabledAISuggestions;
		const hasAutoFillDescription =
			!Tools.FeatureHelper.hasSoftDeployAccess('AUTO_FILL_APPOINTMENTS_DESCRIPTION_SETTING') ||
			Tools.AppService.getMetadata().params.EnableAutoFillAppointmentsDescription;

		this.state = {
			activeOptionsContacts: false,
			hadClientOnLoad: !!appointment.client,
			rooms: [],
			selectedRooms: [],
			isListingRooms: false,
			selectedTab: 'participants',
			showRoomsError: false,
			agendaStartValue: appointment.agenda || '',
			hideOutcomeIrrelevantFields: this.isPassedAppointment || this.props.isCancelled,
			campaignOpts: [],
			campaignOffs: 0,
			campaignInput: '',
			isLoadingCampaigns: false,
			todos: [],
			activeHistoryLog: 'appointment',
			aiMode: false,
			showAISuggestions,
			generatingAIAgenda: false,
			aiPrompt: '',
			aiAgenda: '',
			hasAutoFillDescription
		};

		this.state.agendaStartValue = parseNewlines(this.state.agendaStartValue);

		this.startDateRef = React.createRef();
		this.rescheduleIfDateIsChanged = false;
		this.originStartDate = appointment.date;

		this.clientParamUnsubscribe = Tools.$rootScope.$on('clientParam.updated', (event, clientParam) => {
			if (clientParam?.paramId === 244) {
				this.setState({
					showAISuggestions: this.haveAccessToAISuggestions && clientParam.value,
					aiMode: clientParam.value
				});
			}
		});
	}

	componentWillUnmount() {
		this.clientParamUnsubscribe?.();
		if (this.loadingCampaignsTimer) {
			clearTimeout(this.loadingCampaignsTimer);
		}
		if (this.getCampaignPromise) {
			this.getCampaignPromise.cancel();
		}
	}

	generateAIAgenda() {
		AITracker.track(AITracker.events.GENERATE_AGENDA, {});

		this.setState({ generatingAIAgenda: true });
		AI.find({ prompt: this.state.aiPrompt, task: 'generateMeetingAgenda' })
			.then(result => {
				const { actions } = this.props;
				const aiAgenda = result?.data?.[0]?.agenda ?? '';
				actions.changeAppointmentParam('agenda', aiAgenda, true);
				setTimeout(
					() =>
						this.setState({
							generatingAIAgenda: false,
							aiPrompt: '',
							aiAgenda
						}),
					0
				);
			})
			.catch(error => {
				console.error(error);
			});
	}

	mapCf(custom, fieldErrors) {
		const { actions, appointment } = this.props;
		const editable = appointment.userEditable;

		custom = _.sortBy(custom, 'sortId') ?? [];
		custom = custom.filter(each => {
			return each.$hasAccess && (each.editable || each.visible);
		});

		const returnArray = custom.map(field => {
			const isObligatory = field.obligatoryField ? <b className="text-danger">{'*'}</b> : '';

			if (field.datatype === 'User' || field.datatype === 'Users') {
				if (field.default) {
					field.value = field.default;
					field.default = null;
				}
			}

			return (
				<div
					key={`${field.name}-${field.datatype}-${field.id}`}
					className={'col-md-6' + (fieldErrors['Custom_' + field.id] ? ' has-error' : '')}
				>
					<label>
						{field.name} {isObligatory}
					</label>
					<div className={'input' + (fieldErrors['Custom_' + field.id] ? ' has-error' : '')}>
						<ReactTemplates.customFieldInput
							className="form-control"
							field={field}
							entity={'appointment'}
							disabled={!editable}
							valueChange={(value, data, dropdownDefaultWasSet) =>
								actions.changeCustom(field, value, dropdownDefaultWasSet)
							}
							useNewTime
							useNumberInput
						/>
					</div>
				</div>
			);
		});

		return returnArray;
	}

	handleCloseParticipants() {
		this.setState({
			activeOptionsContacts: false
		});
	}

	findPerson(array, item) {
		const found = array?.find(user => user.name === item.name);
		if (found) {
			return null;
		}

		return item;
	}

	getCampaignData = (resetOffset = false) => {
		if (this.loadingCampaignsTimer) {
			clearTimeout(this.loadingCampaignsTimer);
		}

		this.loadingCampaignsTimer = setTimeout(() => {
			this.setState({
				isLoadingCampaigns: true
			});
		}, 500);

		const rb = new Tools.RequestBuilder();

		rb.limit = 10;
		rb.offset = this.state.campaignOffs;
		rb.fields = ['id', 'name'];
		if (this.state.campaignInput) {
			rb.addFilter(Tools.Campaign.attr.name, rb.comparisonTypes.Search, this.state.campaignInput);
		}
		if (resetOffset) {
			rb.offset = 0;
		}
		rb.addFilter(Tools.Campaign.attr.active, rb.comparisonTypes.Equals, true);
		rb.addSort(Tools.Campaign.attr.name, true);

		this.getCampaignPromise = makeCancelable(
			Tools.Campaign.customer(Tools.AppService.getCustomerId()).find(rb.build())
		);
		this.getCampaignPromise.promise
			.then(response => {
				if (this.loadingCampaignsTimer) {
					clearTimeout(this.loadingCampaignsTimer);
				}
				this.setState({
					campaignOffs: rb.offset + (response.data.length ? rb.limit : 0),
					campaignOpts: resetOffset ? response.data : [...this.state.campaignOpts, ...response.data],
					isLoadingCampaigns: false
				});
			})
			.catch(e => {
				logError(e, 'failed to fetch campaign');
				if (this.loadingCampaignsTimer) {
					clearTimeout(this.loadingCampaignsTimer);
				}
				this.setState({
					isLoadingCampaigns: false
				});
			});
	};

	scrollToElement(componentId, howFar) {
		setTimeout(() => {
			const COMPONENT = document.getElementsByClassName(`react-select-${componentId}--list`)[0];
			COMPONENT.scrollTop = howFar;
		}, 100);
	}

	copyWeblinkUrl = e => {
		e.stopPropagation();
		if (copyToClipboard(this.props.appointment.weblinkUrl)) {
			Tools.NotificationService.addNotification({
				icon: 'check',
				style: Tools.NotificationService.style.SUCCESS,
				title: 'default.linkCopiedToClipboard'
			});
		} else {
			Tools.NotificationService.addNotification({
				icon: 'times',
				style: Tools.NotificationService.style.ERROR,
				title: 'default.error'
			});
		}
	};

	toggleOutcomeIrrelevantFields = () => {
		this.setState({ hideOutcomeIrrelevantFields: !this.state.hideOutcomeIrrelevantFields });
	};

	listRooms = async () => {
		if (!this.calendarIntegrations.length || this.state.isListingRooms) {
			return;
		}

		this.setState({ isListingRooms: true, showRoomsError: false });

		try {
			const appointment = this.props.appointment;
			const selectedRooms = appointment.bookedRooms ? JSON.parse(appointment.bookedRooms) : [];

			let rooms = [];
			for (const integration of this.calendarIntegrations) {
				const res = await Tools.StandardIntegration.data(this.loggedInCustomerId).run({
					data: {
						startDateTime: moment.utc(appointment.date).format(),
						endDateTime: moment.utc(appointment.endDate).format()
					},
					type: 'rooms',
					integrationId: integration.id
				});

				if (res.data && res.data.rooms) {
					rooms = res.data.rooms.map(room => {
						room.isBusy = room.isBusy && !this.isRoomBooked(room.resourceEmail, selectedRooms);
						return room;
					});
				}
			}

			this.setState({
				rooms,
				selectedRooms,
				isListingRooms: false
			});
		} catch (e) {
			this.setState({ isListingRooms: false, showRoomsError: true });
		}
	};

	onRoomChanged = id => {
		let selectedRooms = [...this.state.selectedRooms];

		if (selectedRooms.find(room => room.id === id)) {
			selectedRooms = selectedRooms.filter(room => room.id !== id);
		} else {
			selectedRooms.push({ id });
		}

		this.setState({ selectedRooms }, () => {
			this.props.actions.changeAppointmentParam('bookedRooms', JSON.stringify(selectedRooms));
		});
	};

	isRoomBooked = (id, bookedRooms) => bookedRooms.find(room => room.id === id) !== undefined;

	onTabsChange = value => {
		if (value === this.state.selectedTab) {
			return;
		}

		this.setState({ selectedTab: value }, () => {
			if (value === 'rooms') {
				this.listRooms();
			}
		});
	};

	renderRooms = () => (
		<div className="appointment-rooms">
			{this.state.isListingRooms ? (
				<Loader size="sm" />
			) : this.state.showRoomsError ? (
				<div className="appointment-rooms-error">
					<Text color="grey-10">
						<Icon name="exclamation-triangle" />{' '}
						{Tools.$translate('appointmentModal.noRoomsFoundInYourEmailAccount')}
					</Text>
					<Text color="grey-10">{Tools.$translate('appointmentModal.pleaseContactYourAdministrator')}</Text>
				</div>
			) : (
				this.state.rooms.map((room, index) => {
					const { resourceName, resourceEmail, capacity, isBusy = false } = room;
					return (
						<div key={index} className="appointment-room">
							<Checkbox
								size="xs"
								disabled={isBusy}
								checked={this.isRoomBooked(resourceEmail, this.state.selectedRooms)}
								onChange={() => this.onRoomChanged(resourceEmail)}
							/>
							<div className="appointment-room-info">
								<span>{resourceName}</span>
								<span>
									<Icon name="users" /> {capacity}
								</span>
							</div>
						</div>
					);
				})
			)}
		</div>
	);

	shouldListRoomsOnUpdate = prevProps => {
		return (
			this.state.selectedTab === 'rooms' &&
			(!moment(prevProps.appointment.date).isSame(this.props.appointment.date) ||
				!moment(prevProps.appointment.endDate).isSame(this.props.appointment.endDate))
		);
	};

	fetchTodos = () => {
		Tools.Activity.customer(this.loggedInCustomerId)
			.find({ parentAppointmentId: this.props.appointment.id })
			.then(res => {
				if (res.data && res.data.length) {
					const todoType = Tools.AppService.getTodoTypes().TODO.id;
					this.setState({
						todos: res.data.filter(
							activity => activity.activityType && activity.activityType.id === todoType
						)
					});
				}
			})
			.catch(err => logError(err, 'Could not fetch todos'));
	};

	updateTodo = data => {
		const todos = this.state.todos.map(todo => {
			if (todo.id === data.id) {
				todo = { ...todo, ...data };
			}
			return todo;
		});
		this.setState({ todos });
	};

	removeTodo = id => {
		const todos = this.state.todos.filter(todo => todo.id !== id);
		this.setState({ todos });
	};

	componentDidUpdate(prevProps) {
		if (this.shouldListRoomsOnUpdate(prevProps)) {
			this.listRooms();
		}
	}

	componentDidMount() {
		if (this.props.appointment.id) {
			this.fetchTodos();
		}

		this.getCampaignData();
	}

	setRef = inp => {
		this.startDate = inp;
	};

	openRescheduleDatePicker = () => {
		this.startDateRef.current?.focus?.();
		this.rescheduleIfDateIsChanged = true;
	};

	cancelRescheduling = () => {
		const { actions } = this.props;
		actions.setIsRescheduling(false);
		actions.changeStartDate(this.originStartDate);
		actions.changeStartTime(this.originStartDate);
		this.rescheduleIfDateIsChanged = false;
	};

	render() {
		const { appointment, actions, fieldErrors } = this.props;
		const { hasAutoFillDescription } = this.state;
		const customFields = this.mapCf(appointment.custom, fieldErrors);
		const editable = appointment.userEditable;

		const isNew = !appointment.id;
		const defaultDescription = isNew
			? this.companyName && appointment?.client?.name && hasAutoFillDescription
				? `${this.companyName} / ${appointment?.client?.name}`
				: ''
			: appointment.description;

		return (
			<div className="appointment-fields">
				{!this.canEditAsHost ? (
					<Card borderColor="bright-blue" border="lm" space="plm">
						<Text>{Tools.$translate('appointment.nonHostInfo')}</Text>
					</Card>
				) : null}
				<div className="row-wrapper">
					<div className="flex-row">
						<div className="col">
							<DateRangeInput
								start={appointment.date}
								end={appointment.endDate}
								anchor={this.props.dateAnchor || undefined}
								scrollContainer={this.props.dateScrollContainer || undefined}
								onChangeStart={(type, date) => {
									if (type === 'time') {
										actions.changeStartTime(date);
									} else {
										actions.changeStartDate(date);
									}
									actions.dateTimeDirty('date', `${type}Dirty`, false);
									if (this.rescheduleIfDateIsChanged) {
										const dateHasChanged = !moment(new Date(this.originStartDate)).isSame(date);
										actions.setIsRescheduling(dateHasChanged);
									}
								}}
								onChangeEnd={(type, date) => {
									if (type === 'time') {
										actions.changeEndTime(date);
									} else {
										actions.changeEndDate(date);
									}
									actions.dateTimeDirty('endDate', `${type}Dirty`, false);
								}}
								disabled={!!(!editable || !this.canEditAsHost || this.shouldDisableDateFields)}
								startDateRef={this.startDateRef}
							></DateRangeInput>
						</div>
					</div>

					<DescriptionField
						defaultDescription={defaultDescription}
						required={this.props.requiredFields.Description}
						hasError={fieldErrors.Description}
						disabled={!editable || !this.canEditAsHost}
						handleChange={actions.changeAppointmentParam}
					/>

					{(this.isPassedAppointment ||
						appointment.outcome === AppointmentOutcome.NOT_COMPLETED ||
						this.props.isCancelled) &&
					appointment.client &&
					appointment.userEditable ? (
						<AppointmentOutcomes
							appointment={appointment}
							answerOutcome={isCompleted => actions.answerOutcome(isCompleted)}
							followUp={(isActivity, commentId) => actions.createFollowUp(isActivity, true, commentId)}
							createOpportunity={actions.createOpportunity}
							disqualifyCompany={actions.disqualifyCompany}
							todos={this.state.todos}
							fetchTodos={this.fetchTodos}
							saving={this.props.saving}
							save={actions.saveOutcomeComment}
							disabledOutcomeSelect={this.props.isCancelled}
							saveAppointmentWithAction={actions.saveAppointmentWithAction}
							originalOutcome={this.props.originalOutcome}
							rescheduleAppointment={reschedule =>
								reschedule ? this.openRescheduleDatePicker() : this.cancelRescheduling()
							}
							isRescheduling={actions.isRescheduling}
						/>
					) : null}

					{this.isPassedAppointment ||
					appointment.outcome === AppointmentOutcome.NOT_COMPLETED ||
					this.props.isCancelled ? (
						<div className="flex-row">
							<div className="col">
								<Link onClick={this.toggleOutcomeIrrelevantFields}>
									{this.state.hideOutcomeIrrelevantFields
										? Tools.$translate('appointment.moreDetails')
										: Tools.$translate('appointment.lessDetails')}{' '}
									<Icon name={`caret-${this.state.hideOutcomeIrrelevantFields ? 'down' : 'up'}`} />
								</Link>
							</div>
						</div>
					) : null}

					{!this.state.hideOutcomeIrrelevantFields ? (
						<div className="flex-row">
							<div className="col">
								<Label>{Tools.$translate('default.client')}</Label>
								<div className="account-select-wrapper">
									<div className="TextInput form-control no-border-right select2-inside">
										<div className="icon-holder">
											<i className="fa fa-home" />
										</div>
									</div>
									<UpSelect
										options={selectOpts}
										placeholder={Tools.$translate('default.chooseAnAccount')}
										defaultValue={appointment.client || ''}
										onChange={e => actions.accountChange(e.target.added)}
										disabled={!editable || (!this.canEditAsHost && !appointment.isExternalHost)}
									/>
								</div>
							</div>
						</div>
					) : null}
					{!this.state.hideOutcomeIrrelevantFields ? (
						<div className="flex-row">
							<div className="col">
								{this.calendarIntegrations.length ? (
									<Card className="calendar-integration-settings">
										<Tabs size="sm" onChange={this.onTabsChange} selected={this.state.selectedTab}>
											<Tab
												id="participants"
												title={Tools.$translate('default.participantsClean')}
											/>
											<Tab id="rooms" title={Tools.$translate('default.rooms')} />
										</Tabs>
										<CardContent>
											{this.state.selectedTab === 'participants' ? (
												<RenderParticipants
													appointment={appointment}
													actions={actions}
													loadingContacts={this.props.loadingContacts}
													canEditAsHost={this.canEditAsHost}
													firstBackSpace={this.props.firstBackSpace}
													handleCloseParticipants={this.handleCloseParticipants}
													participants={this.props.participants}
													participantSelect={this.props.participantSelect}
													activeOptionsContacts={this.state.activeOptionsContacts}
													scrollToElement={this.scrollToElement}
													setActiveOptionsContacts={value =>
														this.setState({ activeOptionsContacts: value })
													}
													contactOpened={this.props.contactOpened}
													openContact={this.props.openContact}
												/>
											) : null}
											{this.state.selectedTab === 'rooms' ? this.renderRooms() : null}
										</CardContent>
									</Card>
								) : (
									<RenderParticipants
										appointment={appointment}
										actions={actions}
										loadingContacts={this.props.loadingContacts}
										canEditAsHost={this.canEditAsHost}
										firstBackSpace={this.props.firstBackSpace}
										handleCloseParticipants={this.handleCloseParticipants}
										participants={this.props.participants}
										participantSelect={this.props.participantSelect}
										activeOptionsContacts={this.state.activeOptionsContacts}
										scrollToElement={this.scrollToElement}
										setActiveOptionsContacts={value =>
											this.setState({ activeOptionsContacts: value })
										}
										contactOpened={this.props.contactOpened}
										openContact={this.props.openContact}
									/>
								)}
							</div>
						</div>
					) : null}

					{this.calendarIntegrations.length && !this.state.hideOutcomeIrrelevantFields ? (
						<div className="flex-row">
							<div className="col">
								<Label required={this.props.requiredFields.Agenda}>
									{Tools.$translate('default.publicAgenda')}{' '}
									<Tooltip title={Tools.$translate('appointment.agendaTooltip')}>
										<Icon name="question-circle" />
									</Tooltip>
								</Label>
								{this.haveAccessToAISuggestions && !this.state.showAISuggestions ? (
									<AcceptAISuggestionContract
										title="acceptAISuggestionContract.titleAgenda"
										space="mbxl"
									/>
								) : null}
								{this.state.showAISuggestions ? (
									<Block space="mbxl">
										<ButtonSelect
											className="ai-agenda-toggle"
											options={[
												{ value: true, title: Tools.$translate('appointment.AI.toggle.AI') },
												{ value: false, title: Tools.$translate('appointment.AI.toggle.self') }
											]}
											onChange={value => this.setState({ aiMode: value })}
											value={this.state.aiMode}
										/>
									</Block>
								) : null}
								{this.state.showAISuggestions && this.state.aiMode ? (
									<>
										<Textarea
											type="text"
											value={this.state.aiPrompt}
											onChange={event => {
												this.setState({ aiPrompt: event.target.value });
											}}
											style={{ height: 60 }}
											disabled={!editable || this.state.generatingAIAgenda}
											placeholder={Tools.$translate('appointment.AI.placeholder')}
										/>
										<Block space="mtxl mbxl">
											<Button
												color={!this.state.aiPrompt ? 'grey' : 'green'}
												shadow={!this.state.aiPrompt ? 'none' : 'high'}
												disabled={!this.state.aiPrompt || !editable}
												loading={this.state.generatingAIAgenda}
												onClick={() => this.generateAIAgenda()}
											>
												<Icon space="mrl" name="play" />
												{Tools.$translate('appointment.AI.generate')}
											</Button>
										</Block>
									</>
								) : null}
								<AgendaTextarea
									forceUpdateAgenda={this.state.aiAgenda}
									agenda={parseNewlines(appointment.agenda || '')}
									onChange={value => {
										const isAgendaDirty = (value || '') !== this.state.agendaStartValue;
										actions.changeAppointmentParam('agenda', value, isAgendaDirty);
									}}
									onAgendaEdit={() => {
										actions.addWaitForPromise('agenda');
									}}
									disabled={
										!editable || !this.canEditAsHost || (this.state.aiMode && !appointment.agenda)
									}
									placeholder={Tools.$translate('default.agendaPlaceholder')}
								/>
							</div>
						</div>
					) : null}

					{!this.state.hideOutcomeIrrelevantFields ? (
						<div className="row">
							<div className="col-md-6">
								<label>{Tools.$translate('default.location')}</label>
								<div className="TextInput form-control no-border">
									<LocationPicker
										client={appointment.client}
										value={appointment.location}
										disabled={!editable}
										onChange={selectedOption =>
											actions.changeAppointmentParam('location', selectedOption)
										}
									/>
								</div>
							</div>

							{this.calendarIntegrations.length ? (
								<div className="col-md-6">
									<label>
										{Tools.$translate('default.weblinkUrl')}{' '}
										<Tooltip title={Tools.$translate('appointment.weblinkUrlTooltip')}>
											<Icon name="question-circle" />
										</Tooltip>
									</label>
									{appointment.weblinkUrl ? (
										<div
											className="appointment-weblink-url"
											onClick={() => window.open(appointment.weblinkUrl, '_blank')}
										>
											<Input
												value={appointment.weblinkUrl.substr(0, 23) + '...'}
												icon="video"
												noborder
												disabled
											/>
											<Icon name="copy" onClick={this.copyWeblinkUrl} />
										</div>
									) : (
										<div className="appointment-weblink-toggle">
											<Toggle
												space="mrm"
												color="bright-blue"
												size="lg"
												checked={!!appointment.includeWeblink}
												onChange={value =>
													actions.changeAppointmentParam('includeWeblink', value)
												}
												disabled={!editable || !this.canEditAsHost}
											/>
											<span>{Tools.$translate('default.includeWeblink')}</span>
										</div>
									)}
								</div>
							) : null}

							<div className="col-md-6">
								<label>
									{Tools.$translate('default.appointmentType')}
									<b style={{ margin: '0 3px', color: '#d33f42' }} className="text-danger">
										{'*'}
									</b>
								</label>
								<div
									className={`TextInput form-control no-border-right select2-inside no-icon ellipsis-6${
										fieldErrors.Type ? ' has-error' : ''
									}`}
								>
									<Select
										getOptionValue={option => option.id}
										getOptionLabel={option => option.name}
										isClearable={false}
										name="appointment-type"
										value={appointment.activityType}
										isDisabled={!editable}
										placeholder={Tools.$translate('appointment.typeOfAppointment')}
										options={_.sortBy(
											Tools.AppService.getActivityTypes('appointment', true),
											'name'
										)}
										styles={appointmentReactSelectStyles}
										noOptionsMessage={noOptionsMessage}
										components={reactSelectComponents()}
										onChange={selectedOption => {
											actions.changeAppointmentParam('activityType', selectedOption);
										}}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<label>
									{Tools.$translate('default.campaign')}
									{this.props.requiredFields.Project ? (
										<b style={{ margin: '0 3px', color: '#d33f42' }} className="text-danger">
											{'*'}
										</b>
									) : null}
									{editable && (
										<span
											className="btn btn-link btn-bright-blue label-button"
											onClick={() => actions.createCampaign()}
										>
											{Tools.$translate('default.new')}
										</span>
									)}
								</label>
								<div
									className={
										fieldErrors.Project
											? 'TextInput form-control no-border-right select2-inside no-icon ellipsis-6 has-error'
											: 'TextInput form-control no-border-right select2-inside no-icon ellipsis-6'
									}
								>
									<Select
										styles={appointmentReactSelectStyles}
										filterOption={createFilter({ ignoreAccents: false })}
										isDisabled={!editable}
										getOptionValue={option => option.id}
										getOptionLabel={option => option.name}
										onMenuScrollToBottom={() => this.getCampaignData()}
										onInputChange={val => {
											this.setState({ campaignInput: val }, () => this.getCampaignData(true));
										}}
										required={this.props.requiredFields.Project}
										name="appointment-project"
										value={appointment.project}
										options={this.state.campaignOpts}
										noOptionsMessage={this.state.isLoadingCampaigns ? null : noOptionsMessage}
										isClearable
										isLoading={this.state.isLoadingCampaigns}
										placeholder={Tools.$translate('appointment.pick.campaign')}
										components={reactSelectComponents()}
										onChange={value => {
											actions.checkAgainstFieldErrors('project', value);
											actions.changeAppointmentParam('project', value);
										}}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<label>
									{Tools.$translate('default.opportunity')}
									{appointment.client && editable && (
										<span
											className="btn btn-link btn-bright-blue label-button"
											onClick={() => actions.createOpportunity(null, null, true)}
										>
											{Tools.$translate('default.new')}
										</span>
									)}
								</label>
								<div className="TextInput form-control no-border-right select2-inside no-icon ellipsis-6">
									<Select
										styles={appointmentReactSelectStyles}
										getOptionValue={option => option.id}
										getOptionLabel={option => option.description}
										isDisabled={!editable}
										name="appointment-opportunity"
										value={appointment.opportunity}
										placeholder={Tools.$translate('appointment.pick.opportunity')}
										options={this.props.options.opportunities}
										noOptionsMessage={noOptionsMessage}
										isClearable
										components={reactSelectComponents()}
										onChange={value => actions.changeAppointmentParam('opportunity', value)}
									/>
								</div>
							</div>
							{this.hasAppointmentOutcome && (
								<div className="col-md-6">
									<label>{Tools.$translate('appointment.outcome')}</label>
									<div className="TextInput form-control no-border-right select2-inside no-icon ellipsis-6">
										<Select
											styles={appointmentReactSelectStyles}
											getOptionValue={option => option.id}
											getOptionLabel={option => option.description}
											isDisabled={!editable}
											name="appointment-outcome"
											value={this.props.options.outcomes?.filter(
												({ id }) => id === appointment.outcome || ''
											)}
											placeholder={Tools.$translate('appointment.pick.outcome')}
											options={this.props.options.outcomes}
											noOptionsMessage={noOptionsMessage}
											isClearable
											components={reactSelectComponents()}
											onChange={value => {
												actions.changeAppointmentParam('outcome', value ? value.id : null);
											}}
										/>
									</div>
								</div>
							)}
						</div>
					) : null}

					{customFields.length && !this.state.hideOutcomeIrrelevantFields ? (
						<div className="custom-fields-row row" style={{ marginBottom: '3em' }}>
							<div className="col-md-12" style={{ marginTop: '3em' }}>
								<Title>{Tools.$translate('default.otherInfo')}</Title>
							</div>
							{customFields}
						</div>
					) : null}

					{this.props.appointment.id ? (
						<ModalHistoryLog
							appointment={appointment}
							opportunity={appointment.opportunity}
							client={appointment.client}
							buttonOrder={['appointment', 'client', 'opportunity']}
							showNotes={this.hasTodoList}
							renderedBy={{ id: appointment.id, type: 'appointment' }}
						/>
					) : null}
				</div>
			</div>
		);
	}
}

AppointmentModalFields.propTypes = {
	firstBackSpace: PropTypes.bool,
	actions: PropTypes.object.isRequired,
	appointment: PropTypes.object.isRequired,
	isCancelled: PropTypes.bool,
	calendarAppointments: PropTypes.array,
	participantSelect: PropTypes.object,
	requiredFields: PropTypes.object,
	fieldErrors: PropTypes.object,
	loadingContacts: PropTypes.bool,
	participants: PropTypes.array,
	calendarIsOpenOnFirstLoad: PropTypes.bool,
	options: PropTypes.object,
	saving: PropTypes.bool,
	preSelectedOutcome: PropTypes.string,
	originalOutcome: PropTypes.string,
	dateAnchor: PropTypes.object,
	dateScrollContainer: PropTypes.object,
	contactOpened: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	openContact: PropTypes.func
};

export default AppointmentModalFields;
