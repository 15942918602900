import AdminHeader from 'Components/Admin/AdminHeader';
import UpActivityTypes from 'Components/Inputs/UpActivityTypes';
import { Help, Button, Card, CardHeader, Block, Toggle } from '@upsales/components';
import React from 'react';
import RoleSelect from 'Components/RoleSelect';
import logError from 'Helpers/logError';

ReactTemplates.admin.activityTypes = window.ReactCreateClass({
	getInitialState: function () {
		const appointmentTypes = _.sortBy(Tools.AppService.getActivityTypes('appointment', true), 'name');
		const activityTypes = _.sortBy(Tools.AppService.getActivityTypes('activity', true), 'name');
		const metadata = Tools.AppService.getMetadata();
		const { EnableAutoFillAppointmentsDescription } = metadata.params;

		return {
			editRolesOpen: false,
			edit: null,
			merge: null,
			merging: false,
			touched: {
				name: false
			},
			activitySort: 'name',
			appointmentSort: 'name',
			activityDesc: false,
			appointmentDesc: false,
			appointmentTypes,
			activityTypes,
			EnableAutoFillAppointmentsDescription
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		const clientName = Tools.AppService.getAccountSelf().client.name;
		const hasRemoveActivities = Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES');
		const hasTodoList = Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');
		const hasAutoFillDescriptionSetting = Tools.FeatureHelper.hasSoftDeployAccess(
			'AUTO_FILL_APPOINTMENTS_DESCRIPTION_SETTING'
		);

		const activityDescription =
			hasRemoveActivities && hasTodoList
				? t('admin.appointmentTypes.description')
				: t('admin.activityTypes.description');

		const activityTitle =
			hasRemoveActivities && hasTodoList ? t('default.appointments') : t('admin.activityAndAppointmentTypes');

		const autoFillTitle = hasRemoveActivities ? t('admin.appointments') : t('admin.appointmentsAndTypes');

		this.lang = {
			description: hasAutoFillDescriptionSetting
				? t('admin.appointmentsAndTypes.description')
				: activityDescription,
			title: hasAutoFillDescriptionSetting ? autoFillTitle : activityTitle,
			activityTypes: t('default.activityTypes'),
			activityType: t('default.activityType'),
			appointmentTypes: t('default.appointmentTypes'),
			appointmentType: t('default.appointmentType'),
			addActivity: t('tag.activity.activitytype'),
			addAppointment: t('default.appointmentType'),
			activityTypeMerge: t('admin.activityTypes.activityTypeMerge'),
			appointmentTypeMerge: t('admin.activityTypes.appointmentTypeMerge'),
			mergeActivityTypesDescription: t('admin.activityTypes.mergeActivityTypesDescription'),
			mergeAppointmentTypesDescription: t('admin.activityTypes.mergeAppointmentTypesDescription'),
			newActivityTypeName: t('admin.activityTypes.newActivityTypeName'),
			newAppointmentTypeName: t('admin.activityTypes.newAppointmentTypeName'),
			merge: t('admin.activityTypes.merge'),
			restrictedToRole: t('admin.restrictedToRole'),
			new: t('default.new'),
			save: t('default.save'),
			cancel: t('default.abort'),
			all: t('default.all'),
			none: t('default.none'),
			default: t('default.default'),
			type: t('default.type'),
			name: t('tag.account.name'),
			role: t('default.role'),
			noScripts: t('admin.noScripts'),
			yes: t('default.yes'),
			no: t('default.no'),
			isFirstAppointment: t('admin.firstAppointmentShort'),
			choose: t('default.choose'),
			color: t('mail.color'),
			nothingSelected: t('default.nothingSelected'),
			outcome: t('activity.outcome'),
			active: t('default.active'),
			enableAutoFillAppointmentsDescTitle: t(
				'admin.appointmentsAndTypes.enableAutoFillAppointmentsDescription.title'
			),
			enableAutoFillAppointmentsDescSubtitle: t(
				'admin.appointmentsAndTypes.enableAutoFillAppointmentsDescription.subtitle',
				{ client: clientName }
			)
		};

		this.hasCalendarColors = Tools.FeatureHelper.hasSoftDeployAccess('CALENDAR_VIEW_COLORS');
		this.hasActivityOutcomeAccess = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ACTIVITY_OUTCOMES);
		this.hasAutoFillDescriptionSetting = hasAutoFillDescriptionSetting;
	},
	onNewField: function (isAppointment) {
		if (!(this.state.edit && !this.state.edit.id)) {
			var data = { name: '', roles: [] };
			var state = {
				edit: { name: '', roles: [] },
				touched: { name: false }
			};
			if (isAppointment) {
				data.first = false;
				data.color = '#e4eaf0';
				state.edit.first = false;
				state.edit.color = '#e4eaf0';
			}
			this.setState({
				edit: data,
				editField: 'name'
			});
			this.setState(state);
		}
	},
	inlineEdit: function (item, field) {
		this.setState({
			edit: _.cloneDeep(item),
			editField: field,
			merge: undefined
		});
	},
	validate: function (propery, item) {
		var activityTypes = this.isAppointment(item)
			? this.props.rootData.pageData.appointmentTypes
			: this.props.rootData.pageData.activitytypes;

		if (propery === 'name') {
			var index = _.findIndex(activityTypes, function (_item) {
				return (item.name || '').toLowerCase() === (_item.name || '').toLowerCase() && item.id !== _item.id;
			});

			return index === -1 && !!item.name;
		} else {
			return false;
		}
	},
	editInlineField: function (property, event) {
		var value;

		if (property === 'color') {
			value = event;
		} else {
			value = (event.target.value || '').substring(0, 40);
		}

		if (property === 'first' || property === 'hasOutcome') {
			value = event.target.checked;
		}
		this.state.edit[property] = value;
		var touched = _.cloneDeep(this.state.touched);
		if (property === 'name') {
			touched[property] = true;
		}

		this.setState({
			edit: this.state.edit,
			touched: touched
		});
	},
	colorChange: function (value) {
		this.editInlineField('color', value);
	},
	changeRole: function (value) {
		this.state.edit.roles = value;
		this.setState({
			edit: this.state.edit
		});
	},
	stopEdit: function () {
		this.setState({ edit: null });
	},
	saveEdit: function () {
		if (this.validate('name', this.state.edit)) {
			const isAppointment = this.isAppointment(this.state.edit);
			var type = isAppointment ? 'appointment' : 'activity';
			var onSave = this.props.rootData.onSave;
			var name = this.state.edit.name;
			name = name.trim();
			name = name.charAt(0).toUpperCase() + name.slice(1);
			this.state.edit.name = name;
			onSave(type, this.state.edit, (err, savedItem) => {
				if (err) {
					this.setState({ edit: null });
					return;
				}
				if (isAppointment) {
					this.setState({
						edit: null,
						appointmentTypes: _.sortBy([...this.state.appointmentTypes, savedItem], 'name')
					});
				} else {
					this.setState({
						edit: null,
						activityTypes: _.sortBy([...this.state.activityTypes, savedItem], 'name')
					});
				}
			});
		}
	},
	onClickOutsideSelect2: function () {
		this.closeEditRoles();
	},
	openEditRoles: function () {
		this.setState({ editRolesOpen: true });
		document.body.addEventListener('click', this.onClickOutsideSelect2);
	},
	closeEditRoles: function () {
		document.body.removeEventListener('click', this.onClickOutsideSelect2);
		this.setState({ editRolesOpen: false });
	},
	componentWillUnmount: function () {
		document.body.removeEventListener('click', this.onClickOutsideSelect2);
	},
	keyPressSave: function (item, e) {
		if (e.charCode === 13) {
			this.saveEdit();
		}
	},
	getRows: function (types, type) {
		var self = this;
		var rows = _.chain(types);

		rows = rows.sortBy(type === 'activity' ? this.state.activitySort : this.state.appointmentSort);

		if (type === 'activity' ? this.state.activityDesc : this.state.appointmentDesc) {
			rows = rows.reverse();
		}

		return rows
			.map(function (item, index) {
				if (self.state.edit && self.state.edit.id === item.id) {
					return self.editRow(self.state.edit, index);
				} else {
					return self.row(item, index);
				}
			})
			.value();
	},
	setSort: function (sort, isAppointment) {
		var state = {};
		if (isAppointment) {
			state.appointmentSort = sort;
			state.appointmentDesc =
				sort === this.state.appointmentSort ? !this.state.appointmentDesc : this.state.appointmentDesc;
		} else {
			state.activitySort = sort;
			state.activityDesc = sort === this.state.activitySort ? !this.state.activityDesc : this.state.activityDesc;
		}
		this.setState(state);
	},
	isAppointment: function (type) {
		return type.hasOwnProperty('first');
	},
	toggleActivityTypeMerge: function () {
		if (this.state.merge && !this.state.merge.isAppointment) {
			this.setState({
				merge: undefined
			});
		} else {
			this.setState({
				merge: {
					name: '',
					isAppointment: false,
					typeOne: null,
					typeTwo: null
				},
				edit: null
			});
		}
	},
	toggleAppointmentTypeMerge: function () {
		if (this.state.merge && this.state.merge.isAppointment) {
			this.setState({
				merge: undefined
			});
		} else {
			this.setState({
				merge: {
					name: '',
					isAppointment: true,
					typeOne: null,
					typeTwo: null
				},
				edit: null
			});
		}
	},
	setMergeName: function (event) {
		if (this.state.merge) {
			this.state.merge.name = event.target.value;
			this.state.merge.touchedName = true;
			this.setState({
				merge: this.state.merge
			});
		}
	},
	keyPressMerge: function (isAppointment, e) {
		if (e.charCode === 13) {
			this.doMerge(isAppointment);
		}
	},
	validateMerge: function () {
		return (
			this.state.merge &&
			this.state.merge.name &&
			this.state.merge.typeOne &&
			this.state.merge.typeTwo &&
			this.state.merge.typeOne !== this.state.merge.typeTwo
		);
	},
	updateTypes: function (state, keepType) {
		let activityTypes = state.activityTypes;
		let appointmentTypes = state.appointmentTypes;
		if (state.merge.isAppointment) {
			const index1 = appointmentTypes.findIndex(type => type.id === state.merge.typeOne.id);
			if (index1 !== -1) {
				appointmentTypes.splice(index1, 1);
			}
			const index2 = appointmentTypes.findIndex(type => type.id === state.merge.typeTwo.id);
			if (index2 !== -1) {
				appointmentTypes.splice(index2, 1);
			}
			appointmentTypes.push(keepType);
			appointmentTypes = _.sortBy(appointmentTypes, 'name');
		} else {
			const index1 = activityTypes.findIndex(type => type.id === state.merge.typeOne.id);
			if (index1 !== -1) {
				activityTypes.splice(index1, 1);
			}
			const index2 = activityTypes.findIndex(type => type.id === state.merge.typeTwo.id);
			if (index2 !== -1) {
				activityTypes.splice(index2, 1);
			}
			activityTypes.push(keepType);
			activityTypes = _.sortBy(activityTypes, 'name');
		}

		return { activityTypes, appointmentTypes };
	},
	doMerge: function () {
		var self = this;
		this.setState({ merging: true });
		if (!self.state.merge || !self.state.merge.name || !this.state.merge.typeOne || !this.state.merge.typeTwo) {
			return;
		}

		this.props.rootData.doMerge(this.state.merge, function (err, keepType) {
			if (err) {
				self.setState({
					merge: undefined,
					merging: false
				});
				return;
			}
			const { activityTypes, appointmentTypes } = self.updateTypes(self.state, keepType);

			self.setState({
				merge: undefined,
				merging: false,
				activityTypes,
				appointmentTypes
			});
		});
	},
	saveClientParam: async function (id, clientParam, value) {
		await Tools.ClientParam.save(id, value);
		const metadata = Tools.AppService.getMetadata();
		Tools.AppService.setMetadata({
			...metadata,
			params: {
				...metadata.params,
				[clientParam]: value
			}
		});
	},
	onChangeEnableAutoFill: async function (value) {
		const self = this;
		try {
			self.saveClientParam(259, 'EnableAutoFillAppointmentsDescription', value);
			self.setState({ EnableAutoFillAppointmentsDescription: value });
		} catch (err) {
			logError(err, 'Error while saving EnableAutoFillAppointmentsDescription param');
		}
	},
	row: function (item, index) {
		var self = this;
		var hasUserPermissionsAdvanced = Tools.FeatureHelper.isAvailable(
			Tools.FeatureHelper.Feature.USER_PERMISSIONS_ADVANCED
		);

		var roleString = hasUserPermissionsAdvanced ? <ReactTemplates.admin.tableTokens items={item.roles} /> : null;
		const isAppointment = self.isAppointment(item);

		return (
			<tr key={'activity-type-' + index} className="admin-table-row activity-types">
				<td
					onClick={this.inlineEdit.bind(null, item, 'name')}
					className="activity-types-table-td activity-types-table-td-1 admin-table-cell"
				>
					{item.name}
				</td>
				{isAppointment && !self.hasCalendarColors ? (
					<td
						onClick={this.inlineEdit.bind(null, item, 'name')}
						className="activity-types-table-td activity-types-table-td-1 admin-table-cell"
					>
						<div
							className="colorPad"
							style={
								item.color
									? { backgroundColor: item.color, color: window.getTextColor(item.color) }
									: {}
							}
						>
							{item.color ? item.color : this.lang.nothingSelected}
						</div>
					</td>
				) : null}
				<td
					onClick={this.inlineEdit.bind(null, item, 'name')}
					className="activity-types-table-td activity-types-table-td-2 admin-table-cell"
				>
					<span style={{ fontSize: '12px', color: '#999', fontStyle: 'italic' }}>
						{item.default ? this.lang.yes : ''}
					</span>
				</td>
				{hasUserPermissionsAdvanced ? (
					<td
						onClick={this.inlineEdit.bind(null, item, 'role')}
						className="activity-types-table-td activity-types-table-td-3 admin-table-cell"
					>
						{roleString}
					</td>
				) : null}
				{isAppointment && (
					<td
						onClick={this.inlineEdit.bind(null, item, 'name')}
						className="activity-types-table-td activity-types-table-td-2 admin-table-cell"
					>
						<span style={{ fontSize: '12px', color: '#999', fontStyle: 'italic' }}>
							{item.first ? self.lang.yes : ''}
						</span>
					</td>
				)}
				{!isAppointment && this.hasActivityOutcomeAccess ? (
					<td
						key="yes"
						onClick={this.inlineEdit.bind(null, item, 'hasOutcome')}
						className="activity-types-table-td admin-table-cell"
					>
						{item.hasOutcome ? this.lang.active : ''}
					</td>
				) : null}
				<td
					onClick={this.inlineEdit.bind(null, item, 'name')}
					className="activity-types-table-td admin-table-cell"
				/>
			</tr>
		);
	},
	editRow: function (item, index) {
		var self = this;
		var tools = this.props.tools;

		var names = _.pluck(item.roles, 'name');
		var commaSeparated = names.join(', ');

		var nameClasses = 'floating-label-input';

		if (item.name && item.name.length > 0) {
			nameClasses = nameClasses + ' has-value';
		}
		if (self.state.touched.name && !self.validate('name', item)) {
			nameClasses = nameClasses + ' has-error';
		}

		var roleClasses = 'floating-label-input';

		if (item.roles.length) {
			roleClasses = roleClasses + ' has-value';
		}

		var hasUserPermissionsAdvanced = Tools.FeatureHelper.isAvailable(
			Tools.FeatureHelper.Feature.USER_PERMISSIONS_ADVANCED
		);
		const isAppointment = self.isAppointment(item);

		const hasTreeSelect = Tools.FeatureHelper.hasSoftDeployAccess('TREE_SELECT');

		return (
			<tr
				key={'activity-type-' + index}
				style={{ height: '62px', backgroundColor: '#f5f5f5' }}
				className="admin-table-row activity-type-table-inline-tr"
			>
				<td className="admin-table-cell activity-types-table-inline-td activity-types-table-inline-td-1">
					<div className={nameClasses}>
						<label>{self.lang.name}</label>
						<input
							key={index}
							id="activitytype-edit-name-input"
							type="text"
							name="name"
							ref={'name_activity-type-' + index}
							onChange={self.editInlineField.bind(null, 'name')}
							onKeyPress={self.keyPressSave.bind(null, item)}
							value={item.name}
							data-test-id="admin-add-type-name-input"
							maxLength="40"
						/>
					</div>
				</td>
				{isAppointment && !self.hasCalendarColors ? (
					<td className="colorPad-td admin-table-cell activity-types-table-inline-td activity-types-table-inline-td-1">
						<window.UiColorSelect
							className="colorPad-wrapper"
							onChange={self.colorChange}
							color={item.color || '#e4eaf0'}
						/>
					</td>
				) : null}
				<td className="admin-table-cell activity-types-table-inline-td activity-types-table-inline-td-2">
					<div className="input-checkbox">
						<input
							key={index}
							type="checkbox"
							name="isDefault"
							ref={'isDefault-type-' + index}
							onChange={self.editInlineField.bind(null, 'default')}
							checked={!!item.default}
						/>
					</div>
				</td>
				{hasUserPermissionsAdvanced ? (
					<td
						className="admin-table-cell activity-types-table-inline-td activity-types-table-inline-td-3"
						style={{ position: 'relative' }}
					>
						<div className="role-select-wrap">
							<div style={{ display: self.state.editRolesOpen ? 'block' : 'none' }}>
								{hasTreeSelect ? (
									<RoleSelect
										roles={self.props.rootData.pageData.roles}
										selectedRoles={item.roles}
										onChange={self.changeRole}
									/>
								) : (
									<ReactTemplates.INPUTS.upRoles
										id="admin-stages-role-input"
										key={'role-select'}
										tools={tools}
										data={self.props.rootData.pageData.roles}
										value={item.roles}
										multiple={true}
										onChange={self.changeRole}
										onBlur={self.closeEditRoles}
									/>
								)}
							</div>
							<div
								onClick={self.openEditRoles}
								className={roleClasses}
								style={{ cursor: 'text', display: !self.state.editRolesOpen ? 'block' : 'none' }}
							>
								<label>{self.lang.restrictedToRole}</label>
								<input
									id="activitytype-stages-role-input"
									key={index + 'restrictedToRoleInput'}
									type="text"
									value={commaSeparated}
								/>
							</div>
						</div>
					</td>
				) : null}
				{isAppointment && (
					<td className="admin-table-cell activity-types-table-inline-td activity-types-table-inline-td-2">
						<div className="input-checkbox">
							<input
								key={index}
								type="checkbox"
								name="isFirstAppointment"
								ref={'isFirstAppointment_activity-type-' + index}
								onChange={self.editInlineField.bind(null, 'first')}
								checked={!!item.first}
							/>
						</div>
					</td>
				)}
				{!isAppointment && this.hasActivityOutcomeAccess ? (
					<td className="admin-table-cell activity-types-table-inline-td activity-types-table-inline-td-3">
						<div className="input-checkbox">
							<input
								key={index}
								type="checkbox"
								name="hasOutcome"
								onChange={self.editInlineField.bind(null, 'hasOutcome')}
								checked={!!item.hasOutcome}
							/>
						</div>
					</td>
				) : null}
				<td className="admin-table-cell activity-types-table-inline-td activity-types-table-inline-td-4">
					<div className="btn-wrap">
						<Button
							onClick={self.saveEdit.bind(null, item)}
							size="sm"
							disabled={!self.validate('name', item)}
							id="admin-add-type-save"
						>
							{self.lang.save}
						</Button>
						<Button onClick={self.stopEdit} type="link" size="sm" color="grey">
							{self.lang.cancel}
						</Button>
					</div>
				</td>
			</tr>
		);
	},

	setMergeItem: function (name, value) {
		var merge = Object.assign({}, this.state.merge);
		merge[name] = value || null;
		if (value && (!this.state.merge.touchedName || !merge.name)) {
			merge.name = value.name;
		}

		this.setState({ merge: merge });
	},

	getSortIcon: function (attr, isAppointment) {
		var sort = isAppointment ? this.state.appointmentSort : this.state.activitySort;
		var desc = isAppointment ? this.state.appointmentDesc : this.state.activityDesc;
		return sort === attr ? (
			<b className={desc ? 'fa fa-sort-alpha-desc' : 'fa fa-sort-alpha-asc'} />
		) : (
			<b className="fa fa-sort" />
		);
	},

	getTable: function (isAppointment, rows) {
		var self = this;

		var hasUserPermissionsAdvanced = Tools.FeatureHelper.isAvailable(
			Tools.FeatureHelper.Feature.USER_PERMISSIONS_ADVANCED
		);

		var mergeWrap = null;
		var mergeTrigger = null;
		const types = isAppointment ? self.state.appointmentTypes : self.state.activityTypes;
		if (!self.state.edit && self.state.merge && self.state.merge.isAppointment === isAppointment) {
			var merge1Data = _.filter(types, function (t) {
				return !self.state.merge.typeTwo || self.state.merge.typeTwo.id !== t.id;
			});
			var merge2Data = _.filter(types, function (t) {
				return !self.state.merge.typeOne || self.state.merge.typeOne.id !== t.id;
			});
			mergeWrap = (
				<div className="merge-wrap">
					<div className="merge-description">
						{isAppointment
							? self.lang.mergeAppointmentTypesDescription
							: self.lang.mergeActivityTypesDescription}
					</div>
					<div className="merge-item">
						<label>{self.lang.type + ' 1'}</label>
						<UpActivityTypes
							id="merge-type-1"
							data={merge1Data}
							value={this.state.merge.typeOne}
							onChange={this.setMergeItem.bind(this, 'typeOne')}
							placeholder={
								self.lang.choose +
								' ' +
								(isAppointment
									? self.lang.appointmentType.toLowerCase()
									: self.lang.activityType.toLowerCase())
							}
						/>
					</div>
					<div className="merge-item">
						<label>{self.lang.type + ' 2'}</label>
						<UpActivityTypes
							id="merge-type-2"
							data={merge2Data}
							value={this.state.merge.typeTwo}
							onChange={this.setMergeItem.bind(this, 'typeTwo')}
							placeholder={
								self.lang.choose +
								' ' +
								(isAppointment
									? self.lang.appointmentType.toLowerCase()
									: self.lang.activityType.toLowerCase())
							}
						/>
					</div>

					<label>{isAppointment ? self.lang.newAppointmentTypeName : self.lang.newActivityTypeName}</label>
					<input
						type="text"
						className="form-control"
						value={(self.state.merge && self.state.merge.name) || ''}
						onChange={self.setMergeName.bind(null)}
						onKeyPress={this.keyPressMerge.bind(null, isAppointment)}
						id="merge-new-name"
						maxLength={40}
					/>

					<div className="btn-wrap">
						<Button
							onClick={this.doMerge.bind(null, isAppointment)}
							color="bright-blue"
							disabled={this.state.merging || !self.validateMerge()}
							id="admin-merge-save"
							loading={this.state.merging}
						>
							{self.lang.merge +
								' ' +
								(isAppointment
									? self.lang.appointmentTypes.toLowerCase()
									: self.lang.activityTypes.toLowerCase())}
						</Button>
						<button
							onClick={isAppointment ? self.toggleAppointmentTypeMerge : self.toggleActivityTypeMerge}
							className="btn up-btn no-shadow btn-sm btn-grey btn-link"
						>
							{self.lang.cancel}
						</button>
					</div>
				</div>
			);
		} else if (types.length >= 2) {
			mergeTrigger = (
				<a
					data-test-id={'admin-merge-' + (isAppointment ? 'appointment' : 'activity') + '-types'}
					className="activity-types-merge-link"
					onClick={isAppointment ? self.toggleAppointmentTypeMerge : self.toggleActivityTypeMerge}
				>
					{isAppointment ? self.lang.appointmentTypeMerge : self.lang.activityTypeMerge}
				</a>
			);
		}

		var firstAppointmentColumn = isAppointment ? (
			<th className="activity-types-table-th activity-types-table-th-2">
				{self.lang.isFirstAppointment} <Help articleId={1223} sidebar={true} />
			</th>
		) : null;

		var nameClass = 'activity-types-table-th activity-types-table-th-1 sortable';
		var sort = isAppointment ? this.state.appointmentSort : this.state.activitySort;
		if (sort === 'name') {
			nameClass += ' active';
		}

		return (
			<div className="admin-table" id={'admin-' + (isAppointment ? 'appointment' : 'activity') + '-type-table'}>
				<div className="admin-table-top">
					<span className="admin-table-title">
						{isAppointment ? self.lang.appointmentTypes : self.lang.activityTypes}
					</span>
					<div className="pull-right">
						{mergeTrigger}
						<Button
							style={{ width: '150px' }}
							onClick={self.onNewField.bind(null, isAppointment)}
							size="sm"
							disabled={!!(self.state.edit || self.state.merge)}
							data-test-id={'admin-add-' + (isAppointment ? 'appointment' : 'activity') + '-type'}
						>
							{self.lang.new + ' ' + (isAppointment ? self.lang.addAppointment : self.lang.addActivity)}
						</Button>
					</div>
				</div>

				{mergeWrap}

				{!mergeWrap ? (
					<table style={{ tableLayout: 'fixed' }}>
						<thead>
							<tr>
								<th className={nameClass} onClick={this.setSort.bind(null, 'name', isAppointment)}>
									{self.lang.name} {self.getSortIcon('name', isAppointment)}
								</th>
								{isAppointment && !this.hasCalendarColors ? <th>{self.lang.color}</th> : null}
								<th className="activity-types-table-th activity-types-table-th-2">
									{self.lang.default}
								</th>
								{hasUserPermissionsAdvanced ? (
									<th className={'activity-types-table-th-3'}>{self.lang.restrictedToRole}</th>
								) : null}
								{firstAppointmentColumn}
								{!isAppointment && this.hasActivityOutcomeAccess ? (
									<th className="activity-types-table-th">
										{this.lang.outcome} <Help articleId={1120} sidebar />
									</th>
								) : null}
								<th className="activity-types-table-th activity-types-table-th-4" />
							</tr>
						</thead>
						<tbody>{rows}</tbody>
					</table>
				) : null}
			</div>
		);
	},

	componentDidUpdate: function () {
		if (this.state.edit) {
			if (!this.state.editRolesOpen) {
				this.setState({
					editRolesOpen: true
				});
			}

			if (this.state.editField) {
				if (this.state.editField === 'name') {
					var input = $('#activitytype-edit-name-input');
					input.focus();
					var strLength = 40;
					input[0].setSelectionRange(strLength, strLength);
				} else if (this.state.editField === 'role') {
					setTimeout(function () {
						$('#admin-stages-role-input').select2('open');
					}, 100);
				}
				this.setState({
					editField: null
				});
			}
		}
	},

	render: function () {
		var self = this;
		var activityTypes = this.props.rootData.pageData.activityTypes;
		var appointmentTypes = this.props.rootData.pageData.appointmentTypes;

		var activityTypeRows = self.getRows(activityTypes, 'activity');
		if (self.state.edit && !self.isAppointment(self.state.edit) && !self.state.edit.id) {
			activityTypeRows.unshift(self.editRow(self.state.edit, -1));
		}

		var appointmentTypeRows = self.getRows(appointmentTypes, 'appointment');
		if (self.state.edit && self.isAppointment(self.state.edit) && !self.state.edit.id) {
			appointmentTypeRows.unshift(self.editRow(self.state.edit, -1));
		}

		var activityTypeTable = self.getTable(false, activityTypeRows);
		var appointmentTypeTable = self.getTable(true, appointmentTypeRows);

		return (
			<div id="admin-page-activity-types">
				<AdminHeader
					title={
						Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
						Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') &&
						!self.hasAutoFillDescriptionSetting
							? self.lang.appointmentTypes
							: self.lang.title
					}
					description={self.lang.description}
					image="activity-type.svg"
					articleId={594}
				/>
				<div id="admin-content">
					{self.hasAutoFillDescriptionSetting ? (
						<Card space="mbxl">
							<CardHeader
								title={self.lang.enableAutoFillAppointmentsDescTitle}
								subtitle={self.lang.enableAutoFillAppointmentsDescSubtitle}
								className="enableAutoFillAppointments"
							>
								<Block space="mrxl">
									<Toggle
										color="medium-green"
										checked={self.state.EnableAutoFillAppointmentsDescription}
										onChange={self.onChangeEnableAutoFill}
									/>
								</Block>
							</CardHeader>
						</Card>
					) : null}
					{Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
					Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
						? null
						: activityTypeTable}
					{appointmentTypeTable}
				</div>
			</div>
		);
	}
});
