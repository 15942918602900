import TemplateHeaderSelector from '../../react/templateHeaderSelector/templateHeaderSelector';
// smoke tests will fail if I don't import it like this
import ColumnsTemplateResource from '../../../../../resources/ColumnsTemplate';
import ActivityExport from 'App/enum/exportFields/activity';
import AppointmentExport from 'App/enum/exportFields/appointment';
import OrderExport from 'App/enum/exportFields/order';
import ClientExport from 'App/enum/exportFields/client';
import GrowthExport from 'App/enum/exportFields/growth';
import ContactExport from 'App/enum/exportFields/contact';
import AgreementExport from 'App/enum/exportFields/agreement';
import FormSubmitExport from 'App/enum/exportFields/formSubmit';
import MailExport from 'App/enum/exportFields/mail';
import ProductExport from 'App/enum/exportFields/product';
import ProjectExport from 'App/enum/exportFields/project';
import LeadExport from 'App/enum/exportFields/lead';
import VisitExport from 'App/enum/exportFields/visit';
import logError from 'App/babel/helpers/logError';
import AlertConfirm from 'Components/Dialogs/AlertConfirm';
import openModal from 'App/services/Modal';
import { comparisonTypes } from 'Resources/RequestBuilder';

angular.module('upModals.controllers').controller('ExportData', [
	'$scope',
	'$modalParams',
	'$translate',
	'$multiSelect',
	'ExportService',
	'RequestBuilder',
	'AppService',
	'FeatureHelper',
	'$safeApply',
	function (
		$scope,
		$modalParams,
		$translate,
		$multiSelect,
		ExportService,
		RequestBuilder,
		AppService,
		FeatureHelper,
		$safeApply
	) {
		var ExportData = this;
		ExportData.columns = [];
		ExportData.selectables = {};
		ExportData.customFields = [];
		ExportData.entity = null;
		ExportData.sortableOptions = {
			containment: 'parent',
			cursor: 'move',
			handle: '.handle',
			disabled: false,
			scroll: false,
			placeholder: 'drag-placeholder',
			tolerance: 'pointer',
			update: function () {
				ExportData.templateHasChanged = true;
			}
		};
		ExportData.totalColumns = 0;
		ExportData.exportOrderRows = false;
		ExportData.isExporting = false;
		ExportData.templates = [];
		ExportData.selectedTemplate = null;
		ExportData.defaultTemplate = null;

		const hasExportTemplates = FeatureHelper.hasSoftDeployAccess('EXCEL_EXPORT_TEMPLATES');
		ExportData.TemplateHeaderSelector = hasExportTemplates ? TemplateHeaderSelector : null;
		const hasNewFields = FeatureHelper.hasSoftDeployAccess(FeatureHelper.Feature.NEW_FIELDS);
		const hasAccountPlans = FeatureHelper.hasSoftDeployAccess('ACCOUNT_PLAN');
		const isWidgetExport = !!$modalParams.widget;
		const templatesKey = $modalParams.exportType + 'ExportTemplates';

		var options = $modalParams.options || {};

		const getNewTemplate = (id, name, columns) => {
			return {
				id,
				name,
				columns
			};
		};

		const getReducedColumnInfo = (columns = []) => {
			return columns.map((c, index) => ({
				key: c.key,
				entity: c.entity,
				innerEntity: c.innerEntity,
				sorting: index
			}));
		};

		const saveTemplate = async function (template) {
			const { data, error } = await ColumnsTemplateResource.save({
				...template,
				userId: AppService.getSelf().id,
				columns: getReducedColumnInfo(template.columns),
				templateKey: templatesKey
			});
			if (error) {
				throw error;
			}
			return data;
		};

		const saveDefault = async function () {
			if (hasExportTemplates) {
				const previousSelected = ExportData.templates.find(t => t.selected);
				if (previousSelected?.id !== ExportData.selectedTemplate?.id) {
					const promises = [];
					if (previousSelected && previousSelected.id !== -1) {
						previousSelected.selected = false;
						promises.push(saveTemplate(previousSelected));
					}
					if (ExportData.selectedTemplate?.id !== -1) {
						ExportData.selectedTemplate.selected = true;
						promises.push(saveTemplate(ExportData.selectedTemplate));
					}
					await Promise.all(promises);
				}
			}
		};

		ExportData.onChangeExportOrderRows = function () {
			if (hasExportTemplates && ExportData.selectedTemplate) {
				ExportData.templateHasChanged = true;
				ExportData.selectedTemplate.exportOrderRows = ExportData.exportOrderRows;
			}
		};

		// This is is called from modal when the courtain is closed
		ExportData.onCloseCourtain = () => {
			saveDefault();
		};

		const getValidColumns = () => {
			return ExportData.columns.filter(c => c.isValidField);
		};
		ExportData.getValidColumns = getValidColumns;

		ExportData.done = async function () {
			ExportData.isExporting = true;
			if (hasExportTemplates) {
				await saveDefault();
			}

			var filters = $modalParams.filters;
			if (!isWidgetExport && $modalParams.sort && (!filters.sort || !filters.sort.length)) {
				if ($modalParams.sort.attribute) {
					var sort = {
						a: $modalParams.sort.attribute,
						s: $modalParams.sort.ascending ? 'A' : 'Z'
					};
					filters.sort = [sort];
				}
			}

			const columns = getValidColumns().map(column => {
				/**
					This is for deep customFields, agreements orderrow hax
					and orderrow on orders
				**/
				var key = column.entity + '_' + column.key;
				if (column.innerEntity) {
					key = column.innerEntity + '_' + column.key;
				} else if (column.field) {
					key = column.entity + '_' + column.field;
				}

				const entity = column.entity === 'Client' ? 'column.clientId' : column.entity;
				const title = `${$translate.instant(entity)}: ${$translate.instant(column.title)}`;

				return {
					title,
					key: key
				};
			});

			var opts = { includes: $modalParams.includes };

			if ($modalParams.entity.indexOf('UserDefinedObject') === 0 && $modalParams.exportType) {
				opts.name = $modalParams.exportType;
			}

			if (!$modalParams.justExport && !isWidgetExport) {
				if (!$multiSelect.allSelectedFilter && !$modalParams.allSelected) {
					var requestBuilder = new RequestBuilder();

					var field = 'id';

					if (options.idField) {
						field = options.idField;
					}

					const selected = $multiSelect.selected.length ? $multiSelect.selected : $modalParams.selectedIds;

					requestBuilder.addFilter({ field: field }, requestBuilder.comparisonTypes.Equals, selected);
					if ($modalParams.sort) {
						requestBuilder.addSort($modalParams.sort.attribute, $modalParams.sort.ascending);
					}
					filters.q = filters.q ? filters.q.concat(requestBuilder.build().q) : requestBuilder.build().q;
				}

				if (options) {
					opts = Object.assign(opts, options);
				}
			}
			if (!opts.extraParams) {
				opts.extraParams = {};
			}

			if (['Order', 'Agreement'].includes(ExportData.entity) && ExportData.exportOrderRows) {
				opts.extraParams.exportOrderRows = true;
			}

			if (isWidgetExport) {
				opts.extraParams.widget = $modalParams.widget;
			}

			ExportService.customer(AppService.getCustomerId())
				.create($modalParams.entity, filters, columns, opts)
				.then(function () {
					$scope.resolve();
				})
				.catch(e => {
					logError(e, 'export error');
					ExportData.isExporting = false;
				});
		};

		ExportData.onSave = async (name, isNew) => {
			let selected = null;
			try {
				if (isNew) {
					selected = await saveTemplate({ name, columns: ExportData.columns });
					ExportData.templates = [...ExportData.templates, selected];
				} else {
					selected = await saveTemplate({
						...ExportData.selectedTemplate,
						name,
						columns: ExportData.columns
					});
					ExportData.templates = ExportData.templates.map(t => {
						if (t.id === selected.id) {
							return selected;
						}
						return t;
					});
				}
			} catch (error) {
				logError(error, 'Error saving columns template');
			}

			ExportData.selectedTemplate = selected;
			ExportData.templateHasChanged = false;
			setTimeout(function () {
				$safeApply($scope);
			});
		};

		ExportData.toggleSelect = function (entity, fieldType) {
			if (ExportData.getVisibleCount(entity, fieldType) === 0) {
				deselectAll(entity, fieldType);
			} else {
				selectAll(entity, fieldType);
			}
		};

		function selectAll(entity, fieldType) {
			_.forEach(ExportData.selectables[entity][fieldType], function (col) {
				//this removes current added so there wont be double
				ExportData.remove(col);
				ExportData.add(col, true);
			});
		}

		function deselectAll(entity, fieldType) {
			_.forEach(ExportData.selectables[entity][fieldType], function (col) {
				ExportData.remove(col);
			});
		}

		ExportData.add = function (col, hasChanged) {
			ExportData.templateHasChanged = hasChanged;
			if (!_.find(ExportData.columns, { key: col.key, entity: col.entity })) {
				ExportData.columns.push(col);
				setVisibility(col.entity, col.key, false, col.innerEntity);
			} else if (
				col.innerEntity &&
				!_.find(ExportData.columns, { key: col.key, entity: col.entity, innerEntity: col.innerEntity })
			) {
				ExportData.columns.push(col);
				setVisibility(col.entity, col.key, false, col.innerEntity);
			}
		};

		ExportData.remove = function (col) {
			ExportData.templateHasChanged = true;
			if (col.locked && col.entity === $modalParams.entity) {
				return;
			}
			_.pull(ExportData.columns, col);
			setVisibility(col.entity, col.key, true, col.innerEntity);
		};

		ExportData.getVisibleCount = function (entity, fieldType) {
			var entitySelectable = ExportData.selectables[entity];
			return _.where(entitySelectable[fieldType], { visible: true }).length;
		};

		function setVisibility(entity, key, visible, innerEntity) {
			var entitySelectable = ExportData.selectables[entity];
			var col = null;
			var columns = key.indexOf('Custom_') === 0 ? entitySelectable.customFields : entitySelectable.attributes;

			if (innerEntity) {
				col = _.find(columns, { key: key, innerEntity: innerEntity });
			} else {
				col = _.find(columns, { key: key });
			}

			if (col) {
				col.visible = visible;
			}
		}

		var mapField = function (entity, col) {
			var key = entity + '_' + col.field;
			var c = angular.copy(col);
			c.entity = entity;
			c.$translated = $translate.instant(col.title) || col.field;
			c.key = key;
			c.visible = true;
			c.isValidField = true;
			c.locked = (col.locked && ExportData.entity === entity) || false;
			return c;
		};

		var mapCustomFields = function (entity, field, innerEntity) {
			var key = 'Custom_' + field.id;
			return {
				key: key,
				entity: entity,
				innerEntity: innerEntity,
				title: field.name,
				$translated: $translate.instant(field.name),
				visible: (field.visible || field.editable) && field.$hasAccess,
				isValidField: field.$hasAccess
			};
		};

		const getCustomfieldsByType = type => {
			const obj = {};

			switch (type) {
				case 'appointment':
				case 'activity':
					if (type === 'activity') {
						obj.Activity = AppService.getCustomFields('activity');
					} else {
						obj.Appointment = AppService.getCustomFields('appointment');
					}
					obj.Contact = AppService.getCustomFields('contact');
					obj.Client = AppService.getCustomFields('account');
					break;
				case 'opportunity':
				case 'order':
					obj.Order = {
						Order: AppService.getCustomFields('order'),
						OrderRow: AppService.getCustomFields('orderrow')
					};
					obj.Client = AppService.getCustomFields('account');
					obj.Contact = AppService.getCustomFields('contact');
					break;
				case 'accounts':
				case 'client':
					obj.Client = AppService.getCustomFields('account');
					break;
				case 'contact':
					obj.Contact = AppService.getCustomFields('contact');
					obj.Client = AppService.getCustomFields('account');
					break;
				case 'agreement':
					obj.Agreement = {
						Agreement: AppService.getCustomFields('agreement'),
						Order: AppService.getCustomFields('order'),
						OrderRow: AppService.getCustomFields('orderrow')
					};
					obj.Client = AppService.getCustomFields('account');
					obj.Contact = AppService.getCustomFields('contact');
					break;
				case 'visit':
				case 'mail':
					obj.Contact = AppService.getCustomFields('contact');
					obj.Client = AppService.getCustomFields('account');
					break;
				case 'product':
					obj.Product = AppService.getCustomFields('product');
					break;
				case 'project':
					obj.Project = AppService.getCustomFields('project');
					break;
				case 'formsubmit':
					obj.Contact = AppService.getCustomFields('contact');
					obj.Client = AppService.getCustomFields('account');
					break;
			}

			return obj;
		};

		const getExportFieldsWithCategories = (ExportFields, catType) => {
			const customCategories = AppService.getCategoryTypes(catType).reduce((res, category) => {
				const key = `Category_${category.id}`;
				res[key] = {
					title: category.name,
					field: key
				};
				return res;
			}, {});
			return { ...customCategories, ...ExportFields };
		};

		const getSelectablesByType = type => {
			const obj = {};

			switch (type) {
				case 'appointment':
				case 'activity':
					if (type === 'activity') {
						obj.Activity = ActivityExport;
					} else {
						obj.Appointment = AppointmentExport;
					}
					obj.Client = ClientExport;
					obj.Contact = ContactExport;
					break;
				case 'opportunity':
				case 'order':
					obj.Order = OrderExport;
					obj.Client = ClientExport;
					obj.Contact = ContactExport;
					break;
				case 'accounts':
					obj.Client = getExportFieldsWithCategories(GrowthExport, 'account');
					break;
				case 'client':
					obj.Client = getExportFieldsWithCategories(ClientExport, 'account');
					break;
				case 'contact':
					obj.Client = getExportFieldsWithCategories(ClientExport, 'account');
					obj.Contact = getExportFieldsWithCategories(ContactExport, 'contact');
					break;
				case 'agreement':
					obj.Agreement = AgreementExport;
					obj.Client = ClientExport;
					obj.Contact = ContactExport;
					break;
				case 'mail':
					obj.Mail = MailExport;
					obj.Contact = ContactExport;
					obj.Client = ClientExport;
					break;
				case 'product':
					obj.Product = ProductExport;
					break;
				case 'project':
					obj.Project = ProjectExport;
					break;
				case 'lead':
					obj.Lead = LeadExport;
					break;
				case 'formsubmit':
					obj.FormSubmit = FormSubmitExport;
					obj.Contact = ContactExport;
					obj.Client = ClientExport;
					break;
				case 'visit':
					obj.Contact = ContactExport;
					obj.Visit = VisitExport;
					obj.Client = ClientExport;
					break;
			}

			return obj;
		};

		const getDefaultColumnsByType = type => {
			switch (type) {
				case 'appointment':
				case 'activity':
					return [];
				case 'opportunity':
				case 'order':
					return ['date', 'value', 'description', 'user'];
			}
		};

		const mapAlreadySelected = mapped => {
			if (hasExportTemplates) {
				const alreadySelected = ExportData.columns.findIndex(
					c =>
						c.key === mapped.key &&
						c.entity === mapped.entity &&
						(mapped.innerEntity ?? null) === (c.innerEntity ?? null)
				);
				if (alreadySelected > -1) {
					ExportData.columns[alreadySelected] = mapped;
					return true;
				}
			}
			return false;
		};

		const setFields = (skipDefaults = false) => {
			let customfields;
			let selectables;
			ExportData.selectables = {};
			if ($modalParams.optionsByType) {
				customfields = getCustomfieldsByType($modalParams.exportType);
				selectables = getSelectablesByType($modalParams.exportType);
			} else {
				customfields = $modalParams.customfields;
				selectables = $modalParams.selectables;
			}

			// TODO: This makes Opportunities behave exactly like Orders. This will work, but the text/notifications will be wrong.
			if ($modalParams.entity === 'Opportunity') {
				$modalParams.entity = 'Order';
			}

			ExportData.entity = $modalParams.entity;
			ExportData.udoTitle = null;

			ExportData.showOrderRowToggle = ['Order', 'Agreement'].indexOf(ExportData.entity) !== -1;

			if (ExportData.entity.indexOf('UserDefinedObject') === 0) {
				var metadata = AppService.getMetadata();
				var udos = metadata.params.UserDefinedObject;
				var id = parseInt(ExportData.entity[ExportData.entity.length - 1]);
				id = isNaN(id) ? 1 : id;

				var udo = _.find(udos, { id: id });
				if (udo) {
					ExportData.udoTitle = udo.name;
				}
			}
			var standardFields = AppService.getMetadata().standardFields;

			angular.forEach(selectables, function (attributes, key) {
				var columns = Object.values(attributes);
				if (!ExportData.selectables[key]) {
					ExportData.selectables[key] = { attributes: [], customFields: [] };
				}

				angular.forEach(columns, function (column) {
					var mapped = mapField(key, column);
					var found;

					if (mapped.unreleasedFeature === FeatureHelper.Feature.NEW_FIELDS) {
						found = _.find(standardFields[key], { field: mapped.field });
						if (!hasNewFields || !found || !found.active) {
							return;
						}
					}
					if (mapped?.unreleasedFeature === 'ACCOUNT_PLAN' && !hasAccountPlans) {
						return;
					}
					if (mapped !== null && mapped.field) {
						if (mapAlreadySelected(mapped)) {
							mapped.visible = false;
						}
						ExportData.totalColumns++;
						ExportData.selectables[key].attributes.push(mapped);
						if (mapped.locked && $modalParams.entity === key) {
							ExportData.add(mapped);
						}
					}
				});
				var entityCustom = customfields[key];
				if (!entityCustom || (!entityCustom.length && typeof entityCustom !== 'object')) {
					return;
				}

				angular.forEach(entityCustom, function (column, innerKey) {
					if (typeof innerKey !== 'number') {
						_.forEach(column, function (innerColumn) {
							var mapped = mapCustomFields(key, innerColumn, innerKey);

							if (mapped !== null && innerColumn.visible) {
								if (mapAlreadySelected(mapped)) {
									mapped.visible = false;
								}
								ExportData.totalColumns++;
								ExportData.selectables[key].customFields.push(mapped);
							}
						});
					} else {
						var mapped = mapCustomFields(key, column);
						if (mapped !== null && column.visible) {
							if (mapAlreadySelected(mapped)) {
								mapped.visible = false;
							}
							ExportData.totalColumns++;
							ExportData.selectables[key].customFields.push(mapped);
						}
					}
				});
			});

			if (skipDefaults) {
				return;
			}
			angular.forEach($modalParams.columns || getDefaultColumnsByType(), function (columnName) {
				// Ugly hack. ColumnName refers to old status column which SHOULD have been named History.
				if ($modalParams.entity === 'Client' && columnName === 'status') {
					return;
				}
				var field = selectables[$modalParams.entity][columnName];
				if (field) {
					field = field.field;
				} else {
					field = columnName;
				}
				var entitySelectable = ExportData.selectables[$modalParams.entity];
				var col = null;
				col = _.find(entitySelectable.attributes, { field: field });
				if (!col) {
					col = _.find(entitySelectable.customFields, { key: field });
				}
				if (col) {
					ExportData.add(col);
				}
			});
		};

		const changeTemplate = selectedOption => {
			ExportData.columns = [...selectedOption.columns.sort((a, b) => a.sorting - b.sorting)];
			ExportData.templateHasChanged = false;
			ExportData.selectedTemplate = selectedOption;
			ExportData.exportOrderRows = selectedOption.exportOrderRows ?? false;
			setFields(true);
			setTimeout(function () {
				$safeApply($scope);
			}, 0);
		};

		ExportData.onTemplateChange = selectedOption => {
			if (ExportData.templateHasChanged) {
				const alertConfirmOptions = {
					type: 'confirm',
					reactive: true,
					dialog: AlertConfirm,
					id: 'confirm-non-saved-changes',
					body: Tools.$translate('confirm.doYouWantToContinue'),
					title: Tools.$translate('confirm.changesWillBeLost'),
					confirmButtonText: Tools.$translate('default.confirm')
				};
				openModal('Alert', {
					...alertConfirmOptions,
					onClose: confirmed => {
						if (confirmed) {
							changeTemplate(selectedOption);
						}
					}
				});
				return;
			}
			changeTemplate(selectedOption);
		};

		ExportData.onDelete = async () => {
			const { error } = await ColumnsTemplateResource.delete(ExportData.selectedTemplate.id);
			if (error) {
				logError(error, 'Error deleting columns template');
				return;
			}
			ExportData.templates = ExportData.templates.filter(t => t.id !== ExportData.selectedTemplate.id);
			const defaultTemplate = ExportData.templates[0];
			ExportData.onTemplateChange(defaultTemplate);
		};

		const getTemplates = async function () {
			let templates = [];
			try {
				const filters = new RequestBuilder();
				filters.addFilter({ field: 'userId' }, comparisonTypes.Equals, AppService.getSelf().id);
				filters.addFilter({ field: 'templateKey' }, comparisonTypes.Equals, templatesKey);
				const response = await ColumnsTemplateResource.find(filters.build());
				templates = response.data;
			} catch (e) {
				logError(e, 'error getting columns templates');
			}
			return templates;
		};

		const init = async function () {
			setFields(); // We do it first to get the default values
			if (hasExportTemplates) {
				const defaultTemplate = getNewTemplate(-1, $translate.instant('default.default'), [
					...ExportData.columns
				]);
				ExportData.defaultTemplate = defaultTemplate;
				ExportData.templates.unshift(defaultTemplate);
				const templates = await getTemplates();
				ExportData.templates.push(...templates);
				const defaultSelected = templates.find(t => t.selected) ?? defaultTemplate;
				ExportData.onTemplateChange(defaultSelected);
			}
			setTimeout(function () {
				$safeApply($scope);
			}, 0);
		};

		init();
	}
]);
