import { globalTracker } from 'App/babel/helpers/Tracker';
import _ from 'lodash';
import logError from 'App/babel/helpers/logError';
import { openDrawer } from 'Services/Drawer';
import { openNewMailWithContact } from 'App/helpers/mailHelpers';
import { phoneNumberHelper } from '@upsales/common';
import openModal from 'App/services/Modal';
import { EllipsisTooltip } from '@upsales/components';
import TicketResource from 'App/resources/Ticket';
import InlineInput from 'Components/Inputs/InlineInput';
import { makeCancelable } from 'Helpers/promise';
import ContactResource from 'Resources/Contact';

angular.module('domain.contact').controller('Contact', [
	'$scope',
	'$translate',
	'$upModal',
	'$stateParams',
	'$state',
	'meta',
	'Order',
	'RequestBuilder',
	'Activity',
	'Campaign',
	'Opportunity',
	'Appointment',
	'ActivityList',
	'SaveInline',
	'NotificationService',
	'Contact',
	'Event',
	'Agreement',
	'Links',
	'AppService',
	'ScriptService',
	'ContactRelation',
	'File',
	'FeatureHelper',
	'$q',
	'UserDefinedObject',
	'UdoLink',
	'Esign',
	'Report',
	'Account',
	'LEAD_SOURCE',
	'$safeApply',
	function (
		$scope,
		$translate,
		$upModal,
		$stateParams,
		$state,
		meta,
		Order,
		RequestBuilder,
		Activity,
		Campaign,
		Opportunity,
		Appointment,
		ActivityList,
		SaveInline,
		NotificationService,
		Contact,
		Event,
		Agreement,
		Links,
		AppService,
		ScriptService,
		ContactRelation,
		File,
		FeatureHelper,
		$q,
		UserDefinedObject,
		UdoLink,
		Esign,
		Report,
		Account,
		LEAD_SOURCE,
		$safeApply
	) {
		var contactId = $stateParams.id;
		var ContactCtrl = this;
		var documentTemplates = null;
		var metadata;
		var self;
		var excludedStages = [];

		var firstTouchTypes = {
			Offline: {
				label: 'default.created'
			},
			DirectTraffic: {
				label: 'firstTouch.entranceUrl'
			},
			EmailMarketing: {
				label: 'firstTouch.campaignName'
			},
			Engage: {
				label: 'firstTouch.campaignName'
			},
			OrganicSearch: {
				label: 'firstTouch.platform',
				secondaryType: 'firstTouch.searchTerm',
				defaultSecondaryName: 'firstTouch.unknownKeywords'
			},
			OtherCampaign: {
				label: 'firstTouch.campaignName'
			},
			PaidSearch: {
				label: 'firstTouch.campaignName',
				secondaryType: 'firstTouch.searchTerm',
				defaultSecondaryName: 'firstTouch.unknownKeywords'
			},
			PaidSocialMedia: {
				label: 'firstTouch.socialMedia',
				secondaryType: 'firstTouch.campaignName',
				defaultSecondaryName: 'firstTouch.noCampaign'
			},
			Referral: {
				label: 'firstTouch.referringWebSite',
				secondaryType: 'firstTouch.websiteLink',
				isLink: true
			},
			SocialMedia: {
				label: 'firstTouch.socialMedia',
				secondaryType: 'firstTouch.campaignName',
				defaultSecondaryName: 'firstTouch.noCampaign'
			}
		};
		ContactCtrl.EllipsisTooltip = EllipsisTooltip;
		ContactCtrl.InlineInput = InlineInput;
		ContactCtrl.createElement = React.createElement;
		ContactCtrl.customerId = $stateParams.customerId;
		ContactCtrl.activitiesLoading = false;
		ContactCtrl.opportunitiesLoading = false;
		ContactCtrl.optinExpanded = false;
		ContactCtrl.notesLimit = 80;
		ContactCtrl.orderAgreementTotal = 0;
		ContactCtrl.isLoadingOptinFix = null;
		ContactCtrl.isFirstTouchExpanded = null;

		$scope.$on('contact.unbounce', function (e, email) {
			if (ContactCtrl?.contact?.email === email && Array.isArray(ContactCtrl?.contact?.mailBounces)) {
				ContactCtrl.contact.mailBounces = ContactCtrl.contact.mailBounces.filter(
					bounceType => bounceType !== 'hard_bounce'
				);
				ContactCtrl.emailBounceFn();
			}
		});

		$scope.$on('contact.updated', function (e, updated) {
			if (ContactCtrl.contact.id === updated.id) {
				// Check if moved
				if (updated.client.id !== ContactCtrl.contact.client.id) {
					$scope.$broadcast('ContactCtrl.moved', updated);
				}

				ContactCtrl.contact = updated;
				generateCustomFields();
				getCategories();
				getLinks();
				ContactCtrl.emailBounceFn();

				$scope.standardFields.social = _.filter(metadata.standardFields.Contact, function (field) {
					var firstCheck = field.group === 'social' && field.active;
					var secondCheck = ContactCtrl.contact[field.field] && ContactCtrl.contact[field.field].length;
					return firstCheck && secondCheck;
				});
			}
		});

		$scope.$on('contact.merged', function (e, res) {
			if (res.merged.id === ContactCtrl.contact.id) {
				// Save some stuff from the contact here and set dem after merge
				ContactCtrl.refetchContact();
				// get NO. opportunities
				getNOOpportunities();

				// get NO. activities
				getNOActivities();

				// get NO. market things
				sumRecentScore();

				// get NO. orders
				getNOOrder();
				getNOAgreement();
				getFilesAndEsign();

				getLinks();
				getCategories();

				ContactCtrl.relationsTotal = ContactCtrl.contact.connectedClients
					? ContactCtrl.contact.connectedClients.length
					: 0;

				// Custom fields
				generateCustomFields();

				$scope.standardFields.social = _.filter(metadata.standardFields.Contact, function (field) {
					var firstCheck = field.group === 'social' && field.active;
					var secondCheck = ContactCtrl.contact[field.field] && ContactCtrl.contact[field.field].length;
					return firstCheck && secondCheck;
				});
			}
		});

		// EVENTS

		// Added events changes
		$scope.$on('activity.added', function (e, added) {
			var contact = _.find(added.contacts, { id: ContactCtrl.contact.id });
			if (contact) {
				setTimeout(function () {
					getNOActivities();
				}, 700);
			}
		});

		$scope.$on('activity.updated', function () {
			setTimeout(function () {
				getNOActivities();
			}, 700);
		});

		$scope.$on('appointment.added', function (e, added) {
			var contact = _.find(added.contacts, { id: ContactCtrl.contact.id });
			if (contact) {
				setTimeout(function () {
					getNOActivities();
				}, 700);
			}
		});

		$scope.$on('appointment.updated', function () {
			setTimeout(function () {
				getNOActivities();
			}, 700);
		});

		$scope.$on('opportunity.added', function (e, added) {
			if (added.contact && added.contact.id === ContactCtrl.contact.id) {
				getNOOpportunities();
			}
		});

		$scope.$on('order.added', function (e, added) {
			if (added.contact && added.contact.id === ContactCtrl.contact.id) {
				if (added.probability === 100) {
					setTimeout(function () {
						getNOOrder();
					}, 700);
				} else {
					setTimeout(function () {
						getNOOpportunities();
					}, 700);
				}
			}
		});

		$scope.$on('agreement.added', function (e, added) {
			if (added.contact && added.contact.id === ContactCtrl.contact.id) {
				ContactCtrl.agreementTotal++;
				ContactCtrl.orderAgreementTotal++;
				$safeApply($scope);
			}
		});

		$scope.$on('mail.added', function (e, added) {
			if (added.contact && added.contact.id === ContactCtrl.contact.id) {
				//ContactCtrl.activitiesTotal++;
				if (added.type !== 'sch') {
					ContactCtrl.marketActivities++;
				}
			}
		});

		// Deleted events
		$scope.$on('activity.deleted', function (e, deleted) {
			if (deleted.contacts && _.find(deleted.contacts, { id: ContactCtrl.contact.id })) {
				ContactCtrl.activitiesTotal--;
				setTimeout(function () {
					getNOActivities();
				}, 700);
			}
		});

		$scope.$on('appointment.deleted', function (e, deleted) {
			if (deleted.contacts && _.find(deleted.contacts, { id: ContactCtrl.contact.id })) {
				ContactCtrl.activitiesTotal--;
				setTimeout(function () {
					getNOActivities();
				}, 700);
			}
		});

		$scope.$on('agreement.deleted', function (e, deleted) {
			if (deleted.contact && deleted.contact.id === ContactCtrl.contact.id) {
				ContactCtrl.agreementTotal--;
				ContactCtrl.orderAgreementTotal--;
			}
		});

		$scope.$on('order.deleted', function (e, deleted) {
			if (deleted.contact && deleted.contact.id === ContactCtrl.contact.id) {
				ContactCtrl.orderTotal -= deleted.value;
				ContactCtrl.orderAgreementTotal--;
			}
		});

		$scope.$on('opportunity.deleted', function (e, deleted) {
			if (deleted.contact && deleted.contact.id === ContactCtrl.contact.id) {
				setTimeout(function () {
					getNOOpportunities();
				}, 700);
			}
		});

		$scope.$on('contact.unsubscribed', function (e, contactId) {
			if (contactId === ContactCtrl.contact.id) {
				ContactCtrl.contact.unsubscribed = new Date();
			}

			ContactCtrl.isLoadingOptinFix = false;
		});

		$scope.$on('contact.resubscribed', function (e, contactId) {
			if (contactId === ContactCtrl.contact.id) {
				ContactCtrl.contact.unsubscribed = null;
			}

			ContactCtrl.isLoadingOptinFix = false;
		});

		$scope.$on('account.resetScore', function (e, clientId) {
			if (clientId && clientId === ContactCtrl.contact.client.id) {
				ContactCtrl.contact.score = 0;
				sumRecentScore();
			}
		});

		$scope.$on('market.added', function (e, res) {
			if (res && res.contacts && _.find(res.contacts, { id: ContactCtrl.contact.id })) {
				ContactCtrl.contact.score += res.score;
				ContactCtrl.marketExists = true;
				sumRecentScore();
			}
		});

		$scope.$on('contactRelation.added', function (e, res) {
			if (res.contactId === ContactCtrl.contact.id) {
				ContactCtrl.relationsTotal++;
			}
		});

		$scope.$on('contactRelation.deleted', function (e, res) {
			var index = _.findIndex(ContactCtrl.contact.connectedClients, { id: res.id });
			if (index !== -1) {
				ContactCtrl.relationsTotal--;
			}
		});

		$scope.$on('opportunity.updated', function () {
			getNOOpportunities();
			getNOOrder();
		});

		$scope.$on('order.updated', function () {
			getNOOpportunities();
			getNOOrder();
		});

		$scope.$on('file.uploaded', function (e, file) {
			if (file.entity === File.entityTypes.CONTACT && file.entityId === ContactCtrl.contact.id) {
				ContactCtrl.filesTotal++;
			}
		});

		$scope.$on('file.deleted', function (e, file) {
			if (file.entity === File.entityTypes.CONTACT && file.entityId === ContactCtrl.contact.id) {
				ContactCtrl.filesTotal--;
			}
		});

		$scope.$on('esign.deleted', function (e, deleted) {
			if (deleted.involved && _.find(deleted.involved, { contactId: ContactCtrl.contact.id })) {
				ContactCtrl.filesTotal--;
			}
		});

		$scope.$on('esign.added', function (e, esign) {
			var foundContact = _.find(esign.involved, { contactId: ContactCtrl.contact.id });
			if (foundContact) {
				ContactCtrl.filesTotal++;
			}
		});

		$scope.$on('ticket.added', function () {
			getNOSupportTickets();
		});

		$scope.$on('ticket.updated', function () {
			getNOSupportTickets();
		});

		$scope.$on('ticket.deleted', function () {
			getNOSupportTickets();
		});

		ContactCtrl.track = function (type) {
			globalTracker.track('add', { type: type, location: 'contact_card' });
		};

		// return true if current state is same as provided
		ContactCtrl.isState = function (state) {
			return $state.is('contact.' + state);
		};

		ContactCtrl.createDocument = function (obj, type, e) {
			if (e) {
				e.stopPropagation();
			}

			if (!obj) {
				obj = ContactCtrl.account;
				type = 'account';
			}

			var opts = {
				type: type,
				id: obj.id,
				templates: documentTemplates[type],
				accountId: ContactCtrl.contact.client.id,
				contactId: ContactCtrl.contact.id
			};

			if (type === 'order' && obj.contact) {
				opts.contactId = obj.contact.id;
			}

			$upModal.open('createDocument', opts);
		};

		ContactCtrl.addMarketEvent = function () {
			$upModal.open('newMarketEvent', { account: ContactCtrl.contact.client, contact: ContactCtrl.contact });
		};

		ContactCtrl.openAccount = function (id) {
			return $state.go('account.dashboard', { id: id });
		};

		$scope.addSubObject = function () {
			var state = $state.$current.toString().split('.')[1];

			switch (state) {
				case 'orders':
					ContactCtrl.editOrder();
					break;
				case 'activities':
					ContactCtrl.editActivity();
					break;
				case 'opportunities':
					ContactCtrl.editOpportunity();
					break;
			}
		};

		$scope.addLabel = function () {
			var state = $state.$current.toString().split('.')[1];
			switch (state) {
				case 'orders':
					return 'default.order';
				case 'contacts':
					return 'default.contact';
				case 'activities':
					return 'default.activity';
				case 'order':
					return 'default.opportunity';
				case 'addresses':
					return 'address.address';
			}
		};

		function visibleCustomFields(fields) {
			return _.filter(angular.copy(fields), function (field) {
				if (field.datatype === 'Link') {
					return false;
				}
				return (
					field.$hasAccess &&
					field.visible &&
					field.value !== undefined &&
					field.value !== null &&
					field.value !== ''
				);
			});
		}

		var openSendEmail = function (contact) {
			if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
				openNewMailWithContact(contact);
			} else {
				var options = { customerId: ContactCtrl.customerId, contactId: ContactCtrl.contact.id };
				if (contact) {
					options.contact = contact;
				}
				$upModal.open('sendEmail', options);
			}
		};

		ContactCtrl.emailContact = function () {
			if (ContactCtrl.contact.email) {
				// open mail modal here
				openSendEmail(ContactCtrl.contact);
			} else {
				// eslint-disable-next-line promise/catch-or-return
				$upModal
					.open('infoPrompt', {
						title: 'default.enterEmail',
						placeholder: 'default.email',
						required: true,
						type: 'email'
					})
					.then(function (email) {
						if (email) {
							Contact.customer(ContactCtrl.customerId)
								.save({ id: ContactCtrl.contact.id, email: email })
								.then(function () {
									ContactCtrl.contact.email = email;

									// open mail modal here
									openSendEmail(ContactCtrl.contact);
								})
								.catch(e => {
									logError(e, 'Failed saving contact');
								});
						}
					});
			}
		};

		ContactCtrl.unsub = function (resub) {
			ContactCtrl.isLoadingOptinFix = true;
			var method = resub ? 'resubscribe' : 'unsubscribe';

			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					title: resub ? 'confirm.resub' : 'default.unsubFromMassMail',
					body: resub ? 'confirm.resubBody' : 'confirm.unsubBody',
					confirmButtonText: resub ? 'confirm.resub' : 'default.unsubFromMassMail',
					headerIcon: 'warning',
					onClose: confirmed => {
						if (confirmed) {
							Contact.customer(ContactCtrl.customerId)[method](ContactCtrl.contact);
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('warningConfirm', {
					title: resub
						? $translate.instant('confirm.resub')
						: $translate.instant('default.unsubFromMassMail'),
					body: resub ? $translate.instant('confirm.resubBody') : $translate.instant('confirm.unsubBody'),
					resolveTrue: resub
						? $translate.instant('confirm.resub')
						: $translate.instant('default.unsubFromMassMail'),
					icon: 'fa-warning'
				})
				.then(function () {
					Contact.customer(ContactCtrl.customerId)[method](ContactCtrl.contact);
				});
		};

		ContactCtrl.saveContact = function (data) {
			const saveContact = { id: contactId, ...data };
			makeCancelable(ContactResource.save(saveContact));
			if (data.phone) {
				ContactCtrl.contact.phone = data.phone;
			} else if (data.cellPhone) {
				ContactCtrl.contact.cellPhone = data.cellPhone;
			}
		};

		ContactCtrl.createUdo = function (id) {
			$upModal.open('editUserDefinedObject', {
				typeId: id,
				object: {
					contact: { id: ContactCtrl.contact.id },
					client: { id: ContactCtrl.contact.client.id }
				}
			});
		};

		ContactCtrl.move = function () {
			$upModal.open('moveContact', {
				contact: ContactCtrl.contact
			});
		};

		ContactCtrl.formatCurrency = function (value, currency) {
			return Tools.$filter('currencyFormat')(value, currency || ContactCtrl.metadata.defaultCurrency.iso);
		};

		var getOrderFilter = function (excludeExcludedStages) {
			var filter = new RequestBuilder();
			if (excludeExcludedStages) {
				filter.addFilter(Order.attr.stage, filter.comparisonTypes.NotEquals, excludedStages);
			}
			filter.addFilter({ field: 'contact.id' }, filter.comparisonTypes.Equals, ContactCtrl.contact.id);
			filter.limit = 0;
			return filter;
		};

		function getNOOrder() {
			var orderFilter = getOrderFilter(true);
			orderFilter.addFilter(Order.attr.probability, orderFilter.comparisonTypes.Equals, 100);
			orderFilter.addFilter(
				Order.attr.date,
				orderFilter.comparisonTypes.GreaterThan,
				moment().subtract(1, 'year')
			);
			var valueAgg = orderFilter.aggregationBuilder();
			valueAgg.addAggregation(orderFilter.aggregationTypes.Sum, Order.attr.valueInRoleCurrency.field);
			valueAgg.done();
			var contributionMarginAgg = orderFilter.aggregationBuilder();
			contributionMarginAgg.addAggregation(
				orderFilter.aggregationTypes.Sum,
				Order.attr.contributionMarginInRoleCurrency.field
			);
			contributionMarginAgg.done();
			var agreementFilter = getOrderFilter();

			var or = agreementFilter.orBuilder();
			or.next();
			or.addFilter(Agreement.attr.endDate, agreementFilter.comparisonTypes.Equals, null);
			or.next();
			or.addFilter(Agreement.attr.endDate, agreementFilter.comparisonTypes.GreaterThan, moment().utc());
			or.done();

			var agreementAgg = agreementFilter.aggregationBuilder();
			agreementAgg.addAggregation(
				orderFilter.aggregationTypes.Sum,
				Agreement.attr.yearlyValueInRoleCurrency.field
			);
			agreementAgg.done();

			$q.all({
				order: Report.customer(ContactCtrl.customerId).setType(Report.type.ORDER).find(orderFilter.build()),
				agreement: Agreement.customer(ContactCtrl.customerId).find(agreementFilter.build()),
				agreementReport: Report.customer(ContactCtrl.customerId)
					.setType(Report.type.AGREEMENT)
					.find(agreementFilter.build())
			})
				.then(function (res) {
					var orderAgreementTotal =
						res.order.data.sum_valueInRoleCurrency.doc_count + res.agreement.metadata.total;

					if (!orderAgreementTotal) {
						var oldOrderFilter = getOrderFilter(true);
						oldOrderFilter.addFilter(Order.attr.probability, oldOrderFilter.comparisonTypes.Equals, 100);
						oldOrderFilter.addFilter(
							Order.attr.date,
							oldOrderFilter.comparisonTypes.LessThan,
							moment().subtract(1, 'year')
						);
						oldOrderFilter.addSort(Order.attr.date, false);
						oldOrderFilter.limit = 1;

						Order.customer(ContactCtrl.customerId)
							.find(oldOrderFilter.build())
							.then(function (oldRes) {
								if (oldRes.data.length) {
									ContactCtrl.orderLastDate = oldRes.data[0].date;
									ContactCtrl.orderAgreementTotal = 1;
								} else {
									ContactCtrl.orderTotal =
										ContactCtrl.orderAgreementTotal =
										ContactCtrl.agreementTotal =
											0;
								}
							})
							.catch(e => {
								logError(e, 'Failed finding orders', { filter: oldOrderFilter.build(), error: e });
							});

						ContactCtrl.orderTotal = 0;
						ContactCtrl.orderAgreementTotal = 0;
					} else if (!res.agreement.metadata.total) {
						ContactCtrl.agreementTotal = 0;
						ContactCtrl.agreementTotalValue = 0;
						ContactCtrl.orderAgreementTotal = orderAgreementTotal;
						ContactCtrl.orderTotal = res.order.data.sum_valueInRoleCurrency.value;
						ContactCtrl.contributionMarginTotal = res.order.data.sum_contributionMarginInRoleCurrency.value;
						ContactCtrl.contributionMarginRatio =
							ContactCtrl.contributionMarginTotal / ContactCtrl.orderTotal;
					} else {
						ContactCtrl.orderAgreementTotal = orderAgreementTotal;
						ContactCtrl.orderTotal = res.order.data.sum_valueInRoleCurrency.value;
						ContactCtrl.agreementTotal = res.agreement.metadata.total;
						ContactCtrl.contributionMarginTotal = res.order.data.sum_contributionMarginInRoleCurrency.value;
						ContactCtrl.contributionMarginRatio =
							ContactCtrl.contributionMarginTotal / ContactCtrl.orderTotal;
						ContactCtrl.agreementTotalValue = res.agreementReport.data.sum_yearlyValueInRoleCurrency.value;
						if (ContactCtrl.salesModelOption === 'mrr') {
							ContactCtrl.agreementTotalValue = ContactCtrl.agreementTotalValue / 12;
						}
					}

					ContactCtrl.orderTabValue = ContactCtrl.orderTotal;
					ContactCtrl.tabTooltip = `${$translate.instant(
						'account.tooltip.orderValue'
					)}: ${ContactCtrl.formatCurrency(ContactCtrl.orderTabValue)}`;
					if (ContactCtrl.salesModel === 'cm') {
						if (ContactCtrl.salesModelOption === 'cm') {
							ContactCtrl.orderTabValue = ContactCtrl.contributionMarginTotal;
							ContactCtrl.tabTooltip = `${$translate.instant(
								'account.tooltip.contributionMargin'
							)}: ${ContactCtrl.formatCurrency(ContactCtrl.contributionMarginTotal)}\n${
								ContactCtrl.tabTooltip
							}`;
						} else {
							ContactCtrl.tabTooltip = `${ContactCtrl.tabTooltip}\n${$translate.instant(
								'account.tooltip.contributionMargin'
							)}: ${ContactCtrl.formatCurrency(ContactCtrl.contributionMarginTotal)}`;
						}
					} else if (ContactCtrl.salesModel === 'rr') {
						ContactCtrl.orderTabValue = ContactCtrl.agreementTotalValue;
						if (ContactCtrl.salesModelOption === 'mrr') {
							ContactCtrl.tabTooltip = `${$translate.instant(
								'account.tooltip.mrr'
							)}: ${ContactCtrl.formatCurrency(ContactCtrl.orderTabValue)}\n${ContactCtrl.tabTooltip}`;
						} else {
							ContactCtrl.tabTooltip = `${$translate.instant(
								'account.tooltip.arr'
							)}: ${ContactCtrl.formatCurrency(ContactCtrl.orderTabValue)}\n${ContactCtrl.tabTooltip}`;
						}
					}
				})
				.catch(e => {
					logError(e, 'Failed fetching order figures');
				});
		}

		var getDefaultOpportunityFilter = function () {
			var filter = new RequestBuilder();
			filter.addFilter({ field: 'contact.id' }, filter.comparisonTypes.Equals, ContactCtrl.contact.id);
			filter.addFilter(Opportunity.attr.probability, filter.comparisonTypes.NotEquals, 100);
			return filter;
		};

		function getNOOpportunities() {
			var openFilter = getDefaultOpportunityFilter();
			openFilter.addFilter(Opportunity.attr.probability, openFilter.comparisonTypes.NotEquals, 0);
			openFilter.addSort(Opportunity.attr.date, true);
			openFilter.limit = 1;
			ContactCtrl.opportunitiesOverdue = null;

			return Opportunity.customer(ContactCtrl.customerId)
				.find(openFilter.build())
				.then(function (openRes) {
					ContactCtrl.opportunitiesCount = openRes.metadata.total;
					if (!openRes.data.length) {
						var lostFilter = getDefaultOpportunityFilter();
						lostFilter.addFilter(Opportunity.attr.probability, lostFilter.comparisonTypes.Equals, 0);
						lostFilter.addFilter(
							Opportunity.attr.date,
							lostFilter.comparisonTypes.LessThanEquals,
							moment()
						);
						lostFilter.addSort(Opportunity.attr.date, false);

						lostFilter.limit = 1;

						return Opportunity.customer(ContactCtrl.customerId)
							.find(lostFilter.build())
							.then(function (closedRes) {
								ContactCtrl.opportunitiesCount = closedRes.metadata.total;
								if (closedRes.data.length) {
									ContactCtrl.opportunitiesTotal = 1;
									ContactCtrl.opportunityCloseDate = closedRes.data[0].date;
								} else {
									ContactCtrl.opportunityCloseDate = null;
									ContactCtrl.opportunitiesTotal = 0;
								}
								return ContactCtrl.opportunitiesTotal;
							});
					} else {
						ContactCtrl.opportunitiesOverdue = moment(openRes.data[0].date).endOf('day').isBefore();
						var sumOpenFilter = getDefaultOpportunityFilter();
						sumOpenFilter.addFilter(
							Opportunity.attr.probability,
							sumOpenFilter.comparisonTypes.NotEquals,
							0
						);
						var valueAgg = sumOpenFilter.aggregationBuilder();
						valueAgg.addAggregation(
							sumOpenFilter.aggregationTypes.Sum,
							Order.attr.valueInRoleCurrency.field
						);
						valueAgg.done();
						return Report.customer(ContactCtrl.customerId)
							.setType(Report.type.OPPORTUNITY)
							.find(sumOpenFilter.build())
							.then(function (sumOpenRes) {
								if (sumOpenRes.data && sumOpenRes.data.sum_valueInRoleCurrency) {
									ContactCtrl.opportunitiesTotal = sumOpenRes.data.sum_valueInRoleCurrency.value;
									ContactCtrl.opportunityCloseDate = null;
								}

								return ContactCtrl.opportunitiesTotal;
							});
					}
				});
		}

		var getDefaultActivityFilter = function () {
			var filter = new RequestBuilder();
			filter.addFilter({ field: 'contact.id' }, filter.comparisonTypes.Equals, ContactCtrl.contact.id);
			return filter;
		};

		var getDefaultActivityListFilter = function (isClosed, isDelayed, skipDelayed) {
			var filter = new RequestBuilder();
			filter.addFilter({ field: 'contact.id' }, filter.comparisonTypes.Equals, ContactCtrl.contact.id);
			filter.addFilter(ActivityList.attr.projectPlan.attr.id, filter.comparisonTypes.Equals, null);

			var or = filter.orBuilder();
			or.next();
			or.addFilter(ActivityList.attr.isAppointment, filter.comparisonTypes.Equals, true);
			or.addFilter(
				ActivityList.attr.endDate,
				isClosed ? filter.comparisonTypes.LessThan : filter.comparisonTypes.GreaterThan,
				new Date()
			);
			or.next();
			or.addFilter(ActivityList.attr.isAppointment, filter.comparisonTypes.Equals, false);
			or.addFilter(
				ActivityList.attr.closeDate,
				isClosed ? filter.comparisonTypes.NotEquals : filter.comparisonTypes.Equals,
				null
			);
			//Todo's can have null date
			if (FeatureHelper.hasSoftDeployAccess('TODO_LIST')) {
				or.next();
				or.addFilter(ActivityList.attr.isAppointment, filter.comparisonTypes.Equals, false);
				or.addFilter(
					ActivityList.attr.closeDate,
					isClosed ? filter.comparisonTypes.NotEquals : filter.comparisonTypes.Equals,
					null
				);
				or.addFilter(Activity.attr.date, filter.comparisonTypes.Equals, null);
			}
			if (isClosed) {
				or.next();
				or.addFilter({ field: 'isMap' }, filter.comparisonTypes.Equals, true);
			}
			if (isDelayed) {
				or.addFilter(Activity.attr.date, filter.comparisonTypes.LessThan, moment());
			} else if (!skipDelayed) {
				or.addFilter(Activity.attr.date, filter.comparisonTypes.GreaterThan, moment().subtract(1, 'day'));
			}
			or.done();

			return filter;
		};

		var mapCustomFields = function () {
			// get fields
			$scope.links = [];

			angular.forEach(ContactCtrl.contact.custom, function (field) {
				if (field.datatype === 'Link' && typeof field.value === 'string' && field.value) {
					$scope.links.push(field);
				}
			});
		};

		function getNOActivities() {
			// Nr of delayed
			var delayedFilter = getDefaultActivityFilter();
			delayedFilter.addFilter(Activity.attr.closeDate, delayedFilter.comparisonTypes.Equals, null);
			delayedFilter.addFilter(ActivityList.attr.projectPlan.attr.id, delayedFilter.comparisonTypes.Equals, null);
			delayedFilter.addFilter(
				Activity.attr.date,
				delayedFilter.comparisonTypes.LessThan,
				moment().subtract(1, 'day').endOf('day')
			);
			delayedFilter.limit = 0;

			Activity.customer(ContactCtrl.customerId)
				.find(delayedFilter.build())
				.then(function (delayedRes) {
					if (!delayedRes.metadata.total) {
						ContactCtrl.activityOverDue = false;
						// Open non delayed activities or appointments
						var openFilter = getDefaultActivityListFilter(false, false);

						openFilter.limit = 0;
						ActivityList.customer(ContactCtrl.customerId)
							.find(openFilter.build(), { includes: 'activities,appointments', mapCustom: true })
							.then(function (openRes) {
								if (!openRes.metadata.total) {
									// Latest closed
									var closedFilter = getDefaultActivityListFilter(true, false, true);
									closedFilter.addSort(Activity.attr.date, false);
									closedFilter.limit = 1;
									var or = closedFilter.orBuilder();
									or.next();
									or.addFilter({ field: 'groupMailId' }, closedFilter.comparisonTypes.Equals, null);
									or.next();
									or.addFilter({ field: 'groupMailId' }, closedFilter.comparisonTypes.Equals, 0);
									or.done();
									ActivityList.customer(ContactCtrl.customerId)
										.find(closedFilter.build(), {
											includes: 'activities,appointments,mail',
											mapCustom: true
										})
										.then(function (closedRes) {
											if (closedRes.data.length) {
												ContactCtrl.activitiesTotal = closedRes.metadata.total;
												ContactCtrl.activityCloseDate =
													closedRes.data[0].date ?? closedRes.data[0].closeDate;
											}
										})
										.catch(e => {
											logError(e, 'Failed fetching activity figures', {
												filter: closedFilter.build(),
												error: e
											});
										});
								} else {
									ContactCtrl.activityCloseDate = null;
									ContactCtrl.activityOverDue = false;
									ContactCtrl.activitiesTotal = openRes.metadata.total;
								}
							})
							.catch(e => {
								logError(e, 'Failed fetching activity figures', {
									filter: openFilter.build(),
									error: e
								});
							});
					} else {
						ContactCtrl.activitiesTotal = delayedRes.metadata.total;
						ContactCtrl.activityCloseDate = null;
						ContactCtrl.activityOverDue = true;
					}
				})
				.catch(e => {
					logError(e, 'Failed fetching activity figures', { filter: delayedFilter.build(), error: e });
				});
		}

		var getNOMarketEvents = function () {
			var filter = new RequestBuilder();
			filter.addFilter(Event.attr.contacts.attr.id, filter.comparisonTypes.Equals, ContactCtrl.contact.id);
			filter.addFilter({ field: 'feedContactMarketWithNoScore' }, filter.comparisonTypes.Equals, null);
			filter.limit = 0;

			Event.customer(ContactCtrl.customerId)
				.find(filter.build())
				.then(function (res) {
					if (res.metadata.total > 0) {
						ContactCtrl.marketExists = true;
					} else {
						ContactCtrl.marketExists = false;
					}
				})
				.catch(e => {
					logError(e, 'Failed fetching market events', { filter: filter.build(), error: e });
				});
		};

		function sumRecentScore() {
			var options = {
				contactId: ContactCtrl.contact.id,
				interval: 'month', // irrelevant
				startDate: moment().subtract(90, 'day').format('YYYY-MM-DD'),
				endDate: moment().add(1, 'day').format('YYYY-MM-DD')
			};

			Account.customer(ContactCtrl.customerId)
				.getAccountMarket(options)
				.then(function (results) {
					if (results && results.metadata) {
						ContactCtrl.marketScore = results.metadata.totalScore;
						ContactCtrl.hasMail = !!results.metadata.mailCount;
						ContactCtrl.hasForm = !!results.metadata.formCount;
						ContactCtrl.hasVisit = !!results.metadata.visitCount;
						ContactCtrl.hasMarketingCustom = !!results.metadata.marketingcustomCount;
					} else {
						ContactCtrl.marketScore = 0;
						ContactCtrl.hasMail = false;
						ContactCtrl.hasForm = false;
						ContactCtrl.hasVisit = false;
					}
				})
				.catch(e => {
					logError(e, 'Failed fetching account market', { filter: options, error: e });
				})
				.finally(() => {
					$safeApply($scope);
				});
		}

		function getLinks() {
			ContactCtrl.links = [];
			ContactCtrl.loadingLinks = true;
			Links.customer(ContactCtrl.customerId)
				.get('contact', ContactCtrl.contact.id)
				.then(function (response) {
					ContactCtrl.links = response.data;
					ContactCtrl.loadingLinks = false;
				})
				.catch(function (err) {
					ContactCtrl.loadingLinks = false;
					ContactCtrl.linksErr = err;
				});
		}

		ContactCtrl.emailBounceFn = function () {
			if (!ContactCtrl.contact.email || !ContactCtrl.contact.email.length) {
				return;
			}

			ContactCtrl.emailBounce = null;

			if (ContactCtrl.contact.mailBounces.indexOf('hard_bounce') > -1) {
				ContactCtrl.emailBounce = { type: 'hard_bounce' };
			} else if (ContactCtrl.contact.mailBounces.indexOf('soft_bounce') > -1) {
				ContactCtrl.emailBounce = { type: 'soft_bounce' };
			}
		};

		ContactCtrl.editContact = function (e) {
			var params = { contact: angular.copy(ContactCtrl.contact), customerId: ContactCtrl.customerId, meta: meta };
			// eslint-disable-next-line promise/catch-or-return
			$upModal.open('editContact', params, e).then(function (response) {
				ContactCtrl.contact = response;
			});
		};

		// save contact inline function
		ContactCtrl.saveInline = function (value, promise, field) {
			SaveInline(value, promise, field, contactId, 'Contact');
		};

		ContactCtrl.saveInlineAccount = function (value, promise, field) {
			SaveInline(value, promise, field, ContactCtrl.contact.client.id, 'Account');
		};

		ContactCtrl.saveInlineOpportunity = function (value, promise, field, opportunity) {
			SaveInline(value, promise, field, opportunity.id, 'Opportunity');
		};

		ContactCtrl.showMoreCategories = function (index, e) {
			var data = ContactCtrl.contact.standardCategories;
			if (index > 0) {
				data = ContactCtrl.contact.extraCategories[index];
			}
			$upModal.open(
				'list',
				{
					title: 'default.categories',
					columns: [{ title: 'default.name', value: 'name' }],
					data: data
				},
				e
			);
		};

		ContactCtrl.showMoreCampaigns = function ($event, e) {
			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open(
					'list',
					{
						title: 'default.campaigns',
						columns: [{ title: 'default.name', value: 'name' }],
						data: ContactCtrl.contact.projects
					},
					e
				)
				.then(function (row) {
					$state.go('campaign.dashboard', { id: row.id });
				});
		};

		ContactCtrl.showMoreOptOuts = function () {
			$upModal.open('list', {
				title: 'default.optOutCategories',
				columns: [{ title: 'default.title', value: 'title' }],
				hideControls: true,
				data: _.map(ContactCtrl.contact.optOut, function (o) {
					o.$$disabled = true;
					return o;
				})
			});
		};

		ContactCtrl.showMoreOptIns = function ($event, e) {
			var showNotificationError = function () {
				NotificationService.addNotification({
					title: 'default.error',
					body: 'contact.optin.notFound',
					style: NotificationService.style.ERROR,
					icon: 'times'
				});
			};

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open(
					'list',
					{
						title: 'default.optIns',
						columns: [
							{ title: 'default.name', value: 'title' },
							{ title: 'default.regDate', value: 'date' }
						],
						data: _.map(ContactCtrl.contact.optins, function (optin) {
							optin.date = moment(optin.regDate).format('L LT');
							return optin;
						})
					},
					e
				)
				.then(function (row) {
					// If manual optin
					if (row.type === 'manual') {
						$state.go('administration.optIn', { id: row.id }).catch(function (err) {
							if (err && err.status === 404) {
								NotificationService.addNotification({
									title: 'default.noAccess',
									style: 'info',
									body: 'default.noAccess'
								});
							}
						});
					} else {
						Tools.OptIn.find({ id: row.id })
							.then(function (res) {
								if (!res.data) {
									return showNotificationError();
								}
								Tools.$upModal.open('optIn', { optIn: res.data });
							})
							.catch(function () {
								showNotificationError();
							});
					}
				});
		};

		ContactCtrl.showMoreSegments = function ($event, e) {
			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open(
					'list',
					{
						title: 'segment.segments',
						columns: [{ title: 'default.name', value: 'name' }],
						data: ContactCtrl.contact.segments
					},
					e
				)
				.then(function (row) {
					$state.go('segment.events', { id: row.id }).catch(function (err) {
						if (err && err.status === 404) {
							NotificationService.addNotification({
								title: 'default.noAccess',
								style: 'info',
								body: 'segment.hasBeenRemoved'
							});
						}
					});
				});
		};

		ContactCtrl.createOpportunity = function () {
			var options = {
				customerId: $stateParams.customerId,
				clientId: ContactCtrl.contact.client.id,
				contactId: ContactCtrl.contact.id,
				type: 'opportunity'
			};
			$upModal.open('editOrder', options);
		};

		ContactCtrl.editOpportunity = function (opportunity) {
			var options = {
				customerId: $stateParams.customerId,
				type: 'opportunity'
			};
			if (opportunity) {
				options.id = opportunity.id;
			} else {
				options.contactId = ContactCtrl.contact.id;
			}
			$upModal.open('editOrder', options);
		};

		ContactCtrl.editOrder = function (order) {
			var opts = {
				customerId: $stateParams.customerId
			};

			if (order) {
				opts.id = order.id || order.entityId;
			} else {
				opts.clientId = ContactCtrl.contact.client.id;
				opts.contactId = ContactCtrl.contact.id;
			}

			$upModal.open('editOrder', opts);
		};

		ContactCtrl.editEsign = function (esign) {
			var options = {
				esign: {
					client: {
						id: ContactCtrl.account.id
					},
					involved: [Esign.newInvolved({ contact: ContactCtrl.contact })]
				},
				openInvolved: true
			};

			if (esign) {
				options.id = esign.id;
			}

			// Only open edit if saved as draft
			if (!esign || esign.state === Esign.stateEnum.DRAFT) {
				$upModal.open('editEsign', options);
			} else {
				$upModal.open('confirmEsign', options);
			}
		};

		ContactCtrl.editRelation = function (relation) {
			var opts = {
				customerId: $stateParams.customerId
			};

			if (relation) {
				opts.relation = relation;
			} else {
				opts.contact = ContactCtrl.contact;
			}

			$upModal.open('editContactRelation', opts);
		};

		ContactCtrl.removeRelation = function (relation) {
			if (!relation.id) {
				return;
			}

			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					title: 'client.removeRelation',
					body: 'client.confirmRemoveRelationBody',
					confirmButtonText: 'client.removeRelation',
					headerIcon: 'warning',
					onClose: confirmed => {
						if (confirmed) {
							ContactRelation.customer($stateParams.customerId)['delete'](relation);
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('errorConfirm', {
					title: 'client.removeRelation',
					body: 'client.confirmRemoveRelationBody',
					resolveTrue: 'client.removeRelation',
					icon: 'fa-warning'
				})
				.then(function () {
					ContactRelation.customer($stateParams.customerId)['delete'](relation);
				});
		};

		ContactCtrl.moreNotes = function (e) {
			// eslint-disable-next-line promise/catch-or-return
			const editable = ContactCtrl.contact.userEditable;
			$upModal
				.open(
					'editNote',
					{
						icon: 'pencil',
						notes: ContactCtrl.contact.notes,
						mutable: editable,
						textareaTooltip: Tools.AppService.getMetadata().standardFields.Contact.Notes.tooltip
					},
					e
				)
				.then(newNotes => {
					ContactCtrl.contact.notes = newNotes;
					SaveInline(newNotes, null, 'notes', ContactCtrl.contact.id, 'Contact');
				})
				.catch(err => {
					logError(err, 'Failed to save notes');
				});
		};

		ContactCtrl.editActivity = function (activity) {
			var params = {
				activity: {
					contacts: { id: ContactCtrl.contact.id }
				}
			};

			if (activity) {
				// Because editActivity fn is used in the combined lists
				if (activity.isAppointment) {
					ContactCtrl.editAppointment(activity);
					return;
				}
				params.id = activity.id;
			}

			$upModal.open('editActivity', params);
		};

		ContactCtrl.editAppointment = function (appointment) {
			var params = {
				appointment: {
					contacts: [ContactCtrl.contact]
				}
			};

			if (appointment) {
				params.id = appointment.id;
			}

			$upModal.open('editAppointment', params);
		};

		ContactCtrl.removeActivity = function (activity) {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					title:
						$translate.instant('default.remove') +
						' ' +
						$translate.instant('default.activity').toLowerCase(),
					body: 'confirm.removeActivity',
					confirmButtonText: 'default.remove',
					headerIcon: 'warning',
					onClose: confirmed => {
						if (confirmed) {
							const Service = activity.isAppointment ? Appointment : Activity;
							Service.customer($scope.customerId)['delete'](activity);
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('errorConfirm', {
					title:
						$translate.instant('default.remove') +
						' ' +
						$translate.instant('default.activity').toLowerCase(),
					body: 'confirm.removeActivity',
					resolveTrue: 'default.remove',
					icon: 'fa-warning'
				})
				.then(function () {
					var Service = activity.isAppointment ? Appointment : Activity;
					Service.customer($scope.customerId)['delete'](activity);
				});
		};

		// activity inline save
		ContactCtrl.saveInlineActivity = function (value, promise, field, activity) {
			var Service = activity.isAppointment ? 'Appointment' : 'Activity';
			SaveInline(value, promise, field, activity.id, Service);
		};

		const onCloseActivity = activity => {
			const data = { id: activity.id, closeDate: new Date() };
			const options = {
				updateSuccessBody: 'default.activityClosed'
			};

			Activity.customer($stateParams.customerId)
				.save(data, options)
				.then(function () {
					activity.closeDate = data.closeDate;
				})
				.catch(e => {
					logError(e, 'Failed saving activity ', { activityId: data.id, error: e });
				});
		};

		// close activity
		ContactCtrl.closeActivity = function (activity) {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					title: 'default.closeActivity',
					body: 'confirm.markActivityAsDone',
					confirmButtonText: 'default.closeActivity',
					headerIcon: 'check-square-o',
					onClose: confirmed => {
						if (confirmed) {
							onCloseActivity(activity);
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('defaultConfirm', {
					title: 'default.closeActivity',
					body: 'confirm.markActivityAsDone',
					resolveTrue: 'default.closeActivity',
					icon: 'fa-check-square-o'
				})
				.then(function () {
					onCloseActivity(activity);
				});
		};

		ContactCtrl.removeAgreement = function (agreement) {
			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('warningConfirm', {
					title:
						$translate.instant('default.remove') +
						' ' +
						$translate.instant('agreement.agreement').toLowerCase(),
					body: 'confirm.removeAgreement',
					resolveTrue: 'default.remove',
					icon: 'fa-warning'
				})
				.then(function () {
					Agreement.customer(ContactCtrl.customerId)['delete'](agreement);
				});
		};

		ContactCtrl.editAgreement = function (agreement) {
			var options = {
				customerId: ContactCtrl.customerId,
				accountId: ContactCtrl.contact.client.id,
				contactId: ContactCtrl.contact.id
			};

			if (agreement) {
				options.id = agreement.id;
			}

			if (options.id || !Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_MODAL')) {
				return $upModal.open('editAgreement', options);
			}

			openModal('CreateSubscription', {
				client: ContactCtrl.contact.client,
				contact: ContactCtrl.contact,
				createdFrom: 'contactAddButton',
				dontWait: Tools.FeatureHelper.hasSoftDeployAccess('DONT_WAIT_SUBSCRIPTIONS')
			});
		};

		ContactCtrl.uploadFile = function () {
			$upModal.open('uploadFile', {
				contactId: ContactCtrl.contact.id
			});
		};

		ContactCtrl.createTodo = function () {
			openDrawer('CreateTodo', { contact: ContactCtrl.contact });
		};

		ContactCtrl.createPhonecall = function () {
			openDrawer('CreateCall', { contact: ContactCtrl.contact });
		};

		ContactCtrl.editTicket = () => {
			openModal('EditTicket', {
				client: ContactCtrl.contact.client,
				contact: ContactCtrl.contact
			});
		};

		ContactCtrl.createAppointment = function () {
			Tools.$upModal.open('editAppointment', {
				appointment: {
					contacts: [ContactCtrl.contact],
					client: ContactCtrl.contact.client
				}
			});
		};

		ContactCtrl.merge = function () {
			$upModal.open('mergeContacts', {
				id: ContactCtrl.contact.id,
				accountId: ContactCtrl.contact.client.id,
				customerId: ContactCtrl.customerId
			});
		};

		ContactCtrl.showContactData = function () {
			$upModal.open('exportContactData', {
				contact: ContactCtrl.contact,
				customerId: ContactCtrl.customerId,
				UserDefinedObject: AppService.getMetadata().params.UserDefinedObject
			});
		};

		ContactCtrl.onSetJourneyStepContact = function (journeyStep) {
			if (!ContactCtrl.account) {
				return $q.resolve();
			}
			return Contact.customer(ContactCtrl.customerId)
				.save({
					id: ContactCtrl.contact.id,
					journeyStep: journeyStep
				})
				.then(function (res) {
					ContactCtrl.contact.journeyStep = res.data.journeyStep;
					ContactCtrl.contact = angular.copy(ContactCtrl.contact);
				});
		};

		var errorNotification = function () {
			NotificationService.addNotification({
				title: 'default.error',
				body: 'loadError.contact',
				style: 'Error',
				icon: 'times'
			});
		};

		var getCampaigns = function () {
			// eslint-disable-next-line promise/catch-or-return
			Campaign.customer(ContactCtrl.customerId)
				.find({})
				.then(
					function (response) {
						ContactCtrl.projects = response.data;
					},
					function (err) {
						errorNotification(err);
					}
				);
		};

		function getCategories() {
			ContactCtrl.categories = AppService.getCategories('contact');
			ContactCtrl.contact.standardCategories = ContactCtrl.contact.categories;
			if ($scope.categoryTypes && $scope.categoryTypes.length) {
				ContactCtrl.contact.extraCategories = {};

				_.forEach($scope.categoryTypes, function (categoryType) {
					// Model for this category type
					ContactCtrl.contact.extraCategories[categoryType.id] = [];

					// Options for this category type
					_.forEach(ContactCtrl.contact.categories, function (category) {
						var found = _.find(ContactCtrl.categories, { id: category.id });
						if (found && found.categoryType === categoryType.id) {
							ContactCtrl.contact.extraCategories[categoryType.id].push(category);
						}
					});

					//Remove this category type from arrays
					ContactCtrl.contact.standardCategories = _.difference(
						ContactCtrl.contact.standardCategories,
						ContactCtrl.contact.extraCategories[categoryType.id]
					);
				});
			}
		}

		function getNOAgreement() {
			var filter = new RequestBuilder();
			filter.addFilter(Agreement.attr.contact, filter.comparisonTypes.Equals, ContactCtrl.contact.id);
			filter.limit = 0;
			Agreement.customer(ContactCtrl.customerId)
				.find(filter.build())
				.then(function (res) {
					ContactCtrl.agreementTotal = res.metadata.total;
				})
				.catch(e => {
					logError(e, 'Failed fetching no agreement', { filter: filter.build(), error: e });
				});
		}

		var getNoUDO = function () {
			if (!ContactCtrl.hasUdo) {
				return false;
			}

			var promises = {};
			ContactCtrl.udoTotal = {};

			// Account filter
			var filters = new RequestBuilder();
			filters.limit = 0;
			filters.addFilter(
				UserDefinedObject.attr.contact.attr.id,
				filters.comparisonTypes.Equals,
				ContactCtrl.contact.id
			);

			angular.forEach(ContactCtrl.udo, function (object) {
				// Get total items for each object (for this account)
				ContactCtrl.udoTotal[object.id] = 0;

				promises[object.id] = UserDefinedObject.setId(object.id)
					.find(filters.build())
					.then(function (res) {
						ContactCtrl.udoTotal[object.id] = res.metadata.total;
					});
			});

			return $q.all(promises);
		};

		// Only run once!!! (it initializes event listeners)
		var checkforUDO = function () {
			ContactCtrl.udo = _.filter(metadata.params.UserDefinedObject, { link: UdoLink.CONTACT });

			// Set var so we know there is some accountUdos
			if (ContactCtrl.udo.length && FeatureHelper.isAvailable(FeatureHelper.Feature.UDO)) {
				ContactCtrl.hasUdo = true;

				angular.forEach(ContactCtrl.udo, function (udo) {
					$scope.$on('userDefinedObject' + udo.id + '.added', function (e, updated) {
						if (updated.contact && updated.contact.id === ContactCtrl.contact.id) {
							ContactCtrl.udoTotal[udo.id]++;
						}
					});
					$scope.$on('userDefinedObject' + udo.id + '.deleted', function (e, deleted) {
						if (deleted.contact && deleted.contact.id === ContactCtrl.contact.id) {
							ContactCtrl.udoTotal[udo.id]--;
						}
					});
				});
			} else {
				ContactCtrl.hasUdo = false;
			}
		};

		function getNOSupportTickets() {
			if (!ContactCtrl.hasSupportService) {
				ContactCtrl.supportTicketsTotal = 0;
				ContactCtrl.openSupportTicketsTotal = 0;
				return;
			}

			const buildTicketRequest = (rb, filters = []) => {
				rb.limit = 0;
				rb.addSort('lastUpdated', false);
				filters.forEach(({ field, comparison, value }) => {
					rb.addFilter({ field }, comparison, value);
				});
				return rb.build();
			};

			// First, check if there are any tickets at all
			const rbTotal = new RequestBuilder();
			const totalTicketsFilter = [
				{ field: 'contact.id', comparison: rbTotal.comparisonTypes.Equals, value: ContactCtrl.contact.id }
			];

			return TicketResource.find(buildTicketRequest(rbTotal, totalTicketsFilter))
				.then(data => {
					const totalTickets = data.metadata?.total ?? 0;
					ContactCtrl.supportTicketsTotal = totalTickets;

					if (totalTickets === 0) {
						return (ContactCtrl.supportTicketsTotal = 0);
					}

					// If there were any tickets at all, we check if there are any open tickets
					const rbOpen = new RequestBuilder();
					const openTicketsFilter = [
						{ field: 'status.closed', comparison: rbOpen.comparisonTypes.Equals, value: false },
						{
							field: 'contact.id',
							comparison: rbOpen.comparisonTypes.Equals,
							value: ContactCtrl.contact.id
						},
						{ field: 'isArchived', comparison: rbOpen.comparisonTypes.Equals, value: false }
					];

					return TicketResource.find(buildTicketRequest(rbOpen, openTicketsFilter))
						.then(data => {
							const openTickets = data.metadata?.total ?? 0;
							ContactCtrl.openSupportTicketsTotal = openTickets;
						})
						.catch(error => {
							logError(error, 'Error when getting list of open tickets');
							ContactCtrl.openSupportTicketsTotal = 0;
						});
				})
				.catch(error => {
					logError(error, 'Error when getting list of total tickets');
					ContactCtrl.supportTicketsTotal = 0;
				})
				.finally(() => {
					$safeApply($scope);
				});
		}

		var getFirstTouchInfo = function (firstTouch) {
			if (!firstTouch) return null;

			var primaryType = firstTouch.type;
			var primaryName = firstTouch.primary;
			var firstTouchType = firstTouchTypes[primaryType] || {};

			const opts = {
				primaryType: 'firstTouch.' + primaryType,
				primaryName: primaryName,
				date: firstTouch.date,
				secondaryType: firstTouchType.secondaryType,
				secondaryName: firstTouch.secondary || $translate.instant(firstTouchType.defaultSecondaryName),
				referralLink: firstTouchType.isLink ? primaryName + '' + (firstTouch.secondary || '') : null,
				label: firstTouchType.label
			};

			const config = LEAD_SOURCE.getConfig({ type: firstTouch.type, source: firstTouch.primary });
			const generatedEnum = LEAD_SOURCE[(primaryName || '').toLowerCase()];

			if (firstTouch.icon) {
				opts.icon = firstTouch.icon;
				opts.iconType = 'img';
			} else if (config && config.iconType !== 'none') {
				opts.icon = config.iconType === 'icon' ? config.iconProps.name : config.iconProps.src;
				opts.iconType = config.iconType;
			} else if (generatedEnum && generatedEnum.favicon) {
				opts.icon = generatedEnum.favicon;
				opts.iconType = 'img';
			}

			if (firstTouch.type === 'SupportTicket') {
				opts.icon = 'customer-support';
				opts.iconType = 'icon';
				opts.primaryType = $translate.instant('default.type');
				opts.primaryName = $translate.instant('firstTouch.SupportTicket.short');
			}

			return opts;
		};

		ContactCtrl.optinClick = function () {
			if (ContactCtrl.optinExpanded) {
				return ContactCtrl.optinMinimize();
			}

			return ContactCtrl.optinExpand();
		};

		ContactCtrl.optinExpand = function () {
			ContactCtrl.optinExpanded = true;
			var tarContainer = document.querySelector('.optin');
			tarContainer.classList.add('expanded');

			var chevron = document.querySelector('.fa-chevron-down');
			chevron.classList.remove('fa-chevron-down');
			chevron.classList.add('fa-chevron-up');
		};

		ContactCtrl.optinMinimize = function () {
			ContactCtrl.optinExpanded = false;
			var tarContainer = document.querySelector('.optin');
			tarContainer.classList.remove('expanded');

			var chevron = document.querySelector('.fa-chevron-up');
			chevron.classList.remove('fa-chevron-up');
			chevron.classList.add('fa-chevron-down');
		};

		ContactCtrl.formatName = function (contact) {
			if (contact.salutation?.value && ContactCtrl.showSalutation) {
				return contact.salutation.value + ' ' + contact.name;
			} else {
				return contact.name;
			}
		};

		ContactCtrl.formatPhone = phoneNumberHelper.formatNumber;

		ContactCtrl.refetchContact = async function () {
			const { data } = await Contact.customer(ContactCtrl.customerId).get(ContactCtrl.contact.id);
			if (data) {
				ContactCtrl.contact = data;
				$safeApply($scope);
			}
		};

		function getFilesAndEsign() {
			var promises = {};
			if (ContactCtrl.hasDocumentFeature) {
				var fileFilter = new RequestBuilder();
				fileFilter.addFilter(File.attr.entityId, fileFilter.comparisonTypes.Equals, ContactCtrl.contact.id);
				fileFilter.addFilter(File.attr.entity, fileFilter.comparisonTypes.Equals, File.entityTypes.CONTACT);
				fileFilter.limit = 0;
				promises.files = File.customer(ContactCtrl.customerId).find(fileFilter.build());
			}

			if (ContactCtrl.hasEsign) {
				var esignFilter = new RequestBuilder();
				esignFilter.addFilter(
					Esign.attr.involved.attr.contactId,
					esignFilter.comparisonTypes.Equals,
					ContactCtrl.contact.id
				);
				promises.esign = Esign.find(esignFilter.build());
			}

			$q.all(promises)
				.then(function (res) {
					if (res.files && res.files.metadata) {
						ContactCtrl.filesTotal = res.files.metadata.total;
					} else {
						ContactCtrl.filesTotal = 0;
					}

					if (res.esign && res.esign.metadata) {
						ContactCtrl.openEsign = _.find(res.esign.data, { state: 10 });
						ContactCtrl.filesTotal += res.esign.metadata.total;
					}
				})
				.catch(e => {
					logError(e, 'Failed fetching files and esign');
				});
		}

		function generateCustomFields() {
			ContactCtrl.customFields = visibleCustomFields(ContactCtrl.contact.custom);
			ContactCtrl.canShowFormGroups = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.FORM_GROUPS);
			if (ContactCtrl.canShowFormGroups) {
				ContactCtrl.formGroups = Array.from(
					ContactCtrl.customFields
						.sort((a, b) => a.sortId - b.sortId)
						.reduce((previous, field) => {
							previous.add(field.formGroup);
							return previous;
						}, new Set())
				);
			} else {
				ContactCtrl.formGroups = [null];
			}
		}

		var init = function () {
			ContactCtrl.hasSoftDeployContactTitleCategoryAccess = Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
			ContactCtrl.hasTodoList = Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');
			ContactCtrl.hasSupportService = FeatureHelper.isAvailable(FeatureHelper.Feature.CUSTOMER_SUPPORT);

			excludedStages = AppService.getStages('excludedIds', true);
			metadata = AppService.getMetadata();
			self = AppService.getSelf();

			ContactCtrl.hideMarketTab =
				FeatureHelper.isAvailableProduct(FeatureHelper.Product.MA) &&
				!FeatureHelper.isAvailableProduct(FeatureHelper.Product.CRM);

			ContactCtrl.availableFeatures = {
				email: FeatureHelper.isAvailable(FeatureHelper.Feature.EMAIL),
				groupEmail: FeatureHelper.isAvailable(FeatureHelper.Feature.GROUP_MAIL),
				contact: FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANIES_AND_CONTACTS),
				activity: FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS),
				appointment: FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS),
				opportunity: FeatureHelper.isAvailable(FeatureHelper.Feature.PIPELINE),
				order: FeatureHelper.isAvailable(FeatureHelper.Feature.ORDERS),
				agreement: FeatureHelper.isAvailable(FeatureHelper.Feature.RECURRING_ORDER),
				document: FeatureHelper.isAvailable(FeatureHelper.Feature.DOCUMENTS),
				relation: FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANY_RELATIONS),
				marketEvent: FeatureHelper.isAvailable(FeatureHelper.Feature.MARKETING_EVENTS),
				assign: FeatureHelper.isAvailable(FeatureHelper.Feature.LEADS),
				resetScore: FeatureHelper.isAvailable(FeatureHelper.Feature.LEADS),
				uploadFile: FeatureHelper.isAvailable(FeatureHelper.Feature.DOCUMENTS),
				esign: FeatureHelper.isAvailable(FeatureHelper.Feature.ESIGN),
				udo: FeatureHelper.isAvailable(FeatureHelper.Feature.UDO),
				exportData: FeatureHelper.isAvailable(FeatureHelper.Feature.EXPORT_DATA),
				support: FeatureHelper.isAvailable(FeatureHelper.Feature.CUSTOMER_SUPPORT)
			};

			ContactCtrl.removeActivities =
				Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
				Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');
			ContactCtrl.canDeactivate = FeatureHelper.isAvailable(FeatureHelper.Feature.INACTIVE_COMPANIES);
			ContactCtrl.contact = meta.contact.data || {};
			ContactCtrl.opportunityStages = AppService.getStages('all');
			ContactCtrl.orderStages = AppService.getStages('all');
			ContactCtrl.appointmentTypes = AppService.getActivityTypes('appointment', true);
			ContactCtrl.activityTypes = AppService.getActivityTypes('activity', true);
			ContactCtrl.users = AppService.getActiveUsers();
			ContactCtrl.activityUsers = AppService.getUsers(AppService.AccessType.ACTIVITY);
			ContactCtrl.account = meta.account.data || {};
			ContactCtrl.mailActivated = true;
			ContactCtrl.metadata = metadata;
			generateCustomFields();
			$scope.categoryTypes = AppService.getCategoryTypes('contact');

			$scope.standardFields = {};
			$scope.standardFields.social = _.filter(metadata.standardFields.Contact, function (field) {
				var firstCheck = field.group === 'social' && field.active;
				var secondCheck = ContactCtrl.contact[field.field] && ContactCtrl.contact[field.field].length;
				return firstCheck && secondCheck;
			});

			ContactCtrl.showSalutation =
				Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS') &&
				metadata.standardFields.Contact.Salutation &&
				metadata.standardFields.Contact.Salutation.active;

			// Document templates
			documentTemplates = {
				activity: meta.documentTemplatesActivity.data,
				order: meta.documentTemplatesOrder.data,
				agreement: meta.documentTemplatesAgreement.data
			};

			ContactCtrl.emailBounceFn();

			ContactCtrl.hasDocumentTemplates = meta.documentTemplates?.data?.length;
			ContactCtrl.hasDocumentTemplatesActivity =
				meta.documentTemplatesActivity.data && meta.documentTemplatesActivity.data.length;
			ContactCtrl.hasDocumentTemplatesOrder =
				meta.documentTemplatesOrder.data && meta.documentTemplatesOrder.data.length;
			ContactCtrl.hasDocumentTemplatesAgreement =
				meta.documentTemplatesAgreement.data && meta.documentTemplatesAgreement.data.length;

			ContactCtrl.hasDocumentFeature = FeatureHelper.isAvailable(FeatureHelper.Feature.DOCUMENTS);

			var esignIntegrations = AppService.getEsignIntegrations();
			ContactCtrl.hasEsign = FeatureHelper.isAvailable(FeatureHelper.Feature.ESIGN) && esignIntegrations.length;

			ContactCtrl.salesModel = metadata.params.SalesModel;
			ContactCtrl.salesModelOption = metadata.params.SalesModelOption;

			ContactCtrl.recurringOrderActive =
				metadata.params.AgreementEnabled && AppService.getAccessRights().Agreement;

			ContactCtrl.clientConnectionName = metadata.params.clientOrderRelation;

			var accountOwner = false;
			if (ContactCtrl.contact.client) {
				angular.forEach(ContactCtrl.contact.client.users, function (user) {
					if (user.id === self.id) {
						accountOwner = true;
					}
				});
			}

			ContactCtrl.createRights = {};

			const timerMap = {};
			ContactCtrl.toggleTodoDone = function (e, activity) {
				if (e) {
					e.stopPropagation();
				}
				activity.hasChanged = !activity.hasChanged;
				activity.closeDate = activity.closeDate ? null : new Date();
				if (timerMap[activity.id]) {
					clearTimeout(timerMap[activity.id]);
				}
				timerMap[activity.id] = setTimeout(() => {
					delete timerMap[activity.id];
					if (activity.hasChanged) {
						Activity.customer($stateParams.customerId).save(activity);
					}
				}, 3000);
			};

			angular.forEach(self.createRights, function (value, type) {
				ContactCtrl.createRights[type] = value === 'ALL' || (value === 'OWN' && accountOwner);
			});

			// get NO. opportunities
			getNOOpportunities();

			// get NO. activities
			getNOActivities();

			// get NO. market things
			getNOMarketEvents();
			sumRecentScore();

			// get NO. orders
			getNOOrder();
			getNOAgreement();
			getFilesAndEsign();

			getCampaigns();
			getCategories();
			getLinks();

			// Map custom
			mapCustomFields();

			// Check for UDO on client then find some for this account
			checkforUDO();
			getNoUDO();

			// get NO. support tickets
			getNOSupportTickets();

			if (
				FeatureHelper.isAvailable(FeatureHelper.Feature.OPT_IN) &&
				FeatureHelper.isAvailable(FeatureHelper.Feature.GROUP_MAIL)
			) {
				ContactCtrl.hasOptIn = true;
			}

			ContactCtrl.hasNewFields = FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');

			ContactCtrl.relationsTotal = ContactCtrl.contact.connectedClients
				? ContactCtrl.contact.connectedClients.length
				: 0;

			ContactCtrl.canResub = self.administrator || (self.userParams && self.userParams.mailAdmin);

			ScriptService.contact.open(ContactCtrl.contact);

			// SI ui-elements
			if (metadata.integrations && metadata.integrations.uiElements) {
				ContactCtrl.uiElements = metadata.integrations.uiElements.contact;
			}

			ContactCtrl.firstTouchInfo = getFirstTouchInfo(ContactCtrl.contact.firstTouch);
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);
