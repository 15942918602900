import React from 'react';
import PropTypes from 'prop-types';
import { Editor, Text, Button, Toggle, Label } from '@upsales/components';

const filterValidCustomTypes = (fields, allowedType) => {
	return _.filter(fields, field => {
		return (
			field.datatype === 'String' ||
			field.datatype === 'Select' ||
			field.datatype === 'Currency' ||
			field.datatype === 'Integer' ||
			field.datatype === 'Percent' ||
			field.datatype === 'Discount' ||
			field.datatype === allowedType
		);
	});
};

const upperCaseFirstLetter = string => {
	return string[0].toUpperCase() + string.slice(1);
};

export default class Calculation extends React.Component {
	constructor(props) {
		super(props);

		const t = Tools.$translate;
		this.lang = {
			title: t('admin.calculation.title'),
			sumOf: t('default.sumOf'),
			custom: t('default.custom'),
			typeOrder: t('order.order'),
			typeOrderrow: t('default.orderrow'),
			typeProduct: t('default.product'),
			orderField: t('admin.calculation.orderField'),
			orderrowField: t('admin.calculation.orderrowField'),
			productField: t('admin.calculation.productField'),
			addField: t('form.addField'),
			tips: t('admin.calculation.formulaTips'),
			tips2: t('admin.calculation.formulaTips2'),
			placeholder: t('admin.calculation.placeholder'),
			invalidFormula: t('admin.calculation.invalidFormula'),
			formulaValidation: t('admin.calculation.formulaValidation'),
			showFormula: t('admin.calculation.showFormula'),
			totalGrossValue: t('admin.calculation.totalGrossValue'),
			discount: t('admin.customfieldType.Discount'),
			price: t('default.price'),
			listPrice: t('default.listPrice'),
			totalDiscount: t('tag.order.discount'),
			orderValue: t('field.order.value'),
			totalGrossOrderValue: t('admin.calculation.totalGrossOrderValue'),
			discountValue: t('admin.calculation.discountValue'),
			totalValue: t('admin.calculation.totalValue'),
			orderId: t('tag.appointment.order'),
			probability: t('tag.order.probability'),
			quantity: t('default.quantity'),
			purchaseCost: t('tag.order.product.purchaseCost'),
			contributionMargin: t('admin.calculation.contributionMargin'),
			totalContributionMargin: t('admin.calculation.totalContributionMargin')
		};

		this.state = {};

		this.editor = React.createRef();

		this.fields = this.populateFields();
		this.editorObserver = new MutationObserver(mutationList => {
			for (const mutation of mutationList) {
				if (mutation.type === 'childList') {
					const addedNode = mutation.addedNodes[0];
					if (addedNode?.className === 'mentionSuggestions') {
						addedNode.scrollIntoView();
					}
				}
			}
		});
	}

	componentWillUnmount() {
		if (this.validTimeout) {
			clearTimeout(this.validTimeout);
		}
		this.editorObserver.disconnect();
	}

	componentDidMount() {
		const targetNode = document.getElementsByClassName('RichEditor-editor')[0];
		const config = { childList: true };
		if (targetNode) {
			this.editorObserver.observe(targetNode, config);
		}
	}

	populateFields = () => {
		const { dataType } = this.props;
		let fields;
		const hasContributionMargin = Tools.AppService.getMetadata().params.UseContributionMargin;
		if (dataType === 'order') {
			fields = [
				{
					id: _.uniqueId(),
					tag: 'Order.id',
					name: `${this.lang.orderId}`
				},
				{
					id: _.uniqueId(),
					tag: 'Order.probability',
					name: `${this.lang.probability}`
				},
				{
					id: _.uniqueId(),
					tag: 'Order.discount',
					name: `${this.lang.discount}`
				},
				{
					id: _.uniqueId(),
					tag: 'Order.value',
					name: `${this.lang.orderValue}`
				},
				{
					id: _.uniqueId(),
					tag: 'Order.grossValue',
					name: `${this.lang.totalGrossOrderValue}`
				}
			];

			const orderFields = filterValidCustomTypes(Tools.AppService.getCustomFields('order'));
			fields = fields.concat(
				orderFields.map(order => {
					return {
						id: _.uniqueId(),
						tag: `Order.custom_${order.id}`,
						name: `${upperCaseFirstLetter(this.lang.orderField)}: ${order.name}`
					};
				})
			);

			fields = fields.concat(
				filterValidCustomTypes(Tools.AppService.getCustomFields('orderrow'), 'Calculation').map(orderrow => {
					return {
						id: _.uniqueId(),
						tag: `Order.rowCustomTotal_${orderrow.id}`,
						name: `${this.lang.sumOf} ${this.lang.orderrowField}: ${orderrow.name}`
					};
				})
			);
		} else {
			fields = [
				{
					id: _.uniqueId(),
					tag: 'OrderedProduct.quantity',
					name: `${this.lang.quantity}`
				},
				{
					id: _.uniqueId(),
					tag: 'OrderedProduct.price',
					name: `${this.lang.price}`
				},
				{
					id: _.uniqueId(),
					tag: 'OrderedProduct.listPrice',
					name: `${this.lang.listPrice}`
				},
				{
					id: _.uniqueId(),
					tag: 'OrderedProduct.discount',
					name: `${this.lang.discount}`
				},
				{
					id: _.uniqueId(),
					tag: 'OrderedProduct.totalValue',
					name: `${this.lang.totalValue}`
				},
				{
					id: _.uniqueId(),
					tag: 'OrderedProduct.totalGrossValue',
					name: `${this.lang.totalGrossValue}`
				},
				{
					id: _.uniqueId(),
					tag: 'OrderedProduct.totalDiscount',
					name: `${this.lang.totalDiscount}`
				}
			];

			if (hasContributionMargin) {
				fields.push(
					{
						id: _.uniqueId(),
						tag: 'OrderedProduct.purchaseCost',
						name: `${this.lang.purchaseCost}`
					},
					{
						id: _.uniqueId(),
						tag: 'OrderedProduct.contributionMargin',
						name: `${this.lang.contributionMargin}`
					},
					{
						id: _.uniqueId(),
						tag: 'OrderedProduct.totalContributionMargin',
						name: `${this.lang.totalContributionMargin}`
					}
				);
			}

			fields = fields.concat(
				filterValidCustomTypes(Tools.AppService.getCustomFields('orderrow')).map(orderrow => {
					return {
						id: _.uniqueId(),
						tag: `OrderedProduct.custom_${orderrow.id}`,
						name: `${upperCaseFirstLetter(this.lang.orderrowField)}: ${orderrow.name}`
					};
				})
			);

			fields = fields.concat(
				filterValidCustomTypes(Tools.AppService.getCustomFields('product')).map(product => {
					return {
						id: _.uniqueId(),
						tag: `OrderedProduct.customProduct_${product.id}`,
						name: `${this.lang.sumOf} ${this.lang.productField}: ${product.name}`
					};
				})
			);
		}

		return fields;
	};

	setValidity = (formula, isValid) => {
		const { onChange } = this.props;

		onChange(formula, isValid);
		const timeout = isValid ? 0 : 1000;

		if (this.validTimeout) {
			clearTimeout(this.validTimeout);
		}
		this.validTimeout = setTimeout(() => {
			this.setState({
				invalid: !isValid
			});
		}, timeout);
	};

	onChange = formula => {
		if (!formula || formula.length < 3) {
			this.setValidity('', true);
			return;
		}

		let stringToEval = formula;
		this.fields.forEach(field => {
			const replaceRegex = new RegExp(`{${field.tag}}`, 'gi');
			stringToEval = stringToEval.replace(replaceRegex, ' 1 ');
		});

		try {
			eval(stringToEval);
			this.setValidity(formula, true);
		} catch (err) {
			this.setValidity(formula, false);
		}
	};

	addField = () => {
		this.editor.current.onButtonClick('mention');
	};

	renderEditor = () => {
		return (
			<Editor
				expanded={true}
				onChange={this.onChange}
				placeholder={this.lang.placeholder}
				validRegex={/[\d\s@+\-()*/.,]/gim}
				content={this.props.formula}
				ref={this.editor}
				hideAllButtons={true}
				mentionPluginProps={{
					entityMutability: 'IMMUTABLE',
					mentionTrigger: '\u00A0'
				}}
				suggestionTrigger={'a'}
				suggestions={this.fields}
			/>
		);
	};

	toggleVisibleFormula = () => {
		const toggleChange = !this.props.showFormula;
		this.props.toggleVisibleFormula(toggleChange);
	};

	renderFormulaVisibleToggle = () => {
		const hasFormulaVisible = Tools.FeatureHelper.hasSoftDeployAccess('FORMULA_VISIBLE');

		return hasFormulaVisible ? (
			<div className="toggle-wrap">
				<Toggle
					checked={this.props.showFormula}
					onChange={this.toggleVisibleFormula}
					className="toggle-green"
					space="mtm"
				/>
				<Label>{this.lang.showFormula}</Label>
			</div>
		) : null;
	};

	render() {
		return (
			<div className="formula-container">
				<div className="title">
					<span className="title-text">{this.lang.title}</span>
					<Button color="bright-blue" onClick={() => this.addField()}>
						{this.lang.addField}
					</Button>
				</div>
				<div className={this.state.invalid ? 'row scroll-content invalid' : 'row scroll-content'}>
					{this.renderEditor()}
				</div>
				<Text color="grey-10" size="sm">
					{this.lang.tips}
					<br />
					{this.lang.tips2}
				</Text>
				{this.state.invalid && <Text color="red">{this.lang.invalidFormula}</Text>}
				{this.renderFormulaVisibleToggle()}
			</div>
		);
	}
}

Calculation.propTypes = {
	dataType: PropTypes.string,
	onChange: PropTypes.func,
	formula: PropTypes.string,
	showFormula: PropTypes.number,
	toggleVisibleFormula: PropTypes.func
};
