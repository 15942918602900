import AdminHeader from 'Components/Admin/AdminHeader';
import { hasTodoCustomFields } from 'App/babel/helpers/todo';

ReactTemplates.admin.deleteLog = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			deleteLog: t('admin.deleteLog'),
			deleteLogDescription: t('admin.deleteLogDescription'),
			search: t('default.search'),
			time: t('default.time'),
			event: t('admin.event'),
			idTitle: t('admin.deleteLog.idTitle'),
			user: t('default.user'),
			from: t('default.from'),
			to: t('default.to'),
			entityType: t('default.type'),
			events: {
				Activity: t('admin.deletedActivity'),
				Client: t('admin.deletedClient'),
				Contact: t('admin.deletedContact'),
				FormSubmit: t('admin.deletedFormSubmit'),
				Lead: t('admin.deletedLead'),
				Order: t('admin.deletedOrder'),
				Project: t('admin.deletedProject'),
				Appointment: t('admin.deletedAppointment'),
				UserDefinedObjectCustomField: t('admin.deletedCustomField'),
				UserDefinedObject2CustomField: t('admin.deletedCustomField'),
				UserDefinedObject3CustomField: t('admin.deletedCustomField'),
				UserDefinedObject4CustomField: t('admin.deletedCustomField'),
				ActivityCustomField: t('admin.deletedActivityCustomField'),
				AgreementCustomField: t('admin.deletedAgreementCustomField'),
				AppointmentCustomField: t('admin.deletedAppointmentCustomField'),
				ClientCustomField: t('admin.deletedClientCustomField'),
				ContactCustomField: t('admin.deletedContactCustomField'),
				LeadCustomField: t('admin.deletedLeadCustomField'),
				OrderCustomField: t('admin.deletedOrderCustomField'),
				OrderedProductCustomField: t('admin.deletedOrderedProductCustomField'),
				ProductCustomField: t('admin.deletedProductCustomField'),
				ProjectCustomField: t('admin.deletedProjectCustomField'),
				ProjectPlanCustomField: t('admin.deletedProjectPlanCustomField'),
				UserCustomField: t('admin.deletedUserCustomField'),
				OrderStage: t('admin.deletedOrderStage'),
				BannerGroup: t('admin.deletedBannerGroup'),
				FileMetadata: t('admin.deletedFileMetadata'),
				PowerImport: t('admin.deletedPowerImport'),
				Segment: t('admin.deletedSegment'),
				PhoneCall: t('admin.deletedPhoneCall'),
				MailCampaign: t('admin.deletedMailCampaign'),
				Form: t('admin.deletedForm'),
				Agreement: t('admin.deletedAgreement'),
				MailTemplate: t('admin.deletedMailTemplate'),
				Visit: t('admin.deletedVisit'),
				Esign: t('admin.deletedEsign'),
				Mail: t('admin.deletedMail'),
				ActivityType: t('admin.deletedActivityType'),
				AppointmentType: t('admin.deletedAppointmentType'),
				default: t('admin.deleted')
			},
			eventsNoName: {
				Activity: t('admin.deletedActivityNoName'),
				Client: t('admin.deletedClientNoName'),
				Contact: t('admin.deletedContactNoName'),
				FormSubmit: t('admin.deletedFormSubmitNoName'),
				Lead: t('admin.deletedLeadNoName'),
				Order: t('admin.deletedOrderNoName'),
				Project: t('admin.deletedProjectNoName'),
				Appointment: t('admin.deletedAppointmentNoName'),
				UserDefinedObjectCustomField: t('admin.deletedCustomFieldNoName'),
				UserDefinedObject2CustomField: t('admin.deletedCustomFieldNoName'),
				UserDefinedObject3CustomField: t('admin.deletedCustomFieldNoName'),
				UserDefinedObject4CustomField: t('admin.deletedCustomFieldNoName'),
				ActivityCustomField: t('admin.deletedActivityCustomFieldNoName'),
				AgreementCustomField: t('admin.deletedAgreementCustomFieldNoName'),
				AppointmentCustomField: t('admin.deletedAppointmentCustomFieldNoName'),
				ClientCustomField: t('admin.deletedClientCustomFieldNoName'),
				ContactCustomField: t('admin.deletedContactCustomFieldNoName'),
				LeadCustomField: t('admin.deletedLeadCustomFieldNoName'),
				OrderCustomField: t('admin.deletedOrderCustomFieldNoName'),
				OrderedProductCustomField: t('admin.deletedOrderedProductCustomFieldNoName'),
				ProductCustomField: t('admin.deletedProductCustomFieldNoName'),
				ProjectCustomField: t('admin.deletedProjectCustomFieldNoName'),
				ProjectPlanCustomField: t('admin.deletedProjectPlanCustomFieldNoName'),
				UserCustomField: t('admin.deletedUserCustomFieldNoName'),
				OrderStage: t('admin.deletedOrderStageNoName'),
				BannerGroup: t('admin.deletedBannerGroupNoName'),
				FileMetadata: t('admin.deletedFileMetadataNoName'),
				PowerImport: t('admin.deletedPowerImportNoName'),
				Segment: t('admin.deletedSegmentNoName'),
				PhoneCall: t('admin.deletedPhoneCallNoName'),
				MailCampaign: t('admin.deletedMailCampaignNoName'),
				Form: t('admin.deletedFormNoName'),
				Agreement: t('admin.deletedAgreementNoName'),
				MailTemplate: t('admin.deletedMailTemplateNoName'),
				UserDefinedObject: t('admin.deletedUserDefinedObjectNoName'),
				Visit: t('admin.deletedVisitNoName'),
				Esign: t('admin.deletedEsignNoName'),
				Mail: t('admin.deletedMailNoName'),
				ActivityType: t('admin.deletedActivityTypeNoName'),
				AppointmentType: t('admin.deletedAppointmentTypeNoName'),
				default: t('admin.deleted')
			},
			noResult: t('noResult.default'),
			improveYourData: t('admin.improveYourData'),
			goToSoliditetClean: t('admin.goToSoliditetClean')
		};
		this.searchTimeout = null;
	},
	getInitialState: function () {
		return {
			search: this.props.rootData.pageData.search || ''
		};
	},
	updateSearch: function (event) {
		this.setState({
			search: event.target.value
		});

		this.props.rootData.pageData.search = event.target.value;
		this.props.rootData.onSearch();
	},
	renderEvents: function (event) {
		if (typeof event.obj === 'string') {
			try {
				event.obj = JSON.parse(event.obj);
			} catch (e) {
				event.obj = {};
			}
		}

		var eventText;
		if (event.obj.name || event.obj.description || event.obj.filename || event.obj.subject) {
			eventText =
				(this.lang.events[event.type] || this.lang.events.default) +
				' ' +
				(event.obj.name || event.obj.description || event.obj.filename || event.obj.subject);
		} else if (event.type.indexOf('UserDefinedObject') !== -1) {
			var theUdo = _.find(this.props.tools.AppService.getMetadata().params.UserDefinedObject, function (udo) {
				return udo.id === (parseInt(event.type[event.type.length - 1]) || 1);
			});

			eventText = this.lang.eventsNoName.UserDefinedObject.replace('{1}', theUdo.name);
		} else {
			eventText = this.lang.eventsNoName[event.type] || this.lang.eventsNoName.default;
		}
		const deletedEventID = event.obj.id;

		return (
			<tr>
				<td>{moment(event.date).format('L LT')}</td>
				<td>{deletedEventID} </td>
				<td>{eventText}</td>
				<td className="ellipsis-td">
					<ReactTemplates.TOOLS.avatar
						user={event.user}
						avatarService={this.props.tools.avatarService}
						style={{ position: 'absolute', top: '50%', marginTop: '-10px' }}
						size={20}
					/>
					<span style={{ paddingLeft: '25px' }}>{event.user ? event.user.name : ''}</span>
				</td>
			</tr>
		);
	},
	getEmptyRow: function () {
		return (
			<tr>
				<td colSpan={3} style={{ textAlign: 'center' }}>
					<h2 className="no-result">{this.lang.noResult}</h2>
				</td>
			</tr>
		);
	},
	clearDate: function (field) {
		if (field === 'toDate') {
			this.props.rootData.setToDate(null);
		} else {
			this.props.rootData.setFromDate(null);
		}
	},

	renderTh: function (title, width, sortable) {
		var className = sortable ? 'sortable' : '';
		if (this.props.rootData.pageData.sort === sortable) {
			className += ' active';
		}
		return (
			<th
				className={className}
				style={{ width: width + 'px' }}
				onClick={
					sortable
						? this.props.rootData.setSort.bind(null, sortable, !this.props.rootData.pageData.desc)
						: null
				}
			>
				{title}{' '}
				<b className={this.props.rootData.pageData.desc ? 'fa fa-sort-alpha-desc' : 'fa fa-sort-alpha-asc'}></b>
			</th>
		);
	},

	render: function () {
		var events = this.props.rootData.pageData.log.map(this.renderEvents);

		return (
			<div id="admin-page-delete-log">
				<AdminHeader
					title={this.lang.deleteLog}
					description={this.lang.deleteLogDescription}
					image="removal-log.svg"
					articleId={577}
				/>
				<div id="admin-content">
					<div className="admin-table">
						<div className="admin-table-top">
							<div className="table-search">
								<i className="fa fa-search" aria-hidden="true" />
								<input
									type="text"
									placeholder={this.lang.search}
									value={this.state.search}
									onChange={this.updateSearch}
								/>
							</div>
							<div className="date-column">
								<div className="icon-input">
									<i className="fa fa-calendar" ariaHidden="true" />
									<ReactTemplates.INPUTS.datepicker
										onChange={this.props.rootData.setFromDate}
										tools={this.props.tools}
										placeholder={this.lang.from}
										value={this.props.rootData.pageData.fromDate}
									/>
									{this.props.rootData.pageData.fromDate ? (
										<button
											type="button"
											onClick={this.clearDate.bind(null, 'fromDate')}
											className="clear-date"
										>
											<b className="fa fa-times-circle"></b>
										</button>
									) : null}
								</div>
								<div className="icon-input">
									<i className="fa fa-calendar" ariaHidden="true" />
									<ReactTemplates.INPUTS.datepicker
										onChange={this.props.rootData.setToDate}
										tools={this.props.tools}
										placeholder={this.lang.to}
										value={this.props.rootData.pageData.toDate}
									/>
									{this.props.rootData.pageData.toDate ? (
										<button
											type="button"
											onClick={this.clearDate.bind(null, 'toDate')}
											className="clear-date"
										>
											<b className="fa fa-times-circle"></b>
										</button>
									) : null}
								</div>
							</div>
							<div className="filter-column select2-offset">
								<ReactTemplates.admin.deleteLogTypeSelect
									tools={this.props.tools}
									placeholder={this.lang.entityType}
									onChange={this.props.rootData.setType}
									value={this.props.rootData.pageData.type}
									dropdownCssClass="wide-drop"
								/>
							</div>
							<div className="filter-column select2-offset">
								<ReactTemplates.INPUTS.upUsers
									tools={this.props.tools}
									placeholder={this.lang.user}
									onChange={this.props.rootData.setUser}
									value={this.props.rootData.pageData.user}
									dropdownCssClass="wide-drop"
									data={this.props.rootData.pageData.users}
								/>
							</div>
						</div>
						<table>
							<thead>
								<tr>
									{this.renderTh(this.lang.time, 155, 'date')}
									<th style={{ width: '150px' }}>{this.lang.idTitle}</th>
									<th>{this.lang.event}</th>
									<th style={{ width: '200px' }}>{this.lang.user}</th>
								</tr>
							</thead>
							<tbody>
								{this.props.rootData.pageData.searching ? (
									<tr>
										<td colSpan={4} className="loading-row">
											<ReactTemplates.loader />
										</td>
									</tr>
								) : events.length ? (
									events
								) : (
									this.getEmptyRow()
								)}
							</tbody>
						</table>
					</div>

					<ReactTemplates.TOOLS.Paginator
						currentPage={this.props.rootData.pageData.activePage}
						max={Math.max(this.props.rootData.pageData.totalPages, 1)}
						onChange={this.props.rootData.onChangePage.bind(this, true)}
						total={this.props.rootData.pageData.total}
					/>
				</div>
			</div>
		);
	}
});

ReactTemplates.admin.deleteLogTypeSelect = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {};
		this.options = [
			{
				type: 'Activity',
				translated: t('default.activity')
			},
			{
				type: 'Client',
				translated: t('default.client')
			},
			{
				type: 'Contact',
				translated: t('default.contact')
			},
			{
				type: 'FormSubmit',
				translated: t('default.formSubmit')
			},
			{
				type: 'Lead',
				translated: t('default.lead')
			},
			{
				type: 'Order',
				translated: t('default.order')
			},
			{
				type: 'Project',
				translated: t('default.project')
			},
			{
				type: 'Appointment',
				translated: t('default.appointment')
			},
			{
				type: 'ActivityCustomField',
				translated: t('filters.columns.activity')
			},
			{
				type: 'AgreementCustomField',
				translated: t('filters.columns.agreement')
			},
			{
				type: 'AppointmentCustomField',
				translated: t('filters.columns.appointment')
			},
			{
				type: 'ClientCustomField',
				translated: t('filters.columns.account')
			},
			{
				type: 'ContactCustomField',
				translated: t('filters.columns.contact')
			},
			{
				type: 'LeadCustomField',
				translated: t('filters.columns.lead')
			},
			{
				type: 'OrderCustomField',
				translated: t('filters.columns.order')
			},
			{
				type: 'OrderedProductCustomField',
				translated: t('filters.columns.orderRow')
			},
			{
				type: 'ProductCustomField',
				translated: t('trigger.field.orderedproduct')
			},
			{
				type: 'ProjectCustomField',
				translated: t('filters.columns.campaign')
			},
			{
				type: 'UserCustomField',
				translated: t('filters.columns.user')
			},
			{
				type: 'OrderStage',
				translated: t('default.stage')
			},
			{
				type: 'BannerGroup',
				translated: t('ads.bannergroup')
			},
			{
				type: 'FileMetadata',
				translated: t('file.file')
			},
			{
				type: 'PowerImport',
				translated: t('default.import')
			},
			{
				type: 'Segment',
				translated: t('segment.segment')
			},
			{
				type: 'PhoneCall',
				translated: t('voice.phoneCalls')
			},
			{
				type: 'MailCampaign',
				translated: t('mail.mailings')
			},
			{
				type: 'Form',
				translated: t('form.form')
			},
			{
				type: 'Agreement',
				translated: t('default.agreement')
			},
			{
				type: 'MailTemplate',
				translated: t('mail.template')
			},
			{
				type: 'Visit',
				translated: t('default.siteVisits')
			},
			{
				type: 'Esign',
				translated: t('default.eSign')
			},
			{
				type: 'Mail',
				translated: t('default.mail')
			},
			{
				type: 'ActivityType',
				translated: t('default.activityType')
			},
			{
				type: 'AppointmentType',
				translated: t('default.appointmentType')
			}
		];

		const hasProjectPlanCustomFieldAccess = Tools.FeatureHelper.isAvailable(
			Tools.FeatureHelper.Feature.PROJECT_PLAN_CUSTOM
		);

		if (hasProjectPlanCustomFieldAccess) {
			this.options.push({
				type: 'ProjectPlanCustomField',
				translated: t('filters.columns.projectPlan')
			});
		}

		if (hasTodoCustomFields()) {
			this.options.push({
				type: 'TodoCustomField',
				translated: t('filters.columns.todo')
			});
		}

		var udos = _.map(this.props.tools.AppService.getMetadata().params.UserDefinedObject, function (udo) {
			var type = 'UserDefinedObject';
			if (udo.id > 1) {
				type += udo.id;
			}

			return {
				type: type,
				translated: udo.name
			};
		});

		this.options = this.options.concat(udos);
	},
	componentDidUpdate: ReactTemplates.TOOLS.selectHelper.updateValue,
	componentDidMount: function () {
		var opts = {
			data: this.options,
			ajax: false,
			asIds: false,
			required: this.props.required || false,
			idAttr: { field: 'type' },
			titleAttr: { field: 'translated' },
			onChange: this.props.onChange,
			emptyMsg: '',
			placeholder: this.props.placeholder || false,
			dropdownCssClass: this.props.dropdownCssClass || ''
		};

		var _opts = _.assign({}, opts, this.props.options);

		var input = jQuery(this._input);
		ReactTemplates.TOOLS.selectHelper.getSelect2Options(this, _opts, input, function (options) {
			input.select2(options);
		});
	},
	render: function () {
		return ReactTemplates.TOOLS.selectHelper.getInputComponent(this);
	}
});
