import PropTypes from 'prop-types';
import { Component } from 'react';
import T from 'Components/Helpers/translate';
import _ from 'lodash';

class StandardFieldCustomInput extends Component {
	componentDidUpdate() {
		const selectHelper = ReactTemplates.TOOLS.selectHelper;
		selectHelper.updateValue.call(this);
	}

	componentDidMount() {
		let opts = {
			required: this.props.required || false,
			multiple: this.props.multiple || false,
			placeholder: this.props.placeholder,
			onChange: this.props.onChange,
			ajax: false,
			asIds: false,
			emptyMsg: T('default.noName'),
			filterType: 'contact',
			formatResult: this.props.formatResult || ((object, container, query, escape) => escape(object.name)),
			formatSelection: this.props.formatSelection || ((object, container, escape) => escape(object.name)),
			data: this.getData()
		};

		const input = jQuery(this._input);

		const selectHelper = ReactTemplates.TOOLS.selectHelper;
		opts = _.merge({}, opts, this.props.options);

		selectHelper.getSelect2Options(this, opts, input, options => {
			input.select2(options);
		});
	}

	componentWillUnmount() {
		const input = jQuery(this._input);
		input.select2('destroy');
	}

	getData() {
		return _.sortByOrder(
			[...Tools.AppService.getCustomFields(this.props.entity), ...(this.props.extraValues ?? [])],
			'name'
		);
	}

	render() {
		const selectHelper = ReactTemplates.TOOLS.selectHelper;
		return selectHelper.getInputComponent(this);
	}
}

StandardFieldCustomInput.propTypes = {
	onlyActive: PropTypes.bool,
	multiple: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	required: PropTypes.bool,
	placeholder: PropTypes.string,
	options: PropTypes.object,
	formatResult: PropTypes.func,
	formatSelection: PropTypes.func,
	data: PropTypes.array,
	entity: PropTypes.string,
	extraValues: PropTypes.array
};

export default StandardFieldCustomInput;
