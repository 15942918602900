import { Button, Link, Text, NumberInput } from '@upsales/components';
import openModal from 'App/services/Modal/Modal';
import AdminHeader from 'Components/Admin/AdminHeader';
import UpCountry from 'Components/Inputs/UpCountry';
import ValidationService from 'Services/ValidationService';
import UpSelect from '../../../../../../babel/components/Inputs/UpSelect';
import ColorPicker from '../../bannereditor/colorPicker';

ReactTemplates.admin.editIntegration = window.ReactCreateClass({
	aceJsonInited: false,
	aceMdInited: false,
	getInitialState: function () {
		return this.getStateFromProps(this.props);
	},
	getStateFromProps: function (props) {
		return {
			integration: props.rootData.pageData.integration,
			emailValid: false,
			newImage: false
		};
	},

	UNSAFE_componentWillReceiveProps: function (nextProps) {
		if (!this.state.integration && nextProps.rootData.pageData.integration) {
			this.setState({ integration: nextProps.rootData.pageData.integration });
		}
	},

	availableCategories: function () {
		return [
			{ name: this.lang.category.dataSources, id: 'data-sources' },
			{ name: this.lang.category.npsScoring, id: 'nps-scoring' },
			{ name: this.lang.category.ticketing, id: 'ticketing' },
			{ name: this.lang.category.timeReporting, id: 'time-reporting' },
			{ name: this.lang.category.productivity, id: 'productivity' },
			{ name: this.lang.category.teamCollaboration, id: 'team-collaboration' },
			{ name: this.lang.category.esign, id: 'esign' },
			{ name: this.lang.category.webinar, id: 'webinar' },
			{ name: this.lang.category.phoneServices, id: 'phone-services' },
			{ name: this.lang.category.thirdPartyApps, id: 'third-party-apps' },
			{ name: this.lang.category.marketing, id: 'marketing' },
			{ name: this.lang.category.billing, id: 'billing' },
			{ name: this.lang.category.video, id: 'video' },
			{ name: this.lang.category.chat, id: 'chat' }
		];
	},

	componentWillUnmount: function () {
		if (this.jsonEditor) {
			this.jsonEditor.destroy();
		}
		if (this.mdEditor) {
			this.mdEditor.destroy();
		}
		if (this.mdEditorTerms) {
			this.mdEditorTerms.destroy();
		}
	},

	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			header: 'Your App',
			headerInfo: 'Create an Upsales app to integrate with your other systems and services.',
			name: 'App name',
			appSupportEmail: 'App support email',
			alias: 'App alias (for whitelabel)',
			version: 'Current version',
			description: 'Description',
			descriptionLong: 'Long description',
			langTagPrefix: 'Language tag',
			imageLink: 'Icon',
			endpoint: 'Endpoint',
			url: 'URL for documentation, support, contact info et c',
			tags: 'Tags',
			price: 'Pricing',
			pricePerUser: 'Pricing per user',
			configJson: 'Config Json',
			userConfigurable: 'User configurable',
			apiKey: 'Use API key',
			userOnly: 'User only app ',
			hideForNew: 'Hide for new customers ',
			active: 'Active',
			public: 'Public',
			abort: t('default.abort'),
			saving: t('default.saving'),
			save: t('default.save'),
			integrationInfoHeader: 'Basic information',
			integrationInfoBody:
				'Describe your app for the app directory. Your app will only be visible to your team until you make it public.',
			appColor: 'App color',
			preview: 'Preview',
			chooseImageOnComputor: t('admin.apps.chooseImageOnComputor'),
			authenticationKey: 'Authentication key',
			agreement: t('default.agreement'),
			contractTerms: 'App Terms',
			client: 'Client',
			fieldSync: 'Show the apps used/synced fields',
			disableProducts: 'Disable creating, editing and deleting products',
			limitPriceLists: 'Disable creating new price lists in Upsales and lock the code on price lists',
			category: {
				all: Tools.$translate('integrations.category.all'),
				dataSources: Tools.$translate('integrations.category.data-sources'),
				npsScoring: Tools.$translate('integrations.category.nps-scoring'),
				ticketing: Tools.$translate('integrations.category.ticketing'),
				timeReporting: Tools.$translate('integrations.category.time-reporting'),
				productivity: Tools.$translate('integrations.category.productivity'),
				teamCollaboration: Tools.$translate('integrations.category.team-collaboration'),
				esign: Tools.$translate('integrations.category.esign'),
				webinar: Tools.$translate('integrations.category.webinar'),
				phoneServices: Tools.$translate('integrations.category.phone-services'),
				thirdPartyApps: Tools.$translate('integrations.category.third-party-apps'),
				marketing: Tools.$translate('integrations.category.marketing'),
				billing: Tools.$translate('integrations.category.billing'),
				video: Tools.$translate('integrations.category.video'),
				chat: Tools.$translate('integrations.category.chat')
			}
		};
	},

	initAce: function (prevProps, prevState) {
		var self = this;

		if (
			this.aceJsonInited &&
			this._jsonEditor &&
			prevState &&
			prevState.integration.configJson !== this.state.integration.configJson
		) {
			var jsonEditor = ace.edit(this._jsonEditor);

			if (jsonEditor.getValue() !== this.state.integration.configJson) {
				jsonEditor.setValue(this.state.integration.configJson, -1);
			}
		}

		if (!this.aceJsonInited && this._jsonEditor) {
			this.jsonEditor = ace.edit(this._jsonEditor);
			this.jsonEditor.getSession().setMode('ace/mode/javascript');
			this.jsonEditor.setShowPrintMargin(false);

			if (this.state.integration.configJson) {
				this.jsonEditor.setValue(JSON.stringify(JSON.parse(this.state.integration.configJson), null, '\t'), -1);
			}

			this.jsonEditor.getSession().on('change', function () {
				self.onFieldChange('configJson', { target: { value: self.jsonEditor.getValue() } });
			});

			this.aceJsonInited = true;
		}

		if (!this.aceMdInited && this._mdEditor) {
			this.mdEditor = ace.edit(this._mdEditor);
			this.mdEditor.getSession().setMode('ace/mode/markdown');
			this.mdEditor.setShowPrintMargin(false);

			if (this.state.integration.descriptionLong) {
				this.mdEditor.setValue(this.state.integration.descriptionLong, -1);
			}

			this.mdEditor.getSession().on('change', function () {
				self.onFieldChange('descriptionLong', { target: { value: self.mdEditor.getValue() } });
			});

			this.aceMdInited = true;
		}

		if (!this.aceMdTermsInited && this._mdEditorTerms) {
			this.mdEditorTerms = ace.edit(this._mdEditorTerms);
			this.mdEditorTerms.getSession().setMode('ace/mode/markdown');
			this.mdEditorTerms.setShowPrintMargin(false);

			if (this.state.integration.terms) {
				this.mdEditorTerms.setValue(this.state.integration.terms, -1);
			}

			this.mdEditorTerms.getSession().on('change', function () {
				self.onFieldChange('terms', { target: { value: self.mdEditorTerms.getValue() } });
			});

			this.aceMdTermsInited = true;
		}
	},

	componentDidMount: function () {
		this.initAce();
	},

	componentDidUpdate: function (prevProps, prevState) {
		this.initAce(prevProps, prevState);
	},

	setRef: function (name, ref) {
		this[name] = ref;
	},
	onFieldChange: function (key, e) {
		var file = this.state.integration.$file;
		var integration = JSON.parse(JSON.stringify(this.state.integration));
		integration[key] = e.target.value;

		if (key === 'supportEmail') {
			if (!e.target.value) {
				this.setState({ emailValid: false });
				delete integration[key];
			} else {
				this.setState({ emailValid: !ValidationService.validEmail(e.target.value) });
			}
		}

		if (key === 'description' && !e.target.value) {
			delete integration[key];
		}

		integration.$file = file;
		this.setState({ integration: integration });
		this.props.rootData.onChange(integration);
	},
	onColorChange: function (value) {
		var integration = this.state.integration;
		integration.color = value;
		this.setState({ integration: integration });
		this.props.rootData.onChange(integration);
	},
	toggleChange: function (key) {
		var integration = this.state.integration;
		integration[key] = !integration[key];
		if (key === 'userOnly' && integration.userOnly) {
			integration.userConfigurable = true;
		}
		this.setState({ integration: integration });
		this.props.rootData.onChange(integration);
	},
	initToggleChange: function (init) {
		var integration = this.state.integration;
		integration.inits[init] = !integration.inits[init];
		this.setState({ integration: integration });
		this.props.rootData.onChange(integration);
	},
	premiumChange: function (isPremium) {
		var integration = this.state.integration;
		integration.price = isPremium ? 1 : 0;
		this.setState({ integration: integration });
		this.props.rootData.onChange(integration);
	},
	previewMd: function (text = '') {
		openModal('MailPreview', {
			name: '',
			html: `<div class="app-markdown">${markdown.toHTML(text)}</div>`,
			isPlainText: true
		});
	},

	onImageChange: function (e) {
		if (!e.target.files.length) {
			return;
		}

		var file = e.target.files[0];
		var integration = this.state.integration;
		integration.$file = file;

		if (window.FileReader !== undefined) {
			var reader = new FileReader();
			reader.onload = function (e) {
				$('#imageLink-preview').attr('src', e.target.result);
			};

			reader.readAsDataURL(file);
		}

		this.setState({ integration: integration, newImage: true });
		this.props.rootData.onChange(integration);
	},
	appendToConfigJson: function (value, key, wrapper) {
		var integration = this.state.integration;

		var parsedConfig = JSON.parse(integration.configJson);
		if (parsedConfig && wrapper) {
			if (parsedConfig[wrapper]) {
				parsedConfig[wrapper][key] = value;
			} else {
				parsedConfig[wrapper] = {};
				parsedConfig[wrapper][key] = value;
			}
		} else if (parsedConfig) {
			parsedConfig[key] = value;
		}

		var updatedIntegration = _.assign({}, this.state.integration);
		updatedIntegration.configJson = JSON.stringify(parsedConfig, null, '\t');

		this.setState({ integration: updatedIntegration });
		this.props.rootData.onChange(updatedIntegration);
	},
	selectedCategories: function (cats) {
		var self = this;

		var result = _.compact(
			_.map(cats, function (cat) {
				return _.find(self.availableCategories(), { id: cat });
			})
		);

		return result;
	},
	render: function () {
		var self = this;
		var rootData = this.props.rootData;
		var saving = rootData.saving;
		var integration = this.state.integration;

		if (
			!integration ||
			(integration.publisherClientId && Tools.AppService.getCustomerId() !== integration.publisherClientId)
		) {
			return null;
		}

		var version = integration.version || '0.0.1';
		var imageLink = integration.imageLink || '';

		var parsedConfig = {};
		try {
			parsedConfig = JSON.parse(integration.configJson);
		} catch (e) {
			console.log('cant parse this', integration.configJson, integration);
		}

		var iframeVisible = parsedConfig.iframe;

		var endpoint = integration.endpoint || 'https://';
		var isHacker = [325, 6990].indexOf(Tools.AppService.getCustomerId()) !== -1;

		var imageContent;

		if (self.state.newImage) {
			imageContent = <img key={'image-new'} id="imageLink-preview" />;
		} else if (imageLink) {
			imageContent = <img key={'image-imageLink'} src={imageLink} />;
		} else {
			imageContent = <i key={'image-none'} className="fa fa-picture-o" aria-hidden="true" />;
		}

		return (
			<div id="admin-page-edit-integration">
				<AdminHeader title={self.lang.header} description={self.lang.headerInfo} />
				<div id="admin-content" style={{ maxWidth: '900px' }}>
					<form autoComplete="off" name="EditIntegration">
						<fieldset disabled={saving}>
							<div className="row">
								<div className="col-sm-12">
									<label>
										<b>{'Tip!'}</b>{' '}
										<ReactTemplates.elevio
											text="Read our introduction to Apps in Upsales"
											sidebar="true"
											articleId="410"
										/>
										{' to get an understanding of how apps are built and runs.'}
									</label>
								</div>
							</div>

							<div className="admin-table">
								<div className="admin-table-top">
									<span className="admin-table-title">
										{self.lang.integrationInfoHeader + ' '}
										<ReactTemplates.elevio articleId="411" />
									</span>
									<div className="row">
										<div className="col-sm-12">
											<label>
												{self.lang.integrationInfoBody + ' '}
												<ReactTemplates.elevio text="Learn more." articleId="411" />
											</label>
										</div>
									</div>

									<div className="row">
										<div className="col-sm-9">
											<label>{self.lang.name}</label>
											<input
												autoComplete="integration-name"
												type="text"
												value={integration.name}
												onChange={self.onFieldChange.bind(self, 'name')}
												className="form-control"
											/>
										</div>
									</div>

									<div className="row">
										<div className="col-sm-9">
											<label>{self.lang.appSupportEmail}</label>
											<label style={{ fontStyle: 'italic', fontSize: '85%' }}>
												{'Add email to your support if the user have problems with your app.'}
											</label>
											<input
												autoComplete="integration-email"
												type="email"
												required
												value={integration.supportEmail}
												onChange={self.onFieldChange.bind(self, 'supportEmail')}
												className={(self.state.emailValid ? 'invalid' : '') + ' form-control'}
											/>
										</div>
									</div>

									{isHacker ? (
										<div className="row">
											<div className="col-sm-9">
												<label>{self.lang.alias}</label>
												<input
													autoComplete="integration-alias"
													type="text"
													value={integration.alias}
													onChange={self.onFieldChange.bind(self, 'alias')}
													className="form-control"
												/>
											</div>
										</div>
									) : null}

									<div className="row">
										<div className="col-md-9">
											<label>{self.lang.imageLink}</label>
											<div className="image-wrap">{imageContent}</div>
											<div className="btn btn-bright-blue up-btn btn-sm no-shadow upload-button">
												<i className="fa fa-upload" aria-hidden="true" />{' '}
												{self.lang.chooseImageOnComputor}
												<input
													type="file"
													name="file"
													accept=".png, .jpg, .jpeg, .gif"
													onChange={this.onImageChange}
												/>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-md-9">
											<label>{self.lang.description}</label>
											<textarea
												value={integration.description}
												onChange={self.onFieldChange.bind(self, 'description')}
												className="form-control"
											/>
										</div>
									</div>

									<div className="row">
										<div className="col-md-9">
											<label>{'Category'}</label>
											<label style={{ fontStyle: 'italic', fontSize: '85%' }}>
												{'You can only pick at most 2 categories.'}
											</label>

											<UpSelect
												className="form-control"
												multiple={true}
												options={{
													maximumSelectionLength: 2,
													maximumSelectionSize: 2
												}}
												data={_.sortBy(this.availableCategories(), 'name')}
												defaultValue={integration.category}
												onChange={function (e) {
													self.onFieldChange('category', e);
												}}
												formatSelection={function (cat, container, escape) {
													if (typeof cat === 'string') {
														cat = _.find(self.availableCategories(), { id: cat });
													}

													return escape(cat.name);
												}}
												formatResult={function (cat, container, query, escape) {
													return escape(cat.name);
												}}
												getId={function (cat) {
													if (typeof cat === 'string') {
														cat = _.find(self.availableCategories(), { id: cat });
													}

													return cat.id;
												}}
												matcher={function (term, undef, item) {
													return item.name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
												}}
											/>
										</div>
									</div>

									<div className="row">
										<div className="col-md-9">
											<label>{'Region'}</label>
											<label style={{ fontStyle: 'italic', fontSize: '85%' }}>
												{'Select recommended regions for your app'}
											</label>

											<UpCountry
												className="form-control"
												multiple={true}
												value={integration.region}
												onChange={function (e) {
													self.onFieldChange('region', { target: { value: e } });
												}}
											/>
										</div>
									</div>

									<div className="row">
										<div className="col-md-12">
											<label>
												{self.lang.descriptionLong}
												<Button
													color="bright-blue"
													className="pull-right no-margin"
													size="sm"
													type="link"
													onClick={function () {
														self.previewMd(
															self.state.integration.descriptionLong ?? 'No Description'
														);
													}}
												>
													{this.lang.preview}
												</Button>
												<div className="clearfix" />
											</label>
											<div
												ref={self.setRef.bind(self, '_mdEditor')}
												style={{ height: '300px' }}
											/>
										</div>
									</div>

									<div className="row">
										<div className="col-md-12">
											<label>
												{self.lang.contractTerms}
												<Button
													color="bright-blue"
													className="pull-right no-margin"
													size="sm"
													type="link"
													onClick={function () {
														self.previewMd(self.state.integration.terms);
													}}
												>
													{this.lang.preview}
												</Button>
												<div className="clearfix" />
											</label>
											<div
												ref={self.setRef.bind(self, '_mdEditorTerms')}
												style={{ height: '300px' }}
											/>
										</div>
									</div>

									<div className="row">
										<div className="col-md-9">
											<label>{self.lang.appColor}</label>
											<ColorPicker
												value={integration.color}
												border={true}
												onChange={self.onColorChange}
												className="form-control"
											/>
										</div>
									</div>
								</div>
							</div>

							<div className="admin-table">
								<div className="admin-table-top">
									<div className="row">
										<div className="col-sm-12">
											<span className="admin-table-title">{'App settings'}</span>
											<label>
												{
													'Set up where your app is located, what it will do and what user input you need.'
												}
											</label>
										</div>
									</div>

									<div className="row">
										<div className="col-sm-12">
											<span className="admin-table-title" style={{ fontSize: '14px' }}>
												{'Endpoint '}
												<ReactTemplates.elevio articleId="412" />
											</span>
											<label>
												{'The URL of your app. This is where all requests will be made. '}
												<ReactTemplates.elevio text="Learn more." articleId="412" />
											</label>
										</div>
									</div>
									<div className="row">
										<div className="col-md-9">
											<input
												autoComplete="integration-endpoint"
												type="text"
												value={endpoint}
												onChange={self.onFieldChange.bind(self, 'endpoint')}
												className="form-control"
											/>
										</div>
									</div>

									<div className="row">
										<div className="col-sm-12">
											<span className="admin-table-title" style={{ fontSize: '14px' }}>
												{'Current version '}
												<ReactTemplates.elevio articleId="413" />
											</span>
											<label>
												{'Keep track of which version of your app your users are running. '}
												<ReactTemplates.elevio text="Learn more." articleId="413" />
											</label>
										</div>
									</div>
									<div className="row">
										<div className="col-sm-3">
											<input
												autoComplete="integration-version"
												type="text"
												value={version}
												onChange={self.onFieldChange.bind(self, 'version')}
												className="form-control"
											/>
										</div>
									</div>

									<div className="row">
										<div className="col-sm-12">
											<span className="admin-table-title" style={{ fontSize: '14px' }}>
												{'Use API key '}
												<ReactTemplates.elevio articleId="414" />
											</span>
											<label>
												{'If your app requires the use of an API key. '}
												<ReactTemplates.elevio text="Learn more." articleId="414" />
											</label>
										</div>
										<div className="col-md-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.apiKey}
												onChange={self.toggleChange.bind(self, 'apiKey')}
												className="toggle-bright-blue"
												disabled={integration.id}
											/>{' '}
											<label style={{ display: 'inline-block' }}>{self.lang.apiKey}</label>
										</div>
									</div>

									<div className="row">
										<div className="col-sm-12">
											<span className="admin-table-title" style={{ fontSize: '14px' }}>
												{self.lang.userOnly}
												<ReactTemplates.elevio articleId="414" />
											</span>
											<label>
												{'If your app does not need admin activation or client config. '}
												<ReactTemplates.elevio text="Learn more." articleId="414" />
											</label>
										</div>
										<div className="col-md-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.userOnly}
												onChange={self.toggleChange.bind(self, 'userOnly')}
												className="toggle-bright-blue"
												disabled={integration.id}
											/>{' '}
											<label style={{ display: 'inline-block' }}>{self.lang.userOnly}</label>
										</div>
									</div>

									<div className="row">
										<div className="col-sm-12">
											<span className="admin-table-title" style={{ fontSize: '14px' }}>
												{'User configurable '}
												<ReactTemplates.elevio articleId="415" />
											</span>
											<label>
												{
													'Use this setting if your app should be activated on a per user basis. '
												}
												<ReactTemplates.elevio text="Learn more." articleId="415" />
											</label>
										</div>
										<div className="col-md-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.userConfigurable}
												disabled={integration.userOnly}
												onChange={self.toggleChange.bind(self, 'userConfigurable')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>
												{self.lang.userConfigurable}
											</label>
										</div>
									</div>

									<div className="row">
										<div className="col-sm-12">
											<span className="admin-table-title" style={{ fontSize: '14px' }}>
												{'Show app on Account Card'}
											</span>
											<label>
												{
													'Use this setting if your app should show an iframe on the account card.'
												}
											</label>
										</div>
										<div className="col-md-12">
											<ReactTemplates.upFilters.components.toggle
												checked={iframeVisible || integration.appOnAccountCard}
												onChange={function (value) {
													self.toggleChange('appOnAccountCard');
													self.appendToConfigJson(value, 'iframe');
												}}
												className="toggle-bright-blue"
											/>
										</div>
									</div>

									<div className="row">
										<div className="col-sm-12">
											<span className="admin-table-title" style={{ fontSize: '14px' }}>
												{'App Json '}
												<ReactTemplates.elevio articleId="416" sidebar="true" />
											</span>
											<label>
												{
													'The Json object is where you configure most of your app. Specify fields, UI elements and requirements for your app. '
												}
												<ReactTemplates.elevio
													text="Learn more."
													articleId="416"
													sidebar="true"
												/>
											</label>
											<label>
												<ReactTemplates.elevio
													text="How to add fields"
													articleId="417"
													sidebar="true"
												/>
												<br />
												<ReactTemplates.elevio
													text="How to add UI elements"
													articleId="418"
													sidebar="true"
												/>
												<br />
												<ReactTemplates.elevio
													text="How to add requirements"
													articleId="419"
													sidebar="true"
												/>
											</label>
										</div>
									</div>

									<div className="row">
										<div className="col-sm-12">
											<label>
												{
													'You can serve your config externally at //integrationConfig or specify it here'
												}
											</label>
											<ReactTemplates.upFilters.components.toggle
												checked={integration.externalConfig}
												onChange={self.toggleChange.bind(self, 'externalConfig')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>
												{'Use external config '}
												<ReactTemplates.elevio articleId="1023" />
											</label>
										</div>
									</div>

									<div
										className="row"
										style={{ display: integration.externalConfig ? 'none' : 'block' }}
									>
										<div className="col-md-12">
											<label>{self.lang.configJson}</label>
											<div
												ref={self.setRef.bind(self, '_jsonEditor')}
												style={{ height: '600px' }}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className="admin-table">
								<div className="admin-table-top">
									<div className="row">
										<div className="col-sm-12">
											<span className="admin-table-title">{'App initiations'}</span>
											<label>
												{'Configure when you want Upsales to do requests to your app.'}
											</label>
										</div>
									</div>

									<div className="row">
										<div className="col-sm-12">
											<span className="admin-table-title" style={{ fontSize: '14px' }}>
												{'When a user checks status '}
												<ReactTemplates.elevio articleId="518" />
											</span>
											<label>
												{
													'When a user clicks the "Status & Troubleshooting" tab on your app page. '
												}
												<ReactTemplates.elevio text="Learn more." articleId="518" />
											</label>
										</div>
										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked="true"
												className="toggle-bright-blue"
												disabled="true"
											/>{' '}
											<label style={{ display: 'inline-block' }}>
												{'When a user checks status '}
											</label>
										</div>
									</div>

									<div className="row">
										<div className="col-sm-12">
											<span className="admin-table-title" style={{ fontSize: '14px' }}>
												{'When app is activated or saved '}
												<ReactTemplates.elevio articleId="420" />
											</span>
											<label>
												{
													'Your app will be called when a user activates your app or edits its configuration. '
												}
												<ReactTemplates.elevio text="Learn more." articleId="420" />
											</label>
										</div>
										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked="true"
												className="toggle-bright-blue"
												disabled="true"
											/>{' '}
											<label style={{ display: 'inline-block' }}>
												{'When app is activated or saved'}
											</label>
										</div>
									</div>

									<div className="row">
										<div className="col-sm-12">
											<span className="admin-table-title" style={{ fontSize: '14px' }}>
												{'Manually via Upsales API '}
												<ReactTemplates.elevio articleId="421" />
											</span>
											<label>
												{
													'Your app can be called using Upsales API and your apps verification token. '
												}
												<ReactTemplates.elevio text="Learn more." articleId="421" />
											</label>
										</div>
										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked="true"
												className="toggle-bright-blue"
												disabled="true"
											/>{' '}
											<label style={{ display: 'inline-block' }}>
												{'Manually via Upsales API'}
											</label>
										</div>
									</div>

									<div className="row">
										<div className="col-sm-12">
											<span className="admin-table-title" style={{ fontSize: '14px' }}>
												{'Scheduled run '}
												<ReactTemplates.elevio articleId="422" />
											</span>
											<label>
												{'Your app will be called constantly with a few minutes delay. '}
												<ReactTemplates.elevio text="Learn more." articleId="422" />
											</label>
										</div>
										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.job}
												onChange={self.initToggleChange.bind(self, 'job')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{'Scheduled run'}</label>
										</div>
									</div>

									<div className="row">
										<div className="col-sm-12">
											<span className="admin-table-title" style={{ fontSize: '14px' }}>
												{'Frameworks '}
											</span>
											<label>
												{
													'Frameworks integrates with Upsales on a feature level with multiple initiations and UI elements for the specific feature. Click on each framework to learn more. '
												}
											</label>
										</div>
										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.phone}
												onChange={self.initToggleChange.bind(self, 'phone')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>
												<ReactTemplates.elevio
													text="Upsales Phone"
													articleId="423"
													sidebar="true"
												/>
											</label>
										</div>
										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.esign}
												onChange={self.initToggleChange.bind(self, 'esign')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>
												<ReactTemplates.elevio text="Esign" articleId="433" sidebar="true" />
											</label>
										</div>
										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.dataSource}
												onChange={self.initToggleChange.bind(self, 'dataSource')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>
												<ReactTemplates.elevio
													text="Data source"
													articleId="856"
													sidebar="true"
												/>
											</label>
										</div>
										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.sms}
												onChange={self.initToggleChange.bind(self, 'sms')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{'SMS'}</label>
										</div>
										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.webinar}
												onChange={self.initToggleChange.bind(self, 'webinar')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{'Webinar'}</label>
										</div>
										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.import}
												onChange={self.initToggleChange.bind(self, 'import')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{'Import'}</label>
											<Text italic size="sm" color="grey-10">
												{'Import apps will only be visible during onboarding'}
											</Text>
										</div>
										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.fileStorage}
												onChange={self.initToggleChange.bind(self, 'fileStorage')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{'File storage'}</label>
										</div>
										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.calendar}
												onChange={self.initToggleChange.bind(self, 'calendar')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{'Calendar'}</label>
										</div>
										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.legacyCalendar}
												onChange={self.initToggleChange.bind(self, 'legacyCalendar')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{'Legacy calendars'}</label>
										</div>
										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.mail}
												onChange={self.initToggleChange.bind(self, 'mail')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{'Mail'}</label>
										</div>
										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.erp}
												onChange={self.initToggleChange.bind(self, 'erp')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{'ERP'}</label>
										</div>
										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.chatNotifications}
												onChange={self.initToggleChange.bind(self, 'chatNotifications')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{'Chat'}</label>
										</div>
									</div>

									<div className="row">
										<div className="col-sm-12">
											<span className="admin-table-title" style={{ fontSize: '14px' }}>
												{'Triggers '}
												<ReactTemplates.elevio articleId="425" />
											</span>
											<label>
												{'Trigger your app to run after certain actions are done by users. '}
												<ReactTemplates.elevio text="Learn more." articleId="425" />
											</label>
										</div>

										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.after_client_insert}
												onChange={self.initToggleChange.bind(self, 'after_client_insert')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{'Company was created'}</label>
										</div>

										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.after_client_update}
												onChange={self.initToggleChange.bind(self, 'after_client_update')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{'Company was updated'}</label>
										</div>

										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.after_client_delete}
												onChange={self.initToggleChange.bind(self, 'after_client_delete')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{'Company was deleted'}</label>
										</div>

										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.after_contact_insert}
												onChange={self.initToggleChange.bind(self, 'after_contact_insert')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{'Contact was created'}</label>
										</div>

										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.after_contact_update}
												onChange={self.initToggleChange.bind(self, 'after_contact_update')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{'Contact was updated'}</label>
										</div>

										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.after_contact_delete}
												onChange={self.initToggleChange.bind(self, 'after_contact_delete')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{'Contact was deleted'}</label>
										</div>

										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.after_order_insert}
												onChange={self.initToggleChange.bind(self, 'after_order_insert')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>
												{'Order or Opportunity was created'}
											</label>
										</div>

										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.after_order_update}
												onChange={self.initToggleChange.bind(self, 'after_order_update')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>
												{'Order or Opportunity was updated'}
											</label>
										</div>

										{Tools.FeatureHelper.hasSoftDeployAccess('APP_FRAMEWORK_ON_ORDER_EDIT') ? (
											<div className="col-sm-12">
												<ReactTemplates.upFilters.components.toggle
													checked={integration.inits.after_order_edit}
													onChange={self.initToggleChange.bind(self, 'after_order_edit')}
													className="toggle-bright-blue"
												/>{' '}
												<label style={{ display: 'inline-block' }}>
													{'Order or Opportunity was edited'}
												</label>
												{Tools.FeatureHelper.hasSoftDeployAccess('INPUT_DEBOUNCE_SETTING') &&
												integration.inits.after_order_edit ? (
													<div className="row" style={{ marginLeft: 8, marginTop: -8 }}>
														<div className="col-sm-9">
															<span
																className="admin-table-title"
																style={{ lineHeight: '12px', fontSize: 14 }}
															>
																{'Input debounce for edit fields'}
															</span>
															<label>
																{`Field inputs will wait for the set debounce time after changes have been made before sending a request to your application.
													 Changing the input during wait time will reset the timer. This lets you control how often your app is called when a user changes input value.
													  A lower value will give faster responses but may lead to incorrect values if multiple requests are sent at the same time. Enter desired debounce in milliseconds 
													  (accepted values are between 500-3000 ms).`}
															</label>
															<NumberInput
																min={500}
																max={3000}
																value={integration.inputDebounceInMs || undefined}
																allowEmpty
																onBlur={v =>
																	self.onFieldChange('inputDebounceInMs', {
																		target: { value: v }
																	})
																}
															/>
														</div>
													</div>
												) : null}
											</div>
										) : null}

										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.after_order_delete}
												onChange={self.initToggleChange.bind(self, 'after_order_delete')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>
												{'Order or Opportunity was deleted'}
											</label>
										</div>

										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.after_agreement_insert}
												onChange={self.initToggleChange.bind(self, 'after_agreement_insert')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>
												{'Recurring order was created'}
											</label>
										</div>

										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.after_agreement_update}
												onChange={self.initToggleChange.bind(self, 'after_agreement_update')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>
												{'Recurring order was updated'}
											</label>
										</div>

										{Tools.FeatureHelper.hasSoftDeployAccess(
											'APP_FRAMEWORK_ON_SUBSCRIPTION_EDIT'
										) ? (
											<div className="col-sm-12">
												<ReactTemplates.upFilters.components.toggle
													checked={integration.inits.after_agreement_edit}
													onChange={self.initToggleChange.bind(self, 'after_agreement_edit')}
													className="toggle-bright-blue"
												/>{' '}
												<label style={{ display: 'inline-block' }}>
													{'Recurring order was edited'}
												</label>
											</div>
										) : null}

										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.after_agreement_delete}
												onChange={self.initToggleChange.bind(self, 'after_agreement_delete')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>
												{'Recurring order was deleted'}
											</label>
										</div>

										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.after_activity_insert}
												onChange={self.initToggleChange.bind(self, 'after_activity_insert')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{'Activity was created'}</label>
										</div>

										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.after_activity_update}
												onChange={self.initToggleChange.bind(self, 'after_activity_update')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{'Activity was updated'}</label>
										</div>

										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.after_activity_delete}
												onChange={self.initToggleChange.bind(self, 'after_activity_delete')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{'Activity was deleted'}</label>
										</div>

										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.after_appointment_insert}
												onChange={self.initToggleChange.bind(self, 'after_appointment_insert')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>
												{'Appointment was created'}
											</label>
										</div>

										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.after_appointment_update}
												onChange={self.initToggleChange.bind(self, 'after_appointment_update')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>
												{'Appointment was updated'}
											</label>
										</div>

										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.after_appointment_delete}
												onChange={self.initToggleChange.bind(self, 'after_appointment_delete')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>
												{'Appointment was deleted'}
											</label>
										</div>
									</div>

									<div className="row">
										<div className="col-sm-12">
											<span className="admin-table-title" style={{ fontSize: '14px' }}>
												{'External validation'}
											</span>
											<label>
												{'Validate data before it is saved to Upsales. '}
												<ReactTemplates.elevio text="Learn more." articleId="425" />
											</label>
										</div>

										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.before_order_save}
												onChange={self.initToggleChange.bind(self, 'before_order_save')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{'Order'}</label>
										</div>

										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.before_agreement_save}
												onChange={self.initToggleChange.bind(self, 'before_agreement_save')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{this.lang.agreement}</label>
										</div>

										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.before_client_save}
												onChange={self.initToggleChange.bind(self, 'before_client_save')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{this.lang.client}</label>
										</div>
									</div>

									<div className="row">
										<div className="col-sm-12">
											<span className="admin-table-title" style={{ fontSize: '14px' }}>
												{'App sync status'}
											</span>
											<label>
												{
													"Show the current app status in lists and modals for your end users. Before enabling, make sure you're handling app callbacks."
												}
												<ReactTemplates.elevio text="Learn more." articleId="676" />
											</label>
										</div>
										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.show_field_sync}
												onChange={self.initToggleChange.bind(self, 'show_field_sync')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{this.lang.fieldSync}</label>
										</div>

										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.app_status_order}
												onChange={self.initToggleChange.bind(self, 'app_status_order')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{'Order/Opportunity'}</label>
										</div>

										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.inits.disable_products}
												onChange={self.initToggleChange.bind(self, 'disable_products')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>
												{this.lang.disableProducts}
											</label>
										</div>
										{Tools.FeatureHelper.hasSoftDeployAccess(
											Tools.FeatureHelper.Feature.PRICE_LISTS
										) &&
										Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PRICE_LISTS) ? (
											<div className="col-sm-12">
												<ReactTemplates.upFilters.components.toggle
													checked={integration.inits.limit_pricelists}
													onChange={self.initToggleChange.bind(self, 'limit_pricelists')}
													className="toggle-bright-blue"
												/>{' '}
												<label style={{ display: 'inline-block' }}>
													{this.lang.limitPriceLists}
												</label>
											</div>
										) : null}
									</div>

									{integration.authenticationKey && integration.authenticationKey.length ? (
										<div className="row">
											<div className="col-sm-12">
												<span className="admin-table-title" style={{ fontSize: '14px' }}>
													{this.lang.authenticationKey}
												</span>
											</div>
											<div className="col-sm-12">
												<input
													autoComplete="integration-auth-key"
													type="text"
													readOnly={true}
													className="form-control"
													value={integration.authenticationKey}
												/>
											</div>
										</div>
									) : null}
								</div>
							</div>

							<div className="admin-table">
								<div className="admin-table-top">
									<span className="admin-table-title">{'App Status'}</span>

									<div className="row">
										<div className="col-sm-12">
											<span className="admin-table-title" style={{ fontSize: '14px' }}>
												{'Active '}
												<ReactTemplates.elevio articleId="426" />
											</span>
											<label>
												{'Activate your app to make it available to your team. '}
												<ReactTemplates.elevio text="Learn more." articleId="426" />
											</label>
										</div>
										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.active}
												onChange={self.toggleChange.bind(self, 'active')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{self.lang.active}</label>
										</div>
									</div>

									<div className="row">
										<div className="col-sm-12">
											<span className="admin-table-title" style={{ fontSize: '14px' }}>
												{'Visible '}
											</span>
											<label>
												{'App can be accessed by others via the app URL. '}
												<Link
													href={
														'https://support.upsales.com/hc/en-us/articles/8444812914833-A-How-to-publish-apps-in-the-Upsales-app-directory'
													}
													target={'_blank'}
												>
													{'Learn more.'}
												</Link>
											</label>
										</div>
										<div className="col-sm-12">
											<ReactTemplates.upFilters.components.toggle
												checked={integration.visible}
												onChange={self.toggleChange.bind(self, 'visible')}
												className="toggle-bright-blue"
											/>{' '}
											<label style={{ display: 'inline-block' }}>{'Visible'}</label>
										</div>
									</div>

									{!Tools.FeatureHelper.hasSoftDeployAccess(
										'APP_DIRECTORY_ABLE_SET_PREMIUM'
									) ? null : (
										<div className="row">
											<div className="col-sm-12">
												<span className="admin-table-title" style={{ fontSize: '14px' }}>
													{'Is premium app'}
												</span>
												<label>
													{'Check the box below to specify that this is a premium app.'}
												</label>
											</div>
											<div className="col-sm-12">
												<ReactTemplates.upFilters.components.toggle
													checked={!!integration.price}
													onChange={self.premiumChange}
													className="toggle-bright-blue"
												/>{' '}
												<label style={{ display: 'inline-block' }}>{'Is premium'}</label>
											</div>
										</div>
									)}

									{!Tools.FeatureHelper.hasSoftDeployAccess('APP_PUBLIC_PUBLISHER') ? null : (
										<>
											<div className="row">
												<div className="col-sm-12">
													<span className="admin-table-title" style={{ fontSize: '14px' }}>
														{'Public '}
														<ReactTemplates.elevio articleId="427" />
													</span>
													<label>
														{
															'Set your app to public to make it available in the Upsales App Directory. '
														}
														<ReactTemplates.elevio text="Learn more." articleId="427" />
													</label>
												</div>
												<div className="col-sm-12">
													<ReactTemplates.upFilters.components.toggle
														checked={integration.public}
														onChange={self.toggleChange.bind(self, 'public')}
														className="toggle-bright-blue"
													/>{' '}
													<label style={{ display: 'inline-block' }}>
														{self.lang.public}
													</label>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-12">
													<span className="admin-table-title" style={{ fontSize: '14px' }}>
														{'Hide for new '}
													</span>
													<label>
														{'Hide app for customers who do not already have it enabled. '}
													</label>
												</div>
												<div className="col-sm-12">
													<ReactTemplates.upFilters.components.toggle
														checked={integration.hideForNew}
														onChange={self.toggleChange.bind(self, 'hideForNew')}
														className="toggle-bright-blue"
													/>{' '}
													<label style={{ display: 'inline-block' }}>
														{self.lang.hideForNew}
													</label>
												</div>
											</div>
										</>
									)}
								</div>
							</div>
						</fieldset>
					</form>
				</div>
			</div>
		);
	}
});
