import AdminHeader from 'Components/Admin/AdminHeader';
import { Button } from '@upsales/components';
ReactTemplates.admin.scripts = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			scripts: t('admin.scripts'),
			activeScripts: t('admin.activeScripts'),
			inactiveScripts: t('admin.inactiveScripts'),
			scriptsInfo: t('admin.scriptsInfo'),
			description: t('default.description'),
			newScript: t('admin.newScript'),
			noScripts: t('admin.noScripts'),
			type: t('default.type'),
			triggers: {
				order_edit: t('admin.script.order_edit'),
				order_save: t('admin.script.order_save'),
				activity_edit: t('admin.script.activity_edit'),
				activity_save: t('admin.script.activity_save'),
				client_open: t('admin.script.client_open'),
				client_contacts_open: t('admin.script.client_contacts_open'),
				client_dashboard_open: t('admin.script.client_dashboard_open'),
				client_save: t('admin.script.client_save'),
				client_edit: t('admin.script.client_edit'),
				contact_open: t('admin.script.contact_open'),
				agreement_edit: t('admin.script.agreement_edit'),
				agreement_save: t('admin.script.agreement_save'),
				salesboard_open: t('admin.script.salesboard_open'),
				contact_save: t('admin.script.contact_save'),
				upsales_init: t('admin.script.upsales_init'),
				appointment_edit: t('admin.script.appointment_edit'),
				appointment_save: t('admin.script.appointment_save'),
				contact_edit: t('admin.script.contact_edit'),
				flash_edit: t('admin.script.flash_edit'),
				flash_save: t('admin.script.flash_save'),
				mail_edit: t('admin.script.mail_edit')
			}
		};
	},
	getInitialState: function () {
		return {
			sortInactive: 'description',
			ascInactive: true,
			sort: 'description',
			asc: true
		};
	},
	deleteScript: function (key, e) {
		e.stopPropagation();
		this.props.rootData.deleteScript(key);
	},
	getWhen: function (type) {
		return this.lang.triggers[type] || type;
	},
	setSort: function (sort, active) {
		if (active) {
			this.setState({
				sort: sort,
				asc: sort === this.state.sort ? !this.state.asc : true
			});
		} else {
			this.setState({
				sortInactive: sort,
				ascInactive: sort === this.state.sortInactive ? !this.state.ascInactive : true
			});
		}
	},

	renderScriptTable: function (active, scripts) {
		var sort = active ? this.state.sort : this.state.sortInactive;
		var asc = active ? this.state.asc : this.state.ascInactive;
		return (
			<div className="admin-table">
				<div className="admin-table-top">
					<span className="admin-table-title">
						{active ? this.lang.activeScripts : this.lang.inactiveScripts}
					</span>
					{active ? (
						<div className="pull-right">
							{!Tools.FeatureHelper.hasSoftDeployAccess('DISABLE_NEW_SCRIPTS_ADMIN') ? (
								<Button onClick={this.props.rootData.editScript.bind(this, null)} size="sm">
									{this.lang.newScript}
								</Button>
							) : null}
						</div>
					) : null}
				</div>

				{scripts.length ? (
					<table>
						<thead>
							<tr>
								<th
									className={'sortable' + (sort === 'description' ? ' active' : '')}
									onClick={this.setSort.bind(this, 'description', active)}
								>
									{this.lang.description}{' '}
									{sort === 'description' ? (
										<i className={asc ? 'fa fa-sort-alpha-asc' : 'fa fa-sort-alpha-desc'} />
									) : (
										<i className="fa fa-sort" />
									)}
								</th>
								<th
									className={'sortable' + (sort === 'type' ? ' active' : '')}
									onClick={this.setSort.bind(this, 'type', active)}
								>
									{this.lang.type}{' '}
									{sort === 'type' ? (
										<i className={asc ? 'fa fa-sort-alpha-asc' : 'fa fa-sort-alpha-desc'} />
									) : (
										<i className="fa fa-sort" />
									)}
								</th>
								<th style={{ width: '60px' }}></th>
							</tr>
						</thead>
						<tbody>{scripts}</tbody>
					</table>
				) : null}
				{!scripts.length ? <div className="table-no-result">{this.lang.noScripts}</div> : null}
			</div>
		);
	},

	sortTable: function (attr) {
		var self = this;
		return function (script) {
			if (attr === 'type') {
				return self.getWhen(script.type);
			}

			return script[attr];
		};
	},

	renderTableRow: function (script, i) {
		var when = this.getWhen(script.type);
		var className = 'clickable';
		if (!script.active) {
			className += ' inactive-row';
		}
		return (
			<tr key={'script-' + i} className={className} onClick={this.props.rootData.editScript.bind(null, script)}>
				<td>{script.description}</td>
				<td>{when}</td>
				<td className="admin-row-tools-wrap">
					<div className="admin-row-tools">
						<button
							className="up-btn btn-link btn-grey delete-trigger"
							onClick={this.deleteScript.bind(this, script)}
						>
							<b className="fa fa-trash-o"></b>
						</button>
					</div>
				</td>
			</tr>
		);
	},

	render: function () {
		var rootData = this.props.rootData;

		var scripts = _.chain(rootData.pageData.scripts).filter('active').sortBy(this.sortTable(this.state.sort));

		if (!this.state.asc) {
			scripts = scripts.reverse();
		}

		scripts = scripts.map(this.renderTableRow).value();

		var inactiveScripts = _.chain(rootData.pageData.scripts)
			.filter({ active: false })
			.sortBy(this.sortTable(this.state.sortInactive));

		if (!this.state.ascInactive) {
			inactiveScripts = inactiveScripts.reverse();
		}

		inactiveScripts = inactiveScripts.map(this.renderTableRow).value();

		return (
			<div id="admin-page-scripts">
				<AdminHeader
					title={this.lang.scripts}
					description={this.lang.scriptsInfo}
					image="scripts.svg"
					articleId={455}
				/>

				<div id="admin-content">
					{this.renderScriptTable(true, scripts)}
					{this.renderScriptTable(false, inactiveScripts)}
				</div>
			</div>
		);
	}
});
