import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import _ from 'lodash';
import T from 'Components/Helpers/translate';
import ProductResource from 'App/babel/resources/Product';
/**
/**
 * MAX_CACHED_PRODUCTS sets the limit for locally cacheable products.
 * Products exceeding this limit require server fetching.
 *
 * Usage:
 * - Products are loaded from cache if their count is <= this limit.
 * - Server fetch is required if the count exceeds this limit.
 * 
 * Todo: Replace all hardcoded '4000' values with this constant.
 */
export const MAX_CACHED_PRODUCTS = 4000;

const currencyFormat = (value, curr) => Tools.$filter('currencyFormat')(value, curr);

/*	
	Could sort on name perhaps but order modal does not do that now so I will skipp it
*/
export const getProductCategoryTree = categoryModifier => {
	const AppService = getAngularModule('AppService');
	// AppService actually dont return a copy here so i will clone as we will mutate the objects
	let productCategories = _.cloneDeep(AppService.getProductCategories());

	if (categoryModifier && typeof categoryModifier === 'function') {
		productCategories = productCategories.map(categoryModifier);
	}

	const productCategorMap = productCategories.reduce((res, productCategory) => {
		res[productCategory.id] = productCategory;
		productCategory.children = [];
		return res;
	}, {});

	productCategories.forEach(productCategory => {
		const parent = productCategorMap[productCategory.parentId];
		if (parent) {
			parent.children.push(productCategory);
		}
	});
	return productCategories.filter(productCategory => !productCategory.parentId);
};

/**
 * Updates product bundles containing a specified updated product within a list of products.
 *
 * @param {Array} products - The array of products to be checked and potentially updated.
 * @param {Product} updatedProduct - The product that has been updated.
 * @returns {Promise<Array>} The updated array of products.
 */
export const getUpdatedProductBundles = async (products, updatedProduct) => {
	if (updatedProduct.bundlePriceAdjustment) {
		return products;
	}

	const { data: relatedBundles } = await ProductResource.find({
		'bundle.productId': updatedProduct.id,
		usePriceLists: true
	});

	if (!relatedBundles?.length) {
		return products;
	}

	return products.map(p => {
		const bundle = relatedBundles.find(b => b.id === p.id);
		if (bundle) {
			return bundle;
		}
		return p;
	});
};

export const getProductPriceStr = (product, currency = 'SEK', defaultPriceListId = 1) => {
	if (!product.tiers || !product.tiers.length) {
		return currencyFormat(product.listPrice || 0, currency);
	}

	const defaultTiers = product.tiers.filter(tier => tier.priceListId === defaultPriceListId);
	if (!defaultTiers.length) {
		return `${T('default.from')} ${currencyFormat(0, currency)}`;
	}

	const start = defaultTiers[0];
	const startPriceInMC = _.find(start.currencies, { currency });
	if (defaultTiers.length === 1 && startPriceInMC) {
		return `${T('default.from')} ${currencyFormat(startPriceInMC.value, currency)}`;
	}

	const end = defaultTiers[defaultTiers.length - 1];
	const endPriceInMC = _.find(end.currencies, { currency });

	if (startPriceInMC && endPriceInMC) {
		return `${currencyFormat(Math.min(startPriceInMC.value, endPriceInMC.value), currency)} - ${currencyFormat(
			Math.max(startPriceInMC.value, endPriceInMC.value),
			currency
		)}`;
	}

	return null;
};
