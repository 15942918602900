'use strict';
import logError from 'App/babel/helpers/logError';

angular.module('domain.advancedSearch').directive('customfieldSearchSection', [
	'FeatureHelper',
	function (FeatureHelper) {
		return {
			restrict: 'A',
			templateUrl: require('App/upsales/domain/advanced-search/directives/customfieldSearchSection.tpl.html?file'),
			scope: {
				Filters: '=filters',
				onChange: '&',
				onKeyup: '&',
				fieldsEntity: '@fields'
			},
			controller: [
				'$scope',
				'AppService',
				'$attrs',
				'FilterHelper',
				function ($scope, AppService, $attrs, FilterHelper) {
					$scope.inited = false;

					$scope.warnings = {};

					const resetWarningsForField = fieldId => {
						$scope.warnings[fieldId] = {
							tooLongValue: false,
							tooManyWords: false
						};
					};

					const getCustomFieldFilterValue = fieldId => {
						const customFieldFilter = $scope.Filters[`Custom_${fieldId}`];
						const customFieldFilterHasValue = Array.isArray(customFieldFilter.value)
							? customFieldFilter.value.length
							: customFieldFilter.value;
						if (!customFieldFilter || !customFieldFilterHasValue) {
							resetWarningsForField(fieldId);
							return null;
						}
						return customFieldFilter.value;
					};

					const validateFieldValue = (fieldId, fieldValue) => {
						if (!$scope.warnings[fieldId]) {
							resetWarningsForField(fieldId);
						}

						if (fieldValue.length > 256) {
							// matches backend limit in base/src/core/filter/query/textUtils3.js
							$scope.warnings[fieldId].tooLongValue = true;
						} else if ($scope.warnings[fieldId].tooLongValue === true) {
							$scope.warnings[fieldId].tooLongValue = false;
						}
						if (fieldValue.split(/\s+/g).length > 15) {
							// matches backend limit in base/src/core/filter/query/textUtils3.js
							$scope.warnings[fieldId].tooManyWords = true;
						} else if ($scope.warnings[fieldId].tooManyWords === true) {
							$scope.warnings[fieldId].tooManyWords = false;
						}
					};

					const validateField = function (field) {
						if (FeatureHelper.hasSoftDeployAccess('QUERY_STRING_LIMIT_HANDLING')) {
							if (field.datatype === 'Select') {
								const customFieldFilterValue = getCustomFieldFilterValue(field.id);
								if (!customFieldFilterValue || !Array.isArray(customFieldFilterValue)) {
									return;
								}

								for (const filterValue of customFieldFilterValue) {
									validateFieldValue(field.id, filterValue);
								}
							} else if (['Text', 'String'].includes(field.datatype)) {
								const customFieldFilterValue = getCustomFieldFilterValue(field.id);
								if (!customFieldFilterValue) {
									return;
								}
								validateFieldValue(field.id, customFieldFilterValue);
							}
						}
					};

					$scope.ngChange = function () {
						validateField(this.field);

						if ($scope.onChange) {
							$scope.onChange();
						}
					};

					$scope.ngKeyup = function (e) {
						if ($scope.onKeyup) {
							$scope.onKeyup({ $event: e });
						}
					};

					var init = function () {
						$scope.customFields = _.filter(AppService.getCustomFields($scope.fieldsEntity), function (cf) {
							return cf.visible && cf.$hasAccess;
						});

						// remove pre-selected values from custom Select
						$scope.customFields = $scope.customFields.map(cf => {
							if (cf.datatype !== 'Select') {
								validateField(cf);
								return cf;
							}
							cf.dropdownDefault = '';
							validateField(cf);
							return cf;
						});

						$scope.titleStr = 'default.otherInfo';
						if ($attrs.title) {
							$scope.titleStr = $attrs.title;
						}

						$scope.hide =
							$scope.fieldsEntity === 'account' &&
							!FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANIES_AND_CONTACTS);

						$scope.inited = true;
					};

					$scope.isActive = function (filter) {
						if (!filter) {
							return false;
						}
						const filterConfig = {
							type: 'custom',
							filterName: filter.filterName,
							$field: { datatype: filter.dataType }
						};
						return !FilterHelper.isInactiveValue(filter, $scope.fieldsEntity, filterConfig);
					};

					AppService.loadedPromise
						.then(function () {
							var unWatchInit = $scope.$watch('Filters', function (filters) {
								if (filters !== undefined) {
									unWatchInit();
									init();
								}
							});
						})
						.catch(error => logError(error, 'Failed to load AppService'));
				}
			]
		};
	}
]);
