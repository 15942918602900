import React from 'react';
import PropTypes from 'prop-types';
import { Icon, EllipsisTooltip, Tooltip } from '@upsales/components';
import AdminHeader from './AdminHeader';
import openModal from 'App/services/Modal';

class Fields extends React.Component {
	static propTypes = {
		rootData: PropTypes.object.isRequired
	};

	state = {
		datatypes: [
			{ type: 'String', icon: 'A' },
			{ type: 'Date', icon: <i className="fa fa-calendar" /> },
			{ type: 'Integer', icon: '42', class: 'icon number' },
			{ type: 'Boolean', icon: 'Y/N', class: 'icon yn' },
			{ type: 'Percent', icon: <i className="fa fa-percent" /> },
			{ type: 'Time', icon: <i className="fa fa-clock-o" /> },
			{ type: 'Currency', icon: <i className="fa fa-usd" /> },
			{ type: 'Select', icon: <i className="fa fa-list-ul" /> },
			{ type: 'Email', icon: <i className="fa fa-at" /> },
			{ type: 'Link', icon: <i className="fa fa-link" /> },
			{ type: 'Text', icon: <i className="fa fa-pencil" /> },
			{ type: 'Discount', icon: '25%', class: 'icon number' },
			{ type: 'Calculation', icon: <Icon name="calculator" /> },
			{ type: 'Users', icon: <i className="fa fa-user" /> },
			{ type: 'User', icon: <i className="fa fa-user" /> }
		]
	};

	componentDidMount() {
		var self = this;
		var table = jQuery(this._tableBody);
		var oldIndex;

		var options = {
			containment: 'parent',
			cursor: 'move',
			disabled: false,
			scroll: false,
			axis: 'y',
			items: '> .admin-table-row.custom-fields',
			handle: '.is-draggable',
			tolerance: 'pointer',
			start: function (e, ui) {
				oldIndex = ui.item.index();
			},
			sort: function () {},
			stop: function (e, ui) {
				var newIndex = ui.item.index();

				if (oldIndex !== newIndex) {
					oldIndex = oldIndex - 1;
					newIndex = newIndex - 1;

					self.updateSortIds(oldIndex, newIndex);
				}
			}
		};

		if (table.sortable) {
			table.sortable(options).disableSelection();
		}
	}

	UNSAFE_componentWillMount() {
		var t = Tools.$translate;
		var type = this.props.rootData.pageData.customFieldType;
		var AppService = Tools.AppService;

		this.lang = {
			fields: t('default.field'),
			standardfields: t('admin.standardfields'),
			addField: t('soliditet.addField'),
			description: t('admin.fields.description'),
			allFields: t('default.allFields'),
			all: t('default.all'),
			none: t('default.none'),
			title: t('default.title').toUpperCase(),
			type: t('default.type').toUpperCase(),
			permission: t('default.permissionToRole').toUpperCase(),
			default: t('default.default'),
			required: t('form.required').toUpperCase(),
			addresses: t('admin.fields.addresses'),
			types: {
				String: t('admin.customfieldType.String'),
				Date: t('admin.customfieldType.Date'),
				Integer: t('admin.customfieldType.Integer'),
				Boolean: t('admin.customfieldType.Checkbox'),
				Percent: t('admin.customfieldType.Percent'),
				Time: t('admin.customfieldType.Time'),
				Currency: t('admin.customfieldType.Currency'),
				Select: t('admin.customfieldType.Select'),
				Email: t('admin.customfieldType.Email'),
				Link: t('admin.customfieldType.Link'),
				Text: t('admin.customfieldType.Text'),
				Discount: t('admin.customfieldType.Discount'),
				Calculation: t('admin.customfieldType.Calculation'),
				Users: t('default.users'),
				User: t('column.userId')
			},
			standard: {
				Zipcode: t('address.zip'),
				State: t('address.state'),
				Phone: t('default.phone'),
				Fax: t('default.fax'),
				Www: t('default.webpage'),
				User: t('default.accountManager'),
				Parent: t('default.parentCompany'),
				Notes: t('default.notes'),
				Client: t('default.client'),
				Title: t('default.title'),
				Cellphone: t('default.cellPhone'),
				Salutation: t('default.salutation'),
				Email: t('default.email'),
				Date: t('default.date'),
				Time: t('default.time'),
				Description: t('default.description'),
				Project: t('default.project'),
				Type: t('default.type'),
				Adress: t('tag.client.address.post'),
				DeliveryAddress: t('tag.client.address.delivery'),
				InvoiceAddress: t('tag.client.address.invoice'),
				OtherAddress: t('tag.client.address.other'),
				VisitAddress: t('tag.client.address.visit')
			},
			deleteWarning1: t('admin.fields.deleteWarning1'),
			deleteWarning2: t('admin.fields.deleteWarning2'),
			confirm: t('default.confirm'),
			deleteField: t('soliditet.matcher.actionDelete') + ' ' + t('default.field').toLowerCase(),
			cannotDeleteIntegrationField: t('admin.fields.cannotDeleteIntegrationField'),
			ok: t('default.confirm')
		};

		if (type.indexOf('userDefined') !== -1) {
			var metadata = AppService.getMetadata();
			var udos = metadata.params.UserDefinedObject;

			var id = parseInt(type[type.length - 1]);
			var udo = _.find(udos, { id: id });

			if (!udo) {
				this.title = '';
			}
			this.title = udo.name + ' ' + this.lang.fields.toLowerCase();
		} else if (type === 'orderrow') {
			this.title = t('filters.columns.orderRow');
		} else {
			this.title = t('filters.columns.' + type);
		}

		this.standardFieldWidths = [
			/* name */ 'calc(60% - 50px)',
			/* type */ 'calc(40% - 50px)',
			/* required */ '100px'
		];

		this.fieldWidths = [
			/* sort */ '35px',
			/* name */ 'calc(50% - 130px)',
			/* type */ '150px',
			/* roles */ 'calc(50% - 160px)',
			/* required */ '45px',
			/* delete */ '60px'
		];

		this.hasTooltipFeature = Tools.FeatureHelper.isAvailable(
			Tools.FeatureHelper.Feature.ADMIN_STANDARD_FIELD_TOOLTIP
		);
		this.hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
	}

	setRef = (name, ref) => {
		this[name] = ref;
	};

	onNewField = () => {
		this.openModal(null);
	};

	openModal = field => {
		var $upModal = Tools.$upModal;
		var rootData = this.props.rootData;

		// eslint-disable-next-line promise/catch-or-return
		$upModal
			.open('editCustomFields', {
				field: field,
				customFields: this.props.rootData.pageData.customFields,
				type: this.props.rootData.pageData.customFieldType,
				title: this.title
			})
			.then(function (field) {
				rootData.onChange('customField', field);
			});
	};

	onClickRow = field => {
		this.openModal(field);
	};

	removeItem = (field, event) => {
		var self = this;
		event.stopPropagation();
		event.nativeEvent.stopImmediatePropagation();

		var modalBody = self.lang.deleteWarning1 + ' ' + field.name + '?\n' + self.lang.deleteWarning2;

		const alertConfirmOptions = {
			type: 'confirm',
			id: 'confirm-delete-fields',
			body: modalBody,
			title: this.lang.deleteField,
			confirmationMode: 'text',
			confirmFn: self.props.rootData.onSave.bind(null, 'customField', 'delete', field)
		};

		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
			openModal('Alert', {
				...alertConfirmOptions,
				title: `${this.lang.confirm} ${this.lang.deleteField.toLowerCase()}`,
				onClose: confirmed => {
					if (confirmed) {
						alertConfirmOptions.confirmFn();
					}
				}
			});
		} else {
			Tools.$upModal.open('alert', alertConfirmOptions);
		}
	};

	translateField = standardField => {
		var $upModal = Tools.$upModal;
		$upModal.open('translateFields', {
			fieldName: standardField.name.toLowerCase()
		});
	};

	editStandardField = (standardField, fieldName) => {
		// Use edit custom field modal, for standard fields as well
		var $upModal = Tools.$upModal;
		var rootData = this.props.rootData;
		// eslint-disable-next-line promise/catch-or-return
		$upModal
			.open('editStandardFields', {
				field: standardField,
				name: fieldName
			})
			.then(function (field) {
				return rootData.onSave('standardField', 'save', field, field.name);
			});
	};

	onToggleDefault = (type, field, standardFieldName, value, e) => {
		e.stopPropagation();
		if (type === 'standardField') {
			field.required = value;
			this.props.rootData.onSave('standardField', 'save', field, standardFieldName);
		} else {
			field.obligatoryField = value ? 1 : 0;
			this.props.rootData.onSave('customField', 'save', field);
		}
	};

	updateSortIds = (oldIndex, newIndex) => {
		var customFields = this.props.rootData.pageData.proxyCustomFields;

		var newCustomFields = _.reduce(
			customFields,
			function (res, item, index) {
				if (index !== oldIndex) {
					if (index === newIndex) {
						if (oldIndex < newIndex) {
							res.push(item);
							res.push(customFields[oldIndex]);
						} else {
							res.push(customFields[oldIndex]);
							res.push(item);
						}
					} else {
						res.push(item);
					}
				}
				return res;
			},
			[]
		);

		this.props.rootData.onSave('customField', 'saveSort', newCustomFields);
	};

	iconMap = type => {
		if (type === undefined) {
			return '';
		}

		var icon = _.find(this.state.datatypes, function (d) {
			return d.type === type;
		});

		return <span className={icon && icon.class ? icon.class : 'icon'}>{icon && icon.icon}</span>;
	};

	getStandardFieldRow = (sf, name, isAddress) => {
		var type = this.props.rootData.pageData.customFieldType;
		var editable = sf.editable && this.hasTooltipFeature;
		var translatable = this.hasNewFields && sf.type === 'SelectTranslate';

		var onClick = editable ? this.editStandardField.bind(null, sf, this.lang.standard[name]) : null;
		onClick = translatable ? this.translateField.bind(null, sf) : onClick;

		var unwantedMapObj = {
			contact: ['Client'],
			activity: ['Date', 'Time'],
			appointment: ['Type', 'Client'],
			order: ['Project']
		};

		if (unwantedMapObj[type] && unwantedMapObj[type].indexOf(name) !== -1) {
			return null;
		}

		const fieldName = this.lang.standard[name];
		if (!fieldName) {
			return null;
		}

		var className = 'admin-table-row admin-table-standard-field-row';

		return (
			<div
				key={'standard-field-' + sf.id}
				className={
					className + (isAddress ? ' address-row' : '') + (editable || translatable ? ' clickable' : '')
				}
				onClick={onClick}
			>
				<div className="admin-table-cell" style={{ width: this.standardFieldWidths[0] }}>
					{fieldName}
				</div>
				<div className="admin-table-cell" style={{ width: this.standardFieldWidths[1] }}>
					{isAddress ? null : (
						<span style={{ fontSize: '12px', color: '#999', fontStyle: 'italic' }}>
							{this.lang.default}
						</span>
					)}
				</div>
				<div className="admin-table-cell" style={{ width: this.standardFieldWidths[2] }}>
					<ReactTemplates.checkbox
						className="toggle-md"
						disabled={sf.disabled}
						checked={sf.required}
						onChange={this.onToggleDefault.bind(this, 'standardField', sf, name)}
					/>
				</div>
			</div>
		);
	};

	getFieldRow = cf => {
		var self = this;
		var onClick = this.onClickRow.bind(this, cf);
		var checked = cf.obligatoryField === 0 ? false : true;

		var removeBtn = null;

		if (this.props.rootData.pageData.canAdd) {
			var removeItem = self.removeItem.bind(self, cf);
			removeBtn = (
				<Tooltip
					disabled={!cf.integrationDependency?.integration}
					title={this.lang.cannotDeleteIntegrationField}
				>
					<button
						type="button"
						disabled={cf.integrationDependency?.integration}
						className="up-btn btn-link btn-grey delete-trigger"
						onClick={removeItem}
					>
						<b className="fa fa-trash-o" />
					</button>
				</Tooltip>
			);
		}

		return (
			<div key={'custom-field-' + cf.id} className="admin-table-row clickable custom-fields">
				<div className="admin-table-cell color-grey is-draggable" style={{ width: this.fieldWidths[0] }}>
					<i className="fa fa-bars" />
				</div>
				<div className="admin-table-cell truncate" style={{ width: this.fieldWidths[1] }} onClick={onClick}>
					<EllipsisTooltip title={cf.name}>
						<div>{cf.name}</div>
					</EllipsisTooltip>
				</div>
				<div className="admin-table-cell" style={{ width: this.fieldWidths[2] }} onClick={onClick}>
					{<span className="icon-holder">{this.iconMap(cf.datatype)}</span>}
					{this.lang.types[cf.datatype] || '@ ' + cf.datatype}
				</div>
				<div className="admin-table-cell truncate" style={{ width: this.fieldWidths[3] }} onClick={onClick}>
					<ReactTemplates.admin.tableTokens additionalTokenSpace={35} items={cf.roles} />
				</div>
				<div className="admin-table-cell" style={{ width: this.fieldWidths[4] }}>
					{cf.datatype !== 'Calculation' ? (
						<ReactTemplates.checkbox
							className="toggle-md"
							checked={checked}
							onChange={this.onToggleDefault.bind(this, 'customField', cf, '')}
						/>
					) : null}
				</div>
				<div className="admin-table-cell admin-row-tools-wrap" style={{ width: this.fieldWidths[5] }}>
					<div className="admin-row-tools">{removeBtn}</div>
				</div>
			</div>
		);
	};

	render() {
		var self = this;
		var rootData = this.props.rootData;

		var fieldHeader = (
			<div key="header" className="admin-table-header">
				<div className="admin-table-cell" style={{ width: this.fieldWidths[0] }} />
				<div className={'admin-table-cell'} style={{ width: this.fieldWidths[1] }}>
					{self.lang.title}
				</div>
				<div className={'admin-table-cell'} style={{ width: this.fieldWidths[2] }}>
					{self.lang.type}
				</div>
				<div className={'admin-table-cell'} style={{ width: this.fieldWidths[3] }}>
					{self.lang.permission}
				</div>
				<div
					className={'admin-table-cell'}
					style={{ width: '100px', marginLeft: '-30px', padding: 0, textAlign: 'right' }}
				>
					{self.lang.required}
				</div>
			</div>
		);

		var standardFieldHeader = (
			<div key="header" className="admin-table-header">
				<div className={'admin-table-cell'} style={{ width: this.standardFieldWidths[0] }}>
					{self.lang.title}
				</div>
				<div className="admin-table-cell" style={{ width: this.standardFieldWidths[1] }}>
					{self.lang.type}
				</div>
				<div
					className={'admin-table-cell'}
					style={{ width: '100px', marginLeft: '-30px', padding: 0, textAlign: 'right' }}
				>
					{self.lang.required}
				</div>
			</div>
		);

		var tableRows = _.map(rootData.pageData.customFields, function (cf) {
			return self.getFieldRow(cf);
		});

		var standardFieldRows = [];

		if (rootData.pageLoading) {
			standardFieldRows.push(
				<div key={'loading-row'} className="admin-table-row loading-row">
					<div className="admin-table-cell" style={{ width: '100%' }}>
						<i className="fa fa-refresh fa-spin fa-3x fa-fw" />
					</div>
				</div>
			);
		} else {
			var separatedStandardFileds = _.reduce(
				rootData.pageData.standardFields,
				function (res, sf, name) {
					if (sf.editable) {
						// Editable fields
						sf.name = name;
						res.nonAddress.push(sf);
					} else if ([2, 3, 4, 11, 12, 13, 14].indexOf(sf.id) === -1) {
						// Non address fields
						sf.name = name;
						res.nonAddress.push(sf);
					} else if ([2, 11, 12, 13, 14].indexOf(sf.id) > -1) {
						// Address fields
						sf.name = name;
						res.addresses.push(sf);
					}
					return res;
				},
				{ nonAddress: [], addresses: [] }
			);

			if (this.props.rootData.pageData.standardFieldType === 'Client') {
				separatedStandardFileds.nonAddress.unshift({
					id: -1,
					name: self.lang.addresses,
					required: false
				});
			}

			standardFieldRows = _.reduce(
				separatedStandardFileds.nonAddress,
				function (res, sf) {
					if (sf.id === -1) {
						res.push(
							<div
								key={'standard-field-address'}
								className="admin-table-row clickable"
								onClick={function () {
									self.setState({ openAdress: !self.state.openAdress });
								}}
							>
								<div className="admin-table-cell" style={{ width: self.standardFieldWidths[0] }}>
									{self.lang.addresses}
								</div>
								<div className="admin-table-cell" style={{ width: self.standardFieldWidths[1] }}>
									<span style={{ fontSize: '12px', color: '#999', fontStyle: 'italic' }}>
										{self.lang.default}
									</span>
								</div>
								<div className="admin-table-cell" style={{ width: self.standardFieldWidths[2] }}>
									{self.state.openAdress ? (
										<i style={{ paddingLeft: 3 }} className="fa fa-chevron-up" />
									) : (
										<i style={{ paddingLeft: 3 }} className="fa fa-chevron-down" />
									)}
								</div>
							</div>
						);

						if (self.state.openAdress) {
							var addressRows = _.map(separatedStandardFileds.addresses, function (sf) {
								return self.getStandardFieldRow(sf, sf.name, true);
							});

							res.push(<div className="slide-down">{addressRows}</div>);
						}
					} else {
						const row = self.getStandardFieldRow(sf, sf.name);
						if (row) {
							res.push(row);
						}
					}
					return res;
				},
				[]
			);
		}

		var showStandardFileds = standardFieldRows.length > 0;

		var addBtn = null;
		if (rootData.pageData.canAdd) {
			addBtn = (
				<button
					onClick={self.onNewField}
					className="btn up-btn btn-bright-blue no-shadow btn-sm"
					data-test-id="admin-add-field"
				>
					{self.lang.addField}
				</button>
			);
		}

		return (
			<div id="admin-page-fields">
				<AdminHeader
					title={this.title}
					description={this.lang.description}
					image="fields.svg"
					articleId={597}
				/>
				<div id="admin-content">
					<div className="admin-table">
						<div className="admin-table-top">
							<span className="admin-table-title">{this.lang.fields}</span>
							<div className="pull-right">{addBtn}</div>
						</div>

						<div className="admin-table-body" ref={this.setRef.bind(this, '_tableBody')}>
							{fieldHeader}
							{tableRows}
						</div>
					</div>

					{showStandardFileds ? (
						<div className="admin-table">
							<div className="admin-table-top">
								<span className="admin-table-title">{this.lang.standardfields}</span>
							</div>
							<div className="admin-table-body">
								{standardFieldHeader}
								{standardFieldRows}
							</div>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export default Fields;
window.AdminFieldPage = Fields;
