import { globalTracker } from 'App/babel/helpers/Tracker';
import DashboardButtonGroup from 'Components/Account/AccountDashboard/DashboardButtonGroup';
import TimelineEntityNote from 'Components/TimelineRow/TimelineEntityNote';
import DashboardPinnedComments from 'Components/Account/AccountDashboard/DashboardPinnedComments';
import ContactTimelineCreateContact from 'App/components/HistoryLog/ContactHistoryLog/ContactTimelineCreateContact';
import NotesFilterButton from 'App/components/NotesFilter/NotesFilterButton';
import store from 'Store';
import { ActivityDateSorter } from 'Components/Helpers/Sorters';
import TimelineMail from 'Components/TimelineRow/TimelineMail';
import FlowContactsActive from 'Resources/FlowContactsActive';
import Comment from 'Resources/Comment';
import logError from 'Helpers/logError';

angular.module('domain.contact').controller('ContactDashboard', [
	'$scope',
	'$q',
	'$stateParams',
	'ActivityList',
	'Event',
	'RequestBuilder',
	'EventService',
	'AppService',
	'FilterHelper',
	'$safeApply',
	'FeatureHelper',
	function (
		$scope,
		$q,
		$stateParams,
		ActivityList,
		Event,
		RequestBuilder,
		EventService,
		AppService,
		FilterHelper,
		$safeApply,
		FeatureHelper
	) {
		var ContactCtrl = $scope.ContactCtrl;

		ContactCtrl.DashboardButtonGroup = DashboardButtonGroup;
		ContactCtrl.DashboardPinnedComments = DashboardPinnedComments;
		ContactCtrl.TimelineEntityNote = TimelineEntityNote;
		ContactCtrl.hasPinnedComments = FeatureHelper.hasSoftDeployAccess('PINNED_COMMENTS');
		ContactCtrl.hasMailRelations =
			FeatureHelper.hasSoftDeployAccess('MAIL_RELATION') && FeatureHelper.hasSoftDeployAccess('TODO_LIST');
		ContactCtrl.ContactTimelineCreateContact = ContactTimelineCreateContact;
		ContactCtrl.NotesFilterButton = NotesFilterButton;
		ContactCtrl.TimelineMail = TimelineMail;
		var customerId = $stateParams.customerId;
		var eventLimit = 50;
		var activeFlowLimit = 20;
		var currentTimelineQuery;
		var openActivitiesQuery;
		var self;
		$scope.eventOffset = 0;
		$scope.activeFlowOffset = 0;
		$scope.eventsLoading = false;
		$scope.initialLoading = false;
		$scope.events = [];
		$scope.openActivities = [];
		$scope.activeFlows = [];
		$scope.activeFlowsLoading = false;
		$scope.totalEvents = 0;
		$scope.totalActiveFlows = 0;
		$scope.eventsLength = 0;
		$scope.historySelection = null;
		$scope.historyType = null;

		$scope.notesFilterActive = store.getState().NotesFilterActive;
		$scope.storeUnsubscribe = store.subscribe(() => {
			const notesFilterActive = store.getState().NotesFilterActive;
			if (!notesFilterActive === $scope.notesFilterActive) {
				$scope.notesFilterActive = notesFilterActive;
				$safeApply($scope);
			}
		});

		$scope.$on('$destroy', () => {
			$scope.storeUnsubscribe?.();
		});

		$scope.showCommentOrNote = event => {
			const hasComments = event.data?.comment;
			let hasNotes;
			if (event.entityType === 'Appointment') {
				hasNotes = event.appointment.notes || event.appointment.description;
			} else if (event.entityType === 'Opportunity' || event.entityType === 'Order') {
				hasNotes = event.opportunity.notes || event.opportunity.description;
			} else if (event.entityType === 'Activity') {
				hasNotes = true;
			}
			const hasCommentsOrNotes = hasComments || hasNotes;
			return $scope.notesFilterActive && hasCommentsOrNotes;
		};

		$scope.showEvent = event => {
			return !$scope.showCommentOrNote(event);
		};

		// UPDATED EVENTS
		const activityAppointmentUpdated = function (e, activity) {
			const isInActivity = _.find(activity.contacts, { id: ContactCtrl.contact.id });
			if (isInActivity) {
				const newEvent = EventService.create.Activity(activity);
				let isOpenActivity = false;
				let isAppointment = false;

				/* Find the activity, save the type and remove it */

				// Open activities
				let foundEvent = _.find($scope.openActivities, { entityId: activity.id });

				if (foundEvent) {
					isAppointment = foundEvent.appointment ? true : false;
					isOpenActivity = true;
					_.pull($scope.openActivities, foundEvent);
					$scope.sortedOpenActivities = $scope.openActivities.sort(ActivityDateSorter);
				} else {
					// History activity
					foundEvent = _.find($scope.events, { entityType: 'Activity', entityId: activity.id });

					// History appointment
					if (!foundEvent) {
						isAppointment = true;
						foundEvent = _.find($scope.events, { entityType: 'Appointment', entityId: activity.id });
					}

					if (foundEvent) {
						_.pull($scope.events, foundEvent);
					}
				}

				if (foundEvent) {
					newEvent.$$expand = foundEvent.$$expand;
					Object.keys(foundEvent).forEach(prop => {
						if (typeof foundEvent[prop] === 'function') {
							newEvent[prop] = foundEvent[prop];
						}
					});
				}

				/* Add it if it matches any of the lists filters and update the amount number */

				// Open activities
				if (
					FilterHelper.match(openActivitiesQuery.q, activity, 'activity') &&
					(activity.isAppointment ? moment().isBefore(activity.date) : true) // FilterHelper.match() only compares the day, we want to compare exact time
				) {
					newEvent.date = activity.date;
					$scope.openActivities.push(newEvent);
					$scope.sortedOpenActivities = $scope.openActivities.sort(ActivityDateSorter);

					if (!isOpenActivity) {
						$scope.totalAll--;
						if (isAppointment) {
							$scope.totalAppointments--;
						} else {
							$scope.totalActivities--;
						}
					}
				} else {
					$scope.events.push(newEvent);
					if (isOpenActivity) {
						$scope.totalAll++;
						if (isAppointment) {
							$scope.totalAppointments++;
						} else {
							$scope.totalActivities++;
						}
					}
				}
			}
		};

		$scope.$on('activity.updated', activityAppointmentUpdated);
		$scope.$on('appointment.updated', activityAppointmentUpdated);

		$scope.$on('contact.updated', function (e, contact) {
			if (contact.id === ContactCtrl.contact.id) {
				// Check and update events
				$scope.contactEvent = EventService.extend.Contact($scope.contactEvent, contact);

				angular.forEach($scope.events, function (event) {
					angular.forEach(event.contacts, function (evtContact) {
						if (evtContact?.id === contact.id) {
							angular.extend(evtContact, contact);
						}
					});
				});
			}
		});

		$scope.$on('order.updated', function (e, order) {
			if (order.contact?.id === ContactCtrl.contact.id) {
				const orderEvent = _.find($scope.events, { entityType: 'Order', entityId: order.id });
				if (orderEvent) {
					EventService.extend.Order(orderEvent, order);
					return;
				}
				const opportunityEvent = _.find($scope.events, { entityType: 'Opportunity', entityId: order.id });
				if (opportunityEvent) {
					EventService.extend.Order(opportunityEvent, order);
				}
			}
		});

		$scope.$on('opportunity.updated', function (e, updated) {
			if (updated.contact?.id === ContactCtrl.contact.id) {
				const opportunityEvent = _.find($scope.events, { entityType: 'Opportunity', entityId: updated.id });
				if (opportunityEvent) {
					EventService.extend.Order(opportunityEvent, updated);
				}
			}
		});

		// ADDED EVENTS
		$scope.$on('activity.added', function (e, added) {
			const isInActivity = _.find(added.contacts, { id: ContactCtrl.contact.id });
			if (isInActivity) {
				const event = EventService.create.Activity(added);

				// Check for filter matches in both lists
				if (FilterHelper.match(openActivitiesQuery.q, added, 'activity')) {
					event.date = added.date;
					$scope.openActivities.push(event);
					$scope.sortedOpenActivities = $scope.openActivities.sort(ActivityDateSorter);
					return;
				}

				$scope.totalActivities++;
				$scope.totalAll++;
				if (FilterHelper.match(currentTimelineQuery.q, event, 'event')) {
					if (added.activityType?.name === 'Phonecall' && added.closeDate) {
						event.date = new Date(added.closeDate);
					}
					$scope.events.push(event);
				}
			}
		});

		$scope.$on('mail.added', function (e, mail) {
			const isInMail = mail.contact?.id === ContactCtrl.contact.id;
			if (isInMail) {
				$scope.totalAll++;
				const event = EventService.create.Mail(mail);
				if ($scope.historyType === null || $scope.historyType === 'market') {
					event.date = mail.date;
					$scope.events.push(event);
				}
				$safeApply($scope);
			}
		});

		$scope.$on('appointment.added', function (e, added) {
			const isInActivity = _.find(added.contacts, { id: ContactCtrl.contact.id });
			if (isInActivity) {
				const event = EventService.create.Activity(added);

				// Check for filter matches in both lists
				if (FilterHelper.match(openActivitiesQuery.q, added, 'activity')) {
					$scope.openActivities.push(event);
					$scope.sortedOpenActivities = $scope.openActivities.sort(ActivityDateSorter);
					return;
				}

				$scope.totalAppointments++;
				$scope.totalAll++;
				if (FilterHelper.match(currentTimelineQuery.q, event, 'event')) {
					$scope.events.push(event);
				}
			}
		});

		$scope.$on('ticket.added', function (e, created) {
			if (created.contact?.id !== ContactCtrl.contact.id) {
				return;
			}

			const createdTicket = EventService.create.Ticket(created, 'Created');
			$scope.events.push(createdTicket);
			$safeApply($scope);
		});

		$scope.$on('ticket.statusChanged', function (e, updated) {
			if (updated.contact?.id !== ContactCtrl.contact.id) {
				return;
			}

			const changedStatus = EventService.create.Ticket(updated, 'StatusChanged');
			$scope.events.push(changedStatus);
			$safeApply($scope);
		});

		$scope.$on('comment.updated', function (e, updated) {
			if (updated.contact?.id === ContactCtrl.contact.id) {
				if ($scope.historyType === null || $scope.historyType === 'comments') {
					const foundEvent = _.find($scope.events, { entityId: updated.id, entityType: 'Comment' });
					if (foundEvent) {
						_.pull($scope.events, foundEvent);
						const updatedComment = EventService.extend.Comment(foundEvent, updated);
						$scope.events.push(updatedComment);
						$safeApply($scope);
					}
				}
			}
		});

		$scope.$on('comment.added', async function (e, added) {
			if (added.activity || added.appointment || added.opportunity || added.ticket) {
				return;
			}

			if (added.contact?.id === ContactCtrl.contact.id) {
				if (added.tooBigForPusher) {
					try {
						const { data: comment } = await Comment.get(added.id);
						added = comment;
					} catch (err) {
						logError(err, 'Failed to fetch comment');
						return;
					}
				}

				const event = EventService.create.Comment(added);
				if ($scope.historyType === null) {
					$scope.events.push(event);
				}
				$scope.totalAll++;
				$safeApply($scope);
			}
		});

		$scope.$on('order.added', function (e, added) {
			if (added.contact?.id === ContactCtrl.contact.id) {
				$scope.totalOrder++;
				$scope.totalAll++;
				const event = EventService.create.Order(added);
				if (FilterHelper.match(currentTimelineQuery.q, event, 'event')) {
					$scope.events.push(event);
				}
			}
		});

		$scope.$on('opportunity.added', function (e, added) {
			if (added.contact?.id === ContactCtrl.contact.id) {
				$scope.totalOpportunity++;
				$scope.totalAll++;
				const event = EventService.create.Order(added);
				if (FilterHelper.match(currentTimelineQuery.q, event, 'event')) {
					$scope.events.push(event);
				}
			}
		});

		$scope.$on('esign.added', function (e, added) {
			const contactInvolved = _.find(added.involved, { contactId: ContactCtrl.contact.id });
			if (contactInvolved && added.state !== 0) {
				$scope.totalAll++;
				const event = EventService.create.Esign(added);
				if (FilterHelper.match(currentTimelineQuery.q, event, 'event')) {
					$scope.events.push(event);
				}
			}
		});

		$scope.$on('market.added', function (e, res) {
			if (res?.contacts && _.find(res.contacts, { id: ContactCtrl.contact.id })) {
				ContactCtrl.contact.scoreUpdateDate = res.date;
				$scope.totalMarket++;
				$scope.totalAll++;

				const event = EventService.create.Manual(res);
				if ($scope.historyType === null || $scope.historyType === 'market') {
					$scope.events.push(event);
				}
			}
		});

		// DELETED EVENTS
		$scope.$on('activity.deleted', function (e, activity) {
			//activity will be {id: x} if deleted through babel/resources/Activity
			var isInActivity =
				activity.contacts?.id === ContactCtrl.contact.id ||
				$scope.openActivities.findIndex(openActivity => activity.id === openActivity.entityId) !== -1;
			if (isInActivity) {
				const foundEvent = _.find($scope.openActivities, { entityId: activity.id, entityType: 'Activity' });
				if (foundEvent) {
					_.pull($scope.openActivities, foundEvent);
					$scope.sortedOpenActivities = $scope.openActivities.sort(ActivityDateSorter);
					$scope.totalActivities--;
					$scope.totalAll--;
					$safeApply($scope);
				}
			}
		});

		$scope.$on('comment.deleted', function (e, comment) {
			var isInComment = $scope.events.findIndex(event => comment.id === event.entityId) !== -1;
			if (isInComment) {
				const foundEvent = _.find($scope.events, { entityId: comment.id, entityType: 'Comment' });
				if (foundEvent) {
					$scope.totalAll--;
					_.pull($scope.events, foundEvent);
					$safeApply($scope);
				}
			}
		});

		$scope.$on('appointment.deleted', function (e, appointment) {
			let foundEvent = _.find($scope.openActivities, { entityId: appointment.id, entityType: 'Appointment' });
			if (foundEvent) {
				_.pull($scope.openActivities, foundEvent);
				$scope.sortedOpenActivities = $scope.openActivities.sort(ActivityDateSorter);
				return;
			}

			foundEvent = _.find($scope.events, { entityId: appointment.id, entityType: 'Appointment' });
			if (foundEvent) {
				$scope.totalAppointments--;
				$scope.totalAll--;
				_.pull($scope.events, foundEvent);
			}
		});

		$scope.$on('opportunity.deleted', function (e, opportunity) {
			const foundEvent = _.find($scope.events, { entityId: opportunity.id, entityType: 'Opportunity' });
			if (foundEvent) {
				$scope.totalOpportunity--;
				$scope.totalAll--;
				_.pull($scope.events, foundEvent);
			}
		});

		$scope.$on('order.deleted', function (e, order) {
			const foundEvent = _.find($scope.events, { entityId: order.id, entityType: 'Order' });
			if (foundEvent) {
				$scope.totalOrder--;
				$scope.totalAll--;
				_.pull($scope.events, foundEvent);
			}
		});

		$scope.$on('comment.deleted', function (e, comment) {
			const foundEvent = _.find($scope.events, { entityId: comment.id, entityType: 'Comment' });
			if (foundEvent) {
				$scope.totalComments--;
				$scope.totalAll--;
				_.pull($scope.events, foundEvent);
				$safeApply($scope);
			}
		});

		// OTHER
		$scope.$on('ContactCtrl.moved', function (e, contact) {
			if (contact.id === ContactCtrl.contact.id) {
				setTimeout(function () {
					$scope.getEvents(true);
				}, 1000);
			}
		});

		$scope.$on('account.resetScore', function (e, clientId) {
			if (clientId === ContactCtrl.contact?.client?.id) {
				ContactCtrl.contact.score = 0;
				$scope.getEvents(true);
			}
		});

		$scope.$on('contact.merged', function (e, res) {
			if (res.merged.id === ContactCtrl.contact.id) {
				// Reload stuff
				$scope.getEvents(true);
			}
		});

		$scope.sortedOpenActivities = [];

		$scope.timelineHeader = function (date) {
			return date ? moment(date).format('MMMM YYYY') : Tools.$translate('todo.noDate');
		};

		$scope.showHeader = function (obj1, obj2) {
			if (!obj2 || !obj1) {
				return true;
			}
			return moment(obj1.date).format('YYYY-MM') !== moment(obj2.date).format('YYYY-MM');
		};

		$scope.changeHistoryType = function (type) {
			if (type === $scope.historyType) {
				return;
			}
			$scope.historyType = type;
			$scope.getEvents(true);
		};

		var getData = function (historyType, onlyMeta) {
			var eventFilter = new RequestBuilder();
			eventFilter.addFilter(
				Event.attr.contacts.attr.id,
				eventFilter.comparisonTypes.Equals,
				ContactCtrl.contact.id
			);
			eventFilter.addFilter(Event.attr.date, eventFilter.comparisonTypes.LessThanEquals, moment().format());

			if (historyType !== 'market') {
				eventFilter.addFilter({ field: 'feedContact2' }, eventFilter.comparisonTypes.Equals, null);
				eventFilter.addFilter(Event.attr.entityType, eventFilter.comparisonTypes.NotEquals, ['Contact']);
			}

			eventFilter.addSort(Event.attr.date, false);
			eventFilter.addSort(Event.attr.entityType, false);
			eventFilter.addSort(Event.attr.entityId, false);
			eventFilter.addSort(Event.attr.subType, false);

			eventFilter.limit = eventLimit;
			eventFilter.offset = $scope.eventOffset;

			if (onlyMeta) {
				eventFilter.limit = 0;
			}
			if ($scope.historySelection) {
				eventFilter.addFilter(Event.attr.users.attr.id, eventFilter.comparisonTypes.Equals, [self.id]);
			}

			switch (historyType) {
				case 'activities':
					eventFilter.addFilter(Event.attr.entityType, eventFilter.comparisonTypes.Equals, ['Activity']);
					break;

				case 'order':
					eventFilter.addFilter(Event.attr.entityType, eventFilter.comparisonTypes.Equals, [
						'Order',
						'Agreement'
					]);
					break;

				case 'appointments':
					eventFilter.addFilter(Event.attr.entityType, eventFilter.comparisonTypes.Equals, ['Appointment']);
					break;

				case 'market':
					eventFilter.addFilter({ field: 'feedContactMarket' }, eventFilter.comparisonTypes.Equals, null);
					break;

				case 'opportunities':
					eventFilter.addFilter(Event.attr.entityType, eventFilter.comparisonTypes.Equals, ['Opportunity']);
					break;

				default:
					break;
			}

			currentTimelineQuery = eventFilter.build();
			return Event.customer(customerId)
				.find(currentTimelineQuery)
				.then(function (res) {
					if (res.data.length) {
						// Group Events
						var events = EventService.groupEvents(res.data);

						if ($scope.eventOffset === 0) {
							$scope.events = events;
						} else {
							EventService.groupEvents(res.data, $scope.events);
						}
						$scope.totalEvents = res.metadata.total;
						$scope.eventOffset += eventLimit;
						$scope.eventsLength += $scope.events.length;
					} else {
						if ($scope.eventOffset === 0) {
							$scope.events = [];
							$scope.totalEvents = 0;
							$scope.eventsLength = 0;
						}
					}
					switch (historyType) {
						case 'activities':
							$scope.totalActivities = res.metadata.total;
							break;

						case 'order':
							$scope.totalOrder = res.metadata.total;
							break;

						case 'appointments':
							$scope.totalAppointments = res.metadata.total;
							break;

						case 'market':
							$scope.totalMarket = res.metadata.total;
							break;

						case 'opportunities':
							$scope.totalOpportunity = res.metadata.total;
							break;
						default:
							$scope.totalAll = res.metadata.total;
							break;
					}
					$scope.eventsLoading = false;
					$scope.initialLoading = false;
					return res;
				});
		};

		var getMetaData = function () {
			$q.all([
				getData('activities', true),
				getData('order', true),
				getData('appointments', true),
				getData('market', true),
				getData('opportunities', true),
				getData(null, true)
			])
				.then(function (res) {
					$scope.totalActivities = res[0].metadata.total;
					$scope.totalOrder = res[1].metadata.total;
					$scope.totalAppointments = res[2].metadata.total;
					$scope.totalMarket = res[3].metadata.total;
					$scope.totalOpportunity = res[4].metadata.total;
					$scope.totalAll = res[5].metadata.total;
				})
				.catch(err => console.error(err));
		};

		$scope.toggleNoteFilter = async function () {
			$scope.notesFilterActive = !$scope.notesFilterActive;
			$scope.historyType = null;
			await $scope.getEvents(true);
			getMetaData();
		};

		$scope.getEvents = function (resetOffset) {
			$scope.eventsLoading = true;
			if (resetOffset) {
				$scope.eventOffset = 0;
			}
			return getData($scope.historyType);
		};

		$scope.track = function (type) {
			globalTracker.track('add', { type: type, location: 'contact_card_btn_group' });
		};

		$scope.getActiveFlows = function () {
			$scope.activeFlowsLoading = true;
			// eslint-disable-next-line promise/catch-or-return
			return FlowContactsActive.getActiveFlows(
				'contact',
				ContactCtrl.contact.id,
				activeFlowLimit,
				$scope.activeFlowOffset
			)
				.then(function (res) {
					res.data.forEach(({ flowId, flowName, date, contactId, contactName, segmentId, regByName }) =>
						$scope.activeFlows.push({
							flowName,
							date,
							contacts: [{ name: contactName, id: contactId }],
							segmentId,
							entityType: 'ActiveFlow',
							entityId: flowId,
							users: [],
							regByName
						})
					);
					$scope.activeFlowOffset += activeFlowLimit;
					$scope.totalActiveFlows = res.metadata.total;
					$scope.activeFlowsLoading = false;
					$safeApply($scope);
				})
				.catch(err => {
					console.error(err);
					$scope.activeFlowsLoading = false;
					$safeApply($scope);
				});
		};

		var getActivities = function () {
			var filter = new RequestBuilder();
			filter.addFilter(ActivityList.attr.contacts.attr.id, filter.comparisonTypes.Equals, ContactCtrl.contact.id);
			filter.addFilter(ActivityList.attr.projectPlan.attr.id, filter.comparisonTypes.Equals, null);

			// Open activity filter (aka activity AND appointment)
			var orBuilder = filter.orBuilder();
			orBuilder.next();
			orBuilder.addFilter(ActivityList.attr.closeDate, filter.comparisonTypes.Equals, null);
			orBuilder.addFilter(ActivityList.attr.isAppointment, filter.comparisonTypes.Equals, false);
			orBuilder.next();
			orBuilder.addFilter(ActivityList.attr.isAppointment, filter.comparisonTypes.Equals, true);
			orBuilder.addFilter(ActivityList.attr.date, filter.comparisonTypes.GreaterThanEquals, new Date());

			openActivitiesQuery = filter.build();
			openActivitiesQuery.q = openActivitiesQuery.q || [];
			openActivitiesQuery.q.push(orBuilder.getQuery());

			ActivityList.customer(customerId)
				.find(openActivitiesQuery)
				.then(function (res) {
					var activityEvents = [];
					angular.forEach(res.data, function (activity) {
						var event = EventService.create.Activity(activity);
						event.date = activity.date;
						activityEvents.push(event);
					});
					$scope.openActivities = activityEvents;
					$scope.sortedOpenActivities = $scope.openActivities.sort(ActivityDateSorter);
				})
				.catch(err => console.error(err));
		};

		var init = function () {
			$scope.initialLoading = true;
			self = AppService.getSelf();
			$scope.getEvents();

			getMetaData();
			getActivities();
			if (!Tools.FeatureHelper.hasSoftDeployAccess('SKIP_MAIL_CAMPAIGN_INFO')) {
				$scope.getActiveFlows();
			}

			$scope.contactEvent = EventService.create.Contact(ContactCtrl.contact);
			$scope.firstTouch = ContactCtrl.firstTouchInfo;
			$scope.syntheticContactEvent = {
				contact: {
					...ContactCtrl.contact
				},
				users: $scope.contactEvent.users
			};
		};

		AppService.loadedPromise.then(init).catch(err => console.error(err));
	}
]);
