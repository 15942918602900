import SocialEventAttributes from 'App/babel/attributes/SocialEventAttributes';
import QuotaAttributes from 'App/babel/attributes/QuotaAttributes';
import quotaFilters from 'App/babel/filterConfigs/Quota';
import TicketAttributes from 'App/babel/attributes/Ticket';
import ProjectPlanAttributes from 'App/babel/attributes/ProjectPlanAttributes';
const QuotaFilters = quotaFilters();

angular.module('services.listViewService').factory('ListAttributes', [
	'ActivityAttributes',
	'OrderAttributes',
	'AccountAttributes',
	'LeadAttributes',
	'Lead2Attributes',
	'CampaignAttributes',
	'VisitorAttributes',
	'ContactAttributes',
	'AppointmentAttributes',
	'MailTemplateAttributes',
	'MailAttributes',
	'mailCampaignAttributes',
	'ScoreAttributes',
	'EventAttributes',
	'OpportunityAttributes',
	'SalesReportAttributes',
	'AgreementAttributes',
	'FormSubmitAttributes',
	'UserDefinedObjectAttributes',
	'EsignAttributes',
	'FormAttributes',
	'SoliditetClientAttributes',
	'PhoneCallAttributes',
	'SegmentAttributes',
	'FlowAttributes',
	'AdCampaignAttributes',
	'BannerAttributes',
	function (
		ActivityAttributes,
		OrderAttributes,
		AccountAttributes,
		LeadAttributes,
		Lead2Attributes,
		CampaignAttributes,
		VisitorAttributes,
		ContactAttributes,
		AppointmentAttributes,
		MailTemplateAttributes,
		MailAttributes,
		mailCampaignAttributes,
		ScoreAttributes,
		EventAttributes,
		OpportunityAttributes,
		SalesReportAttributes,
		AgreementAttributes,
		FormSubmitAttributes,
		UserDefinedObjectAttributes,
		EsignAttributes,
		FormAttributes,
		SoliditetClientAttributes,
		PhoneCallAttributes,
		SegmentAttributes,
		FlowAttributes,
		AdCampaignAttributes,
		BannerAttributes
	) {
		var attrs = {
			activity: ActivityAttributes,
			activityOutcome: ActivityAttributes,
			appointment: AppointmentAttributes,
			agreement: AgreementAttributes,
			// We get an error if we dont have the attributes in the broadcast type list.
			// But agreement lists broadcast agreementGroup and we don't have agreementGroup lists.
			agreementGroup: AgreementAttributes,
			opportunity: OpportunityAttributes,
			periodization: OpportunityAttributes,
			order: OrderAttributes,
			account: AccountAttributes,
			lead: LeadAttributes,
			leads2: Lead2Attributes,
			campaign: CampaignAttributes,
			visitor: VisitorAttributes,
			contact: ContactAttributes,
			mail: MailAttributes,
			mailTemplate: MailTemplateAttributes,
			mailCampaign: mailCampaignAttributes,
			scoreboard: ScoreAttributes,
			assignedLeads: ScoreAttributes,
			unassignedLeads: ScoreAttributes,
			salesboard: EventAttributes,
			salesReport: SalesReportAttributes,
			event: EventAttributes,
			formSubmit: FormSubmitAttributes,
			form: FormAttributes,
			page: FormAttributes,
			userDefinedObject: UserDefinedObjectAttributes,
			esign: EsignAttributes,
			soliditet: SoliditetClientAttributes,
			phoneCall: PhoneCallAttributes,
			segment: SegmentAttributes,
			flow: FlowAttributes,
			adCampaign: AdCampaignAttributes,
			banner: BannerAttributes,
			ticket: function () {
				return {
					attr: TicketAttributes
				};
			},
			projectPlan: function () {
				return {
					attr: ProjectPlanAttributes
				};
			},
			socialEvent: function () {
				return {
					attr: SocialEventAttributes
				};
			},
			quota: function () {
				return {
					attr: QuotaAttributes,
					standardFilters: QuotaFilters
				};
			}
		};

		return {
			get: function (type) {
				return attrs[type]();
			}
		};
	}
]);
