import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { useTranslation } from 'Components/Helpers/translate';
import './DocumentContentEditorProductTable.scss';
import { Row, Text, Toggle, Headline, Column, Input } from '@upsales/components';
import { DocumentTemplateState } from './DocumentTemplateEditor';
import { getValueHash, onEditorChange } from './DocumentTemplateEditorDesign';
import { getIndicesFromColId } from './DocumentContentEditorProductTable';

type Props = {
	state: DocumentTemplateState;
	dispatch: React.Dispatch<Partial<DocumentTemplateState>>;
};

export const DocumentContentEditorProductTableAdditional = ({ state, dispatch }: Props) => {
	const { t } = useTranslation();
	const lang = {
		additionalSettings: t('admin.documentTemplateEditor.productTable.additionalSettings'),
		discounts: t('admin.documentTemplateEditor.productTable.discounts'),
		priceSummation: t('admin.documentTemplateEditor.productTable.priceSummation'),
		productDescription: t('default.productDescription'),
		productDescriptionDescription: t('admin.documentTemplateEditor.productTable.productDescription.description'),
		totalRecurringPrice: t('admin.documentTemplateEditor.productTable.totalPriceRecurring'),
		totalOneOffPrice: t('admin.documentTemplateEditor.productTable.totalPriceOneOff'),
		totalPrice: t('admin.documentTemplateEditor.productTable.totalPrice'),
		totalRecurringPriceDefaultValue: t(
			'admin.documentTemplateEditor.productTable.totalPriceRecurring.defaultValue'
		),
		totalOneOffPriceDefaultValue: t('admin.documentTemplateEditor.productTable.totalPriceOneOff.defaultValue'),
		totalPriceDefaultValue: t('admin.documentTemplateEditor.productTable.totalPrice.defaultValue')
	};

	const classes = new bemClass('DocumentContentEditorProductTable');

	const enum Settings {
		PriceSummation,
		ProductDescription,
		Discounts
	}

	const PriceType = {
		Recurring: 'recurring',
		OneOff: 'oneOff',
		Total: 'total'
	} as const;

	const onToggleSetting = (type: Settings) => {
		const [rowIndex, colIndex] = getIndicesFromColId(state);
		if (rowIndex === undefined || colIndex === undefined) return;
		if (rowIndex === -1 || colIndex === -1) {
			return;
		}
		const config = { ...state.config };
		const column = state.selectedColumn;
		if (!column) return;
		switch (type) {
			case Settings.PriceSummation:
				column.showPriceSummation = !column.showPriceSummation;
				if (!column.showPriceSummation && column.ProductTableTotalPrice) {
					column.ProductTableTotalPrice.recurring = lang.totalRecurringPriceDefaultValue;
					column.ProductTableTotalPrice.oneOff = lang.totalOneOffPriceDefaultValue;
					column.ProductTableTotalPrice.total = lang.totalPriceDefaultValue;
				}
				break;
			case Settings.ProductDescription:
				column.showProductDescription = !column.showProductDescription;
				break;
			case Settings.Discounts:
				column.showDiscounts = !column.showDiscounts;
				break;
		}
		config.rows[rowIndex].columns[colIndex] = column;
		dispatch({
			config: config,
			hash: getValueHash(config),
			selectedColumn: column
		});
		onEditorChange(state, dispatch, { config });
	};

	const onFieldChange = (type: (typeof PriceType)[keyof typeof PriceType], value: string) => {
		const [rowIndex, colIndex] = getIndicesFromColId(state);
		if (rowIndex === undefined || colIndex === undefined) return;
		if (rowIndex === -1 || colIndex === -1) {
			return;
		}
		const config = { ...state.config };
		const column = state.selectedColumn;
		if (!column?.ProductTableTotalPrice) return;
		switch (type) {
			case PriceType.Recurring:
				column.ProductTableTotalPrice.recurring = value;
				break;
			case PriceType.OneOff:
				column.ProductTableTotalPrice.oneOff = value;
				break;
			case PriceType.Total:
				column.ProductTableTotalPrice.total = value;
				break;
		}
		config.rows[rowIndex].columns[colIndex] = column;
		dispatch({
			config: config,
			hash: getValueHash(config),
			selectedColumn: column
		});
		onEditorChange(state, dispatch, { config });
	};

	return (
		<div className={classes.b()}>
			<Headline size={'xs'}>{lang.additionalSettings}</Headline>
			<Row className={classes.elem('additional-setting').b()}>
				<Column>
					<Text>{lang.priceSummation}</Text>
				</Column>
				<Column className={classes.elem('toggle').b()} data-testid="price-summation-toggle">
					<Toggle
						checked={state.selectedColumn ? state.selectedColumn.showPriceSummation : false}
						onChange={() => onToggleSetting(Settings.PriceSummation)}
					/>
				</Column>
			</Row>
			{state.selectedColumn && state.selectedColumn.showPriceSummation ? (
				<Row className={classes.elem('additional-setting').mod('text-input').b()}>
					<Column>
						<Text>{lang.totalRecurringPrice}</Text>
					</Column>
					<Column>
						<Input
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								onFieldChange(PriceType.Recurring, e.target.value)
							}
							value={state.selectedColumn.ProductTableTotalPrice?.recurring || ''}
						></Input>
					</Column>
				</Row>
			) : null}
			{state.selectedColumn && state.selectedColumn.showPriceSummation ? (
				<Row className={classes.elem('additional-setting').mod('text-input').b()}>
					<Column>
						<Text>{lang.totalOneOffPrice}</Text>
					</Column>
					<Column>
						<Input
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								onFieldChange(PriceType.OneOff, e.target.value)
							}
							value={state.selectedColumn.ProductTableTotalPrice?.oneOff || ''}
						></Input>
					</Column>
				</Row>
			) : null}
			{state.selectedColumn && state.selectedColumn.showPriceSummation ? (
				<Row className={classes.elem('additional-setting').mod('text-input').b()}>
					<Column>
						<Text>{lang.totalPrice}</Text>
					</Column>
					<Column>
						<Input
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								onFieldChange(PriceType.Total, e.target.value)
							}
							value={state.selectedColumn.ProductTableTotalPrice?.total || ''}
						></Input>
					</Column>
				</Row>
			) : null}
			<Row className={classes.elem('additional-setting').b()}>
				<Column>
					<Text>{lang.productDescription}</Text>
					<Text size="sm" className={classes.elem('description').b()}>
						{lang.productDescriptionDescription}
					</Text>
				</Column>
				<Column className={classes.elem('toggle').b()}>
					<Toggle
						data-testid="product-description-toggle"
						checked={state.selectedColumn ? state.selectedColumn.showProductDescription : false}
						onChange={() => onToggleSetting(Settings.ProductDescription)}
					/>
				</Column>
			</Row>
			<Row className={classes.elem('additional-setting').b()}>
				<Column>
					<Text>{lang.discounts}</Text>
				</Column>
				<Column className={classes.elem('toggle').b()}>
					<Toggle
						data-testid="discounts-toggle"
						checked={state.selectedColumn ? state.selectedColumn.showDiscounts : false}
						onChange={() => onToggleSetting(Settings.Discounts)}
					/>
				</Column>
			</Row>
		</div>
	);
};
