import React from 'react';
import { removeItem, replaceItem } from '../../store/helpers/array';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import File from 'App/babel/resources/File';
import Label from 'App/resources/Label';
import FileAttributes from 'Attributes/FileAttributes';
import logError from 'App/babel/helpers/logError';
import { fileBrowserTracker } from 'App/babel/helpers/Tracker';
import t from 'Components/Helpers/translate';

const ignoredErrors = ['file.tooLarge'];

const FileBrowserHoc = FileBrowser => {
	return class extends React.PureComponent {
		static defaultProps = {
			types: []
		};
		constructor(props) {
			super(props);

			this.sortTypes = [
				{ name: t('file.uploadedDate'), id: 'uploadDate' },
				{ name: t('file.fileName'), id: 'filename' },
				{ name: t('file.fileType'), id: 'mimetype' },
				{ name: t('file.fileSize'), id: 'size' },
				{ name: t('default.user'), id: 'user.attr.name' }
			];

			this.unlabeled = { name: t('file.label.unlabeled'), type: 'Unlabeled', numEntities: 0 };
			this.allImagesLabel = { name: t('file.label.allImages'), type: 'AllImages', numEntities: 0 };

			this.standardLabels = [
				{ name: t('file.label.standard.customerCard'), type: 'ContactAndClient', numEntities: 0 },
				{ name: t('file.label.standard.profilePictures'), type: 'ProfileImage', numEntities: 0 }
			];

			const AppService = getAngularModule('AppService');
			this.self = AppService.getSelf();

			this.state = {
				files: [],
				uploadedFiles: [],
				filesLoading: [],
				currentSort: this.sortTypes[0].id,
				offset: 0,
				selectedUser: this.self,
				currentDirection: false,
				searchString: '',
				loading: true,
				imageOnly: false,
				labels: [],
				selectedLabel: this.allImagesLabel,
				standardLabels: this.standardLabels,
				unlabeled: this.unlabeled,
				allImagesLabel: this.allImagesLabel
			};

			if (props.types.length === 1 && props.types[0] === 'image') {
				this.state.imageOnly = true;
			}
		}

		hasFileLabel = Tools.FeatureHelper.hasSoftDeployAccess('FILE_METADATA_LABEL');

		displayError = (err, message) => {
			if (!ignoredErrors.includes(err)) {
				logError(err, message);
			}
			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.ERROR,
				icon: 'times',
				title: 'default.error',
				body: typeof err !== 'string' ? (err && err.message) || '' : err
			});
		};

		displaySuccess = message => {
			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.SUCCESS,
				icon: 'check',
				title: 'default.successful',
				body: message
			});
		};

		async componentDidMount() {
			await this.getFiles(true);
			if (this.hasFileLabel) {
				await this.getLabels();
				await this.getLabelsNumber();
			}
		}

		// This is very wonky but it's still an ok way of calculating whether we have to fetch more images
		hasScrollbar = () => {
			return (document.querySelector('.FileBrowserFiles__thumbnails')?.clientHeight || 0) > window.innerHeight;
		};

		filesLimit = 30;
		getFilesFilter = () => {
			const rb = new RequestBuilder();
			rb.offset = this.state.offset;
			rb.limit = this.filesLimit;

			rb.addSort(this.state.currentSort, this.state.currentDirection);
			rb.addFilter(FileAttributes.entity, comparisonTypes.NotEquals, 'Esign');

			if (this.state.selectedLabel.id) {
				rb.addFilter(FileAttributes.labels.attr.id, comparisonTypes.Equals, this.state.selectedLabel.id);
			}

			if (this.state.selectedLabel.type === 'Unlabeled') {
				rb.addFilter(FileAttributes.labels.attr.id, comparisonTypes.Equals, null);
				rb.addFilter(FileAttributes.entity, comparisonTypes.NotEquals, 'Contact');
				rb.addFilter(FileAttributes.entity, comparisonTypes.NotEquals, 'ProfileImage');
				rb.addFilter(FileAttributes.entity, comparisonTypes.NotEquals, 'Client');
			}

			if (this.state.selectedLabel.type === 'ProfileImage') {
				rb.addFilter(FileAttributes.entity, comparisonTypes.Equals, 'ProfileImage');
			}

			if (this.state.selectedLabel.type === 'ContactAndClient') {
				rb.addFilter(FileAttributes.entity, comparisonTypes.Equals, 'Contact');
				rb.addFilter(FileAttributes.entity, comparisonTypes.Equals, 'Client');
			}

			if (this.state.selectedUser) {
				rb.addFilter(FileAttributes.user.attr.id, comparisonTypes.Equals, this.state.selectedUser.id);
			}
			if (this.state.searchString) {
				rb.addFilter(FileAttributes.filename, comparisonTypes.Search, this.state.searchString);
			}
			if (this.props.types.length) {
				rb.addFilter(FileAttributes.mimetype, comparisonTypes.WildcardEnd, this.props.types);
			}
			if (Array.isArray(this.props.filters)) {
				for (const filter of this.props.filters) {
					rb.addFilter(filter.a, filter.c, filter.v);
				}
			}

			return rb.build();
		};

		getFiles = async reset => {
			this.setState({ loading: true });
			try {
				const { unlabeled, allImagesLabel } = this.state;
				const { data, metadata } = await File.find(this.getFilesFilter());
				if (this.state.selectedLabel?.type === this.state.unlabeled.type) {
					unlabeled.numEntities = metadata.total;
				}
				if (this.state.selectedLabel?.type === 'AllImages') {
					allImagesLabel.numEntities = metadata.total;
				}
				this.setState({
					files: reset ? data : this.state.files.concat(data),
					loading: false,
					unlabeled: unlabeled,
					allImagesLabel: allImagesLabel
				});
				if (!this.hasScrollbar()) {
					this.fetchMoreFiles(this.filesLimit + this.state.offset);
				}
			} catch (err) {
				this.displayError(err, 'Failed to fetch files');
			}
		};

		updateFileLabels = (label, remove, files) => {
			let updatedFiles = this.state.files;
			const { labels, unlabeled, uploadedFiles } = this.state;

			if (files) {
				files.forEach(file => {
					const i = updatedFiles.findIndex(f => file.id === f.id);
					const ui = uploadedFiles.findIndex(f => file.id === f.id);

					if (this.state.selectedUser?.id === file.user?.id || !this.state.selectedUser) {
						this.updateLabelsNumber(labels, unlabeled, file, label);
					}
					if (i !== -1) {
						if (
							(this.state.selectedLabel.id && this.state.selectedLabel?.id !== label.id) ||
							this.state.selectedLabel.type === 'Unlabeled'
						) {
							updatedFiles.splice(i, 1);
						} else if (remove) {
							updatedFiles[i].labels = [];
						} else {
							updatedFiles[i].labels = [label];
						}
					}
					if (ui !== -1) {
						file.labels = [label];
						if (this.state.selectedUser?.id === file.user?.id || !this.state.selectedUser) {
							if (
								this.state.selectedLabel?.id === label.id ||
								(this.state.selectedLabel.type === 'AllImages' && i === -1)
							) {
								updatedFiles.unshift(file);
							}
							uploadedFiles.splice(ui, 1);
						}
					}
				});
			} else {
				updatedFiles = this.state.files.map(file => {
					if (file.labels) {
						if (file.labels[0]?.id === label?.id) {
							if (remove) {
								this.updateLabelsNumber(labels, unlabeled, file, null);
								file.labels = [];
							} else {
								file.labels = [label];
							}
						}
					}
					return file;
				});
			}

			this.setState({
				files: updatedFiles,
				uploadedFiles: [...uploadedFiles],
				labels: labels,
				unlabeled: unlabeled
			});
		};

		getLabelsNumber = async () => {
			const { labels, standardLabels, unlabeled, allImagesLabel } = this.state;
			const customerId = Tools.AppService.getCustomerId();
			const rb = new RequestBuilder();
			const rb2 = new RequestBuilder();
			rb.addFilter(FileAttributes.mimetype, comparisonTypes.WildcardEnd, this.props.types);
			rb.addFilter(FileAttributes.entity, comparisonTypes.NotEquals, 'Esign');

			if (this.state.selectedUser) {
				rb.addFilter(FileAttributes.user.attr.id, comparisonTypes.Equals, this.state.selectedUser.id);
				rb2.addFilter(FileAttributes.user.attr.id, comparisonTypes.Equals, this.state.selectedUser.id);
			}
			if (this.state.searchString) {
				rb.addFilter(FileAttributes.filename, comparisonTypes.Search, this.state.searchString);
				rb2.addFilter(FileAttributes.filename, comparisonTypes.Search, this.state.searchString);
			}
			const aggs = rb.aggregationBuilder();
			const aggs2 = rb.aggregationBuilder();
			aggs.addAggregation('terms', FileAttributes.labels.attr.id);
			aggs.aggregationName('numOfEntities');
			aggs2.addAggregation('terms', FileAttributes.entity);
			aggs2.aggregationName('numOfEntitiesStandard');
			aggs.done();
			aggs2.done();
			try {
				const res = await Tools.Report.customer(customerId)
					.setType(Tools.Report.type.FILEMETADATA)
					.find(rb.build());
				const numOfEntities = res.data.numOfEntities.buckets;
				const numOfEntitiesStandard = res.data.numOfEntitiesStandard.buckets;

				const updatedLabels = labels.map(label => {
					label.numEntities = _.find(numOfEntities, { key: label.id })?.doc_count || 0;
					return label;
				});

				allImagesLabel.numEntities = res.data.numOfEntities.doc_count || 0;

				const updatedStandardLabels = standardLabels.map(label => {
					if (label.type === 'ProfileImage') {
						label.numEntities = _.find(numOfEntitiesStandard, { key: 'profileimage' })?.doc_count || 0;
					} else {
						const numClients = _.find(numOfEntitiesStandard, { key: 'client' })?.doc_count || 0;
						const numContacts = _.find(numOfEntitiesStandard, { key: 'contact' })?.doc_count || 0;
						label.numEntities = numClients + numContacts;
					}
					return label;
				});

				rb2.limit = 0;
				rb2.addFilter(FileAttributes.mimetype, comparisonTypes.WildcardEnd, this.props.types);
				rb2.addFilter(FileAttributes.entity, comparisonTypes.NotEquals, 'Esign');
				rb2.addFilter(FileAttributes.labels.attr.id, comparisonTypes.Equals, null);
				rb2.addFilter(FileAttributes.entity, comparisonTypes.NotEquals, 'Contact');
				rb2.addFilter(FileAttributes.entity, comparisonTypes.NotEquals, 'ProfileImage');
				rb2.addFilter(FileAttributes.entity, comparisonTypes.NotEquals, 'Client');

				const res2 = await File.find(rb2.build());

				unlabeled.numEntities = res2.metadata.total;

				this.setState({
					labels: updatedLabels,
					standardLabels: updatedStandardLabels,
					allImagesLabel: allImagesLabel,
					unlabeled: unlabeled
				});
			} catch (err) {
				this.displayError(err, 'Failed to fetch number of entities for labels');
			}
		};

		getLabels = async () => {
			const rb = new RequestBuilder();
			rb.addFilter({ field: 'entity' }, comparisonTypes.Equals, 'FileMetadata');
			rb.addSort('name', true);
			this.setState({ loading: true });
			try {
				const { data } = await Label.find(rb.build());
				this.setState({
					labels: data,
					loading: false
				});
			} catch (err) {
				this.displayError(err, 'Failed to fetch labels');
			}
		};

		searchTimeout;
		bouncedGetFiles = () => {
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(async () => {
				try {
					await this.getFiles(true);
					if (this.hasFileLabel) {
						this.getLabelsNumber();
					}
				} catch (err) {
					this.displayError(err, 'Failed to fetch files');
				}
			}, 300);
		};

		onSearch = e => {
			this.setState({ offset: 0, searchString: e.target.value });

			this.bouncedGetFiles();
		};

		onSelectLabel = label => {
			if (this.state.selectedLabel.name === label.name) {
				this.setState({ selectedLabel: this.allImagesLabel }, () => this.getFiles(true));
			} else {
				this.setState({ selectedLabel: label }, () => this.getFiles(true));
			}
			this.state.offset = 0;
		};

		onAddFileLabel = (files, label) => {
			if (label.id) {
				fileBrowserTracker.track(fileBrowserTracker.events.ADDED_LABEL);
			}
			const filters = new RequestBuilder();
			const fileIds = files.map(file => file.id);
			filters.addFilter({ field: 'id' }, comparisonTypes.Equals, fileIds);

			const labelId = label.id ? [label.id] : [];

			Tools.MultiActions.customer(Tools.AppService.getCustomerId())
				.updateFile(fileIds.length, [{ name: 'Labels', value: labelId }], filters.build())
				.then(() => {
					this.updateFileLabels(label, labelId.length === 0, files);
					this.displaySuccess(label.id ? t('file.label.added') : t('file.label.removed'));
				})
				.catch(e => this.displayError(e, 'Failed to add label to files'));
		};

		onDeleteLabel = label => {
			Label.delete(label.id)
				.then(() => {
					const { labels, unlabeled } = this.state;

					const i = labels.findIndex(l => l.id === label.id);

					if (i !== -1) {
						this.setState({
							labels: [...labels.slice(0, i), ...labels.slice(i + 1)],
							unlabeled: unlabeled
						});
					}
					if (this.state.selectedLabel.name === label.name) {
						this.setState({ selectedLabel: this.allImagesLabel }, () => this.updateFileLabels(label, true));
					} else if (label.numEntities > 0) {
						if (this.state.selectedLabel?.type === 'Unlabeled') {
							this.getFiles(true);
						} else {
							this.updateFileLabels(label, true);
						}
					}
				})
				.catch(e => this.displayError(e, 'Failed to delete label'));
		};

		deleteFile = file => {
			return File.delete(file);
		};

		updateLabelsNumber = (labels, unlabeled, file, label) => {
			let oldLabel = -1;
			if (file.labels) {
				oldLabel = labels.findIndex(l => l.id === file.labels[0]?.id);
			}
			const newLabel = labels.findIndex(l => l.id === label?.id);

			if (oldLabel !== -1) {
				labels[oldLabel].numEntities = labels[oldLabel].numEntities - 1;
			}
			if (newLabel !== -1) {
				labels[newLabel].numEntities = labels[newLabel].numEntities + 1;
			}
			if (file.entity !== 'ProfileImage' && file.entity !== 'Client' && file.entity !== 'Contact') {
				if (newLabel !== -1 && oldLabel === -1) {
					unlabeled.numEntities = unlabeled.numEntities - 1;
				} else if (newLabel === -1) {
					unlabeled.numEntities = unlabeled.numEntities + 1;
				}
			}
		};

		onDeleteFile = file => {
			File.delete(file.id)
				.then(() => {
					const { files, uploadedFiles, labels, standardLabels, unlabeled, allImagesLabel } = this.state;

					if (this.hasFileLabel) {
						let labelIndex = -1;
						if (file.labels) {
							labelIndex = labels.findIndex(label => label.id === file.labels[0]?.id);
						}
						if (labelIndex !== -1) {
							labels[labelIndex].numEntities = labels[labelIndex].numEntities - 1;
						}
						if (file.entity === 'Client' || file.entity === 'Contact') {
							const sli = standardLabels.findIndex(label => label.type === 'ContactAndClient');
							standardLabels[sli].numEntities = standardLabels[sli].numEntities - 1;
						} else if (file.entity === 'ProfileImage') {
							const sli = standardLabels.findIndex(label => label.type === 'ProfileImage');
							standardLabels[sli].numEntities = standardLabels[sli].numEntities - 1;
						} else if (labelIndex === -1) {
							unlabeled.numEntities = unlabeled.numEntities - 1;
						}

						this.setState({
							labels: labels,
							standardLabels: standardLabels,
							unlabeled: unlabeled
						});
					}
					const i = files.findIndex(f => f.id === file.id);
					const ui = uploadedFiles.findIndex(f => f.id === file.id);

					if (i !== -1) {
						allImagesLabel.numEntities = allImagesLabel.numEntities - 1;
						this.setState({
							files: [...files.slice(0, i), ...files.slice(i + 1)],
							allImagesLabel: allImagesLabel
						});
					}
					if (ui !== -1) {
						this.setState({
							uploadedFiles: [...uploadedFiles.slice(0, ui), ...uploadedFiles.slice(ui + 1)]
						});
					}
				})
				.catch(e => this.displayError(e, 'Failed to delete file in filebrowser'));
		};

		onSaveLabel = label => {
			if (label.id) {
				this.updateFileLabels(label, false);
			}
			Label.save(label, { entity: 'FileMetadata' })
				.then(res => {
					const { labels } = this.state;

					const i = labels.findIndex(l => l.id === res.data.id);

					if (i === -1) {
						res.data.numEntities = 0;
						const l = this.state.labels.concat([res.data]);
						l.sort((a, b) => a.name.localeCompare(b.name));
						this.setState({
							labels: l
						});
					} else {
						const updatedLabels = [...labels];
						res.data.numEntities = labels[i].numEntities;
						updatedLabels[i] = res.data;
						updatedLabels.sort((a, b) => a.name.localeCompare(b.name));
						this.setState({
							labels: updatedLabels
						});
					}
				})
				.catch(e => this.displayError(e, 'Failed to save label'));
		};

		onSelectFile = async file => {
			// Check so that we are not trying to select a loading file
			const loadingI = this.state.filesLoading.findIndex(id => id === file.id);
			if (loadingI !== -1) {
				return;
			}
			// Make file public if flag is set and file is not.
			if (this.props.public) {
				this.setState({
					filesLoading: [...this.state.filesLoading, file.id],
					loading: true,
					files: this.state.files.map(file => {
						file.preventLoad = true;
						return file;
					})
				});
				try {
					await File.makePublic(file.id);
					file.public = true;
				} catch (err) {
					this.displayError(err, 'Failed to select image');
					return this.props.reject();
				}
			}

			this.props.resolve([file]);
		};

		changeUserFilter = selectedUser => {
			this.setState({ selectedUser, offset: 0 }, this.bouncedGetFiles);
		};

		onUploadFile = async files => {
			const file = files[0];
			fileBrowserTracker.track(fileBrowserTracker.events.UPLOAD);
			// Push to list of recently uploaded
			this.setState({
				uploadedFiles: [
					...this.state.uploadedFiles,
					{
						filename: file.name,
						size: file.size,
						mimetype: file.type,
						user: this.self,
						loading: true
					}
				]
			});
			File.upload(file, {
				library: !this.props.fileEntity,
				fileEntity: this.props.fileEntity
			})
				.then(res => {
					const i = this.state.uploadedFiles.findIndex(f => file.name === f.filename);
					this.setState({
						uploadedFiles: replaceItem(this.state.uploadedFiles, i, res)
					});

					if (this.hasFileLabel && this.state.selectedUser?.id === res.user?.id) {
						this.setState({
							unlabeled: { ...this.state.unlabeled, numEntities: this.state.unlabeled.numEntities + 1 },
							allImagesLabel: {
								...this.state.allImagesLabel,
								numEntities: this.state.allImagesLabel.numEntities + 1
							}
						});
					}
				})
				.catch(err => {
					this.displayError(err, 'Failed to upload file');
					const i = this.state.uploadedFiles.findIndex(f => file.name === f.filename);
					this.setState({ uploadedFiles: removeItem(this.state.uploadedFiles, i) });
				});
		};

		onSortChange = sort => {
			if (sort.target) {
				sort = {
					field: sort.target.value || this.state.currentSort,
					asc: sort.target.value ? true : !this.state.currentDirection
				};
			}
			this.setState(
				{
					offset: 0,
					currentSort: sort.field,
					currentDirection: sort.asc
				},
				this.bouncedGetFiles
			);
		};

		fetchMoreFiles = filesCount => {
			const newOffset = this.state.offset + this.filesLimit;

			if (!this.state.loading && filesCount === newOffset) {
				this.setState({ offset: newOffset }, async () => {
					try {
						await this.getFiles(false);
					} catch (err) {
						this.displayError(err, 'Failed to fetch more files');
					}
				});
			}
		};

		isInViewport = elem => {
			if (!elem) {
				return false;
			}
			const bounding = elem.getBoundingClientRect();
			return (
				bounding.top >= 0 &&
				bounding.left >= 0 &&
				bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
				bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
			);
		};

		contentScroll;
		onScrollContent = e => {
			let content = e.target.querySelector('.FileBrowserFiles__thumbnails:last-child');
			if (!content) {
				content = e.target.querySelector('.FileBrowserFiles__table tbody:last-child');
				if (!content) {
					return;
				}
			}

			clearTimeout(this.contentScroll);
			this.contentScroll = setTimeout(() => {
				if (this.isInViewport(content.lastChild)) {
					this.fetchMoreFiles(content.childElementCount);
				}
			}, 300);
		};

		render() {
			return (
				<FileBrowser
					files={this.state.files}
					imageOnly={this.state.imageOnly}
					searchString={this.state.searchString}
					selectedUser={this.state.selectedUser}
					onSearch={this.onSearch}
					changeUserFilter={this.changeUserFilter}
					loading={this.state.loading}
					onDeleteFile={this.onDeleteFile}
					onSelectFile={this.onSelectFile}
					onSortChange={this.onSortChange}
					onClose={this.props.reject}
					onUploadFile={this.onUploadFile}
					currentSort={this.state.currentSort}
					currentDirection={this.state.currentDirection}
					uploadedFiles={this.state.uploadedFiles}
					sortTypes={this.sortTypes}
					onScrollContent={this.onScrollContent}
					labels={this.state.labels}
					onSaveLabel={this.onSaveLabel}
					onDeleteLabel={this.onDeleteLabel}
					onAddFileLabel={this.onAddFileLabel}
					selectedLabel={this.state.selectedLabel}
					onSelectLabel={this.onSelectLabel}
					standardLabels={this.standardLabels}
					unlabeled={this.state.unlabeled}
					allImagesLabel={this.allImagesLabel}
					tracker={fileBrowserTracker}
				/>
			);
		}
	};
};

export default FileBrowserHoc;
