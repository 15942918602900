import AdminProducts from '../../../../babel/components/Admin/products';
import sortFunction from '../../../../babel/helpers/fieldSort';
import setUILanguage from '../../../../babel/helpers/setUILanguage';
import pdfTemplateResource from '../../../../babel/resources/pdfTemplates';
import logError from '../../../../babel/helpers/logError';
import async from 'async';
import Fields from 'Components/Admin/Fields2';
import ActiveInvites from 'Components/Admin/ActiveInvites';
import IpBlock from 'Components/Admin/IpBlock';
import ClientIp from 'App/resources/ClientIp';
import openModal from 'App/services/Modal';

angular.module('domain.admin').controller('AdminSubCtrl', [
	'$safeApply',
	'UserTreeFilterMeta',
	'AppService',
	'FeatureHelper',
	'$stateParams',
	'$state',
	'URL',
	'$scope',
	'StandardField',
	'Self',
	'ActivityType',
	'AppointmentType',
	'$q',
	'Scripts',
	'$upModal',
	'$translate',
	'CustomField',
	'OrderStage',
	'ClientParam',
	'ListViewService',
	'User',
	'Import',
	'WhatIsMyIp',
	'RequestBuilder',
	'DocumentTemplate',
	'TagService',
	'CacheRefresher',
	'Product',
	'API',
	'File',
	'userDefinedDefinition',
	'Order',
	'Agreement',
	'ProductCategory',
	'ContactAttributes',
	'ActivityAttributes',
	'AppointmentAttributes',
	'OrderAttributes',
	'OpportunityAttributes',
	'MailAttributes',
	'MultiActions',
	'NotificationService',
	'EventAttributes',
	'AgreementAttributes',
	'TriggerHelper',
	'$filter',
	'OrderHelper',
	function (
		$safeApply,
		UserTreeFilterMeta,
		AppService,
		FeatureHelper,
		$stateParams,
		$state,
		URL,
		$scope,
		StandardField,
		Self,
		ActivityType,
		AppointmentType,
		$q,
		Scripts,
		$upModal,
		$translate,
		CustomField,
		OrderStage,
		ClientParam,
		ListViewService,
		User,
		Import,
		WhatIsMyIp,
		RequestBuilder,
		DocumentTemplate,
		TagService,
		CacheRefresher,
		Product,
		API,
		File,
		userDefinedDefinition,
		Order,
		Agreement,
		ProductCategory,
		ContactAttributes,
		ActivityAttributes,
		AppointmentAttributes,
		OrderAttributes,
		OpportunityAttributes,
		MailAttributes,
		MultiActions,
		NotificationService,
		EventAttributes,
		AgreementAttributes,
		TriggerHelper,
		$filter,
		OrderHelper
	) {
		var AdminSubCtrl = this;
		var AdminCtrl = $scope.AdminCtrl;
		var currencyFormat = $filter('currencyFormat');

		AdminSubCtrl.rootData = AdminCtrl.getRootObject();

		const reloadAngularIfScriptNeedScope = script => {
			if (!document.querySelector('.ng-scope') && script.active && script.code.includes('scope()')) {
				angular.reloadWithDebugInfo();
			}
		};

		var init = function () {
			var component;
			var useWebPack = false;
			var initialData;
			var customerId;
			var metadata;

			switch ($state.current.name) {
				case 'administration.imports':
					component = 'imports';

					AdminSubCtrl.rootData.pageLoading = true;

					AdminSubCtrl.rootData.pageData = {
						doneLoading: false,
						doneTotal: 0,
						doneOffset: 0,
						done: [],
						ongoingLoading: false,
						ongoingTotal: 0,
						ongoingOffset: 0,
						ongoing: [],
						draftLoading: false,
						draftTotal: 0,
						draftOffset: 0,
						draft: [],
						startedLoading: false,
						startedTotal: 0,
						startedOffset: 0,
						started: [],
						limit: 50
					};

					var getImports = function (status, offset) {
						var importFilter = new RequestBuilder();
						importFilter.addSort('regDate', false);
						importFilter.addFilter({ field: 'status' }, importFilter.comparisonTypes.Equals, status);
						importFilter.fields = ['file', 'status', 'regDate', 'user', 'numRows'];
						importFilter.limit = AdminSubCtrl.rootData.pageData.limit;
						importFilter.offset = offset;

						return Import.find(importFilter.build());
					};

					AdminSubCtrl.rootData.pageData.getDone = function (offset = 0) {
						AdminSubCtrl.rootData.pageData.doneLoading = true;
						AdminSubCtrl.rootData.pageData.doneOffset = offset;
						return getImports(Import.status.FINISHED, AdminSubCtrl.rootData.pageData.doneOffset).then(
							res => {
								AdminSubCtrl.rootData.pageData.doneLoading = false;
								AdminSubCtrl.rootData.pageData.done = res.data;
								AdminSubCtrl.rootData.pageData.doneTotal = res.metadata.total;
							}
						);
					};

					AdminSubCtrl.rootData.pageData.getStarted = function (offset = 0) {
						AdminSubCtrl.rootData.pageData.startedLoading = true;
						AdminSubCtrl.rootData.pageData.startedOffset = offset;
						return getImports(
							[Import.status.START, Import.status.STARTED],
							AdminSubCtrl.rootData.pageData.startedOffset
						).then(res => {
							AdminSubCtrl.rootData.pageData.startedLoading = false;
							AdminSubCtrl.rootData.pageData.started = res.data;
							AdminSubCtrl.rootData.pageData.startedTotal = res.metadata.total;
						});
					};

					AdminSubCtrl.rootData.pageData.getDraft = function (offset = 0) {
						AdminSubCtrl.rootData.pageData.draftLoading = true;
						AdminSubCtrl.rootData.pageData.draftOffset = offset;
						return getImports(Import.status.DRAFT, AdminSubCtrl.rootData.pageData.draftOffset).then(res => {
							AdminSubCtrl.rootData.pageData.draftLoading = false;
							AdminSubCtrl.rootData.pageData.draft = res.data;
							AdminSubCtrl.rootData.pageData.draftTotal = res.metadata.total;
						});
					};

					Promise.all([
						AdminSubCtrl.rootData.pageData.getDone(0),
						AdminSubCtrl.rootData.pageData.getStarted(0),
						AdminSubCtrl.rootData.pageData.getDraft(0)
					])
						.then(() => {
							AdminSubCtrl.rootData.pageLoading = false;
						})
						.catch(e => console.log('get imports error', e));

					AdminSubCtrl.rootData.onCreate = function (file, importType) {
						AdminSubCtrl.rootData.saving = true;

						Import.upload(file, false, { type: importType })
							.then(function (importData) {
								AdminSubCtrl.rootData.pageData.draft.push(importData.data);

								AdminSubCtrl.rootData.saving = false;

								var id = importData.data.id;

								Tools.$state.go('administration.import', {
									id: id,
									stepId: Tools.$state.params.stepId,
									isNew: true
								});
							})
							.catch(function (response) {
								NotificationService.addNotification({
									style: Tools.NotificationService.style.ERROR,
									title: 'default.error',
									body: response?.data?.error?.translated ?? 'file.uploadFailed',
									icon: 'times'
								});
								AdminSubCtrl.rootData.saving = false;
							});
					};

					AdminSubCtrl.rootData.deleteImport = function (imprt) {
						if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
							openModal('RemoveAlert', {
								title: 'import.import',
								body: 'confirm.removeImport',
								onClose: confirmed => {
									if (confirmed) {
										Import.delete(imprt)
											.then(function () {
												var index = _.findIndex(AdminSubCtrl.rootData.pageData.draft, {
													id: imprt.id
												});
												AdminSubCtrl.rootData.pageData.draft.splice(index, 1);
											})
											.catch(e => console.log('delete import error', e));
									}
								}
							});
							return;
						}

						// eslint-disable-next-line promise/catch-or-return
						$upModal
							.open('warningConfirm', {
								title:
									$translate.instant('default.remove') +
									' ' +
									$translate.instant('import.import').toLowerCase(),
								body: 'confirm.removeImport',
								resolveTrue: 'default.remove',
								icon: 'fa-warning'
							})
							.then(function () {
								Import.delete(imprt)
									.then(function () {
										var index = _.findIndex(AdminSubCtrl.rootData.pageData.draft, { id: imprt.id });
										AdminSubCtrl.rootData.pageData.draft.splice(index, 1);
									})
									.catch(e => console.log('delete import error', e));
							});
					};

					$scope.$on('pushNotification.data', function (e, data) {
						if (data && data.type === 'Import') {
							var i = _.findIndex(AdminSubCtrl.rootData.pageData.ongoing, { id: data.id });
							if (i !== -1) {
								// Update status
								AdminSubCtrl.rootData.pageData.ongoing[i].status = data.status;
							} else if (data.status !== 100 && data.status !== -1) {
								AdminSubCtrl.rootData.pageData.ongoing.push(data);
							}

							if (data.status === 100) {
								if (i !== -1) {
									AdminSubCtrl.rootData.pageData.ongoing.splice(i, 1);
								}
								getImports();
							}

							$safeApply($scope);
						}
					});

					// Check for ongoing imports
					var notiFilter = new Tools.RequestBuilder();
					notiFilter.addFilter({ field: 'type' }, notiFilter.comparisonTypes.Equals, 'Import');
					notiFilter.addFilter({ field: 'status' }, notiFilter.comparisonTypes.NotEquals, '100');
					notiFilter.addFilter({ field: 'status' }, notiFilter.comparisonTypes.NotEquals, '-1');
					Tools.PushNotifications.customer(AppService.getCustomerId())
						.find(notiFilter.build())
						.then(function (res) {
							AdminSubCtrl.rootData.pageData.ongoing = res.data;
						})
						.catch(e => console.log('find notification error', e));

					break;
				case 'administration.anonymization':
					useWebPack = true;
					component = 'Anonymization';

					var runNotifications = function (contacts) {
						NotificationService.addNotification({
							style: NotificationService.style.SUCCESS,
							icon: 'check',
							title: 'default.saved',
							body: contacts.length <= 10 ? 'anonymization.done' : 'updateQued.anonymization'
						});
					};

					var getTranslated = function (type) {
						return Tools.$translate('admin.anonymization.fields.' + type);
					};

					var getAnonymizedValue = function (field) {
						var types = {
							name: getTranslated('name'),
							description: getTranslated('description'),
							titleCategory: null
						};

						return types.hasOwnProperty(field) ? types[field] : '';
					};

					var generatePropertiesConfig = function (groupedFields) {
						var object = {};

						_.each(groupedFields, function (fields, key) {
							var objectKey = key[0].toUpperCase() + key.slice(1, key.length);
							if (fields[0].hasOwnProperty('isUdo') && fields[0].isUdo === true) {
								objectKey =
									fields[0].field[0].toUpperCase() +
									fields[0].field.slice(1, fields[0].field.length).replace('_', '');
							}
							object[objectKey] = {};
							_.each(fields, function (f) {
								var fieldKey = f.field[0].toUpperCase() + f.field.slice(1, f.field.length);
								object[objectKey][fieldKey] = getAnonymizedValue(f.field);
							});
						});

						return object;
					};

					var startProcessing = function (contacts, config) {
						var def = $q.defer();

						config.fields.push({ name: 'default.email', field: 'to', parent: 'mail' });

						var groupedFields = _.groupBy(config.fields, 'parent');

						var rb = new Tools.RequestBuilder('Contact');
						rb.addFilter({ field: 'id' }, rb.comparisonTypes.Equals, contacts);

						var actionObject = {
							objects: contacts,
							filter: rb.build(),
							properties: generatePropertiesConfig(groupedFields)
						};

						if (contacts.length > 50) {
							def.resolve(true);
						}

						MultiActions.customer(AppService.getCustomerId())
							.anonymize(actionObject)
							.then(function (response) {
								runNotifications(contacts);
								def.resolve(response.metadata);
							})
							.catch(function (anonymizeError) {
								def.reject();
								NotificationService.addNotification({
									style: NotificationService.style.ERROR,
									icon: 'times',
									title: 'default.error',
									body: anonymizeError
								});
							});

						return def.promise;
					};

					var setPage = function (str, page) {
						AdminSubCtrl.rootData.pageData.currentPage = page;

						$safeApply($scope);
					};

					var setSort = function (key) {
						var qr = Object.assign({}, AdminSubCtrl.rootData.pageData.query);

						qr.sort = key;
						AdminSubCtrl.rootData.pageData.query = qr;

						$safeApply($scope);
					};

					var filterContacts = function (checks, config) {
						var active = [];
						_.each(checks, function (bool, key) {
							if (bool) {
								active.push(key);
							}
						});

						if (!active.length) {
							AdminSubCtrl.rootData.pageData.numContacts = 0;
							AdminSubCtrl.rootData.pageData.query = {};
							return;
						}

						var filteredConfig = _.filter(config, function (item, key) {
							if (active.indexOf(key) !== -1) {
								item.key = key;
								return item;
							}
						});

						AdminSubCtrl.rootData.pageData.loading = true;
						AdminSubCtrl.rootData.pageData.query = { opts: JSON.stringify(filteredConfig) };
						Tools.Contact.anonymize(
							Object.assign({}, AdminSubCtrl.rootData.pageData.query, { count: true })
						)
							.then(function (res) {
								AdminSubCtrl.rootData.pageData.numContacts = res.metadata.total;
								AdminSubCtrl.rootData.pageData.loading = false;
							})
							.catch(e => console.log('anonymize error', e));
					};

					var getDataFromQuery = function (query) {
						var deferred = $q.defer();

						Tools.Contact.anonymize(query)
							.then(function (res) {
								AdminSubCtrl.rootData.pageData.numContacts = res.metadata.total;
								deferred.resolve({ data: res.data, metadata: res.metadata });
							})
							.catch(e => console.log('anonymize error', e));

						return deferred.promise;
					};

					AdminSubCtrl.rootData.pageData = {
						gotErrors: false,
						errorMessage: null,
						filterContacts: filterContacts,
						getDataFromQuery: getDataFromQuery,
						startProcessing: startProcessing,
						setPage: setPage,
						setSort: setSort,
						stateParams: $stateParams,
						currentPage: 1,
						contacts: [],
						numContacts: 0,
						query: {},
						loading: false,
						hasUpdated: false,
						entities: {
							udos: [],
							contact: ContactAttributes().attr,
							activity: ActivityAttributes().attr,
							appointment: AppointmentAttributes().attr,
							order: OrderAttributes().attr,
							opportunity: OpportunityAttributes().attr,
							mail: MailAttributes().attr,
							formsubmit: ['fields'],
							esign: ['fields'],
							document: ['fields'],
							event: EventAttributes().attr,
							agreement: AgreementAttributes().attr
						}
					};

					metadata = AppService.getMetadata();
					_.each(metadata.params.UserDefinedObject, function (udo) {
						AdminSubCtrl.rootData.pageData.entities.udos.push(udo);
					});

					break;
				case 'administration.ipblock':
					component = IpBlock;

					var pageData = {
						customerId: AppService.getCustomerId(),
						erorr: null,
						enabled: false,
						pageLoading: true,
						ipList: [],
						originalIps: [],
						myIp: ''
					};
					AdminSubCtrl.rootData.pageData = pageData;

					$q.all({
						myIp: WhatIsMyIp.get(),
						ips: ClientIp.find()
					})
						.then(function (res) {
							if (res.myIp && res.myIp.data) {
								pageData.myIp = res.myIp.data;
							}
							if (res.ips && res.ips.data) {
								pageData.originalIps = _.filter(res.ips.data, function (ipObject) {
									return ipObject.userId === 0;
								});

								if (
									_.find(pageData.originalIps, { ipAddress: 'all', rule: 'deny', userId: 0 }) !==
									undefined
								) {
									pageData.enabled = true;
								}
								pageData.ipList = _.pluck(pageData.originalIps, 'ipAddress').filter(function (ip) {
									return ip !== 'all';
								});
							}
							pageData.pageLoading = false;
						})
						.catch(function (err) {
							pageData.pageLoading = false;
							pageData.error = err;
						});

					pageData.onChange = function (ipAddresses) {
						var pageData = AdminSubCtrl.rootData.pageData;
						pageData.ipList = ipAddresses;

						var originalIpAddresses = _.pluck(pageData.originalIps, 'ipAddress').filter(function (ip) {
							return ip !== 'all';
						});

						if (
							_.difference(ipAddresses, originalIpAddresses).length ||
							_.difference(originalIpAddresses, ipAddresses).length
						) {
							AdminSubCtrl.rootData.saveVisible = true;
						} else {
							AdminSubCtrl.rootData.saveVisible = false;
						}

						$safeApply($scope);
					};

					AdminSubCtrl.rootData.onSave = function () {
						var ipAddresses = AdminSubCtrl.rootData.pageData.ipList;
						pageData.pageLoading = true;
						AdminSubCtrl.rootData.saving = true;

						var addIps = _.reduce(
							ipAddresses,
							function (out, ipAddress) {
								if (_.find(pageData.originalIps, { ipAddress }) === undefined) {
									out.push({
										ipAddress,
										userId: 0,
										sortIdx: 0,
										rule: 'allow'
									});
								}
								return out;
							},
							[]
						);

						var removeIps = _.reduce(
							pageData.originalIps,
							function (out, ipObject) {
								if (ipAddresses.indexOf(ipObject.ipAddress) === -1 && ipObject.ipAddress !== 'all') {
									out.push(ipObject);
								}
								return out;
							},
							[]
						);

						var removePromises = removeIps.map(function (ipObject) {
							return ClientIp.delete(ipObject.id, {});
						});
						var addPromises = addIps.map(function (ipObject) {
							return ClientIp.save(ipObject, {});
						});

						var promises = {
							delete: $q.all(removePromises).then(res => _.pluck(res, 'data')),
							add: $q.all(addPromises).then(res => _.pluck(res, 'data'))
						};

						$q.all(promises)
							.then(function (res) {
								_.each(res.add, function (ipObject) {
									var noIdObjectIndex = _.findIndex(pageData.ipList, {
										ipAddress: ipObject.ipAddress
									});

									if (noIdObjectIndex > -1) {
										pageData.originalIps[noIdObjectIndex] = ipObject;
									} else {
										pageData.originalIps.push(ipObject);
									}
								});

								_.each(res.delete, function (ipObject) {
									var noIdObjectIndex = _.findIndex(pageData.originalIps, {
										id: ipObject.id
									});

									if (noIdObjectIndex > -1) {
										pageData.originalIps.splice(noIdObjectIndex, 1);
									}
								});

								pageData.ipList = _.pluck(pageData.originalIps, 'ipAddress').filter(function (ip) {
									return ip !== 'all';
								});

								AdminSubCtrl.rootData.saveVisible = false;
								pageData.pageLoading = false;
								AdminSubCtrl.rootData.saving = false;

								$safeApply($scope);
							})
							.catch(function (err) {
								pageData.pageLoading = false;
								AdminSubCtrl.rootData.saving = false;
								pageData.error = err;
							});
					};

					pageData.toggleIpBlock = function (state) {
						var oldRestrictAllObject = _.find(pageData.originalIps, {
							ipAddress: 'all',
							rule: 'deny',
							userId: 0
						});
						var newRestrictAllObject = {
							clientId: pageData.customerId,
							userId: 0,
							ipAddress: 'all',
							rule: 'deny',
							sortIdx: 99999
						};

						var toggleOn = state && oldRestrictAllObject === undefined;
						var toggleOff = !state && oldRestrictAllObject !== undefined;

						if (toggleOn || toggleOff) {
							pageData.pageLoading = true;

							(toggleOn
								? ClientIp.save(newRestrictAllObject, {})
								: ClientIp.delete(oldRestrictAllObject.id, {})
							)
								.then(function (res) {
									pageData.pageLoading = false;

									if (!res) {
										pageData.error = 'Broken response from server';
									} else if (res.error) {
										pageData.error = res.error;
									} else if (toggleOn) {
										pageData.enabled = true;
										pageData.originalIps.push(res.data);
									} else {
										pageData.enabled = false;
										pageData.originalIps = _.filter(pageData.originalIps, function (ipObject) {
											return ipObject.ipAddress !== 'all';
										});
									}

									$safeApply($scope);
								})
								.catch(function (err) {
									pageData.error = err;
									pageData.pageLoading = false;

									$safeApply($scope);
								});
						}
					};

					break;

				case 'administration.documentTemplates': {
					component = 'documentTemplates';

					var documentTemplateTypes = [
						'order',
						'activity',
						'client',
						'agreement',
						'object1',
						'object2',
						'object3',
						'object4'
					];
					var documentTemplates = _.reduce(
						documentTemplateTypes,
						function (res, type) {
							return res.concat(AppService.getDocumentTemplates(type));
						},
						[]
					);
					const oldTemplates = documentTemplates;

					var tagGroups = [
						{
							name: $translate.instant('tags.account'),
							tags: TagService.getClassicTagsByEntity('Client')
						},
						{
							name: $translate.instant(
								Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES')
									? 'tags.appointment'
									: 'tags.activityAppointment'
							),
							tags: TagService.getClassicTagsByEntity('Activity')
						},
						{
							name: $translate.instant('tags.order'),
							tags: TagService.getClassicTagsByEntity('Order')
						},
						{
							name: $translate.instant('tags.agreement'),
							tags: TagService.getClassicTagsByEntity('Agreement')
						}
					];

					metadata = AppService.getMetadata();
					var udoTagGroups = _.map(metadata.params.UserDefinedObject, function (udo) {
						return {
							name: udo.name,
							tags: TagService.getClassicTagsByEntity('UserDefinedObject' + udo.id)
						};
					});

					const allTags = tagGroups.concat(udoTagGroups);

					allTags.forEach(tagGroup => {
						tagGroup.tags.forEach((tag, index) => {
							tag.id = index + 1;
						});
					});

					AdminSubCtrl.rootData.pageData = {
						templates: documentTemplates,
						tags: allTags
					};

					//Binding standard templates to old ui props
					AdminSubCtrl.rootData.getrows = async function () {
						return new Promise((resolve, reject) => {
							pdfTemplateResource
								.find()
								.then(response => {
									resolve(response.data);
								})
								.catch(error => {
									reject(error);
								});
						});
					};

					// Update template details
					AdminSubCtrl.rootData.updateTemplate = async function (template, type, showNotification = true) {
						const templateId = type === 'custom' ? template.entity.templateId : template.entity.id;
						const id = templateId === 0 ? null : templateId;
						return pdfTemplateResource
							.save({ id: id, ...template })
							.then(response => {
								if (showNotification) {
									NotificationService.addNotification({
										title: 'admin.customTemplates.updateSuccess.title',
										body: 'admin.customTemplates.updateSuccess.body',
										style: NotificationService.style.SUCCESS,
										icon: 'check'
									});
								}
								return response;
							})
							.catch(error => {
								NotificationService.addNotification({
									icon: 'times',
									style: Tools.NotificationService.style.ERROR,
									title: 'default.error'
								});
								logError(error, `Failed to update the template with id ${templateId}`);
							});
					};

					// Map template user roles with system user roles
					AdminSubCtrl.rootData.mapUserRoles = function (rolesToMap) {
						const userRoles = Tools.AppService.getRoles();
						const mappedRoles = [];
						rolesToMap.forEach(templateRole => {
							userRoles.forEach(role => {
								if (templateRole.roleId === role.id) {
									mappedRoles.push(role);
								}
							});
						});
						return mappedRoles;
					};

					// Get single template
					AdminSubCtrl.rootData.getTemplate = function (templateId) {
						return pdfTemplateResource.get(templateId, { params: { isCustom: true } }).then(response => {
							const template = JSON.parse(response.data);
							const templateDetails = template.data;
							return templateDetails;
						});
					};

					// Save document activation status function
					AdminSubCtrl.rootData.saveTemplateActivation = function (
						templateToEdit,
						value,
						type,
						showNotification = true
					) {
						const updateObj = {};

						if (type === 'custom') {
							AdminSubCtrl.rootData
								.getTemplate(templateToEdit.uuid)
								.then(templateDetails => {
									const mappedUserRoles = AdminSubCtrl.rootData.mapUserRoles(templateToEdit.roles);
									const editor = {
										templateId: templateToEdit.id,
										templateName: decodeURIComponent(templateDetails.template_name),
										userRoles: mappedUserRoles,
										html: templateDetails.template_details.body,
										css: templateDetails.template_details.css || '',
										json: templateDetails.template_details.dataJson
											? JSON.stringify(templateDetails.template_details.dataJson, null, '\t')
											: '',
										language: templateDetails.template_details.language
											? JSON.stringify(templateDetails.template_details.language, null, '\t')
											: '',
										uuid: templateDetails.id,
										active: value,
										isSkeleton: templateToEdit.isSkeleton,
										isEditorFormat: templateToEdit.isEditorFormat,
										configHash: templateDetails.template_details.templateConfigHash
									};
									updateObj.dbUpdateOnly = false;
									updateObj.entity = editor;
									AdminSubCtrl.rootData.updateTemplate(updateObj, type, showNotification);
								})
								.catch(error => {
									logError(error, 'Failed to receive the template');
								});
						} else {
							updateObj.dbUpdateOnly = true;
							updateObj.entity = {
								...(templateToEdit.id !== 0 && { id: templateToEdit.id }),
								uuid: templateToEdit.uuid,
								active: value
							};
							AdminSubCtrl.rootData.updateTemplate(updateObj, type, showNotification);
						}
					};

					// Save document templates roles function
					AdminSubCtrl.rootData.saveTemplateRoles = async function (templateToEdit, roles, type) {
						const updateObj = {};
						if (type === 'custom') {
							return AdminSubCtrl.rootData
								.getTemplate(templateToEdit.uuid)
								.then(templateDetails => {
									const rolesToBeMApped = roles.map(role => {
										return { roleId: role.id };
									});
									const mappedUserRoles = AdminSubCtrl.rootData.mapUserRoles(rolesToBeMApped);
									const editor = {
										templateId: templateToEdit.id,
										templateName: templateDetails.template_name,
										userRoles: mappedUserRoles,
										html: templateDetails.template_details.body,
										css: templateDetails.template_details.css || '',
										json: templateDetails.template_details.dataJson
											? JSON.stringify(templateDetails.template_details.dataJson, null, '\t')
											: '',
										language: templateDetails.template_details.language
											? JSON.stringify(templateDetails.template_details.language, null, '\t')
											: '',
										uuid: templateDetails.id,
										active: templateToEdit.active
									};
									updateObj.dbUpdateOnly = false;
									updateObj.entity = editor;
									return AdminSubCtrl.rootData.updateTemplate(updateObj, type);
								})
								.catch(error => {
									logError(error, 'Failed to receive the template');
								});
						} else {
							const edittedRoles = roles.map(role => {
								return { roleId: role.id };
							});
							updateObj.dbUpdateOnly = true;
							updateObj.entity = {
								...(templateToEdit.id !== 0 && { id: templateToEdit.id }),
								uuid: templateToEdit.uuid,
								active: templateToEdit.active,
								roles: edittedRoles
							};
							return AdminSubCtrl.rootData.updateTemplate(updateObj, type);
						}
					};

					// Retrieve pdf file and open modal function
					AdminSubCtrl.rootData.retrievePdf = function (
						templateUuid,
						upModal,
						name,
						sampleDataJson,
						templateType,
						language,
						previewMeta
					) {
						$upModal.open('PreviewTemplate', {
							upModal: upModal,
							pdfTemplateResource: {
								resource: pdfTemplateResource,
								uuid: templateUuid,
								type: 'default',
								supportedLanguages: language,
								pdfName: name,
								sampleDataJson: sampleDataJson,
								templateType: templateType,
								previewMeta: previewMeta
							}
						});
					};

					// Delete document templates function
					AdminSubCtrl.rootData.deleteTemplate = async function (item) {
						return pdfTemplateResource
							.delete(item.id, { params: { uuid: item.uuid } })
							.then(response => {
								NotificationService.addNotification({
									title: 'admin.customTemplates.deleteSuccess.title',
									body: 'admin.customTemplates.deleteSuccess.body',
									style: NotificationService.style.SUCCESS,
									icon: 'check'
								});
								return response;
							})
							.catch(error => {
								NotificationService.addNotification({
									icon: 'times',
									style: Tools.NotificationService.style.ERROR,
									title: 'default.error'
								});
								logError(error, `Failed to delete the template with id ${item.uuid}`);
							});
					};

					AdminSubCtrl.rootData.onSave = function (action, documentTemplate) {
						var customerId = AppService.getCustomerId();
						var documentTemplates;

						switch (action) {
							case 'delete':
								return DocumentTemplate.customer(customerId)
									.delete(documentTemplate, { propagateError: true })
									.then(function (res) {
										if (res && !res.error) {
											AdminSubCtrl.rootData.pageData.templates = _.filter(
												AdminSubCtrl.rootData.pageData.templates,
												function (dt) {
													return dt.id !== documentTemplate.id;
												}
											);

											var documentTemplates = AppService.getDocumentTemplates(
												documentTemplate.type
											).filter(function (dt) {
												return dt.id !== documentTemplate.id;
											});

											AppService.setDocumentTemplates(documentTemplate.type, documentTemplates);
										}
									});
							case 'update':
								documentTemplates = AppService.getDocumentTemplates(documentTemplate.type);
								var appServiceIndex = _.findIndex(documentTemplates, { id: documentTemplate.id });
								var ctrlIndex = _.findIndex(AdminSubCtrl.rootData.pageData.templates, {
									id: documentTemplate.id
								});

								if (appServiceIndex > -1) {
									documentTemplates[appServiceIndex] = documentTemplate;
									AppService.setDocumentTemplates(documentTemplate.type, documentTemplates);
								}
								// Type was changed
								else {
									// Remove template from old type
									const found = oldTemplates.find(old => old.id === documentTemplate.id);
									if (found) {
										const oldTypeTemplates = AppService.getDocumentTemplates(found.type);
										AppService.setDocumentTemplates(
											found.type,
											oldTypeTemplates.filter(old => old.id !== found.id)
										);
									}

									// Add template to new type
									documentTemplates.push(documentTemplate);
									AppService.setDocumentTemplates(documentTemplate.type, documentTemplates);
								}
								if (ctrlIndex > -1) {
									AdminSubCtrl.rootData.pageData.templates[ctrlIndex] = documentTemplate;
								}

								break;
							case 'create':
								documentTemplates = AppService.getDocumentTemplates(documentTemplate.type);
								documentTemplates.push(documentTemplate);
								AppService.setDocumentTemplates(documentTemplate.type, documentTemplates);

								AdminSubCtrl.rootData.pageData.templates.push(documentTemplate);
								break;
						}
					};
					break;
				}
				case 'administration.products':
					component = AdminProducts;
					customerId = AppService.getCustomerId();

					AdminSubCtrl.rootData.pageData = {
						version: 0,
						removeCategory: function (category) {
							if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
								openModal('RemoveAlert', {
									title: 'default.productCategory',
									body: Tools.$translate('admin.deleteProdCatConfirm', { NAME: category.name }),
									onClose: confirmed => {
										if (confirmed) {
											ProductCategory.customer(customerId)
												.delete(category, {})
												.then(function (err) {
													if (err && err.data && err.data.error.errorCode === 100) {
														$upModal.open('warningAlert', {
															title: 'admin.cantDelete',
															body: 'confirm.categoryOrProductExist',
															icon: 'fa-warning'
														});
													}
												})
												.catch(e => console.log('delete product error', e));
										}
									}
								});
								return;
							}

							// eslint-disable-next-line promise/catch-or-return
							$upModal
								.open('warningConfirm', {
									title: 'confirm.removeCategory',
									body: Tools.$translate('admin.deleteProdCatConfirm', { NAME: category.name }),
									resolveTrue: 'default.remove',
									icon: 'fa-warning'
								})
								.then(function () {
									// remove from server
									ProductCategory.customer(customerId)
										.delete(category, {})
										.then(function (err) {
											if (err && err.data && err.data.error.errorCode === 100) {
												$upModal.open('warningAlert', {
													title: 'admin.cantDelete',
													body: 'confirm.categoryOrProductExist',
													icon: 'fa-warning'
												});
											}
										})
										.catch(e => console.log('delete product error', e));
								});
						}
					};

					var update = function (type, data, shallRemove) {
						if (type === 'Product') {
							if (!_.isArray(data)) {
								data = [data];
							}
							var products = AppService.getProducts(false, false, true);
							_.each(data, function (p) {
								var productIndex = _.findIndex(products, { id: p.id || p });
								if (productIndex !== -1) {
									if (shallRemove) {
										products.splice(productIndex, 1);
									} else {
										p.$hasAccess = true;
										products[productIndex] = p;
									}
								} else {
									p.$hasAccess = true;
									products.push(p);
								}
							});
							AppService.setProducts(products);
						}

						if (type === 'ProductCategory') {
							var productCategories = AppService.getProductCategories();
							var productCategoryIndex = _.findIndex(productCategories, { id: data.id || data });
							if (productCategoryIndex !== -1) {
								if (shallRemove) {
									productCategories.splice(productCategoryIndex, 1);
								} else {
									data.$hasAccess = true;
									productCategories[productCategoryIndex] = data;
								}
							} else {
								data.$hasAccess = true;
								productCategories.push(data);
							}
							AppService.setProductCategories(productCategories);
						}

						AdminSubCtrl.rootData.pageData.version = AdminSubCtrl.rootData.pageData.version + 1;

						$safeApply($scope);
					};

					AdminSubCtrl.rootData.pageData.onMoveProducts = function (item, target, down) {
						const hasTooManyProducts = Tools.AppService.getTotals('products') > 4000;
						const toSave = [];

						const itemSortId = item.sortId;

						item.sortId = target.sortId;
						target.sortId = itemSortId;

						item.sortId = down && !item.sortId ? 1 : item.sortId;
						target.sortId = !down && !target.sortId ? 1 : target.sortId;

						toSave.push({ id: item.id, sortId: item.sortId, custom: item.custom });
						toSave.push({ id: target.id, sortId: target.sortId, custom: target.custom });

						Product.customer(customerId)
							.saveMulti(toSave)
							.then(function () {
								AdminSubCtrl.rootData.saving = false;
								if (!hasTooManyProducts) {
									var oldProducts = AppService.getProducts();
									for (const p of toSave) {
										const i = oldProducts.findIndex(product => product.id === p.id);
										if (i !== -1) {
											oldProducts[i].sortId = p.sortId;
										}
									}
									AppService.setProducts(oldProducts);
								}
								AdminSubCtrl.rootData.pageData.version = AdminSubCtrl.rootData.pageData.version + 1;

								$safeApply($scope);
							})
							.catch(function () {
								AdminSubCtrl.rootData.saving = false;
							});
					};

					AdminSubCtrl.rootData.pageData.checkProduct = function (item) {
						let rb = new RequestBuilder();
						rb.addFilter(Order.attr.product, rb.comparisonTypes.Equals, item.id);
						rb.limit = 0;

						var query = rb.build();
						return Order.customer(AppService.getCustomerId())
							.find(query)
							.then(function (res) {
								var count = res.metadata.total;

								rb = new RequestBuilder();
								rb.addFilter(Agreement.attr.product, rb.comparisonTypes.Equals, item.id);
								rb.limit = 0;

								const query = rb.build();
								return Agreement.customer(AppService.getCustomerId())
									.find(query)
									.then(function (res) {
										count += res.metadata.total;
										return count;
									});
							});
					};
					AdminSubCtrl.rootData.pageData.onDeleteProduct = function (item) {
						Product.customer(AppService.getCustomerId())
							.delete(item)
							.then(function (res) {
								const oldProducts = AppService.getProducts();
								if (res && !res.error) {
									const products = _.filter(oldProducts, function (product) {
										return product.id !== item.id;
									});
									AppService.setProducts(products);

									AdminSubCtrl.rootData.pageData.version = AdminSubCtrl.rootData.pageData.version + 1;
									$safeApply($scope);
								}
							})
							.catch(function (err) {
								AdminSubCtrl.rootData.saving = false;

								if (
									err &&
									err.data &&
									err.data.error ===
										'Product delete: Can not delete because it exists orders or opportunities with the product you try to delete'
								) {
									$upModal.open('infoAlert', {
										title: $translate.instant('default.warning'),
										body: $translate.instant('admin.products.productHasOrders')
									});
								}
							});
					};

					$scope.$on('productCategory.deleted', function (opts, data) {
						update('ProductCategory', data, true);
					});

					$scope.$on('product.updated', function (opts, data) {
						update('Product', data);
					});

					$scope.$on('product.added', function (opts, data) {
						update('Product', data);
					});

					$scope.$on('product.deleted', function (opts, data) {
						update('Product', data, true);
					});

					$scope.$on('productCategory.updated', function (opts, data) {
						update('ProductCategory', data);
					});

					$scope.$on('productCategory.added', function (opts, data) {
						update('ProductCategory', data);
					});

					break;

				case 'administration.usersAndRoles':
					component = 'usersAndRoles';

					initialData = AppService.getUsers();

					var rawUserData = _.cloneDeep(initialData);

					var updateTree = function (type, data, shallRemove) {
						if (type === 'Role') {
							var roleMap = AppService.getRoleMap();
							var roleIndex = _.findIndex(roleMap.all, { id: data.id || data });

							if (roleIndex !== -1) {
								if (shallRemove) {
									roleMap.all.splice(roleIndex, 1);
								} else {
									roleMap.all[roleIndex] = data;
								}
							} else {
								roleMap.all.push(data);
							}
							AppService.setRoleMap(roleMap);
						}

						var deletedAndInactiveUsers = AppService.getUsers('inactive', false, true)
							.concat(AppService.getUsers('deleted', false, true))
							.sort(function (a, b) {
								if (a.name < b.name) {
									return -1;
								}
								if (a.name > b.name) {
									return 1;
								}
								return 0;
							});
						AdminSubCtrl.rootData.pageData = {
							roles: AppService.getRoles(),
							activeUsers: AppService.getActiveUsers(true),
							deletedAndInactiveUsers: deletedAndInactiveUsers,
							userTree: null
						};
						UserTreeFilterMeta(null, true, false, true)
							.then(function (res) {
								AdminSubCtrl.rootData.pageData.random = Math.random() * 10;
								AdminSubCtrl.rootData.pageData.userTree = res.data;
							})
							.catch(e => console.log('generate userTree error', e));
					};

					AdminSubCtrl.rootData.onChange = function (currentUsers, savingUser) {
						if (!savingUser) {
							return;
						}

						User.update(savingUser)
							.then(function () {
								//var hash = getCurrentHash(currentUsers);
								AdminSubCtrl.rootData.pageData.users = currentUsers;
							})
							.catch(function (error) {
								console.error('Error in UserSave', error);
								AdminSubCtrl.rootData.saving = false;
								AdminSubCtrl.rootData.saveVisible = false;
							});
					};

					AdminSubCtrl.rootData.onSave = function () {
						AdminSubCtrl.rootData.saving = true;

						var itemsToSave = [];
						_.each(AdminSubCtrl.rootData.pageData.users, function (user) {
							_.each(rawUserData, function (initUser) {
								if (initUser.id === user.id) {
									var tempUser = _.clone(user);
									var tempinitUser = _.clone(initUser);

									delete tempUser.sortId;
									delete tempinitUser.sortId;

									if (!_.isEqual(tempUser, tempinitUser)) {
										itemsToSave.push(user);
									}
								}
							});
						});

						_.each(itemsToSave, function (item) {
							User.update(item)
								.then(function () {
									AdminSubCtrl.rootData.saving = false;
									AdminSubCtrl.rootData.saveVisible = false;
								})
								.catch(function (error) {
									console.error('Error in UserSave', error);
									AdminSubCtrl.rootData.saving = false;
									AdminSubCtrl.rootData.saveVisible = false;
								});
						});
					};

					$scope.$on('role.deleted', function (opts, data) {
						updateTree('Role', data, true);
					});

					$scope.$on('role.updated', function (opts, data) {
						updateTree('Role', data);
					});

					$scope.$on('role.added', function (opts, data) {
						updateTree('Role', data);
					});

					updateTree();

					break;

				case 'administration.singleuser':
					component = 'singleuser';
					break;

				case 'administration.fields':
					component = 'fields';

					var hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
					if (hasNewFields) {
						component = Fields;
					}

					var customFieldType = $state.params.type || 'account';
					var standardFieldType = $state.params.type || 'client';
					var udoCf = {
						userDefined1: 'UserDefinedObjectCustomField',
						userDefined2: 'UserDefinedObject2CustomField',
						userDefined3: 'UserDefinedObject3CustomField',
						userDefined4: 'UserDefinedObject4CustomField'
					};

					var cfTypesMap = {
						activity: 'ActivityCustomField',
						todo: 'TodoCustomField',
						agreement: 'AgreementCustomField',
						appointment: 'AppointmentCustomField',
						account: 'ClientCustomField',
						contact: 'ContactCustomField',
						lead: 'LeadCustomField',
						order: 'OrderCustomField',
						orderrow: 'OrderedProductCustomField',
						product: 'ProductCustomField',
						project: 'ProjectCustomField',
						projectPlan: 'ProjectPlanCustomField',
						user: 'UserCustomField',
						ticket: 'TicketCustomField'
					};

					standardFieldType = standardFieldType.charAt(0).toUpperCase() + standardFieldType.slice(1);

					switch (customFieldType) {
						case 'account':
							standardFieldType = 'Client';
							break;
						case 'orderAndOpportunities':
							customFieldType = 'order';
							standardFieldType = 'Order';
							break;
						case 'campaign':
							customFieldType = 'project';
							break;
					}

					var canAddMap = {
						account: 'CREATE_CUSTOM_FIELDS_CLIENT',
						contact: 'CREATE_CUSTOM_FIELDS_CONTACT',
						activity: 'CREATE_CUSTOM_FIELDS_ACTIVITY',
						todo: 'CREATE_CUSTOM_FIELDS_ACTIVITY',
						appointment: 'CREATE_CUSTOM_FIELDS_APPOINTMENT',
						project: 'CREATE_CUSTOM_FIELDS_PROJECT',
						projectPlan: 'CREATE_CUSTOM_FIELDS_PROJECT_PLAN',
						user: 'CREATE_CUSTOM_FIELDS_USER',
						agreement: 'CREATE_CUSTOM_FIELDS_RECCURING_ORDER',
						order: 'CREATE_CUSTOM_FIELDS_ORDER',
						orderrow: 'CREATE_CUSTOM_FIELDS_ORDER_ROW',
						product: 'CREATE_CUSTOM_FIELDS_PRODUCT',
						ticket: 'CREATE_CUSTOM_FIELDS_TICKET'
					};
					var canAdd = Tools.FeatureHelper.isAvailable(
						Tools.FeatureHelper.Feature[canAddMap[customFieldType]]
					);

					if (customFieldType.indexOf('userDefined') === 0) {
						canAdd = true;
					}

					var udoDefinition = udoCf[customFieldType];
					var customFields = AppService.getCustomFields(customFieldType);
					customFields.sort(sortFunction);

					AdminSubCtrl.rootData.pageData = {
						customFields: customFields,
						proxyCustomFields: customFields,
						standardFields: null,
						standardFieldType: standardFieldType,
						customFieldType: customFieldType,
						canAdd: canAdd
					};

					var loadStandardFields = function (shouldResetMeta) {
						AdminSubCtrl.rootData.pageLoading = true;
						return StandardField.setType(standardFieldType)
							.list()
							.then(res => {
								AdminSubCtrl.rootData.pageLoading = false;

								AdminSubCtrl.rootData.pageData.standardFields = res.data[standardFieldType];
								AdminSubCtrl.rootData.pageData.standardFieldType = standardFieldType;

								AdminSubCtrl.rootData.pageData.standardFields = _.omit(
									AdminSubCtrl.rootData.pageData.standardFields,
									function (field) {
										return field.disabled;
									}
								);

								if (shouldResetMeta) {
									const metadata = AppService.getMetadata();

									AppService.setMetadata({
										...metadata,
										standardFields: {
											...metadata.standardFields,
											[standardFieldType]: res.data[standardFieldType]
										}
									});
								}
							})
							.catch(e => logError(e, 'standard field list error'));
					};

					AdminSubCtrl.rootData.reLoadStandardFields = function () {
						loadStandardFields(true);
					};

					loadStandardFields();

					var insertField = function (field) {
						var oldCfIndex = _.findIndex(AdminSubCtrl.rootData.pageData.customFields, { id: field.id });

						if (oldCfIndex > -1) {
							AdminSubCtrl.rootData.pageData.customFields[oldCfIndex] = field;
						} else {
							AdminSubCtrl.rootData.pageData.customFields.push(field);
						}
					};

					AdminSubCtrl.rootData.onChange = function (type, field) {
						if (Array.isArray(field)) {
							AdminSubCtrl.rootData.pageData.customFields = field;
						} else {
							insertField(field);
						}
					};

					AdminSubCtrl.rootData.onSave = function (type, action, field, listType) {
						AdminSubCtrl.rootData.saving = true;

						var customerId = AppService.getCustomerId();

						if (type === 'customField') {
							switch (action) {
								case 'delete':
									var listViews =
										Tools.AppService.getListViews(
											listType || AdminSubCtrl.rootData.pageData.customFieldType
										) || [];
									var customString = 'Custom_' + field.id.toString();

									var viewsToUpdate = [];
									listViews.forEach(function (view) {
										var filtersFound = _.filter(view.filters, function (vf) {
											return vf.field === field.id.toString();
										});

										if (filtersFound.length > 0) {
											viewsToUpdate.push(view);
										} else {
											var sortingFound = _.filter(view.sorting, function (vs) {
												if (
													vs.attribute.indexOf('Custom_') === 0 &&
													vs.attribute === customString
												) {
													return true;
												}

												return false;
											});

											if (sortingFound.length > 0) {
												viewsToUpdate.push(view);
											} else {
												var columnFound = _.filter(view.columns, function (vc) {
													return vc === customString;
												});

												if (columnFound.length > 0) {
													viewsToUpdate.push(view);
												}
											}
										}
									});

									return new Promise(function (resolve) {
										async.each(
											viewsToUpdate,
											function (view, cb) {
												view.filters = _.filter(view.filters, function (vf) {
													return vf.field !== field.id.toString();
												});

												view.sorting = _.filter(view.sorting, function (vs) {
													return (
														vs.attribute.indexOf('Custom_') !== 0 ||
														vs.attribute !== customString
													);
												});

												view.columns = _.filter(view.columns, function (vc) {
													return vc !== customString;
												});

												Tools.ListViewService.save(view.type, view, customerId, {
													skipNotification: true
												})
													.then(function () {
														cb();
													})
													.catch(e => console.log('save listView error', e));
											},
											function () {
												CustomField.customer(customerId)
													.setType(customFieldType)
													.delete(field)
													.then(function () {
														var index = _.findIndex(customFields, { id: field.id });

														if (index > -1) {
															customFields.splice(index, 1);
														}

														CacheRefresher.refresh([
															udoDefinition ? udoDefinition : cfTypesMap[customFieldType]
														]);
														AdminSubCtrl.rootData.saving = false;
														resolve();
													})
													.catch(e => console.log('delete custom field error', e));
											}
										);
									});

								case 'save':
									delete field.parFieldId;

									CustomField.customer(customerId)
										.setType(customFieldType)
										.save(field)
										.then(function (res) {
											insertField(res.data);
											AdminSubCtrl.rootData.pageData.proxyCustomFields =
												AdminSubCtrl.rootData.pageData.customFields;
											AdminSubCtrl.rootData.saving = false;
											// eslint-disable-next-line promise/catch-or-return
											CustomField.customer(customerId)
												.setType(customFieldType)
												.find({})
												.then(function (res) {
													AppService.setCustomFields(customFieldType, res.data);
												});
										})
										.catch(e => console.log('save custom field error', e));
									break;

								case 'saveSort':
									var fields = _.map(field, function (cf, index) {
										cf.sortId = index;
										return cf;
									});
									AdminSubCtrl.rootData.pageData.proxyCustomFields = field;
									CustomField.customer(customerId)
										.setType(customFieldType)
										.saveMulti(fields)
										.then(function () {
											AppService.setCustomFields(customFieldType, fields);
										})
										.finally(function () {
											AdminSubCtrl.rootData.saving = false;
										})
										.catch(e => console.log('save multi customfield error', e));
									break;
							}
						} else {
							switch (action) {
								case 'save':
									var saveObj = {
										id: field.id,
										required: field.required,
										active: field.active,
										tooltip: field.tooltip
									};

									StandardField.setType(standardFieldType)
										.save(saveObj)
										.then(function (res) {
											if (!res.error) {
												var metadata = AppService.getMetadata();
												metadata.requiredFields[standardFieldType][field.name] = field.required;
												metadata.standardFields[standardFieldType][field.name] = field;
												AppService.setMetadata(metadata);
												TriggerHelper.buildCriterias();

												AdminSubCtrl.rootData.pageData.standardFields[field.name] = field;
											}
											AdminSubCtrl.rootData.saving = false;
										})
										.catch(e => console.log('save standard field error', e));
									break;
							}
						}
					};
					break;

				case 'administration.profile':
					component = 'profile';
					var masterCurrency = _.find(AppService.getMetadata().customerCurrencies, 'masterCurrency').iso;
					customerId = AppService.getCustomerId();

					AdminSubCtrl.rootData.pageData = {
						user: AppService.getSelf(),
						features: AppService.getAccountSelf().features,
						customerId: AppService.getCustomerId(),
						URL: URL,
						API: API,
						masterCurrency: masterCurrency,
						statsLoading: true,
						stats: {
							activities: 0,
							appointments: 0
						},
						canChangePassword: !AppService.getMetadata().params.HasSamlLogin
					};

					File.find({ entityId: AdminSubCtrl.rootData.pageData.user.id, entity: 'ProfileImage' })
						.then(function (res) {
							if (res.data.length) {
								AdminSubCtrl.rootData.pageData.profileImageId = res.data[0].id;
							}
						})
						.catch(e => console.log('find file error', e));

					initialData = _.cloneDeep(AdminSubCtrl.rootData.pageData.user);

					AdminSubCtrl.rootData.imgChanged = function (id) {
						Tools.AppService.setProfileImageId(AdminSubCtrl.rootData.pageData.user.id, id);
						AdminSubCtrl.rootData.pageData.profileImageId = id;
					};

					AdminSubCtrl.rootData.onChange = function (useUser) {
						AdminSubCtrl.rootData.pageData.user = useUser;
						AdminSubCtrl.rootData.saveVisible = !_.isEqual(useUser, initialData);
						$safeApply($scope);
					};

					var getTotal = function (res) {
						if (res && res.metadata) {
							return res.metadata.total || 0;
						}

						return 0;
					};

					var getStats = function () {
						var userId = AppService.getSelf().id;
						var salesRb = new Tools.RequestBuilder();
						salesRb.addFilter({ field: 'user.id' }, salesRb.comparisonTypes.Equals, userId);

						var promises = {
							masterCurrency: OrderHelper.getMasterCurrencySum(salesRb, customerId),
							roleCurrency: OrderHelper.getRoleCurrencySum(salesRb, customerId)
						};

						return $q.all({
							activities: Tools.Activity.customer(customerId)
								.find({ 'user.id': userId, limit: 0 })
								.then(getTotal),
							appointments: Tools.Appointment.customer(customerId)
								.find({ 'user.id': userId, limit: 0 })
								.then(getTotal),
							opportunities: Tools.Order.customer(customerId)
								.find({ 'user.id': userId, limit: 0 })
								.then(getTotal),
							sales: $q.all(promises).then(function (res) {
								var sum = '';
								var masterCurrencyData = res.masterCurrency.data;
								var roleData = res.roleCurrency.data;

								var metadata = AppService.getMetadata();
								var currencies = metadata.customerCurrencies;
								var currency = _.find(metadata.customerCurrencies, 'masterCurrency').iso;

								var hasMultiCurrencies =
									currencies && currencies.length > 1 && metadata.params.MultiCurrency;
								var hasAggregationData =
									roleData &&
									roleData.currency &&
									roleData.currency.buckets &&
									roleData.currency.buckets.length;
								var roleCurrency = metadata.role
									? _.find(currencies, function (curr) {
											return curr.iso === metadata.role.defaultCurrency;
									  })
									: null;

								if (hasMultiCurrencies && hasAggregationData && roleCurrency) {
									var currecyMap = {};
									var totalSumInRoleCurrency = 0;

									_.each(roleData.currency.buckets, function (currency) {
										currecyMap[currency.key] = {
											sum: currency.sum.value,
											sumInMasterCurrency: currency.sumInMasterCurrency.value
										};

										if (currency.key === roleCurrency.iso.toLowerCase()) {
											totalSumInRoleCurrency += currency.sum.value;
										} else {
											totalSumInRoleCurrency +=
												currency.sumInMasterCurrency.value * roleCurrency.rate;
										}
									});

									sum = currencyFormat(totalSumInRoleCurrency, roleCurrency.iso) || 0;
								} else if (masterCurrencyData && masterCurrencyData.sum) {
									sum = currencyFormat(masterCurrencyData.sum.value, currency) || 0;
								}

								return sum;
							})
						});
					};

					getStats()
						.then(function (res) {
							AdminSubCtrl.rootData.pageData.stats = res;
							AdminSubCtrl.rootData.pageData.statsLoading = false;
						})
						.catch(e => console.log('getStats error', e));

					var paramMap = {
						startPage: 1,
						mailAdmin: 55,
						multiSoliditetAccess: 56,
						dnbCountryCodes: 57,
						timeZone: 53,
						locale: 58
					};

					var mapUserParams = function (params) {
						return _.chain(params)
							.map(function (value, key) {
								if (!paramMap[key]) {
									return null;
								}

								return { param_id: paramMap[key], value: value };
							})
							.filter(function (field) {
								return field;
							})
							.value();
					};

					AdminSubCtrl.rootData.removeImage = function () {
						File.delete(
							{ id: AdminSubCtrl.rootData.pageData.profileImageId, entity: 'ProfileImage' },
							{ noConfirm: true }
						);
						AdminSubCtrl.rootData.pageData.profileImageId = null;
					};

					var save = function (user) {
						user.userParams = mapUserParams(user.userParams);
						user.clientId = Tools.AppService.getCustomerId();

						return User.update(user).then(function () {
							Self.getCustomerSelf(customerId)
								.then(function (res) {
									res.userParams = Object.assign(
										{},
										res.userParams,
										AdminSubCtrl.rootData.pageData.user.userParams
									);
									AppService.setSelf(res);
								})
								.catch(e => console.log('getSelf error', e));
						});
					};

					AdminSubCtrl.rootData.updateUserPhone = function (userPhone) {
						const id = AppService.getSelf().id;
						const props = { id, userPhone };

						AdminSubCtrl.rootData.updatingPhone = true;

						save(props)
							.then(function () {
								const newUser = Object.assign(AdminSubCtrl.rootData.pageData.user, { userPhone });
								initialData = Object.assign({}, initialData, { userPhone });
								AdminSubCtrl.rootData.saveVisible = !_.isEqual(newUser, initialData);
								AdminSubCtrl.rootData.pageData.user = newUser;
							})
							.finally(function () {
								AdminSubCtrl.rootData.updatingPhone = false;
							})
							.catch(e => console.log('save self error', e));
					};

					AdminSubCtrl.rootData.onSave = function () {
						AdminSubCtrl.rootData.saving = true;
						var user = Object.assign({}, AdminSubCtrl.rootData.pageData.user);
						// update translate service language
						let reload = false;
						if (user.language !== AppService.getSelf().language) {
							reload = true;
						}

						save(user)
							.then(function () {
								AdminSubCtrl.rootData.saveVisible = false;
								initialData = _.cloneDeep(AdminSubCtrl.rootData.pageData.user);

								if (reload) {
									setUILanguage(user.language)
										.then(() => {
											Tools.$state.reload();
										})
										.catch(e => console.log('set ui lang error', e));
								}
							})
							.finally(function () {
								AdminSubCtrl.rootData.saving = false;
							})
							.catch(e => console.log('save user error', e));
					};

					var mapFeatures = function () {
						AdminSubCtrl.rootData.pageData.experimentalFeatures = _.map(
							Object.keys(Tools.AppService.getAccountSelf().userActivatedFeatures || {}),
							function (key) {
								return {
									name: key,
									active: Tools.AppService.getAccountSelf().userActivatedFeatures[key].active,
									released: !!Tools.AppService.getAccountSelf().userActivatedFeatures[key].released
								};
							}
						);

						AdminSubCtrl.rootData.pageData.experimentalFeatures = _.filter(
							AdminSubCtrl.rootData.pageData.experimentalFeatures,
							{ released: false }
						);
					};

					// Map experimentalFeatures
					mapFeatures();

					AdminSubCtrl.rootData.toggleExperimentalFeature = function (featureName, val) {
						var self = Tools.AppService.getAccountSelf();
						self.userActivatedFeatures[featureName] = val;
						Tools.AppService.setAccountSelf(self);

						Tools.UnreleasedFeatureUser.toggle({ name: featureName, active: val });
					};
					break;

				case 'administration.dashboard':
					component = 'dashboard';
					AdminSubCtrl.rootData.pageLoading = true;

					AdminSubCtrl.rootData.pageData = {
						accountSelf: AppService.getAccountSelf(),
						self: AppService.getSelf(),
						profile: Tools.AccountProfile.new(),
						hasProfile: false,
						loadingSeminars: true,
						unfinishedOnboarding: false,
						onboardingTrack: null
					};

					var updateOnboarding = function () {
						var unfinishedOnboarding = false;
						var meta = Tools.AppService.getMetadata();
						var track = null;
						if (meta.listOnboarding && meta.listOnboarding.track) {
							var steps = _.pull(Object.keys(meta.listOnboarding.track), 'type');
							track = { total: 0, done: 0, next: null };
							_.each(steps, function (step) {
								if (step === 'type') {
									return;
								}
								track.total++;
								if (meta.listOnboarding.track[step]) {
									track.done++;
								} else {
									unfinishedOnboarding = true;
									if (!track.next) {
										track.next = step;
									}
								}
							});

							AdminSubCtrl.rootData.pageData.onboardingTrack = track;
							AdminSubCtrl.rootData.pageData.unfinishedOnboarding = unfinishedOnboarding;
						}
					};

					updateOnboarding();

					$scope.$on('onboarding.updated', function () {
						updateOnboarding();
					});

					Tools.AccountProfile.get()
						.then(function (res) {
							if (res.data) {
								AdminSubCtrl.rootData.pageData.profile = res.data;
								AdminSubCtrl.rootData.pageData.hasProfile = true;
							}
						})
						.finally(function () {
							AdminSubCtrl.rootData.pageLoading = false;
						})
						.catch(e => console.log('get account profile error', e));
					break;

				case 'administration.2fa':
					component = 'twofa';

					AdminSubCtrl.rootData.loading = false;
					AdminSubCtrl.rootData.pageData = {
						smsAuth: AppService.getMetadata().params.SmsAuthentication
					};

					AdminSubCtrl.rootData.pageData.users = _.filter(AppService.getUsers(), function (user) {
						return user.ghost !== 1;
					});

					initialData = AdminSubCtrl.rootData.pageData.smsAuth;

					AdminSubCtrl.rootData.onChange = function (useSmsAuth) {
						AdminSubCtrl.rootData.pageData.smsAuth = useSmsAuth;

						var value = AdminSubCtrl.rootData.pageData.smsAuth ? '1' : '0';
						ClientParam.save(142, value)
							.then(function (res) {
								if (res && res.data) {
									var metadata = AppService.getMetadata();
									metadata.params.SmsAuthentication = res.data.value === '1' ? true : false;
									AppService.setMetadata(metadata);

									AdminSubCtrl.rootData.pageData.smsAuth = metadata.params.SmsAuthentication;
								}
							})
							.catch(e => console.log('save client param error', e));
					};
					break;

				case 'administration.activityTypes':
					component = 'activityTypes';

					var typeAdded = function (type, e, added) {
						var types = Tools.AppService.getActivityTypes(type);
						types.push(added);
						Tools.AppService.setActivityTypes(type, types);
					};

					var typeUpdated = function (type, e, updated) {
						// Update appService
						var types = AppService.getActivityTypes(type);
						var appserviceIndex = updated ? _.findIndex(types, { id: updated.id }) : -1;
						if (appserviceIndex !== -1) {
							types[appserviceIndex] = updated;
							AppService.setActivityTypes(type, types);
						}
					};

					$scope.$on('appointmentType.added', typeAdded.bind(null, 'appointment'));
					$scope.$on('activityType.added', typeAdded.bind(null, 'activity'));
					$scope.$on('appointmentType.updated', typeUpdated.bind(null, 'appointment'));
					$scope.$on('activityType.updated', typeUpdated.bind(null, 'activity'));

					var sortTypes = function (defaultId, types) {
						return _.sortBy(types, function (type) {
							if (type.id === defaultId) {
								type.default = true;
							} else {
								type.default = false;
							}
							return type.name.toLowerCase();
						});
					};

					metadata = AppService.getMetadata();
					AdminSubCtrl.rootData.pageData = {
						activityTypes: sortTypes(
							metadata.params.DefaultActivityTypeId,
							AppService.getActivityTypes('activity')
						),
						appointmentTypes: sortTypes(
							metadata.params.DefaultAppointmentTypeId,
							AppService.getActivityTypes('appointment')
						),
						roles: AppService.getRoles(),
						merging: false
					};

					AdminSubCtrl.rootData.doMerge = function (mergeObject, callback) {
						if (AdminSubCtrl.rootData.pageData.merging) {
							return;
						}
						AdminSubCtrl.rootData.pageData.merging = true;
						var MergeResource = mergeObject.isAppointment ? AppointmentType : ActivityType;
						var types = mergeObject.isAppointment
							? AdminSubCtrl.rootData.pageData.appointmentTypes
							: AdminSubCtrl.rootData.pageData.activityTypes;
						var idArray = [];
						var roles = [];
						var roleIds = [];

						_.forEach([mergeObject.typeOne, mergeObject.typeTwo], function (type) {
							idArray.push(type.id);
							roleIds = roleIds.concat(_.map(type.roles, 'id'));
							roles = roles.concat(type.roles);
						});
						var keepId = idArray.pop();

						async.eachSeries(
							idArray,
							function (mergeId, cb) {
								MergeResource.merge(keepId, mergeId, { skipNotification: true })
									.then(function () {
										const defaultType = mergeObject.isAppointment
											? 'DefaultAppointmentTypeId'
											: 'DefaultActivityTypeId';
										if (metadata.params[defaultType] === mergeId) {
											metadata = AppService.getMetadata();
											metadata.params[defaultType] = keepId;
											AppService.setMetadata(metadata);
										}
										cb();
									})
									.catch(cb);
							},
							function (err) {
								if (err) {
									AdminSubCtrl.rootData.pageData.merging = false;
									return callback(err);
								}
								var keepType = {
									id: keepId,
									name: mergeObject.name,
									roles: _.unique(roleIds)
								};
								MergeResource.save(keepType)
									.then(function () {
										_.forEach(idArray, function (removeId) {
											var index = _.findIndex(types, { id: removeId });
											if (index > -1) {
												types.splice(index, 1);
											}
										});

										var index = _.findIndex(types, { id: keepId });
										if (index > -1) {
											types[index].name = mergeObject.name;
											types[index].roles = _.unique(roles, function (role) {
												return role.id;
											});
										}

										if (mergeObject.isAppointment) {
											AdminSubCtrl.rootData.pageData.appointmentTypes = sortTypes(
												metadata.params.DefaultAppointmentTypeId,
												types
											);
										} else {
											AdminSubCtrl.rootData.pageData.activityTypes = sortTypes(
												metadata.params.DefaultActivityTypeId,
												types
											);
										}

										AdminSubCtrl.rootData.pageData.merging = false;

										$safeApply($scope);
										callback(null, keepType);
									})
									.catch(err => {
										AdminSubCtrl.rootData.pageData.merging = false;
										callback(err);
									});
							}
						);
					};

					AdminSubCtrl.rootData.onSave = function (type, item, callback) {
						var isAppointment = type === 'appointment';
						var types = isAppointment
							? AdminSubCtrl.rootData.pageData.appointmentTypes
							: AdminSubCtrl.rootData.pageData.activityTypes;
						var TypeResource = isAppointment ? AppointmentType : ActivityType;

						async.waterfall([
							function saveType(cb) {
								TypeResource.save(item)
									.then(function (res) {
										if (res && res.data) {
											var savedType = res.data;
											var index = _.findIndex(types, { id: savedType.id });
											var defaultId;

											if (index > -1) {
												types[index] = savedType;
											} else {
												types.push(savedType);
											}
											if (isAppointment) {
												defaultId = item.default
													? res.data.id
													: metadata.params.DefaultAppointmentTypeId;
												AdminSubCtrl.rootData.pageData.appointmentTypes = sortTypes(
													defaultId,
													types
												);
											} else {
												defaultId = item.default
													? res.data.id
													: metadata.params.DefaultActivityTypeId;
												AdminSubCtrl.rootData.pageData.activityTypes = sortTypes(
													defaultId,
													types
												);
											}

											cb(null, defaultId);
											callback(null, savedType);
										}
									})
									.catch(e => {
										console.log('save activityType error', e);
										callback(e);
									});
							},
							function setDefault(defaultId, cb) {
								if (!item.default) {
									return cb(null, null);
								}
								if (isAppointment) {
									ClientParam.save(213, defaultId, { skipNotification: true })
										.then(function () {
											metadata = AppService.getMetadata();
											metadata.params.DefaultAppointmentTypeId = defaultId;
											AppService.setMetadata(metadata);
											cb();
										})
										.catch(e => console.log('save client param error', e));
								} else {
									ClientParam.save(5, defaultId, { skipNotification: true })
										.then(function () {
											metadata = AppService.getMetadata();
											metadata.params.DefaultActivityTypeId = defaultId;
											AppService.setMetadata(metadata);
											cb();
										})
										.catch(e => console.log('save client param error', e));
								}
							}
						]);
					};
					break;

				case 'administration.orderstages':
					component = 'orderstages';
					AdminSubCtrl.rootData.pageData = {};
					AdminSubCtrl.rootData.pageData.hasRoleFeature = Tools.FeatureHelper.isAvailable(
						Tools.FeatureHelper.Feature.USER_PERMISSIONS_ADVANCED
					);

					var stageFilter = new RequestBuilder();
					stageFilter.addSort(OrderStage.attr.probability, true);
					stageFilter.addSort(OrderStage.attr.name, true);

					OrderStage.customer(AppService.getCustomerId())
						.find(stageFilter.build())
						.then(function (res) {
							AppService.setStages(res.data);

							return res.data;
						})
						.then(function (stages) {
							AdminSubCtrl.rootData.pageData.stages = stages;
							AdminSubCtrl.rootData.pageData.roles = AppService.getRoles();
						})
						.catch(e => console.log('save order stage error', e));

					AdminSubCtrl.rootData.pageData.checkStage = function (item) {
						var rb = new RequestBuilder();
						rb.addFilter(Order.attr.stage, rb.comparisonTypes.Equals, item.id);
						rb.limit = 0;

						var query = rb.build();
						return Order.customer(AppService.getCustomerId())
							.find(query)
							.then(function (res) {
								const orderCount = res.metadata.total;

								rb = new RequestBuilder();
								rb.addFilter(Agreement.attr.stage, rb.comparisonTypes.Equals, item.id);
								rb.limit = 0;

								var query = rb.build();
								return Agreement.customer(AppService.getCustomerId())
									.find(query)
									.then(function (res) {
										const subscriptionCount = res.metadata.total;
										return { orderCount, subscriptionCount };
									});
							});
					};

					AdminSubCtrl.rootData.pageData.onDeleteStage = function (item) {
						AdminSubCtrl.rootData.saving = true;

						OrderStage.customer(AppService.getCustomerId())
							.delete(item)
							.then(function (res) {
								AdminSubCtrl.rootData.saving = false;

								if (res && !res.error) {
									AdminSubCtrl.rootData.pageData.stages = _.filter(
										AdminSubCtrl.rootData.pageData.stages,
										function (stage) {
											return stage.id !== item.id;
										}
									);
								}
							})
							.catch(function (err) {
								AdminSubCtrl.rootData.saving = false;

								if (
									err &&
									err.data &&
									err.data.error ===
										'Order stage delet: Can not delete because it exists orders or opportunities within the stage you try to delete'
								) {
									$upModal.open('infoAlert', {
										title: $translate.instant('default.warning'),
										body: $translate.instant('admin.stages.stageHasOrders')
									});
								}
							});
					};

					AdminSubCtrl.rootData.onSave = function (item) {
						AdminSubCtrl.rootData.saving = true;

						OrderStage.customer(AppService.getCustomerId())
							.save(item)
							.then(function (res) {
								AdminSubCtrl.rootData.saving = false;

								if (res && res.data) {
									var stage = res.data;
									var stages = AppService.getStages('all', true);

									var index = _.findIndex(stages, { id: stage.id });

									if (index > -1) {
										stages[index] = stage;
									} else {
										stages.push(stage);
									}

									AppService.setStages(stages);

									AdminSubCtrl.rootData.pageData.stages = _.sortBy(stages, 'probability');
								}
							})
							.catch(function () {
								AdminSubCtrl.rootData.saving = false;
							});
					};
					break;

				case 'administration.scripts':
					component = 'scripts';
					AdminSubCtrl.rootData.pageLoading = true;
					AdminSubCtrl.rootData.pageData = {
						scripts: []
					};

					$scope.$on(Scripts.events.deleted, function (eventObj, res) {
						_.pull(AdminSubCtrl.rootData.pageData.scripts, res);
					});

					AdminSubCtrl.rootData.editScript = function (script) {
						if (script && script.id) {
							$state.go('administration.script', { id: script.id });
						} else {
							$state.go('administration.script');
						}
					};

					AdminSubCtrl.rootData.deleteScript = function (script) {
						if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
							openModal('RemoveAlert', {
								title: 'default.script',
								body: 'confirm.removeScript',
								onClose: confirmed => {
									if (confirmed) {
										Scripts.delete(script);
									}
								}
							});
							return;
						}

						// eslint-disable-next-line promise/catch-or-return
						$upModal
							.open('warningConfirm', {
								title:
									$translate.instant('default.remove') +
									' ' +
									$translate.instant('default.script').toLowerCase(),
								body: 'confirm.removeScript',
								resolveTrue: 'default.remove',
								icon: 'fa-warning'
							})
							.then(function () {
								Scripts.delete(script);
							});
					};

					Scripts.list()
						.then(function (res) {
							AdminSubCtrl.rootData.pageData.scripts = res.data;
							AdminSubCtrl.rootData.pageLoading = false;
						})
						.catch(e => console.log('get scripts error', e));
					break;

				case 'administration.script':
					var initialReference;

					component = 'script';

					AdminSubCtrl.rootData.pageLoading = true;

					AdminSubCtrl.rootData.pageData = {
						missingDesc: false
					};

					var setInitialReference = function () {
						initialReference = Tools.LZString.compressToBase64(
							JSON.stringify(AdminSubCtrl.rootData.pageData.script)
						);
					};

					var setSaveVisible = function () {
						if (
							initialReference !==
							Tools.LZString.compressToBase64(JSON.stringify(AdminSubCtrl.rootData.pageData.script))
						) {
							AdminSubCtrl.rootData.saveVisible = true;
						} else {
							AdminSubCtrl.rootData.saveVisible = false;
						}
					};

					var validate = function () {
						if (
							!AdminSubCtrl.rootData.pageData.script.description ||
							!AdminSubCtrl.rootData.pageData.script.description.length
						) {
							AdminSubCtrl.rootData.pageData.missingDesc = true;
							return false;
						} else {
							AdminSubCtrl.rootData.pageData.missingDesc = false;
							return true;
						}
					};

					AdminSubCtrl.rootData.onChange = function (scriptObj) {
						AdminSubCtrl.rootData.pageData.script = scriptObj;
						setSaveVisible();
						if (AdminSubCtrl.rootData.pageData.missingDesc) {
							validate();
						}
						$safeApply($scope);
					};

					AdminSubCtrl.rootData.onSave = function () {
						AdminSubCtrl.rootData.saving = true;

						if (!validate()) {
							AdminSubCtrl.rootData.saving = false;
							return;
						}

						Scripts.save(AdminSubCtrl.rootData.pageData.script)
							.then(function () {
								reloadAngularIfScriptNeedScope(AdminSubCtrl.rootData.pageData.script);
								AdminSubCtrl.rootData.saving = false;
								setInitialReference();
								setSaveVisible();
								$state.go('administration.scripts');
							})
							.catch(e => console.log('save scripts error', e));
					};

					var init = function () {
						var script = _.find(AppService.getScripts(), function (o) {
							return o.id === _.parseInt($state.params.id);
						});
						if (!script) {
							script = Scripts.new();
						}
						AdminSubCtrl.rootData.pageData.script = script;
						setInitialReference();
						AdminSubCtrl.rootData.pageLoading = false;
					};

					init();
					break;

				case 'administration.userdefinedobjects':
					component = 'udos';
					metadata = AppService.getMetadata();

					AdminSubCtrl.rootData.pageData = {
						udos: metadata.params.UserDefinedObject,
						fieldCount: {
							1: AppService.getCustomFields('userDefined1').length,
							2: AppService.getCustomFields('userDefined2').length,
							3: AppService.getCustomFields('userDefined3').length,
							4: AppService.getCustomFields('userDefined4').length
						}
					};

					AdminSubCtrl.rootData.pageData.delete = function (udo) {
						userDefinedDefinition
							.delete(udo)
							.then(function (res) {
								if (res && !res.error) {
									var metadata = AppService.getMetadata();
									AdminSubCtrl.rootData.pageData.udos = metadata.params.UserDefinedObject;

									AppService.setCustomFields('userDefined' + udo.id, []);
									AdminSubCtrl.rootData.pageData.fieldCount[udo.id] = 0;

									var listViews = AppService.getListViews('userDefinedObject' + udo.id);

									_.each(listViews, function (listView) {
										// No need to remove default view
										if (!(typeof listView.id === 'string' && listView.id.length === 9)) {
											$scope.$emit('listView.deleted', listView);
										}
									});
								}
							})
							.catch(function (err) {
								console.log(err);
							});
					};

					AdminSubCtrl.rootData.onSave = function (udo) {
						userDefinedDefinition
							.save(udo)
							.then(function (res) {
								if (res && !res.error && res.data) {
									const metadata = AppService.getMetadata();
									AdminSubCtrl.rootData.pageData.udos = metadata.params.UserDefinedObject;

									const type = 'userDefinedObject' + udo.id;
									const listViews = AppService.getListViews(type);

									_.each(listViews, function (listView) {
										if (!(typeof listView.id === 'string' && listView.id.length === 32)) {
											listView.title =
												udo && udo.name && udo.name.length
													? udo.name
													: '<<' + $translate.instant('default.noName') + '>>';

											// 1 = campaign
											if (udo.link === 1) {
												listView.columns = ['campaign'];
											}
											// 4 = client
											else if (udo.link === 4) {
												listView.columns = ['client'];
											}
											// 6 = contact
											else if (udo.link === 6) {
												listView.columns = ['client', 'contact'];
											}

											$scope.$emit('listView.updated', listView);
										}
									});
								}
							})
							.catch(function (err) {
								console.log(err);
							});
					};
					break;

				case 'administration.automations':
					component = 'automations';
					break;

				case 'administration.salesboards':
					customerId = AppService.getCustomerId();
					component = 'salesboards';

					AdminSubCtrl.rootData.editSalesboard = function (salesboard) {
						var id = salesboard ? salesboard.id : 'new';
						$state.go('admin.salesboard', { id: id });
					};

					AdminSubCtrl.rootData.removeBoard = function (salesboard) {
						// create confirmation dialog
						if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
							openModal('RemoveAlert', {
								title: 'default.salesboard',
								body: 'admin.salesboard.alert.removeBoardText',
								onClose: confirmed => {
									if (confirmed) {
										// remove view from list
										_.pull(AdminSubCtrl.rootData.pageData.salesboards, salesboard);

										// remove from server
										ListViewService.delete('salesboard', salesboard, customerId);
									}
								}
							});
							return;
						}

						// eslint-disable-next-line promise/catch-or-return
						$upModal
							.open('warningConfirm', {
								title: '',
								body: 'filters.removeViewConfirm',
								resolveTrue: 'default.remove',
								icon: 'fa-warning'
							})
							.then(function () {
								// remove view from list
								_.pull(AdminSubCtrl.rootData.pageData.salesboards, salesboard);

								// remove from server
								ListViewService.delete('salesboard', salesboard, customerId);
							});
					};

					var boardViews = AppService.getListViews('salesboard');

					AdminSubCtrl.rootData.pageData = {
						salesboards: _.where(boardViews, { private: false, editable: true })
					};
					break;

				case 'administration.activeInvites':
					component = ActiveInvites;
					AdminSubCtrl.rootData.pageData = {
						invites: []
					};
					AdminSubCtrl.rootData.pageLoading = true;

					AdminSubCtrl.rootData.pageData.removeInvite = function (invite) {
						if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
							openModal('RemoveAlert', {
								title: 'admin.invite',
								body: 'confirm.removeInvite',
								onClose: confirmed => {
									if (confirmed) {
										User.removeInvite(invite.id)
											.then(function () {
												var i = _.findIndex(AdminSubCtrl.rootData.pageData.invites, {
													id: invite.id
												});
												if (i !== -1) {
													AdminSubCtrl.rootData.pageData.invites.splice(i, 1);
												}
											})
											.catch(e => console.log('remove userInvite error', e));
									}
								}
							});
							return;
						}

						// eslint-disable-next-line promise/catch-or-return
						$upModal
							.open('warningConfirm', {
								title:
									$translate.instant('default.remove') +
									' ' +
									$translate.instant('admin.invite').toLowerCase(),
								body: 'confirm.removeInvite',
								resolveTrue: 'default.remove',
								icon: 'fa-warning'
							})
							.then(function () {
								User.removeInvite(invite.id)
									.then(function () {
										var i = _.findIndex(AdminSubCtrl.rootData.pageData.invites, { id: invite.id });
										if (i !== -1) {
											AdminSubCtrl.rootData.pageData.invites.splice(i, 1);
										}
									})
									.catch(e => console.log('remove userInvite error', e));
							});
					};

					User.getInvites()
						.then(function (res) {
							AdminSubCtrl.rootData.pageData.invites = _.map(res.data, function (invite) {
								invite.link =
									window.location.origin +
									'/' +
									$state.href('invite') +
									'?customer=' +
									AppService.getCustomerId() +
									'&code=' +
									invite.id;
								return invite;
							});
							AdminSubCtrl.rootData.pageLoading = false;
						})
						.catch(e => console.log('get userInvites error', e));
					break;
				default:
					component = 'notFound';
					break;
			}

			AdminSubCtrl.rootData.pageComponent = component;
			AdminSubCtrl.rootData.useWebPack = useWebPack;
		};

		AppService.loadedPromise.then(init).catch(e => console.log('admin load error', e));
	}
]);
